import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DropdownEditMenu from '../../components/Dashboard/DropdownEditMenu'
import ResumeEventsChart from '../../components/Dashboard/ResumeEventsChart'
import DashboardConfigTaskModal from '../../components/Dashboard/DashboardConfigTaskModal'
import { useStateContext } from '../../components/contexts/ContextProvider'
import LoadContentSpinner from '../../components/Utils/LoadContentSpinner'
import axiosClient from '../../components/api/axiosClient'
import { formatDateToBar, formatNumber, formatPerformNumber } from '../../components/Utils/UtilsNumbersFormat'
import { formatPrice } from '../../components/Utils/UtilsPricesFormat'
import { useError } from '../../components/contexts/ErrorContext'

export default function HomeToDashboard() {

    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const { user, loading } = useStateContext();
    const { handleError } = useError();
    const [allTasksCompleted, setAllTasksCompleted] = useState(false);
    const [isAccountConfigured, setIsAccountConfigured] = useState(false);
    const [salesData, setSalesData] = useState(null); // État pour les événements
    const [loadingContent, setLoading] = useState(true); // État pour le chargement
    const [loadingDatasDash, setLoadingDatasDash] = useState(true); // État pour le chargement
    const [errorsDatasDash, setErrorsDatasDash] = useState(true); // État pour le chargement
    const [datasDash, setDatasDash] = useState(true); // État pour le chargement
    const [error, setError] = useState(null); // État pour gérer les erreurs
    const [openDescriptions, setOpenDescriptions] = useState([]);
    const [copiedLink, setCopiedLink] = useState(""); // Gère l'état de la copie
    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    const [loadingTasks, setLoadingTasks] = useState(true); // Ajout d'un état de chargement pour les tâches

    useEffect(() => {
        const fetchTasks = async () => {
            setLoadingTasks(true);
            try {
                const response = await axiosClient.get('/dashboard/tasks/getConfig');
                const stateAccount = response.data.account_state;
                setIsAccountConfigured(stateAccount);
                if (response.data.uncompletedTasks.length === 0) {
                    setAllTasksCompleted(true);
                } else {
                    setAllTasksCompleted(false);
                }
            } catch (error) {
                handleError("Erreur survenue lors de la récupération des configurations:", error);
                // console.error("Erreur Axios capturée :", error);
            } finally {
                setLoadingTasks(false);
            }
        };

        fetchTasks();
    }, []); // L'effet s'exécute au démarrage

    const fetchSalesStatistics = async () => {
        // Appel API pour récupérer les événements en cours de validation
        setLoading(true);
        setError(null);
        
        axiosClient.get('/dashboard/global/organizer/sales-statistics')
            .then(response => {
                setSalesData(response.data.data); // Mettez à jour la liste des événements
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    // Récupérer les messages spécifiques de l'erreur backend
                    setError(error.response.data);
                } else {
                    setError({message: 'Impossible de récupérer les statistiques des ventes.',});
                }
                setLoading(false); // Arrêtez le chargement même en cas d'erreur
            });
    };

    // Utilisez useEffect pour appeler fetchSalesStatistics au montage du composant
    useEffect(() => {
        fetchSalesStatistics();
    }, []);

    useEffect(() => {
        const fetchDatasDashboard = async () => {
            setLoadingDatasDash(true);
            setErrorsDatasDash(null);
            // Appel API pour récupérer les événements en cours de validation
            axiosClient.get('/dashboard/getDatas/recap/dashboard')
                .then(response => {
                    setDatasDash(response.data.data); // Mettez à jour la liste des événements
                    setLoadingDatasDash(false);
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        // Récupérer les messages spécifiques de l'erreur backend
                        setErrorsDatasDash(error.response.data);
                    } else {
                        setErrorsDatasDash({message: 'Impossible de récupérer les statistiques des ventes.',});
                    }
                    setLoadingDatasDash(false); // Arrêtez le chargement même en cas d'erreur
                });
        };

        fetchDatasDashboard();
    }, []); // L'effet s'exécute au démarrage

    if (loading || loadingTasks || loadingDatasDash) {
        return <LoadContentSpinner />;
    }

    const handleAllTasksCompleted = () => {
        setAllTasksCompleted(true);
    };

    // Fonction pour ouvrir le modal
    const openFormModal = () => {
        setIsTaskModalOpen(true);
    };

    // Fonction pour fermer le modal
    const closeFormModal = () => {
        setIsTaskModalOpen(false);
    };

    // Fonction pour gérer l'ouverture/fermeture de la description
    const toggleDescription = (index) => {
        setOpenDescriptions(openDescriptions.includes(index) ? [] : [index]);
    };

    const handleCopy = (link) => {
        navigator.clipboard.writeText(link).then(
          () => {
            setCopiedLink((prevState) => ({ ...prevState, [link]: true })); // Marque le lien comme copié
            setTimeout(() => {
              setCopiedLink((prevState) => ({ ...prevState, [link]: false })); // Réinitialise après 3 secondes
            }, 3000);
          },
          (err) => {
            // console.error("Erreur lors de la copie : ", err);
            alert("Échec de la copie du lien.");
          }
        );
    };
    
    const googlePlayLink = "https://play.google.com/store/apps/details?id=com.event.event_app";
    const appleStoreLink = "https://www.apple.com/store";
    
    const renderCopyIcon = (link) => {
        return copiedLink[link] ? (
          <svg className='text-green-500' viewBox="0 0 24 24" fill="currentColor" width={24} height={24}>
            <path d="M9.75 15.25 7 12.5 6 13.499 9.75 17.25 18 8.999 17 8z" />
          </svg>
        ) : (
            <svg
            viewBox="0 0 18 18"
            fill="currentColor"
            width={18}
            height={18}
            className="wds_1_146_2_ListItemAction__prefixIcon"
            data-hook="list-item-action-prefix-icon"
            >
            <path d="M14,4 C14.5522847,4 15,4.44771525 15,5 L15,12 C15,12.5522847 14.5522847,13 14,13 L13,13 L13,14 C13,14.5522847 12.5522847,15 12,15 L5,15 C4.44771525,15 4,14.5522847 4,14 L4,7 C4,6.44771525 4.44771525,6 5,6 L6,6 L6,5 C6,4.44771525 6.44771525,4 7,4 L14,4 Z M6,7 L5,7 L5,14 L12,14 L12,13 L7,13 C6.44771525,13 6,12.5522847 6,12 L6,7 Z M14,5 L7,5 L7,12 L14,12 L14,5 Z" />
            </svg>
        );
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {isAccountConfigured === 0 && (
                <div className="mb-6 gradient cvwie cosgb c33r0 cgnhv cb8zv cm84d csusu">
                    <div className="cqdkw cq5bq cqb5u">
                        <svg
                        width={44}
                        height={42}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                        <defs>
                            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ill-b">
                            <stop stopColor="#5bcfc5" offset="0%" />
                            <stop stopColor="#5bcfc5" offset="100%" />
                            </linearGradient>
                            <linearGradient x1="50%" y1="24.537%" x2="50%" y2="100%" id="ill-c">
                            <stop stopColor="#4634B1" offset="0%" />
                            <stop stopColor="#4634B1" stopOpacity={0} offset="100%" />
                            </linearGradient>
                            <path id="ill-a" d="m20 0 20 40-20-6.25L0 40z" />
                        </defs>
                        <g
                            transform="scale(-1 1) rotate(-51 -11.267 67.017)"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <mask id="ill-d" fill="#5bcfc5">
                            <use xlinkHref="#ill-a" />
                            </mask>
                            <use fill="url(#ill-b)" xlinkHref="#ill-a" />
                            <path
                            fill="url(#ill-c)"
                            mask="url(#ill-d)"
                            d="M20.586-7.913h25v47.5h-25z"
                            />
                        </g>
                        </svg>
                    </div>
                    {allTasksCompleted ? (
                        <div className="cm84d">

                            <div className="text-sm text-gray-700 ctv3j c1k3n c6f83">
                                <div className="c6f83">
                                Félicitations <b className="text-gray-900 dark:text-gray-100 c1k3n">{user.formatted_nom_complet}</b>{" "}
                                🎉, toutes vos configurations sont complètes !
                                </div>
                                
                                Vous êtes désormais prêt à publier vos événements sur notre application mobile <b>Events</b> 🤑.
                                A présent nous examinons votre compte organisateur et vous feront des suggestions a propos de vos informations si nécessaire. <br /> <br />
                                Ne perdez pas de temps, commencez dès maintenant à publier votre événement et à partager vos passions avec le monde ! 🌍
                            </div>
                            <div className="chvik">
                                <Link to='/dashboard/events/registration' className="text-sm text-sky-500 hover:text-sky-500 ceetm c1k3n">
                                Publier un événemen -&gt;
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className="cm84d">
                            <div className="text-sm text-gray-700 ctv3j c1k3n c6f83">
                                <div className="c6f83">
                                Bienvenue <b className="text-gray-900 dark:text-gray-100 c1k3n">{user.formatted_nom_complet}</b>{" "}
                                👋, voici votre tableau de bord.
                                </div>

                                Il manque deux ou trois configurations à finaliser rapidement afin que vous soyez apte à publier vos événements sur notre application mobile <b>Events</b> 🤑
                            </div>
                            <div className="chvik">
                                <button onClick={openFormModal} className="text-sm text-sky-500 hover:text-sky-500 ceetm c1k3n">
                                    Commencer -&gt;
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">Tableau de bord</h1>
                </div>

            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
                
                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
                    <div className="px-5 py-5">
                        <header className="flex justify-between items-start mb-2">
                        <h2 className="text-base text-gray-800 dark:text-gray-100 mb-2">Total des tickets</h2>
                        {/* Menu button */}
                        <DropdownEditMenu align="left" className="relative inline-flex max-w-full">
                            <li>
                            <Link to={'/dashboard/events/registration'} className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3">
                                Nouvel événement
                            </Link>
                            </li>
                            <li>
                            <Link to={'/dashboard/events/show-list'} className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3">
                                Mes événements
                            </Link>
                            </li>
                        </DropdownEditMenu>
                        </header>
                        <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase mb-1">Vendus</div>
                        {errorsDatasDash ? (
                            <span className='text-gray-800 dark:text-gray-100 font-bold text-xs'>...</span>
                        ) : (
                            <div className="flex items-start">
                                <div className="text-xl font-bold text-gray-800 dark:text-gray-100 mr-2">{formatPrice(datasDash.total_gross_revenue)}</div>
                                {/* <div className="text-sm font-medium text-green-700 px-1.5 bg-green-500/20 rounded-full">+49%</div> */}
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
                    <div className="px-5 py-5">
                        <header className="flex justify-between items-start mb-2">
                        <h2 className="text-base text-gray-800 dark:text-gray-100 mb-2">Revenus générés</h2>
                        {/* Menu button */}
                        <DropdownEditMenu align="left" className="relative inline-flex">
                            <li>
                            <Link to={'/dashboard/ventes/statistiques'} className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3">
                                Voir les chiffres
                            </Link>
                            </li>
                            <li>
                            <Link to={'/dashboard/factures/events/invoices'} className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3">
                                Mes factures
                            </Link>
                            </li>
                        </DropdownEditMenu>
                        </header>
                        <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase mb-1">Ce mois</div>
                        {errorsDatasDash ? (
                            <span className='text-gray-800 dark:text-gray-100 font-bold text-xs'>...</span>
                        ) : (
                            <div className="flex items-start">
                                <div className="text-xl font-bold text-gray-800 dark:text-gray-100 mr-2">{formatPrice(datasDash.total_gross_revenue_this_month)}</div>
                                {/* <div className="text-sm font-medium text-red-700 px-1.5 bg-red-500/20 rounded-full">-14%</div> */}
                            </div>
                        )}
                    </div>
              
                </div>

                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
                    <div className="px-5 py-5">
                        <header className="flex justify-between items-start mb-2">
                        <h2 className="text-base text-gray-800 dark:text-gray-100 mb-2">Tickets restants</h2>
                        </header>
                        <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase mb-1">Des événements actifs</div>
                        {errorsDatasDash ? (
                            <span className='text-gray-800 dark:text-gray-100 font-bold text-xs'>...</span>
                        ) : (

                            <div className="flex items-start">
                                <div className="text-xl font-bold text-gray-800 dark:text-gray-100 mr-2">{formatPerformNumber(datasDash.total_tickets_remaining)}</div>
                                {/* <div className="text-sm font-medium text-green-700 px-1.5 bg-green-500/20 rounded-full">+49%</div> */}
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-span-full xl:col-span-7 bg-white dark:bg-gray-800 shadow-sm rounded-xl h-fit">
                    <header className="flex items-center cghq3 cbv37 ctv3r cx3hp cz8qb">
                        <h2 className="text-gray-800 dark:text-gray-100 cgulq">Résumé des Événements</h2>
                    </header>
                    <div className="cx3hp cwn3v ">
                        {errorsDatasDash ? (
                            <div className="w-full h-full flex justify-center items-center cweej">
                                <div className="cydwr clbq0">
                                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path
                                                className="text-gray-500 co1wq"
                                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="dark:text-gray-400 ckbo4"
                                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="cmpw7 cdqku"
                                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                    Erreur de récupération
                                    </h4>
                                    <div className="mb-6 text-sm break-all">
                                    {errorsDatasDash.message}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-wrap ccue7">
                                    {/* Unique Visitors */}
                                    <div className="flex items-center cuvgf">
                                        <div className="clxab">
                                            <div className="flex items-center">
                                                <div className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mr-2">
                                                {formatNumber(datasDash.all_events)}
                                                </div>
                                                {/* <div className="text-sm cdjj4 c1k3n">+49%</div> */}
                                            </div>
                                            <div className="text-sm text-gray-500 dark:text-gray-400">
                                                Total événement
                                            </div>
                                        </div>
                                        <div
                                        className="hidden dark:bg-gray-700 cvwbh cyqqd clxab cn8zk cue4z"
                                        aria-hidden="true"
                                        />
                                    </div>
                                    {/* Total Pageviews */}
                                    <div className="flex items-center cuvgf">
                                        <div className="clxab">
                                        <div className="flex items-center">
                                            <div className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mr-2">
                                            {formatNumber(datasDash.unpublished_events)}
                                            </div>
                                            {/* <div className="text-sm cdjj4 c1k3n">+7%</div> */}
                                        </div>
                                        <div className="text-sm text-gray-500 dark:text-gray-400">
                                            Attente de validation
                                        </div>
                                        </div>
                                        <div
                                        className="hidden dark:bg-gray-700 cvwbh cyqqd clxab cn8zk cue4z"
                                        aria-hidden="true"
                                        />
                                    </div>
                                    {/* Bounce Rate */}
                                    <div className="flex items-center cuvgf">
                                        <div className="clxab">
                                            <div className="flex items-center">
                                                <div className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mr-2">
                                                {formatNumber(datasDash.published)}
                                                </div>
                                                <div className="flex items-center justify-center w-3 h-3 cm84d chfzq">
                                                    <div className='inline-flex rounded-full cu2fn cattr cr9x4 cqdkw cav8x c6btv' />
                                                    <div className='inline-flex rounded-full cg902 cm84d csh1p co6q2' />
                                                    
                                                </div>
                                            </div>
                                            <div className="text-sm text-gray-500 dark:text-gray-400">
                                                Publié
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ResumeEventsChart all={datasDash.all_events} published={datasDash.published} unpublished={datasDash.unpublished_events} error={errorsDatasDash} />
                            </>
                        )}
                    </div>
                    
                </div>
                <div className='flex flex-col col-span-full sm:col-span-6 xl:col-span-5 bg-white dark:bg-gray-800 shadow-sm rounded-xl'>
                    <header className="flex items-center cghq3 cbv37 ctv3r cx3hp cz8qb">
                        <h2 className="text-gray-800 dark:text-gray-100 cgulq">L'appli Events</h2>
                    </header>
                    <div className="cx3hp cwn3v ">
                        {/* <div className="w-full h-full flex justify-center items-center cweej">
                            
                        </div> */}
                        <div className="flex cetff cav8x">
                            {/* Live visitors number */}
                            <div className="px-5 py-3">
                                <div className="flex items-center">
                                {/* Red dot */}
                                <div
                                    className="flex items-center justify-center relative mr-3"
                                    aria-hidden="true"
                                >
                                    <img
                                    srcSet={`${imagePath}images/hzft.avif`}
                                    className="object-cover w-60 h-full"
                                    alt="nc-imgs"
                                    />
                                    <img
                                    srcSet={`${imagePath}images/app-icons-grouped.png`}
                                    className="object-cover w-16 h-full"
                                    alt="nc-imgs"
                                    />
                                </div>
                                </div>
                            </div>
                            {/* Table */}
                            <div className="cbw8w cqynh cmyi8">
                                <div className="cocyr">

                                <div className='mb-4'>
                                    <label className="block text-xs c1k3n cu6vl">
                                        Lien Google Play Store
                                    </label>
                                    <div className="cm84d">
                                        <input className="!text-xs !pr-24 caqf9 c6btv cdo3e select-none" 
                                            readOnly value={googlePlayLink} />
                                        <div className="flex items-center cimu8 cqdkw cini7">
                                            <button onClick={() => handleCopy(googlePlayLink)} className="text-sm cmpw7 cdqku c1k3n pr-2 font-semibold text-gray-800">
                                                {renderCopyIcon(googlePlayLink)}
                                            </button>
                                            <span className="text-xs border-gray-300 border-l font-semibold text-gray-800 cmpw7 cdqku c1k3n pr-3 pl-2">
                                                <a href={googlePlayLink} target="_blank" rel="noopener noreferrer">Visiter</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-xs c1k3n cu6vl">
                                    Lien Apple Store
                                    </label>
                                    <div className="cm84d">
                                        <input className="!text-xs !pr-24 caqf9 c6btv cdo3e select-none" 
                                            readOnly value={appleStoreLink} />
                                        <div className="flex items-center cimu8 cqdkw cini7">
                                            <button onClick={() => handleCopy(appleStoreLink)} className="text-sm cmpw7 cdqku c1k3n pr-2 font-semibold text-gray-800">
                                                {renderCopyIcon(appleStoreLink)}
                                                {/* <svg
                                                    viewBox="0 0 18 18"
                                                    fill="currentColor"
                                                    width={18}
                                                    height={18}
                                                    className="wds_1_146_2_ListItemAction__prefixIcon"
                                                    data-hook="list-item-action-prefix-icon"
                                                >
                                                <path d="M14,4 C14.5522847,4 15,4.44771525 15,5 L15,12 C15,12.5522847 14.5522847,13 14,13 L13,13 L13,14 C13,14.5522847 12.5522847,15 12,15 L5,15 C4.44771525,15 4,14.5522847 4,14 L4,7 C4,6.44771525 4.44771525,6 5,6 L6,6 L6,5 C6,4.44771525 6.44771525,4 7,4 L14,4 Z M6,7 L5,7 L5,14 L12,14 L12,13 L7,13 C6.44771525,13 6,12.5522847 6,12 L6,7 Z M14,5 L7,5 L7,12 L14,12 L14,5 Z" />
                                                </svg> */}
                                            </button>
                                            <span className="text-xs border-gray-300 border-l font-semibold text-gray-800 cmpw7 cdqku c1k3n pr-3 pl-2">
                                                <a href={appleStoreLink} target="_blank" rel="noopener noreferrer">Visiter</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>

                    </div>
                </div>
                <div className="col-span-full xl:col-span-12 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
                    <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60">
                        <h2 className="font-semibold text-gray-800 dark:text-gray-100">10 Événements récents </h2>
                    </header>
                    <div className="p-3">
                        {/* Table */}
                        {loadingContent ? (
                            <LoadContentSpinner />
                        ) : (
                            error ? (
                                <div className="w-full h-full flex justify-center items-center cweej">
                                    <div className="cydwr clbq0">
                                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                            <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                                <path
                                                    className="text-gray-500 co1wq"
                                                    d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    className="dark:text-gray-400 ckbo4"
                                                    d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    className="cmpw7 cdqku"
                                                    d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </div>
                                        <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                        Erreur de récupération
                                        </h4>
                                        <div className="mb-6 text-sm">
                                        {error.message}
                                        </div>
                                    </div>
                                </div>
                            ) : (

                                salesData.eventsList.length > 0 ? (
    
                                    <div className="overflow-x-auto">
                                        <table className="table-auto w-full dark:text-gray-300">
                                            {/* Table header */}
                                            <thead className="text-xs uppercase text-gray-400 dark:text-gray-500 bg-gray-50 dark:bg-gray-700 dark:bg-opacity-50 rounded-sm">
                                                <tr>
                                                    <th className="whitespace-nowrap p-2">
                                                    <div className="font-semibold text-left w-fit">A Propos</div>
                                                    </th>
                                                    <th className="whitespace-nowrap p-2">
                                                    <div className="font-semibold text-center">Total en vente</div>
                                                    </th>
                                                    <th className="whitespace-nowrap p-2">
                                                    <div className="font-semibold text-center">Vendus</div>
                                                    </th>
                                                    <th className="whitespace-nowrap p-2">
                                                    <div className="font-semibold text-center">Restants</div>
                                                    </th>
                                                    <th className="whitespace-nowrap p-2">
                                                    <div className="font-semibold text-left">statut</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {/* Table body */}
                                            <tbody className="text-sm font-medium divide-y divide-gray-100 dark:divide-gray-700/60">
                                            {/* Row */}
                                            {salesData.eventsList.map(event => (
                                                <tr key={event.id}>
                                                    <td className="inline-block p-2">
                                                        <div className="flex items-center">
                                                            <img 
                                                                src={`data:image/jpeg;base64,${event.event_banner}`}
                                                                alt={event.name} className="w-16 rounded-lg shrink-0 mr-2 sm:mr-3"
                                                            />
                                                            <div className="text-gray-800 dark:text-gray-100 truncate w-72">{event.event_name} Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae numquam veniam sunt fugit adipisci nemo a, dolor blanditiis ut voluptates?</div>
                                                        </div>
                                                    </td>
                                                    <td className="p-2">
                                                    <div className="text-center">{formatNumber(event.total_tickets)}</div>
                                                    </td>
                                                    <td className="p-2">
                                                    <div className="text-center text-green-500">{formatPrice(event.tickets_sold)}</div>
                                                    </td>
                                                    <td className="p-2">
                                                    <div className="text-center">{formatNumber(event.tickets_remaining)}</div>
                                                    </td>
                                                    <td className="p-2">
                                                        {event.annule ? (
                                                            <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                                                Annulé
                                                            </div>
                                                        ) : (
    
                                                            event.status === 'Disponible' ? (
                                                                <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n cdw1w c9hxi">
                                                                    {event.status}
                                                                </div>
                                                            ) : event.status === 'Disabled' ? (
                                                                <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                                                    Désactivé
                                                                </div>
                                                            ) : (
                                                                <div className="inline-flex text-xs rounded-full clksd cydwr c1k3n cdw1w c9hxi">
                                                                    {event.status}
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="w-full h-full flex justify-center items-center cweej">
                                        <div className="cydwr clbq0">
                                            <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                                <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                                    <path
                                                        className="text-gray-500 co1wq"
                                                        d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        className="dark:text-gray-400 ckbo4"
                                                        d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        className="cmpw7 cdqku"
                                                        d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </div>
                                            <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                            Aucune Données
                                            </h4>
                                        </div>
                                    </div>
                                )
                            )
                        )}
                    </div>
                </div>

                <div className="col-span-full xl:col-span-12 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
                    <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60">
                        <h2 className="font-semibold text-gray-800 dark:text-gray-100">10 Paiements récents </h2>
                    </header>
                    <div className="p-3">
                        {errorsDatasDash ? (
                            <div className="w-full h-full flex justify-center items-center cweej">
                                <div className="cydwr clbq0">
                                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path
                                                className="text-gray-500 co1wq"
                                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="dark:text-gray-400 ckbo4"
                                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="cmpw7 cdqku"
                                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                    Erreur de récupération
                                    </h4>
                                    <div className="mb-6 text-sm break-all">
                                    {errorsDatasDash.message}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            datasDash.orders.length > 0 ? (

                                <div className="overflow-x-auto">
                                    <table className="table-auto w-full dark:text-gray-300">
                                        {/* Table header */}
                                        <thead className="text-xs uppercase text-gray-400 dark:text-gray-500 bg-gray-50 dark:bg-gray-700 dark:bg-opacity-50 rounded-sm">
                                            <tr>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq c2hoo">##</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq c2hoo">Date d'achat</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq c2hoo">Acheteur</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq c2hoo">Prix</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq c2hoo">Statut</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq">Quantité</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <span className="cn8jz">Menu</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-sm font-medium divide-y divide-gray-100 dark:divide-gray-700/60">
                                            {/* Row */}
                                            {datasDash.orders.map((order, index) => (

                                                <React.Fragment key={index}>

                                                <tr>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!order.transaction_id ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="flex items-center text-gray-800">
                                                                <div className="c740r c1k3n">#{order.transaction_id.slice(-6)}</div>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!order.date_payment ? (
                                                            <>...</>
                                                        ) : (
                                                            <div>{formatDateToBar(order.date_payment)} </div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!order.get_datas_to_order_customer.username ? (
                                                            <>...</>
                                                        ) : (
                                                            <Link to={`/dashboard/achats/tickets/clients/profil/p/${order.get_datas_to_order_customer.id}`}>

                                                                <div className="text-gray-800 dark:text-gray-100 c1k3n">
                                                                {order.get_datas_to_order_customer.username}
                                                                </div>
                                                            </Link>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!order.total_price ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="cdjj4 c1k3n c2hoo">{formatPrice(order.total_price)}</div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!order.status ? (
                                                            <>...</>
                                                        ) : (
                                                            order.status === 'ACCEPTED' ? (

                                                                <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n ch4gv cdw1w">
                                                                    {order.status}
                                                                </div>
                                                            ) : order.status === 'REFUSED' ? (
                                                                
                                                                <div className='inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n ch4gv cdw1w'>
                                                                    {order.status}
                                                                </div>
                                                                
                                                            ) : (
                                                                <div className="inline-flex text-xs rounded-full cfts0 c5px7 cydwr c1k3n ch4gv cdw1w">
                                                                    {order.status}
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!order.quantity ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="cydwr">{formatNumber(order.quantity)}</div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 cn8zk c9hxi c72q5">
                                                        <div className="flex items-center">
                                                            <button
                                                            className="bg-white border-2 border-gray-50 rounded-full text-gray-900 font-bold cxxol c5vqk c3e4j cg12x cmpw7 cdqku c98dn"
                                                            aria-expanded={openDescriptions.includes(index) ? "true" : "false"}
                                                            onClick={() => toggleDescription(index)}
                                                            >
                                                            <span className="cn8jz">Menu</span>
                                                            <svg className="cbm9w cue4z cmwfi" viewBox="0 0 32 32">
                                                                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                                                            </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {openDescriptions.includes(index) && (
                                                    <tr id="description-01">
                                                        <td colSpan={10} className="cyjcc cgn91 c9hxi c72q5">
                                                        <div className="bg-sidebar-linkGroup rounded-md animate flex items-center flex-wrap -m-1 dark:text-gray-400 coulr c1f2y c9j8s">
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                {!order.pivot_to_order.findthislabel.title ? (
                                                                    <>...</>
                                                                ) : (
                                                                    order.pivot_to_order.findthislabel.title
                                                                )}
                                                                </h3>
                                                                <div>Type de Ticket</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">·</div>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                {!order.payment_method ? (
                                                                    <>...</>
                                                                ) : (
                                                                    order.payment_method
                                                                )}
                                                                </h3>
                                                                <div>Payé par</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">·</div>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                {!order.get_datas_to_order_customer.adresse ? (
                                                                    <>...</>
                                                                ) : (
                                                                    order.get_datas_to_order_customer.adresse
                                                                )}
                                                                </h3>
                                                                <div>Adresse</div>
                                                            </div>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                )}
                                                </React.Fragment>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="w-full h-full flex justify-center items-center cweej">
                                    <div className="cydwr clbq0">
                                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                            <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                                <path
                                                    className="text-gray-500 co1wq"
                                                    d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    className="dark:text-gray-400 ckbo4"
                                                    d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    className="cmpw7 cdqku"
                                                    d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </div>
                                        <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                        Aucune Données
                                        </h4>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
            {/* Modal to add card */}
            <DashboardConfigTaskModal 
                onAllTasksCompleted={handleAllTasksCompleted}
                isTaskModalOpen={isTaskModalOpen}
                closeModal={closeFormModal}
            />
        </div>
    )
}
