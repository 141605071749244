export const formatNumber = (number) => {
    return new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(number);
};

export const formatPerformNumber = (number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + 'M'; // Millions
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K'; // Milliers
    }
    return number.toString(); // Pas de format pour les nombres inférieurs à 1000
};

export const formatDateToBar = (dateString) => {
  const date = new Date(dateString); // Convertir la chaîne en objet Date
  const day = String(date.getDate()).padStart(2, '0'); // Ajouter un 0 si nécessaire
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
  const year = date.getFullYear(); // Obtenir l'année complète

  return `${day}/${month}/${year}`;
};