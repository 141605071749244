import React from 'react'
import FormattedDate from '../../Utils/FormattedDate'
import { formatPrice } from '../../Utils/UtilsPricesFormat'
import { formatNumber } from '../../Utils/UtilsNumbersFormat'

export default function GeneralOrdersSection({ data }) {
    return (
        <div className="bg-white c2vpa c1hly c5vqk cgd7w cmxzb c41yb">
            <section>
                <h3 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl">
                Généralité sur les ventes
                </h3>
                <ul>
                    <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                        {/* Left */}
                        <div className="text-sm text-gray-800 dark:text-gray-100">
                        Tickets Vendus
                        </div>
                        {/* Right */}
                        <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                            <span className="chfzq font-semibold">{formatNumber(data.ticketPaidQt)} sur {formatNumber(data.ticketAvailableQt)}</span>
                        </div>
                    </li>
                    <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                        {/* Left */}
                        <div className="text-sm text-gray-800 dark:text-gray-100">
                        Tickets Restants
                        </div>
                        {/* Right */}
                        <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                            <span className="chfzq font-semibold">{formatNumber(data.ticketremainingQt)}</span>
                        </div>
                    </li>
                    <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                        {/* Left */}
                        <div className="text-sm text-gray-800 dark:text-gray-100">
                        Tickets mis en vente
                        </div>
                        {/* Right */}
                        <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                            {data.eventGlobalSta.pivots ? (
                                data.eventGlobalSta.pivots.map(ticket => (

                                    <span key={ticket.id} className="inline-flex items-center justify-center font-semibold text-sm rounded-lg border-2 border-gray-200 text-gray-800 dark:text-gray-400 cc0oq cghq3 cspbm c2vpa cxxol cdzfq c5vqk px-3 py-1 mx-2">
                                        {ticket.findthislabel.title}<span className="mx-1 cdqku">-&gt;</span> {formatPrice(ticket.prix_unitaire)}
                                    </span>
                                ))
                            ) : (
                                'AUCUN'
                            )}
                            
                        </div>
                    </li>
                    <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                        {/* Left */}
                        <div className="text-sm text-gray-800 dark:text-gray-100">
                        Revenu Total
                        </div>
                        {/* Right */}
                        <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                            <span className="chfzq font-semibold">{formatPrice(data.ticketPaidAmount)}</span>
                        </div>
                    </li>
                    
                    <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                        {/* Left */}
                        <div className="text-sm text-gray-800 dark:text-gray-100">
                        Période de Vente
                        </div>
                        {/* Right */}
                        <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                            <span className="chfzq font-semibold"><FormattedDate date={data.eventGlobalSta.created_at} /> - {!data.eventGlobalSta.cloture_vente ? (<>Non Défini</>) : (<FormattedDate date={data.eventGlobalSta.cloture_vente} />)}</span>
                        </div>
                    </li>
                    <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                        {/* Left */}
                        <div className="text-sm text-gray-800 dark:text-gray-100">
                        Statut actuel de l'Événement
                        </div>
                        {/* Right */}
                        <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                            {data.eventGlobalSta.annule ? (
                                <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi ch4gv c1iho cwn3v">
                                    Annulé
                                </div>
                            ) : (

                                data.eventGlobalSta.status === 'Disponible' ? (
                                    <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n cdw1w c9hxi ch4gv c1iho cwn3v">
                                        {data.eventGlobalSta.status}
                                    </div>
                                ) : data.eventGlobalSta.status === 'Disabled' ? (
                                    <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi ch4gv c1iho cwn3v">
                                        Désactivé
                                    </div>
                                ) : (
                                    <div className="inline-flex text-xs rounded-full clksd cydwr c1k3n cdw1w c9hxi ch4gv c1iho cwn3v">
                                        {data.eventGlobalSta.status}
                                    </div>
                                )
                            )}
                            
                        </div>
                    </li>
                    <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                        {/* Left */}
                        <div className="text-sm text-gray-800 dark:text-gray-100">
                        Titre
                        </div>
                        {/* Right */}
                        <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                            <span className="chfzq font-semibold">{data.eventGlobalSta.event_title}</span>
                        </div>
                    </li>
                    <li className="cghq3 cfe7d cq3sl clgee c72q5">
                        {/* Left */}
                        <div className="text-sm text-gray-800 dark:text-gray-100">
                        Date d'enregistrement
                        </div>
                        {/* Right */}
                        <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                            <span className="chfzq font-semibold">
                                <FormattedDate date={data.eventGlobalSta.created_at} />
                            </span>
                        </div>
                    </li>
                </ul>
            </section>

        </div>
    )
}
