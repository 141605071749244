import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useStateContext } from '../../components/contexts/ContextProvider';
import LoadContentSpinner from '../../components/Utils/LoadContentSpinner';

export default function MainPage() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Devenir Organisateur événementiel - Events";
        
    }, []);

    const { loading } = useStateContext();
    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    if (loading) {
        return <LoadContentSpinner />;
    }

    return (
        <div className='bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'>
            <Helmet>
                <meta name="description" content="Bienvenue sur la page d'accueil dédiée aux organisateurs de Events. Publiez, gérez et promouvez vos événements facilement avec notre plateforme intuitive. Rejoignez-nous pour atteindre un large public et maximiser vos ventes de billets." />
                <meta 
                    name="keywords" 
                    content="organisateurs d'événements, publication d'événements, gestion d'événements, promotion d'événements, vente de billets, Events" 
                />
            </Helmet>
            <div className="nc-BgGlassmorphism absolute inset-x-0 md:top-10 xl:top-40 min-h-0 pl-20 py-24 flex overflow-hidden z-0" data-nc-id="BgGlassmorphism">
            
                <span className="block bg-[#5bcfc5] w-72 h-72 rounded-full mix-blend-multiply filter blur-3xl opacity-5 lg:w-96 lg:h-96" />
                <span className="block bg-[#04868b] w-72 h-72 -ml-20 mt-40 rounded-full mix-blend-multiply filter blur-3xl opacity-5 lg:w-96 lg:h-96 nc-animation-delay-2000" />
            </div>
            <div className="container px-1 sm:px-4 h-fit mb-24">
                <div className="nc-SectionHero3 relative mt-10">
                    <div className="relative z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-4xl mx-auto space-y-4 lg:space-y-3 xl:space-y-5">
                        <span className="sm:text-md md:text-md font-semibold text-gray-900">
                        Tous les hôtes, gestionnaires et organisateurs d’événements !
                        </span>
                        <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-5xl">
                        <span className='underlined'>Réduisez le stress</span> des ventes de vos tickets événementiels de plus de 60% grâce à <span className='underlined'>Events</span>
                        </h2>
                        <a href={"/dashboard/events/registration"} className="nc-Button !mt-10 relative h-auto inline-flex items-center justify-center transition-colors text-sm sm:text-base lg:text-lg font-medium px-6 py-2 lg:px-8 lg:py-2 rounded-lg ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 focus:outline-none focus:ring-offset-2 dark:focus:ring-offset-0">
                            Commencer A Publier
                        </a>
                    </div>
                </div>
                
            <div className="relative grid grid-cols-1 gap-10 p-4 lg:grid-cols-2 lg:gap-16 !mt-24 !mb-10">
                <div className="flex-shrink-0 lg:mt-0 lg:pl-8 sm:mx-8">
                    <h2 className="font-semibold text-4xl mt-3 mb-4">
                    Ne laissez pas votre gestion d’événements sombrer dans l’ordinaire – Passez à la vitesse supérieure <span className='underlined'>avec Events !</span>
                    </h2>
                    <span className="text-gray-700 dark:text-gray-300">
                    Voici quelques signes qui montrent que votre système de gestion d’événements est trop basique ou obsolète.
                    </span>
                    <div className="w-14 border-b border-gray-200 dark:border-gray-700 mt-3" />
                    <nav className="space-y-4 mb-8 mt-3">
                        <li className="flex items-center">
                            <span className="mr-4 inline-flex flex-shrink-0 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    data-slot="icon"
                                    className="w-5 h-5 text-red-600 ml-3"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                                        clipRule="evenodd"
                                    />
                                </svg>

                            </span>
                            <span className="text-gray-700 font-semibold dark:text-gray-300">
                            Grosse coupe de billet
                            </span>
                        </li>
                        <li className="flex items-center">
                            <span className="mr-4 inline-flex flex-shrink-0 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    data-slot="icon"
                                    className="w-5 h-5 text-red-600 ml-3"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                                        clipRule="evenodd"
                                    />
                                </svg>

                            </span>
                            <span className="text-gray-700 font-semibold dark:text-gray-300">
                            Sites Web et inscriptions non professionnels
                            </span>
                        </li>
                        <li className="flex items-center">
                            <span className="mr-4 inline-flex flex-shrink-0 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    data-slot="icon"
                                    className="w-5 h-5 text-red-600 ml-3"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                                        clipRule="evenodd"
                                    />
                                </svg>

                            </span>
                            <span className="text-gray-700 font-semibold dark:text-gray-300">
                            Trop d’outils pour un seul événement
                            </span>
                        </li>
                        <li className="flex items-center">
                            <span className="mr-4 inline-flex flex-shrink-0 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    data-slot="icon"
                                    className="w-5 h-5 text-red-600 ml-3"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                                        clipRule="evenodd"
                                    />
                                </svg>

                            </span>
                            <span className="text-gray-700 font-semibold dark:text-gray-300">
                            Problèmes de sécurité des données
                            </span>
                        </li>
                    </nav>
                </div>
                <div
                    className="flex-grow w-full"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <img
                        className="max-w-full sm:!max-w-sm h-fit rounded-xl shadow-md"
                        alt=""
                        loading="lazy"
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent",  }}
                        srcSet="https://cdn.prod.website-files.com/61cee5eb4d566d3471eca114/65c36609c29a3642a123ff48_branding.png"
                        // src={`${imagePath}images/Mobile_pay.png`}
                    />
                </div>
            </div>

            <div className="relative grid grid-cols-1 gap-10 p-4 lg:grid-cols-2 lg:gap-16 !mt-10 !mb-10">
                <div
                    className="flex-grow w-full"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <img
                        className="max-w-full sm:!max-w-sm h-fit shadow-md rounded-xl"
                        alt=""
                        loading="lazy"
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent",  }}
                        srcSet="https://cdn.prod.website-files.com/64d5f875c62eddf43295cf7a/654427bf670484c2bbe83dc9_Rec-0-p-800.png"
                        // src={`${imagePath}images/Mobile_pay.png`}
                    />
                </div>
                <div className="flex-shrink-0 lg:mt-0 lg:pl-8 sm:mx-8">
                    <h2 className="font-semibold text-4xl mt-3 mb-4">
                        Faites de votre Prochaine planification d’événements une réussite <span className='underlined'>grâce à Events</span>
                    </h2>
                    <span className="text-gray-700 dark:text-gray-300">
                        Avec Events, chaque détail de la planification devient simple, efficace et sans stress. 
                        De la création de l’événement à la gestion des inscriptions en passant par la promotion, 
                        notre plateforme tout-en-un vous offre les outils nécessaires pour garantir le succès de 
                        votre prochain événement. Gagnez du temps, 
                        réduisez les complications et offrez à vos participants une expérience mémorable !
                    </span>
                </div>
                
            </div>

            <div className="">
                <div className="nc-SectionHowItWork" style={{marginBottom: '3rem'}}>
                    <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-center justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50" style={{textAlign: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <div className="text-center w-full max-w-2xl mx-auto">
                            <h2 className="text-3xl md:text-4xl font-semibold">Pourquoi Choisir Events pour Publier Votre Événement ?</h2>
                        </div>
                        <span className="mt-2 px-2 md:mt-3 font-normal block text-base sm:text-lg text-gray-700 dark:text-neutral-400 w79">
                            
                            Choisir Events, c'est opter pour une plateforme fiable qui facilite la gestion de vos événements, rejoignez-nous et transformez chaque événement en une expérience mémorable.
                        </span>
                    </div>
                </div>

                <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
                    <div className="block flex-grow mb-2 lg:mb-0">
                        <div className="lg:sticky lg:top-2">
                            <div className="w-full items-center space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
                                <img
                                    className="inset-0 w-full h-full object-cover"
                                    srcSet={`${imagePath}images/Events.avif`}
                                    alt="Image illustration"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
                        <div className="prose">
                            <ol>
                                <li>
                                    <h2 className="text-xl font-semibold">Flexibilité de Publication</h2>
                                    <span className="block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                        Découvrez la liberté de gérer vos événements comme bon vous semble avec notre application de réservation de tickets. Que ce soit pour des concerts, des conférences ou des spectacles (etc ...) notre plateforme vous permet de publier et de personnaliser vos événements facilement et rapidement. <br /><br /> Profitez d'une interface intuitive et d'outils puissants pour atteindre votre audience et maximiser vos ventes en toute simplicité. 
                                        Faites de chaque événement un succès avec notre solution flexible et adaptée à tous vos besoins. 
                                        <a href={"/dashboard/events/registration"} className="font-medium text-primary-6000">
                                            &nbsp;&nbsp;Commencer A Publier Maintenant
                                        </a>
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Publication Facile et Rapide</h2>
                                    <span className="block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                        Simplifiez la mise en ligne de vos événements avec notre interface intuitive. En quelques clics, votre événement est publié et prêt à recevoir des réservations.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Contrôlez vos ventes : Choisissez votre date de clôture et recevez vos paiements rapidement !</h2>
                                    <span className="block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                        En tant qu'organisateur d'événements, nous comprenons l'importance de la flexibilité et de la maîtrise 
                                        de vos ventes. C'est pourquoi, avec notre plateforme Events, 
                                        nous vous offrons la possibilité de choisir vous-même la date de clôture de vos ventes de billets.
                                    </span>
                                    <span className="listingSection__wrap li-style block mt-2 mb-5 text-gray-700 dark:text-gray-400">
                                        Cela signifie que vous pouvez décider quand vous souhaitez arrêter les ventes et 
                                        <a heref={"/dashboard"} className="font-medium text-green-800"> commencer à recevoir vos paiements</a>. 
                                        Que vous souhaitiez clôturer les ventes bien avant l'événement pour mieux planifier ou continuer les ventes jusqu'à la dernière minute pour maximiser vos revenus, la décision vous appartient entièrement.
                                    </span>
                                    <span className="listingSection__wrap li-style block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                        Avec cette fonctionnalité, vous avez le contrôle total sur votre trésorerie et pouvez planifier en toute confiance. 
                                        Recevez vos paiements rapidement après la date de clôture choisie et gérez vos finances avec plus de précision et de sérénité. <br /><br />
                                        <a heref={"/create-account"} className="font-medium text-green-800">Rejoignez la communauté des organisateurs d'événements</a> qui profitent déjà de cette flexibilité sur Events et optimisez vos ventes dès aujourd'hui!
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Notre Application</h2>
                                    <span className="block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                        Nous avons développé pour vous une solution fiable et robuste dans une application mobile. Changez de perspective avec notre application de reservation pour tous types d'événement.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Gestion Intégrée des Billets</h2>
                                    <span className="block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                        Suivez les ventes de billets en temps réel et gérez les entrées facilement. Vous avez accès à toutes les informations nécessaires pour assurer le bon déroulement de votre événement.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Paiements Sécurisés et Fiables</h2>
                                    <span className="block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                    Bénéficiez d'un système de paiement sécurisé pour vous et vos participants. Nos technologies avancées garantissent la sécurité de chaque transaction, vous offrant ainsi une tranquillité d'esprit totale. Recevez vos fonds rapidement grâce à des versements automatisés et transparents, simplifiant la gestion financière de votre événement.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Validation Rapide des Tickets</h2>
                                    <span className="block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                        Nous utilisons notre système de scan pour vérifier les tickets (QR Code) des participants. La validation se fait en quelques secondes, offrant une efficacité bien supérieure à celle des tickets papiers. Cela réduit non seulement les files d'attente mais améliore également l'expérience globale des participants.
                                    </span>
                                </li>

                                <li>
                                    <h2 className="text-xl font-semibold">Support Dédié 24h/24 - 7j/7</h2>
                                    <span className="block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                        Profitez d'une assistance personnalisée pour répondre à toutes vos questions et vous accompagner dans l'organisation de votre événement. Notre équipe est à votre disposition pour vous aider à chaque moment. Que ce soit pour des conseils sur la mise en ligne de votre événement ou pour une assistance technique, nous sommes là pour vous.
                                    </span>
                                </li>
                            </ol>
                            <span className="listingSection__wrap block mt-2 mb-10 text-gray-700 dark:text-gray-400">
                                En choisissant Events, vous optez pour bien plus qu'une simple plateforme de gestion d'événements. Nous sommes là pour vous accompagner et vous offrir les outils dont vous avez besoin pour organiser des événements exceptionnels, créer des expériences mémorables et atteindre vos objectifs avec succès.
                                <br />
                                <a href={"/Events-usedFolder/FAQs-events?tab=organizer"} className="font-medium text-primary-6000">
                                    Centre d'aide organisateurs
                                </a>
                            </span>
                        </div>
                    </div>
                </main>

            </div>
            <div className="relative py-16 max-w-7xl bg-gray-800 bg-logo rounded-3xl">
                <div className="relative flex flex-col items-center sm:max-w-4xl mx-auto">
                    <div className="text-center mt-auto px-4">
                        <h3 className="text-3xl text-gray-50 font-bold">
                        Réduisez vos coûts techniques événementiels grâce à
                        une plateforme <span className='underlined'>tout-en-un</span>.
                        </h3>
                        <span className="block text-md mt-5 text-gray-200 dark:text-gray-300">
                        Nous soutenons plus de nombreux événements sur notre plateforme dans leur transition vers un avenir axé sur les données et la flexibilité.
                        </span>
                    </div>
                </div>
                <div className="flex mt-2 justify-center items-center">
                    <a href='/login' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary bg-primary-6000 hover:bg-primary-700 text-white mt-6 sm:mt-6">
                        Commencer maintenant
                    </a>
                </div>
            </div>
            </div>

        </div>
    )
}
