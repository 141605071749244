import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useStateContext } from '../components/contexts/ContextProvider';
import LoadContentSpinner from '../components/Utils/LoadContentSpinner';
import { Link } from 'react-router-dom';

export default function AboutUs() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Page à propos - Events";
        
    }, []);

    const imagePath = process.env.REACT_APP_IMAGE_PATH;
    const { loading } = useStateContext();

    if (loading) {
        return <LoadContentSpinner />;
    }

    return (
        <>
            <Helmet>
                <meta name="description" content="Découvrez Events, la plateforme ultime pour publier et gérer vos événements. Apprenez-en plus sur notre mission, nos services, et comment nous facilitons l'organisation et la promotion d'événements réussis." />
                <meta 
                    name="keywords" 
                    content="Events, plateforme d'événements, gestion d'événements, publication d'événements, organisation d'événements, promotion d'événements, billetterie, gestion de tickets, support événementiel, organisateurs d'événements" 
                />
            </Helmet>
            <div className="nc-BgGlassmorphism absolute inset-x-0 md:top-10 xl:top-40 min-h-0 pl-20 py-24 flex overflow-hidden z-0" data-nc-id="BgGlassmorphism">
            
                <span className="block bg-[#5bcfc5] w-72 h-72 rounded-full mix-blend-multiply filter blur-3xl opacity-5 lg:w-96 lg:h-96" />
                <span className="block bg-[#04868b] w-72 h-72 -ml-20 mt-40 rounded-full mix-blend-multiply filter blur-3xl opacity-5 lg:w-96 lg:h-96 nc-animation-delay-2000" />
            </div>
            <div className="container py-16 lg:py-15 space-y-16 lg:space-y-28">

                <div className="relative grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-16">
                    <div className="flex-shrink-0 lg:mt-0 lg:pl-8">
                        <h2 className="text-3xl !leading-tight font-bold text-gray-900 mb-4 md:text-4xl xl:text-5xl dark:text-neutral-100">
                            👋 Events.
                        </h2>
                        <span className="block text-base xl:text-lg text-gray-600 dark:text-neutral-400">
                            Bienvenue sur <b>Events</b>, votre application de billeterie en ligne ultime pour découvrir, réserver 
                            les meilleurs tickets événementiels autour de vous. <br /><br />
                            Que ce soit pour des concerts, des conférences ou autres, nous avons 
                            créé une application mobile qui vous connecte aux moments qui comptent et simplifie le processus de réservation pour tous les passionnés d'événements.
                        </span>
                        <div className="flex relative mt-6 justify-center items-center">
                            <div className="flex space-x-3">

                                <a href="https://play.google.com/store/apps/details?id=com.event.event_app" target="_blank" rel="noopener noreferrer">
                                    <img
                                        alt="Android logo"
                                        loading="lazy"
                                        width={160}
                                        height={60}
                                        style={{ color: "transparent", height: "80%" }}
                                        srcSet={`${imagePath}images/btn-android.webp`}
                                        // src={`${imagePath}images/btn-android.webp`}
                                    />
                                </a>
                                <a href="##" target="_blank" rel="noopener noreferrer">
                                    <img
                                        alt="APP Store logo"
                                        width={180}
                                        height={60}
                                        loading="lazy"
                                        style={{ color: "transparent", height: "80%" }}
                                        srcSet={`${imagePath}images/btn-ios.webp`}
                                        // src={`${imagePath}images/btn-ios.webp`}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow w-full -mt-9"
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            className="max-w-full sm:!max-w-sm"
                            alt=""
                            loading="lazy"
                            decoding="async"
                            style={{ color: "transparent",width: '85%' }}
                            srcSet={`${imagePath}images/about_app_events.png`}
                            // src={`${imagePath}images/about_app_events.png`}
                            width="600"
                            height="600"
                            data-nimg="1"
                        />
                    </div>
                    
                </div>

                <div className="sm:shadow-md sm:p-8 rounded-md mx-auto">
                    <h2 className="text-2xl capitalize font-bold">Notre mission dans votre quotidien</h2>
                    <div className="w-14 py-2 border-b border-neutral-200 dark:border-neutral-700" />
                    <div className="text-gray-600 pt-2 text-md dark:text-neutral-300">
                        <p>
                            Chez Events, nous avons révolutionné la façon dont vous vivez les événements. 
                            Fini les déplacements fastidieux et les files d'attente interminables. 
                            Avec notre application conviviale, découvrez et achetez facilement les meilleurs 
                            événements sans quitter le confort de votre foyer. Bienvenue dans 
                            une nouvelle ère de découverte sans limites, où le monde des événements s'ouvre à vous, 
                            où que vous soyez.
                            <br />
                            <br />
                            Notre mission est de connecter les organisateurs d'événements exceptionnels avec un public avide de découvertes. 
                            Que vous soyez un amateur de musique, de sport, de conférences, ou de tout autre type d'événement, 
                            Events vous offre une plateforme conviviale pour explorer, acheter et participer.
                            {/* <br />
                            <br /> */} {' '}
                            Chez Events, nous mettons l'accent sur la simplicité, la diversité et l'accessibilité. 
                            Explorez une large sélection d'événements, achetez facilement vos places, et vivez des expériences 
                            mémorables avec nous.
                            <br />
                            <br />
                            L'application a été fondée avec la vision de créer une large communauté passionnée par les événements. 
                            Notre équipe dévouée travaille sans relâche pour améliorer continuellement l'expérience de nos utilisateurs, 
                            en intégrant les dernières technologies et en établissant des partenariats solides avec des 
                            organisateurs d'événements renommés. {' '}
                            {/* <br />
                            <br /> */}
                            Nous croyons en la valeur des moments partagés, des souvenirs créés et des passions explorées. 
                            Avec cette plateforme, chaque événement devient une opportunité de vivre pleinement, de créer des liens et de découvrir 
                            le monde qui vous entoure.
                            <br />
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-5 mt-5" />
                            <span className='font-bold text-neutral-6000 dark:text-neutral-300'>
                                Merci de faire partie de la communauté Events. Explorez, achetez, et vivez l'expérience Events dès aujourd'hui.
                            </span>
                        </p>
                    </div>
                </div>

                <div className="relative">
                    <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between lg:mb-8 text-gray-900 dark:text-gray-50">
                        <div className="text-center w-full max-w-4xl mx-auto">
                            <span className="nc-Badge inline-flex px-2.5 py-1 mb-4 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100">
                                <i class="text-sm las la-star mr-1"></i>
                                <i class="text-sm las la-star mr-1"></i>
                                <i class="text-sm las la-star mr-1"></i>
                                Nos produits
                            </span>
                            <h2 className="text-2xl md:text-3xl capitalize font-bold">
                            Un Écosystème Complémentaire pour Simplifier Vos Événements
                            </h2>
                            <span className="mt-2 md:mt-3 font-normal block text-md text-gray-600 dark:text-gray-400">
                                Events met à votre disposition une suite d'outils puissants pour répondre à tous vos besoins événementiels. De la publication et la gestion de vos événements à la vente et au contrôle des billets, chaque produit est conçu pour offrir une expérience fluide et performante, que vous soyez organisateur ou participant.
                            </span>
                        </div>
                    </div>
                    <div className="relative grid md:grid-cols-2 gap-10 lg:grid-cols-3 xl:gap-16 mt-6 sm:mx-8">
                        <div className="nc-CardAuthorBox2 h-fit flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow">
                            <div className="relative flex-shrink-0 ">
                                <div className="nc-NcImage flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0">
                                    <img
                                    srcSet="https://cdn.prod.website-files.com/61cee5eb4d566d3471eca114/624d50bada5d6b0f3f9acfa8_Automated%20Marketing%20(1).png"
                                    className="object-cover w-full h-full"
                                    alt="nc-imgs"
                                    />
                                </div>
                            </div>
                            <div className="pt-[10px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                                
                                <div className="mt-6">
                                    <h2 className="text-lg font-bold">
                                        Application de réservation de tickets
                                    </h2>
                                    <span className="block mt-1 text-md text-gray-600 dark:text-neutral-400">
                                    Avec l’application mobile Events, achetez vos tickets en quelques clics, où que vous soyez. Profitez d’une navigation intuitive, de paiements sécurisés et d’un accès instantané à vos événements préférés.
                                    </span>
                                </div>
                                <div className="flex justify-between mt-6">
                                    <a
                                    className='max-w-12'
                                    href=""
                                    target="_blank"
                                    >
                                    <img
                                        srcSet={`${imagePath}images/apple-logo.png`}
                                        loading="lazy"
                                        alt="Apple logo"
                                        className="image-17"
                                    />
                                    </a>
                                    <a
                                    className='max-w-12 ml-4'
                                    href="https://play.google.com/store/apps/details?id=com.event.event_app"
                                    target="_blank"
                                    >
                                    <img
                                        srcSet={`${imagePath}images/android%20logo.png`}
                                        loading="lazy"
                                        alt="Play store logo"
                                        className="image-17"
                                    />
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="nc-CardAuthorBox2 h-fit flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow">
                            <div className="relative flex-shrink-0">
                                <div className="nc-NcImage flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0">
                                    <img
                                    srcSet="https://cdn.prod.website-files.com/61cee5eb4d566d3471eca114/67486cf0cb9628c08d1a0a7f_Frame%20427319099-p-500.png"
                                    className="object-cover w-full h-full"
                                    alt="nc-imgs"
                                    />
                                </div>
                                
                            </div>
                            <div className="pt-[10px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                                
                                <div className="mt-6">
                                    <h2 className="text-lg font-bold">
                                    Gérer vos événements comme des Pro
                                    </h2>
                                    <span className="block mt-1text-md text-gray-600 dark:text-neutral-400">
                                    Publier et gérer vos événements n’a jamais été aussi simple. Depuis notre tableau de bord dédié, créez vos événements, personnalisez les détails, suivez vos ventes en temps réel et atteignez votre public en toute sérénité. Tout est centralisé pour une gestion sans stress.
                                    </span>
                                </div>
                                <a href='/dashboard/events/registration' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-gray-800 hover:bg-gray-700 text-gray-50 mt-6 sm:mt-6">
                                    Commencer A Publier
                                </a>
                            </div>
                        </div>
                        <div className="nc-CardAuthorBox2 h-fit flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow">
                            <div className="relative flex-shrink-0 ">
                                <div className="nc-NcImage flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0">
                                    <img
                                    srcSet={`${imagePath}images/QR_Scan.png`}
                                    className="object-cover w-full h-full"
                                    alt="nc-imgs"
                                    />
                                </div>
                            </div>
                            <div className="pt-[10px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                                
                                <div className="mt-6">
                                    <h2 className="text-lg font-bold">
                                        Application de scan de tickets
                                    </h2>
                                    <span className="block mt-1 text-md text-gray-600 dark:text-neutral-400">
                                    Accélérez les contrôles d’entrée grâce à l’application de scan de tickets. Rapide, précise et sécurisée, elle garantit une gestion efficace des flux lors de vos événements.
                                    </span>
                                </div>
                                <Link to='/organisateurs/devenir-organisateur-events' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-gray-800 hover:bg-gray-700 text-gray-50 mt-6 sm:mt-6">
                                    En savoir d'avantage
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative py-16 max-w-7xl bg-gray-800 bg-logo rounded-3xl">
                    <div className="relative flex flex-col items-center sm:max-w-4xl mx-auto">
                        <div className="text-center mt-auto px-4">
                            <h3 className="text-3xl text-gray-50 font-bold">
                            Réduisez vos coûts techniques événementiels grâce à
                            une plateforme <span className='underlined'>tout-en-un</span>.
                            {/* Avec <span className="underlined">Events</span>, organisez vos événements comme jamais auparavant. */}
                            </h3>
                            <span className="block text-md mt-5 text-gray-200 dark:text-gray-300">
                            Nous soutenons plus de nombreux événements sur notre plateforme dans leur transition vers un avenir axé sur les données et la flexibilité.
                            </span>
                        </div>
                    </div>
                    <div className="flex mt-2 justify-center items-center">
                        <a href='/login' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary bg-primary-6000 hover:bg-primary-700 text-white mt-6 sm:mt-6">
                            Commencer maintenant
                        </a>
                    </div>
                </div>

            </div>
        </>
    )
}
