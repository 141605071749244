import React, { useEffect } from 'react'
import LoginForm from '../../../components/Authentication/LoginForm'
import { Link } from 'react-router-dom'

export default function Index() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Page de connexion - Events";
        
    }, []);

    return (
        <>
            <div className="nc-PageLogin ">
                <div className="container mb-24 lg:mb-16">
                    <h2 className="my-10 mb-5 flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-bold text-neutral-900 dark:text-neutral-100 justify-center">
                        Connexion
                    </h2>
                    <div className="relative text-center max-w-md mx-auto space-y-11">
                        <span className="relative inline-block px-4 text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                            Connectez-vous à votre espace organisateur pour gérer vos événements et accéder à toutes les fonctionnalités.
                        </span>
                    </div>
                    <div className="max-w-md mx-auto space-y-6 mt-12">
                    
                        <LoginForm />
                        <span className="block text-center text-neutral-700 dark:text-neutral-300">
                            Pas encore de compte?{' '} <Link to="/create-account" className='font-medium text-primary-6000'>Inscrivez-vous</Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}
