import React, { useEffect, useState } from 'react'
import DropdownEditMenu from '../../../components/Dashboard/DropdownEditMenu'
import { Link, useParams } from 'react-router-dom'
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import axiosClient from '../../../components/api/axiosClient';
import FormattedDate from '../../../components/Utils/FormattedDate';
import { formatPrice } from '../../../components/Utils/UtilsPricesFormat';
import EventsRecapOrdersComponent from '../../../components/Dashboard/eventsContent/EventsRecapOrdersComponent';
import DoEventActions from '../../../components/Dashboard/eventsContent/DoEventActions';
import { formatNumber } from '../../../components/Utils/UtilsNumbersFormat';

export default function ViewDetailsToEvents() {

    const [activeTab, setActiveTab] = useState('general'); // État pour gérer l'onglet actif
    const { id } = useParams();  // Récupérer l'ID de l'événement à partir de l'URL
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [details, setDetails] = useState(null);

    // Fonction pour changer l'onglet actif
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const fetchEventDetails = async () => {
        setError(null);   // Réinitialiser les erreurs
        // Appel API pour récupérer les événements en cours de validation
        try {
            const response = await axiosClient.get(`/dashboard/event/details/e/${id}`);
            setDetails(response.data.data); // Données de l'événement
        } catch (error) {
            if (error.response && error.response.data) {
                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Une erreur inconnue est survenue. Veuillez réessayer plus tard.',});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    useEffect(() => {
        if (id) {
            fetchEventDetails();
        }
    }, [id]);

    if(!id) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Aucun événement trouvé, impossible d'accéder à cette page.
                    </h4>
                    <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                    Retour aux événements
                    </Link>
                </div>
            </div>
        )
    }

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Afficher une section dédiée en cas d'erreur
    if (error) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Erreur
                    </h4>
                    <div className="mb-6 text-sm">
                    {error.message}
                    </div>
                    {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                        <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Retour aux événements
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="h-full translate-x-0 bg-white">
                {/* background */} 
                <div className="cecbd cvwbh cm84d cmg8v">
                    <img
                    className="cfwu1 cav8x c6btv"
                    width={979}
                    height={220}
                    alt={details.titre}
                    src={`data:image/jpeg;base64,${details.affiche}`}
                    />
                </div>
                {/* Content */} 
                <div className="cm84d cnbwt clbq0 c085h">
                     {/* Pre-header */} 
                    <div className="c3iy1 c3lsi cai6b">
                    <div className="flex items-center c2g1r c8fe8 c6o0w cetff">
                         {/* Avatar */} 
                        <div className="inline-flex c2rn6 cxx4k ccpi7 cdiog">
                        <img
                            className="rounded-lg cdnc2 cv1so cmjfg"
                            width={128}
                            height={128}
                            alt={details.titre}
                            src={`data:image/jpeg;base64,${details.affiche}`}
                        />
                        </div>
                        {/* Actions */} 
                        <div className="flex relative ch3kz cecaj">
                            <DropdownEditMenu align="right" className="relative inline-flex bg-white border border-gray-200 rounded-full cspbm">
                                <li>
                                    <Link onClick={() => handleTabClick('Actions')} className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3" to="#0">
                                        Modifier
                                    </Link>
                                </li>
                                <li>
                                    <Link className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3" to={`/dashboard/ventes/tickets-vendus/e/${details.id}`}>
                                        Voir les chiffres
                                    </Link>
                                </li>
                                <li>
                                    <Link className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3" to="/dashboard/achats/events/validation/events-scan">
                                    Gérer l'Accès
                                    </Link>
                                </li>
                                <li>
                                    <Link className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3" to={`/dashboard/factures/events/invoices/show-details/i/${details.id}`}>
                                    Mes Factures
                                    </Link>
                                </li>
                                <li>
                                    <Link className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3" to="/dashboard/account/administration-organizer/contact/events-supprort-organizers">
                                    Contacter le support
                                    </Link>
                                </li>
                                <hr />
                                <li>
                                <Link onClick={() => handleTabClick('Actions')} className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3" to="#0">
                                    Supprimer
                                </Link>
                                </li>
                            </DropdownEditMenu>
                            <span className="bg-white border border-gray-200 font-bold text-gray-950 cc0oq cghq3 cspbm c2vpa cb8zv c5vqk coqgc c1ga4">
                            {details.event_code}
                            </span>
                            <Link to={'/dashboard/events/registration'} className="bg-primary-600 hover:bg-primary-700 font-semibold cdj8c cg0jr ch8z9 cilvw cyn7a cnf4p">
                                <svg className="cdqku cbm9w coqgc text-white" width={16} height={16} viewBox="0 0 16 16">
                                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                </svg>

                                <span className="c8bkw">Nouvel événement</span>
                            </Link>
                        </div>
                    </div>
                    </div>
                     {/* Header */} 
                    <header className="c2bzj cydwr cai6b">
                     {/* Name */} 
                    <div className="inline-flex ce4zk c6f83">
                        <h1 className="text-gray-800 dark:text-gray-100 font-bold cbtcb">
                        {details.event_title}
                        </h1>
                    </div>
                    {/* Bio */}
                    {/* <div className="text-sm cxg65">
                        Fitness Fanatic, Design Enthusiast, Mentor, Meetup Organizer &amp; PHP
                        Lover.
                    </div> */}
                     {/* Meta */} 
                    <div className="flex flex-wrap items-center cn439">
                        <div className="flex items-left mr-3 sm:mb-0 mb-2">
                            <svg
                                className="cmpw7 cdqku cbm9w coqgc"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z" />
                            </svg>
                            <span className="text-sm text-gray-500 dark:text-gray-400 cq84g c1k3n c8bkw">
                            {details.place}
                            </span>
                        </div>
                        <div className="flex items-center mr-3 sm:mb-0 mb-2">
                            <svg
                                className="cmpw7 cdqku cbm9w coqgc"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M3.886 1.137A8 8 0 1 1 .08 9.142a1 1 0 0 1 1.98-.284 6 6 0 1 0 3.314-6.256l.844.83a1 1 0 0 1-.53 1.698l-3.745.653a1 1 0 0 1-1.16-1.142L1.38.887A1 1 0 0 1 3.07.331l.817.806ZM9 7.586l1.707 1.707a1 1 0 1 1-1.414 1.414l-2-2A1 1 0 0 1 7 8V5a1 1 0 1 1 2 0v2.586Z" />
                            </svg>
                            <span
                                className="text-sm text-gray-500 dark:text-gray-400 cq84g c1k3n c8bkw"
                            >
                                {details.default_date} . {details.hour}
                            </span>
                        </div>
                        {details.annule ? (
                            <div className="inline-flex text-[13px] rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                Evénement Annulé
                            </div>
                        ) : (

                            details.status === 'Disponible' ? (
                                <div className="inline-flex text-[13px] rounded-full c1lu4 c19il cydwr c1k3n cdw1w c9hxi">
                                    {details.status}
                                </div>
                            ) : details.status === 'Disabled' ? (
                                <div className="inline-flex text-[13px] rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                    Désactivé
                                </div>
                            ) : (
                                <div className="inline-flex text-[13px] rounded-full clksd cydwr c1k3n cdw1w c9hxi">
                                    {details.status}
                                </div>
                            )
                        )}
                    </div>
                    </header>
                    {/* Tabs */} 
                    <div className="cm84d cai6b">
                    <div className="cmr9m cvwbh cqdkw cq5bq c6btv cfwuq" aria-hidden="true" />
                    <ul className="text-sm flex c9zp2 cu49b c8vtj c1k3n c49xy csf8j cm84d chp98">
                        <li className="chndw cddmn cxjgq ci2ft ckjy4 cbhw6 c5ggj c3osb">
                        <button
                            className={`block ${activeTab === 'general' ? 'text-blue-500 cq84g ci0dd cp2rp' : 'text-gray-500 dark:text-gray-400'} cphv1`}
                            onClick={() => handleTabClick('general')}
                        >
                            General
                        </button>
                        </li>
                        <li className="chndw cddmn cxjgq ci2ft ckjy4 cbhw6 c5ggj c3osb">
                            <button
                                className={`block ${activeTab === 'stats' ? 'text-blue-500 cq84g ci0dd cp2rp' : 'text-gray-500 dark:text-gray-400'} cphv1`}
                                // href="#0"
                                onClick={() => handleTabClick('stats')}
                            >
                                Statistique des ventes
                            </button>
                        </li>
                        <li className="chndw cddmn cxjgq ci2ft ckjy4 cbhw6 c5ggj c3osb">
                            <button
                                className={`block ${activeTab === 'Actions' ? 'text-blue-500 cq84g ci0dd cp2rp' : 'text-gray-500 dark:text-gray-400'} cphv1`}
                                // href="#0"
                                onClick={() => handleTabClick('Actions')}
                            >
                                Actions
                            </button>
                        </li>
                    </ul>
                    </div>
                    {/* content */} 
                    {activeTab === 'general' ? (
                        <div className="flex c7k8z cqp75 cetff">
                            {/* Main content */} 
                            <div className="cvw0d cgwj2 c7jh9 cgd7w max-w-xl">
                                <div className='cyhlg rounded-lg py-3 px-4'>
                                    <div className="text-sm cweej">
                                        <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Période de vente</h3>
                                        <span>
                                            <FormattedDate date={details.created_at} /> - {!details.cloture_vente ? (<>Non Défini</>) : (<FormattedDate date={details.cloture_vente} />)}
                                        </span>
                                    </div>
                                </div>

                                <div className="block c2hoo cm84d c6btv">
                                    <h2 class="text-gray-800 dark:text-gray-100 cgulq c6f83">Billet & Prix</h2>
                                    {details.pivots.map((data, index) => {
                                        // Function to check if the ticket is sold out
                                        const isSoldOut = () => {
                                            const acceptedTickets = data.pivot_to_orders
                                                .filter((purchase) => purchase.status === "ACCEPTED")
                                                .reduce((sum, purchase) => sum + purchase.quantity, 0);

                                            return data.nbr_ticket === 0 && acceptedTickets === data.total_billet;
                                        };

                                        return (
                                            <div
                                                key={index}
                                                className="border border-gray-200 cc0oq cghq3 c2vpa cb8zv cxxol c5vqk p-3 mb-4"
                                            >
                                                <div className="items-center c7nom czohk c4sak">
                                                    {/* Card */}
                                                    <div className="c6z41 cehrd c5l47 cjac8 cm4ey c7xaj">
                                                        <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                                            {data.findthislabel.title}
                                                        </div>
                                                    </div>
                                                    {/* Name */}
                                                    <div className="c851m cqt5h cehrd c5l47 cjac8 c2hoo c8zuu">
                                                        <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n c941w">
                                                            {formatPrice(data.prix_unitaire)}
                                                        </div>
                                                    </div>
                                                    {/* Card limits */}
                                                    <div className="clwtw cqt5h cehrd cpxid cjac8 chvik c7xaj">
                                                        <div className="text-sm">{formatNumber(data.total_billet)} &nbsp;tickets</div>
                                                    </div>
                                                    {/* Card status */}
                                                    <div className="c851m cehrd cjcps cjac8 chvik c8zuu">
                                                        <div
                                                            className={`inline-flex text-xs rounded-full text-center px-3 py-1 ${
                                                                isSoldOut() ? "bg-red-500 text-white" : "c1lu4 c19il"
                                                            }`}
                                                        >
                                                            {isSoldOut() ? "Sold Out" : "En stock"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                {/* Description */} 
                                <div>
                                    <h2 className="text-gray-800 dark:text-gray-100 cgulq c6f83">
                                        Description de l'événement
                                    </h2>
                                    <div className="text-sm cweej">
                                        <p>
                                        {details.descrip_apk}
                                        </p>
                                    </div>
                                </div>


                                {/*  */}
                            </div>
                            {/* Sidebar */} 
                            <aside className="cxsb5alonge cjav5 cvgr8alonge">
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Titre</h3>
                                    {!details.event_title ? (
                                        <span>...</span>
                                    ) : (
                                        <div>{details.event_title}</div>
                                    )}
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Code événement</h3>
                                    {!details.event_code ? (
                                        <span>...</span>
                                    ) : (
                                        <div>{details.event_code}</div>
                                    )}
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Date de début </h3>
                                    {!details.default_date ? (
                                        <span>...</span>
                                    ) : (
                                        <div>{details.default_date}</div>
                                    )}
                                </div>
                                {details.other_date && (
                                    <div className="text-sm">
                                        <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Date de fin</h3>
                                        {!details.other_date ? (
                                            <span>...</span>
                                        ) : (
                                            <div>{details.other_date}</div>
                                        )}
                                    </div>
                                )}
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Heure</h3>
                                    {!details.hour ? (
                                        <span>...</span>
                                    ) : (
                                        <div>{details.hour} {'GMT'}</div>
                                    )}
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Lieu</h3>
                                    {!details.place ? (
                                        <span>...</span>
                                    ) : (
                                        <div>{details.place}</div>
                                    )}
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Date de clôture des ventes</h3>
                                    {!details.cloture_vente ? (
                                        <span>...</span>
                                    ) : (
                                        <div><FormattedDate date={details.cloture_vente} /> </div>
                                    )}
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Lien GPS</h3>
                                    {!details.events_request.lien_gps ? (
                                        <span>...</span>
                                    ) : (
                                        <div>{details.events_request.lien_gps}</div>
                                    )}
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Acteur</h3>
                                    {!details.artist ? (
                                        <span>...</span>
                                    ) : (
                                        <div>{details.artist}</div>
                                    )}
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Catégorie</h3>
                                    {!details.eventcategorie ? (
                                        <span>...</span>
                                    ) : (
                                        <div>{details.eventcategorie.name}</div>
                                    )}
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">Type</h3>
                                    <div className='flex'>
                                        {(details.populaire === 0 && details.vedette === 0)  ? (
                                            <span>...</span>
                                        ) : (
                                            <>
                                            {details.populaire === 1 && (
                                                <div className="flex items-center c5464 cyn7a text-xs py-1 px-2 mr-3 w-fit rounded-full ch3kz">
                                                <svg className="cveo1 cbm9w" width={14} height={14} viewBox="0 0 16 16">
                                                <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                                                </svg>
                                                {/* Rate */}
                                                <div className="text-xs cq84g c1k3n">
                                                    <span>POPULAIRE</span>
                                                </div>
                                            </div>                                          
                                            )}
                                            {details.vedette === 1 && (
                                                <div className="flex items-center c5464 cyn7a text-xs py-1 px-2 w-fit rounded-full ch3kz">
                                                    <svg className="cveo1 cbm9w" width={14} height={14} viewBox="0 0 16 16">
                                                    <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                                                    </svg>
                                                    {/* Rate */}
                                                    <div className="text-xs cq84g c1k3n">
                                                    <span>VEDETTE</span>
                                                    </div>
                                            </div>                                          
                                            )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="text-sm">
                                    <h3 className="text-gray-800 dark:text-gray-100 c1k3n">
                                        Créé le
                                    </h3>
                                    <div><FormattedDate date={details.created_at} /></div>
                                </div>
                            </aside>
                        </div>
                    ) : activeTab === 'stats' ? (
                        <EventsRecapOrdersComponent event={id} />
                    ) : activeTab === 'Actions' && (
                        <DoEventActions event={id} />
                    )}
                
                </div>

            </div>
        </>
    )
}
