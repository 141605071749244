import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ListEventsComponentToAgentScanner from '../../../components/Dashboard/agentScanner/ListEventsComponentToAgentScanner';
import axiosClient from '../../../components/api/axiosClient';
import { useError } from '../../../components/contexts/ErrorContext';

export default function IndexEventsValidations() {

    const [activeTab, setActiveTab] = useState('documentation'); // État pour gérer l'onglet actif
    const [loading, setLoading] = useState(false);
    const { handleError } = useError();

    // Fonction pour changer l'onglet actif
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    const handleDownload = async () => {
        setLoading(true)
        try {
            // Envoyer une requête POST à votre API Laravel pour enregistrer l'activité
            await axiosClient.post('/request/log-activity/store-activityDownload-EventsAppScan:m/c/7fea0-6e99-4a-40dbc09572', {
                activity: 'Téléchargement de l\'application de Events scan',
            });
        
            // Lancer le téléchargement du fichier APK
            const link = document.createElement('a');
            link.href = `${imagePath}APK-Base2482-Events_scan32/Events-Scan.apk`;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            handleError('Erreur lors du téléchargement !', error);
            // console.error("Erreur Axios capturée :", error);
        } finally {
            setLoading(false)
        }
    };
    
    return (
        <div className='ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz'>
            <div className="mb-4">
                {/* Title */}
                <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                Accès à l'Application de Scan
                </h1>
            </div>

            <div className="clgee c7jh9">
                {/* Left content */}
                <div className="cuqol c0ud1 c6btv cgd7w">
                    <div className="c81t4 ci8jx c0cvr cu49b c2mtx c5hel">
                        <div className="ccvej">
                            
                            {/* Links */}
                            <div className="flex c9zp2 cjq31 cschv cu49b c8vtj cyqqd chp98 clbq0">
                                {/* Group 1 */}
                                <div>
                                    <div className="cmpw7 cgulq cdqku cqddb c0ef0 c1iho cxg65">
                                    Menu
                                    </div>
                                    <ul className="flex c8vtj cyqqd cri0s chfzq">
                                    <li className="cved1 cri0s ce4il">
                                        <button
                                        className={`flex items-center ${activeTab === 'documentation' ? ' bg-white': ''} w-full cq84g cb8zv ch4gv cuvgf`}
                                        onClick={() => handleTabClick('documentation')}
                                        >
                                        <svg
                                            className={`${activeTab === 'documentation' ? 'text-primary-6000': ''} mr-2 cbm9w coqgc`}
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M4.904 10.114a.98.98 0 0 1 0-1.961h5.886a.98.98 0 0 1 0 1.961H4.904ZM2.863 5.166a1.962 1.962 0 0 0-.901 1.651v5.26c0 1.083.878 1.961 1.961 1.961h7.85a1.962 1.962 0 0 0 1.961-1.961v-5.26c0-.668-.34-1.29-.901-1.65L7.848 1.961 2.863 5.166ZM6.786.312a1.962 1.962 0 0 1 2.123 0l4.985 3.204a3.923 3.923 0 0 1 1.802 3.301v5.26A3.923 3.923 0 0 1 11.772 16H3.923A3.923 3.923 0 0 1 0 12.077v-5.26c0-1.335.679-2.579 1.802-3.3L6.786.311Z" />
                                        </svg>
                                        <span className={`${activeTab === 'documentation' ? 'text-primary-6000': ''} text-sm c1k3n`}>Documentation</span>
                                        </button>
                                    </li>
                                    <li className="cved1 cri0s ce4il">
                                        <button
                                            className={`flex items-center ${activeTab === 'access' ? ' bg-white': ''} w-full cq84g cb8zv ch4gv cuvgf`}
                                            onClick={() => handleTabClick('access')}
                                        >
                                        <svg
                                            className={`${activeTab === 'access' ? 'text-primary-6000': ''} mr-2 cbm9w coqgc`}
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M5 9a1 1 0 1 1 0-2h6a1 1 0 0 1 0 2H5ZM1 4a1 1 0 1 1 0-2h14a1 1 0 0 1 0 2H1Zm0 10a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2H1Z" />
                                        </svg>
                                        <span className={`${activeTab === 'access' ? 'text-primary-6000': 'c1ukq'} text-sm c0zkc c1k3n`}>
                                            Mes accès
                                        </span>
                                        </button>
                                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Middle content */}
                <div className="2xl:mx-8 cpzza cnt4k cexdx c7jh9">
                    <div className="ccvej">
                        {activeTab === 'documentation' ? (
                            <article className="bg-white c2vpa c1hly c5vqk csusu">
                                
                                {/* Header */}
                                <header className="c1rbs">
                                {/* Title */}
                                <div className="flex ce4zk cp3jk cxg65">
                                    <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb">
                                        Documentation de l'Application de Scan
                                    </h2>
                                </div>
                                </header>
                                {/* Content */}
                                <div className="cr78y cai6b">
                                    <section className='Introduction'>
                                        <div className="cvwie cosgb c33r0 cgnhv cf10p c340b coaix cfcf6 cydwr cb8zv clxbf cx3hp c72q5">
                                            <div className="text-gray-900 dark:text-gray-100 cgulq cgwj2 c6f83">
                                                Bon à savoir
                                                <p className='text-sm text-gray-800 font-normal mt-2'>
                                                    L'application de scan est un outil dédié aux organisateurs d'événements, permettant de vérifier l'authenticité des billets et de gérer les accès des participants. Elle offre une solution rapide et 
                                                    fiable pour contrôler les entrées, ce qui aide à garantir un flux ordonné et sécurisé des participants.
                                                </p>
                                                <button onClick={handleDownload} className="btn bg-primary-600 hover:bg-primary-700 text-white mt-4 cdj8c cg0jr ch8z9 cilvw cyn7a">
                                                {loading ? (
                                                    <>
                                                        <svg
                                                            className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx={12}
                                                                cy={12}
                                                                r={10}
                                                                stroke="currentColor"
                                                                strokeWidth={3}
                                                            />
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            />
                                                        </svg>
                                                        En cours...
                                                    </>
                                                ) : (
                                                    "Télécharger Events Scan"
                                                )}
                                                </button>

                                            </div>
                                        </div>
                                    </section>
                                    <section className='!mt-6 fonctionality'>
                                        <h4 className='text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl'>
                                            Fonctionnalités Principales
                                        </h4>
                                        <p className=''>
                                            L'application est conçue pour offrir les fonctionnalités suivantes :
                                            <ul className='cjqz5 cektu c1647 cai6b mt-2'>
                                                <li className='leading-normal'><b>Vérification des Billets :</b> Scannez les billets des participants en utilisant l'appareil photo du smartphone. L'application reconnaît les QR codes ou les codes-barres des billets électroniques de <b>Events</b>.</li>
                                                <li className='leading-normal'><b>Validation en Temps Réel :</b> Lorsqu'un billet est scanné, l'application vérifie immédiatement sa validité dans la base de données, et informe l'utilisateur si le billet est valide, déjà scanné ou invalide.</li>
                                            </ul>
                                        </p>
                                    </section>
                                    <section className='!mt-6 prerequis'>
                                        <h4 className='text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl'>
                                        Prérequis et Installation
                                        </h4>
                                        <p className=''>
                                            Pour utiliser l'application, les organisateurs doivent :
                                            <ul className='cjqz5 cektu c1647 cai6b mt-2'>
                                                <li className='leading-normal'>Disposer d'un compte organisateur valide sur la plateforme Events.</li>
                                                <li className='leading-normal'>Avoir un smartphone sous Android</li>
                                                <li className='leading-normal'>S'assurer que l'appareil dispose d'une connexion Internet active pour la validation des billets en temps réel.</li>
                                                <li className='leading-normal'>Avoir télécharger l'application <Link to={'#0'} className='text-blue-600 font-semibold'>Events Scan</Link> sur leur appareil</li>
                                            </ul>
                                        </p>
                                    </section>
                                    <section className='!mt-6 using'>
                                        <h4 className='text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl'>
                                        Guide d'Utilisation
                                        </h4>
                                        <p className=''>
                                            Voici les étapes à suivre pour utiliser l'application de scan :
                                            <ul className='cjqz5 cektu c1647 cai6b mt-2'>
                                                <li className='leading-normal'><b>Connexion :</b> Ouvrez l'application et connectez-vous avec vos identifiants d'organisateur que Events génère pour chaque événemnt.</li>
                                                <li className='leading-normal'><b>Sélectionner l'Événement :</b> Choisissez l'événement pour lequel vous souhaitez scanner les billets.</li>
                                                <li className='leading-normal'><b>Scanner les Billets :</b> Utilisez la caméra de votre smartphone pour scanner les QR codes ou les codes-barres des billets.</li>
                                                <li className='leading-normal'><b>Vérifier les Résultats :</b> L'application affiche le statut du billet (<b>Valide</b> : Le billet est accepté ; <b>Déjà scanné</b> : Le billet a déjà été utilisé ; <b>Invalide</b> : Le billet n'est pas reconnu ou est incorrect.)</li>
                                            </ul>
                                        </p>
                                        <div className='flex w-full items-center justify-center'>
                                            <div className="flex items-center justify-center sm:w-3/4 cm84d c2ma9">
                                                <img
                                                    className="c6btv"
                                                    src="https://preview.cruip.com/mosaic/images/feed-image-02.jpg"
                                                    // width={590}
                                                    // height={332}
                                                    alt="Feed 01"
                                                />
                                                <button className="cqdkw cqogy">
                                                    <svg
                                                    className="c3nk1 crzrx"
                                                    width={64}
                                                    height={64}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                    <circle className="cq2b5 caoao ctk2l cxxol" cx={32} cy={32} r={32} />
                                                    <path
                                                        className="cwjz0"
                                                        d="M40 33a.999.999 0 0 0-.427-.82l-10-7A1 1 0 0 0 28 26v14.002a.999.999 0 0 0 1.573.819l10-7A.995.995 0 0 0 40 33V33c0 .002 0 .002 0 0Z"
                                                    />
                                                    </svg>
                                                </button>
                                                <div className="cqdkw clnj8 caz52 cyfoq">
                                                    <div className="flex items-center cqkjy">
                                                    <div className="c4wgs ckbo4 c1k3n caiw6 c1iho c9hxi cwn3v">0:48</div>
                                                    <div className="c4wgs ckbo4 c1k3n caiw6 c1iho c9hxi cwn3v">1M Views</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                    <section className='!mt-6 config'>
                                        <h4 className='text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl'>
                                        Configuration et Paramètres
                                        </h4>
                                        <p className=''>
                                            Voici les étapes à suivre pour utiliser l'application de scan :
                                            <ul className='cjqz5 cektu c1647 cai6b mt-2'>
                                                <li className='leading-normal'><b>Mises à Jour :</b> Assurez-vous que l'application est à jour pour bénéficier des dernières fonctionnalités et correctifs.</li>
                                                <li className='leading-normal'><b>Options de Connexion :</b> Si vous avez oublié vos identifiants, vous pouvez toujours les recuperer depuis votre tableau de bord en fonction de l'événement.</li>
                                            </ul>
                                        </p>
                                    </section>
                                    <section className='!mt-6 maj'>
                                        <h4 className='text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl'>
                                        Mises à Jour Futures
                                        </h4>
                                        <p className=''>
                                            L'équipe Events prévoit de nouvelles fonctionnalités dans les prochaines mises à jour :
                                            <ul className='cjqz5 cektu c1647 cai6b mt-2'>
                                                <li className='leading-normal'><b>Intégration avec le tableau de bord principal :</b> Synchronisation automatique avec la plateforme de gestion des événements.</li>
                                                <li className='leading-normal'><b>Amélioration des rapports :</b> Rapports détaillés et statistique d'accès.</li>
                                                <li className='leading-normal'>Amélioration des performances de l'application de scan pour rendre l'expérience utilisateur facile.</li>
                                            </ul>
                                        </p>
                                    </section>
                                    <section className='!mt-6 Support et Assistance'>
                                        <h4 className='text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl'>
                                        Support et Assistance
                                        </h4>
                                        <p className=''>
                                            Pour toute assistance technique ou question relative à l'utilisation de l'application, contactez notre support :
                                            <ul className='cjqz5 cektu c1647 cai6b mt-2'>
                                                <li className='leading-normal'><b>E-mail :</b> <a href="mailto:support.organizers@app-events.com" target='_blank' rel="noreferrer" className='text-blue-600 font-semibold'>Support.organizers@app-events.com</a></li>
                                                <li className='leading-normal'><b>Téléphone :</b> +225 07 17 51 9518</li>
                                            </ul>
                                        </p>
                                    </section>
                                    <hr />
                                    <section>
                                        <div className="chmcf">
                                            <h4 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl">FAQs</h4>
                                        </div>
                                        <ul className="cvw0d">
                                            <li>
                                            <div className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                            Que faire si l'application ne scanne pas les billets correctement ?
                                            </div>
                                            <div className="text-sm">
                                            Vérifiez que l'appareil photo fonctionne correctement, assurez-vous que l'écran du billet est propre et bien éclairé, et essayez de redémarrer l'application.
                                            </div>
                                            </li>
                                            <li>
                                            <div className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                            Comment gérer les participants ayant perdu leur billet ?
                                            </div>
                                            <div className="text-sm">
                                            Vous pouvez vérifier l'identité du participant et rechercher le billet correspondant dans la liste des billets vendus depuis le tableau de bord de l'application.
                                            </div>
                                            </li>
                                            <li>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                                Quels types de billets peuvent être scannés avec l'application ?
                                                </div>
                                                <div className="text-sm">
                                                L'application prend en charge les billets avec des QR codes. Elle peut scanner les billets électroniques envoyés par email ou les billets imprimés, à condition que le QR code soit lisible.
                                                    
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                                Que se passe-t-il si un billet est déjà scanné ?
                                                </div>
                                                <div className="text-sm">
                                                Lorsqu'un billet est déjà scanné, l'application affiche un message indiquant que le billet a déjà été utilisé. Cela permet d'éviter les fraudes et de s'assurer que chaque participant n'entre qu'une seule fois.
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                                Pourquoi l'application ne scanne-t-elle pas certains billets ?
                                                </div>
                                                <div className="text-sm">
                                                Plusieurs raisons peuvent expliquer ce problème :
                                                <ul className='cjqz5 cektu c1647 cai6b mt-2'>
                                                    <li className='leading-normal'>Le QR code est endommagé ou de mauvaise qualité.</li>
                                                    <li className='leading-normal'>La caméra du téléphone n'est pas correctement orientée ou l'éclairage est insuffisant.</li>
                                                    <li className='leading-normal'>Le billet n'est pas valide pour l'événement sélectionné. Assurez-vous que l'événement correct est sélectionné dans l'application.</li>
                                                </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                                Comment mettre à jour l'application ?
                                                </div>
                                                <div className="text-sm">
                                                Il faut télécharger régulièrement l'application depuis le tableau de bord, Vous recevrez une notification lorsque des mises à jour sont disponibles, et il est recommandé de toujours utiliser la dernière version pour bénéficier des améliorations de sécurité et des nouvelles fonctionnalités.
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                                L'application prend-elle en charge plusieurs utilisateurs ?
                                                </div>
                                                <div className="text-sm">
                                                Oui, plusieurs utilisateurs peuvent se connecter à l'application en même temps pour scanner les billets d'un même événement. Les données seront synchronisées sur le compte de l'organisateur principal.
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                                Que faire si l'application se ferme soudainement pendant un événement ?
                                                </div>
                                                <div className="text-sm">
                                                En cas de fermeture soudaine de l'application, redémarrez-la et reconnectez-vous. Les données de scan précédentes ne seront pas perdues si elles ont déjà été synchronisées. Il est également conseillé de vérifier que l'application est à jour et que l'appareil dispose de suffisamment de mémoire.
                                                </div>
                                            </li>
                                        </ul>
                                    </section>

                                </div>
                            </article>
                        ) : ( 
                            <article className="bg-white c2vpa c1hly c5vqk csusu">
                                
                                {/* Header */}
                                <header className="c1rbs">
                                    {/* Title */}
                                    <div className="ce4zk cp3jk cxg65">
                                        <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb">
                                        Événements Publiés
                                        </h2>
                                        <span className='text-sm !ml-0'>
                                        Votre liste de tous les événements publiés ainsi que leur accès à l'application de scan de Events.
                                        </span>
                                    </div>
                                </header>
                                {/* Content */}

                                <ListEventsComponentToAgentScanner />
                            </article>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}
