import React, { useEffect, useState } from 'react'
import FormattedDate from '../Utils/FormattedDate';
import { formatPrice } from '../Utils/UtilsPricesFormat';
import { formatDateToBar } from '../Utils/UtilsNumbersFormat';
import { Link } from 'react-router-dom';
import axiosClient from '../api/axiosClient';

export default function InvoiceDetails({ invoice, isModalOpen, closeModal }) {
    
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [invoiceCode, setInvoiceCode] = useState('');
    const [objet, setObjet] = useState('');
    const [comment, setComment] = useState('');
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState();
    const [validationError, setValidationError] = useState('');
    const [errorDownloading, setErrorDownloading] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    // Mettre à jour les valeurs du formulaire avec les informations de la carte sélectionnée
    useEffect(() => {
        if (invoice) {
            setInvoiceCode(invoice.invoice_number || "");  // Assurez-vous de gérer les valeurs nulles
        }
    }, [invoice]);

    useEffect(() => {
        if (!isModalOpen) {
            resetForm();
        }
    }, [isModalOpen]);

    // Fonction pour réinitialiser le formulaire
    const resetForm = () => {
        setErrors({});
        setValidationError('');
        setSuccessMessage('');
    };
    
    if (!invoice) return null;
    
    const showTooltip = () => setIsTooltipVisible(true);
    const hideTooltip = () => setIsTooltipVisible(false);

    // Fonction pour ouvrir le modal
    const openFormModal = () => {
        setIsFormModalOpen(true);
    };

    // Fonction pour fermer le modal
    const closeFormModal = () => {
        setIsFormModalOpen(false);
    };

    // Fonction de validation
    const validateFields = () => {

        const validationErrors = {};
        // const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]{3,}$/; // Lettres uniquement, minimum 3 caractères
        const numberRegex = /^[a-zA-Z0-9]{3,}$/; // Alphanumérique, minimum 3 caractères
        const commentRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9\s.,!?'-_%]{3,}$/; // Lettres, chiffres, ponctuation basique

        const TrimedInvoiceCode = invoiceCode.trim();
        if (!TrimedInvoiceCode) {
            validationErrors.invoiceCode = "Ce champ est requis";
        } else if (!numberRegex.test(TrimedInvoiceCode)) {
            validationErrors.invoiceCode = 'Le nom de la carte doit contenir au moins 3 lettres sans caractères spéciaux';
        }

        const TrimedObjet = objet.trim();
        if (!TrimedObjet) {
            validationErrors.objet = "Ce champ est requis";
        } else if (!commentRegex.test(TrimedObjet)) {
            validationErrors.objet = 'Veuillez rédiger correctement votre objet';
        }

        const TrimedComment = comment.trim();
        if (!TrimedComment) {
            validationErrors.comment = "Ce champ est requis";
        } else if (!commentRegex.test(TrimedComment)) {
            validationErrors.comment = "Veuillez rédiger correctement votre préoccupation";
        }

        return validationErrors;
    };

    // Fonction pour gérer la soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateFields();

        setErrors(validationErrors);

        // Si des erreurs existent, on arrête l'exécution
        if (Object.keys(validationErrors).length > 0) return;

        // Création des données de formulaire
        const formData = new FormData();
        formData.append('invoiceCode', invoiceCode);
        formData.append('objet', objet);
        formData.append('comment', comment);

        setLoading(true);

        axiosClient.post('/request/user/organizer/invoice/sendNotifications/to-events', formData)
            .then(
                ({data}) => {
                    setSuccessMessage(data.message);

                    setInvoiceCode('');
                    setObjet('');
                    setComment('');

                    setLoading(false);
                    setTimeout(() => {
                        closeModal();
                    }, 3000);

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        if (response.data.errors) {
                            // Erreurs spécifiques aux champs
                            setErrors(response.data.errors);
                        }
                        if (response.data.message) {
                            // Erreur générale
                            setValidationError(response.data.message);
                        }
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Erreur lors de lors de l'envoie de votre méssage.");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
    };

    const downloadInvoice = async () => {
        setLoadingBtn(true);
    
        try {
            const response = await axiosClient.get(`/dashboard/user/organizer/invoice/download/f/${invoice.id}`);
            
            const { invoice_number, file } = response.data;

            // Convertir le fichier base64 en Blob
            const pdfBlob = new Blob([Uint8Array.from(atob(file), (c) => c.charCodeAt(0))], { type: 'application/pdf' });

            // Créer un lien de téléchargement
            const fileName = `Facture-${invoice_number}.pdf`;
            const url = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            if (error.response && error.response.data.message) {
                setErrorDownloading(error.response.data.message);
            } else {
                setErrorDownloading('Erreur lors du téléchargement de la facture.');
            }
            setTimeout(() => setErrorDownloading(""), 7000);
        } finally {
            setLoadingBtn(false);
        }
    };

    const isSubmitDisabled = () => {
        return loading;
    };

    return (
        <>
            {isModalOpen && (
                <div className={`czdd1 cbxb2 c8uqq cz9ag cqm85 cqdkw cini7 cunkr transition-transform duration-300 ease-in-out ${
                    isModalOpen ? 'translate-x-0' : 'translate-x-full'
                }`}>
                    
                    <div className="border-gray-200 czwcc cghq3 cnxx1 cbyyo ciumw c6nvv c2p0j c9f90 ccpww cu49b coqgc c4o1l ckxlc ckxi0 cf5iw c6btv">
                        <button className="cqdkw cgky2 cli41 cqogy c3osb cgndh c27zx" onClick={closeModal}>
                        <svg
                            className="csmdn cyq9w"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                        </svg>
                        </button>
                        <div className="cs7xl clbq0 cxsfz">
                        <div className="ceu9r c91aq clu2m">
                            <div className="text-gray-800 dark:text-gray-100 cgulq cydwr cu6vl">
                                Facture N° 
                                {invoice.invoice_number ? (
                                    invoice.invoice_number
                                ) : (
                                    <>...</>
                                )}
                            </div>
                            <div className="text-sm cydwr caf78">créé le {""}
                                {invoice.created_at ? (
                                    <FormattedDate date={invoice.created_at} />
                                ) : (
                                <>...</>
                                )}
                            </div>
                            {/* Details */}
                            <div className="c079l chlvh">
                            {/* Top */}
                            <div className="bg-white c2vpa cbtrn cydwr cwuak cx3hp">
                                <div className="cydwr cxg65">
                                <img
                                    className="inline-flex rounded-full cz4lh cutow ct03h"
                                    width={48}
                                    height={48}
                                    alt={invoice.titre}
                                    src={`data:image/jpeg;base64,${invoice.affiche}`}
                                />
                                </div>
                                <div className="c612e cgulq cbtcb cu6vl">
                                    {invoice.to_pay ? (
                                        formatPrice(invoice.to_pay)
                                    ) : (
                                        <>...</>
                                    )}
                                </div>
                                <div className="flex items-center justify-center cxg65">
                                    <div className='text-sm text-gray-800 dark:text-gray-100 c1k3n'>
                                        Montant total
                                    </div>
                                    <div className="cm84d ml-2">
                                        <button className="block" aria-haspopup="true" aria-expanded="false">
                                            <svg
                                            className="cmpw7 cdqku cbm9w"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            aria-haspopup="true"
                                            aria-expanded={isTooltipVisible}
                                            onMouseEnter={showTooltip}
                                            onMouseLeave={hideTooltip}
                                            >
                                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                                            </svg>
                                        </button>
                                        <div className={`-left-12 c7yzf cbh7j cqdkw cvggx group-hover:block transition-opacity duration-300 ${isTooltipVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
                                            }`}>
                                            <div className="bg-white border border-gray-200 cxe43 c1ukq cb8zv ccwri cx54e c6f83 cb2br cuvgf">
                                                <div className="c1iho text-left">
                                                    <div className="text-gray-800 c1k3n cjxkd">Montant total</div>
                                                    <div>
                                                        Ce montant représente la somme finale de la facture à regler, incluant 50% du montant total des ventes en fonction des modalités de paiement de Events
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!invoice.status ? (
                                    <>...</>
                                ) : (
                                    invoice.status === 'overdue' ? (

                                        <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n ch4gv cdw1w">
                                        En retard
                                        </div>
                                    ) : invoice.status === 'paid' ? (
                                        <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n ch4gv cdw1w">
                                        Payé
                                        </div>
                                    ) : invoice.status === 'unpaid' && (
                                        <div className="inline-flex text-xs rounded-full cfts0 c5px7 cydwr c1k3n ch4gv cdw1w">
                                        En attente
                                        </div>
                                    )
                                )}
                            </div>
                            {/* Divider */}
                            <div className="flex items-center cm3rx" aria-hidden="true">
                                <svg
                                className="cuxxq caoao"
                                width={20}
                                height={20}
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path d="M0 20c5.523 0 10-4.477 10-10S5.523 0 0 0h20v20H0Z" />
                                </svg>
                                <div className="bg-white flex justify-center c2vpa cetff c6btv cbw8w cf3id">
                                <div className="border-gray-200 dark:border-gray-700 cxxjf cr4kg c6btv cfwuq" />
                                </div>
                                <svg
                                className="cuxxq cbjxm caoao"
                                width={20}
                                height={20}
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path d="M0 20c5.523 0 10-4.477 10-10S5.523 0 0 0h20v20H0Z" />
                                </svg>
                            </div>
                            {/* Bottom */}
                            <div className="bg-white text-sm c2vpa ce8hz cjav5 cfxh8 csusu">
                                {invoice.organizer_invoice_payments ? (
                                    invoice.organizer_invoice_payments.payment_method === 'Virement_bancaire' ? (
                                        <div className="flex cm3rx cqkjy">
                                            <span className="text-xs caf78">
                                                IBAN:
                                            </span>
                                            {invoice.organizer_invoice_payments.payment_method ? (

                                                <span className="dark:text-gray-100 cf8dr c1k3n chvik">
                                                    {invoice.organizer_invoice_payments.payment_method}
                                                </span>
                                            ) : (
                                                <>...</>
                                            )}
                                        </div>
                                    ) : invoice.organizer_invoice_payments.payment_method === 'Cheque' && (
                                        <div className="flex cm3rx cqkjy">
                                            <span className="text-xs caf78">
                                                Numéro:
                                            </span>
                                            {invoice.organizer_invoice_payments.payment_method ? (

                                                <span className="dark:text-gray-100 cf8dr c1k3n chvik">
                                                    {invoice.organizer_invoice_payments.payment_method}
                                                </span>
                                            ) : (
                                                <>...</>
                                            )}
                                        </div>
                                    )
                                    
                                ) : (
                                    <div className="flex cm3rx cqkjy">
                                        <span className="text-xs caf78">
                                            IBAN:
                                        </span>
                                        <span className="dark:text-gray-100 cf8dr c1k3n chvik">
                                            CI 00 0000 0000 00
                                        </span>
                                    </div>
                                )}
                                <div className="flex cm3rx cqkjy">
                                    <span className="text-xs caf78">Montant initial :</span>
                                    <span className="dark:text-gray-100 cf8dr c1k3n chvik">
                                    {formatPrice(invoice.invoice_amount)}
                                    </span>
                                </div>
                                <div className="flex cm3rx cqkjy">
                                    <span className="text-xs caf78">Frais appliqués :</span>
                                    {invoice.frais_modal_facture ? (
                                        <span className="dark:text-gray-100 cf8dr c1k3n chvik">
                                            {invoice.frais_modal_facture}%
                                        </span>
                                    ) : (
                                        <>...</>
                                    )}
                                </div>
                                <div className="flex cm3rx cqkjy">
                                    <span className="text-xs caf78">Mode de paiement :</span>
                                    {invoice.organizer_invoice_payments ? (
                                        <span className="dark:text-gray-100 cf8dr c1k3n chvik">
                                        {invoice.organizer_invoice_payments.payment_method}
                                        </span>
                                    ) : (
                                        <>...</>
                                    )}
                                </div>
                                <div className="flex cm3rx cqkjy">
                                    <span className="text-xs caf78">Date paiement :</span>
                                    {invoice.organizer_invoice_payments ? (
                                        <span className="dark:text-gray-100 cf8dr c1k3n chvik">
                                        {formatDateToBar(invoice.organizer_invoice_payments.payment_date)}
                                        </span>
                                    ) : (
                                        <>...</>
                                    )}
                                </div>
                                <div className="flex cm3rx cqkjy">
                                <span className="text-xs caf78">Evénement:</span>
                                {!invoice.get_events_request_invoice ? (
                                    <>...</>
                                ) : (
                                    
                                    <span className="dark:text-gray-100 cf8dr c1k3n chvik">
                                        <Link to={`/dashboard/events/view-details/to/${invoice.get_events_request_invoice.id}`}>
                                            Giga concert live au palais de la culture
                                        </Link>
                                    </span>
                                )}
                                </div>
                            </div>
                            </div>
                            {/* Titre */}
                            <div className="cgndh">
                                <div className="text-sm text-gray-800 dark:text-gray-100 cgulq c6f83">
                                    Titre de la facture
                                </div>
                                <span className="text-sm">
                                {invoice.invoice_title}
                                </span>
                            </div>
                            {/* Notes */}
                            {invoice.comment && (
                                <div className="cgndh">
                                    <div className="text-sm text-gray-800 dark:text-gray-100 cgulq c6f83">
                                        Notes
                                    </div>
                                    <span className="text-sm">
                                        {invoice.comment}
                                    </span>
                                </div>
                            )}
                            {invoice.termes && (
                                <div className="cgndh">
                                    <div className="text-sm text-gray-800 dark:text-gray-100 cgulq c6f83">
                                        Termes
                                    </div>
                                    <span className="text-sm">
                                        {invoice.termes}
                                    </span>
                                </div>
                            )}
                            {/* Download / Report */}
                            <div className="flex items-center cp3jk cgndh">
                            <div className="c539s">
                                <button onClick={() => downloadInvoice(invoice.id)} disabled={loading} className="btn border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c6btv">
                                    {loadingBtn ? (
                                        <>
                                        <svg
                                            className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx={12}
                                                cy={12}
                                                r={10}
                                                stroke="currentColor"
                                                strokeWidth={3}
                                            />
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            />
                                        </svg>
                                        En cours...
                                        </>
                                    ) : (
                                        <>
                                        <svg
                                            className="cmpw7 cdqku cbm9w cbjxm coqgc"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M8 4c-.3 0-.5.1-.7.3L1.6 10 3 11.4l4-4V16h2V7.4l4 4 1.4-1.4-5.7-5.7C8.5 4.1 8.3 4 8 4ZM1 2h14V0H1v2Z" />
                                        </svg>
                                        <span className="c8bkw">Télécharger</span>
                                        </>
                                    )}
                                </button>
                            </div>
                            <div className="c539s">
                                <button onClick={openFormModal} className="btn border-gray-200 cc0oq cghq3 cspbm czr3n c6btv">
                                <svg
                                    className="cbm9w coqgc"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M7.001 3h2v4h-2V3Zm1 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM15 16a1 1 0 0 1-.6-.2L10.667 13H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1ZM2 11h9a1 1 0 0 1 .6.2L14 13V2H2v9Z" />
                                </svg>
                                <span className="c8bkw">Notifier Events</span>
                                </button>

                            </div>
                            </div>
                            {errorDownloading && (
                                <small className="text-red-600 mt-2">
                                    {errorDownloading}
                                </small>
                            )}
                        </div>
                        </div>
                    </div>
                </div>
                
            )}

        
            {isFormModalOpen && (
                <div className='inset-0 z-10 w-screen overflow-y-auto fixed bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center' style={{
                    zIndex: 100,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    overflowX: "hidden",
                    overflowY: "auto",
                    outline: 0,
                    left: 0,
                    margin: 0,
                }}>

                        {validationError && (
                            <div className="text-sm fixed top-0 z-[61] mt-4 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                                <div className="flex cm3rx ce4zk c6btv">
                                    <div className="flex">
                                    <svg
                                        className="cbm9w czr3n cq1qg coqgc chfzq"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                                    </svg>
                                    <div>{validationError}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {successMessage && (
                            <div className="text-sm fixed top-0 z-[61] mt-4 cf8dr cje53 cb8zv clbq0 cuvgf">
                                <div className="flex cm3rx ce4zk c6btv">
                                    <div className="flex">
                                    <svg
                                        className="c612e cbm9w cq1qg coqgc chfzq"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                                    </svg>
                                    <div>
                                    {successMessage}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    <div
                        id="feedback-modal"
                        className="flex items-center justify-center cxe43 cnbwt cini7 cjxg0 cys4p codu7 clbq0"
                        role="dialog"
                        aria-modal="true">
                        <div className="bg-white c2vpa co669 caufm cb8zv ccwri crwo8 c6btv">
                            {/* Modal header */}
                            <div className="border-gray-200 cghq3 ctv3r cx3hp c72q5">
                            <div className="flex items-center cm3rx">
                                <div className="text-gray-800 dark:text-gray-100 cgulq">
                                Notification à Events
                                </div>
                                <button onClick={closeFormModal} className="c3e4j cg12x cmpw7 cdqku">
                                    <div className="cn8jz">Fermer</div>
                                    <svg className="cbm9w" width={16} height={16} viewBox="0 0 16 16">
                                        <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                                    </svg>
                                </button>
                            </div>
                            </div>
                            {/* Modal content */}
                            <form onSubmit={handleSubmit}>
                                <div className="cx3hp cz8qb">
                                    <div className="text-sm mb-4">
                                        <div className="text-sm c9v1h cf8dr cb8zv clbq0 cuvgf">
                                            <div className='flex cm3rx ce4zk c6btv'>
                                                <div className="flex">
                                                    <svg
                                                    className="text-violet-500 cbm9w cq1qg coqgc chfzq"
                                                    width={16}
                                                    height={16}
                                                    viewBox="0 0 16 16"
                                                    >
                                                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
                                                    </svg>
                                                    <span>
                                                        Ce message contient des informations préremplis comme le code la facture de notification et sera envoyé à Events.
                                                        Veuillez envoyer un message qui concerne uniquement cette facture.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cjav5">
                                        <div>
                                        <label className="block text-sm c1k3n cu6vl" htmlFor="invoie_number">
                                            Code facture <span className="czr3n">*</span>
                                        </label>
                                        <input
                                            id="invoie_number"
                                            className="caqf9 c6btv c9hxi cwn3v"
                                            type="number"
                                            readOnly
                                            disabled
                                            required=""
                                            value={invoiceCode}
                                            onChange={(e) => setInvoiceCode(e.target.value)}
                                        />
                                        {errors.invoiceCode && (
                                            <small className="text-red-600 mt-2">
                                                {errors.invoiceCode}
                                            </small>
                                        )}
                                        </div>
                                        <div>
                                        <label className="block text-sm c1k3n cu6vl" htmlFor="objet">
                                            Objet de la notification <span className="czr3n">*</span>
                                        </label>
                                        <input
                                            id="objet"
                                            className="caqf9 c6btv c9hxi cwn3v"
                                            type="text"
                                            required=""
                                            placeholder="Entrez l'objet de votre notification"
                                            value={objet}
                                            onChange={(e) => setObjet(e.target.value)}
                                        />
                                        {errors.objet && (
                                            <small className="text-red-600 mt-2">
                                                {errors.objet}
                                            </small>
                                        )}
                                        </div>
                                        <div>
                                        <label className="block text-sm c1k3n cu6vl" htmlFor="message">
                                            Message <span className="czr3n">*</span>
                                        </label>
                                        <textarea
                                            id="message"
                                            className="c071z c6btv c9hxi cwn3v"
                                            rows={4}
                                            required=""
                                            placeholder='Entrez votre message'
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                        {errors.comment && (
                                            <small className="text-red-600 mt-2">
                                                {errors.comment}
                                            </small>
                                        )}
                                        </div>
                                    </div>
                                </div>
                                {/* Modal footer */}
                                <div className="border-gray-200 cghq3 cr4kg cx3hp cz8qb">
                                    <div className="flex flex-wrap justify-end ch3kz">
                                        <button onClick={closeFormModal} className="border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc cnf4p">
                                        Annuler
                                        </button>
                                        <button disabled={isSubmitDisabled()} type='submit' className="bg-primary-6000 cdj8c cg0jr ch8z9 cilvw cyn7a cnf4p hover:bg-primary-700">
                                        {loading ? (
                                            <>
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx={12}
                                                        cy={12}
                                                        r={10}
                                                        stroke="currentColor"
                                                        strokeWidth={3}
                                                    />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                </svg>
                                                Un instant...
                                            </>
                                        ) : (
                                            <>Envoyer la notification</>
                                        )}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
