import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import RecapFirstContentTab from '../../../components/OrganizersFoldersComponents/RecapFirstContentTab'
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';

export default function ViewDetails() {

    const { id } = useParams();  // Récupérer l'ID de l'événement à partir de l'URL
    const [eventDetails, setEventDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [responseDataActivities, setResponseDataActivities] = useState([]);
    const [eventCertification, setEventCertification] = useState(null);
    const [error, setError] = useState(null); // Nouvel état pour gérer les erreurs

    const fetchEventValidatorDetails = async () => {
        setError(null);   // Réinitialiser les erreurs
        // Appel API pour récupérer les événements en cours de validation
        try {
            const response = await axiosClient.get(`/dashboard/event-validation/get-details/e/${id}`);
            setEventDetails(response.data.event); // Données de l'événement
            setResponseDataActivities(response.data.responseDataActivities); // Activités associées 
            setEventCertification(response.data.eventCertification); // Activités associées  
        } catch (error) {
            if (error.response && error.response.data) {
                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Une erreur inconnue est survenue. Veuillez réessayer plus tard.',});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    // Fonction de mise à jour des données après soumission
    const handleCertificationUpdate = async () => {
        await fetchEventValidatorDetails(); // Recharge les données depuis l'API
    };

    useEffect(() => {
        if (id) {
            fetchEventValidatorDetails();
        }
    }, [id]);

    if(!id) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Aucun événement trouvé, impossible d'accéder à cette page.
                    </h4>
                    <Link to={'/dashboard/events/validations'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                    Evénements en cours de validation
                    </Link>
                </div>
            </div>
        )
    }

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Afficher une section dédiée en cas d'erreur
    if (error) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Erreur
                    </h4>
                    <div className="mb-6 text-sm">
                    {error.message}
                    </div>
                    {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                        <Link to={'/dashboard/events/validations'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Evénements en cours de validation
                        </Link>
                    )}
                    {error.errorCode === 'EVENT_NOT_PENDING' && (
                        <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Retour aux événements
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className='bg-white h-full'>
            <Link
            className="bg-white border-gray-200 text-gray-800 mt-4 ml-2 cc0oq cghq3 cspbm c0zkc c2vpa cnf4p cb2br"
            to="/dashboard/events/validations"
            >
                <svg
                    className="mr-2 cmpw7 cdqku cbm9w"
                    width={7}
                    height={12}
                    viewBox="0 0 7 12"
                >
                    <path d="M5.4.6 6.8 2l-4 4 4 4-1.4 1.4L0 6z" />
                </svg>
                <span>Voir la liste de validation</span>
            </Link>
            <div className="border-gray-200 cghq3 c2g1r cwnq4 ctv3r cnlq0 cnbwt clbq0 py-4">
                {/* Left: Title */}
                <div className="c2rn6 cdiog">
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                    Contrôle des Procédures <span className='text-primary-800'>{eventDetails.titre}</span>
                    </h4>
                </div>
                {/* Right: Actions */}
                <div className="cm3b7 c51uw ccww4 csdex cbe1i c4sak ml-4">
                    {/* Add event button */}
                    <Link to={'/dashboard/events/registration'} className="btn bg-primary-600 hover:bg-primary-700 text-white cdj8c cg0jr ch8z9 cilvw cyn7a">
                        Ajouter un événement
                    </Link>
                </div>
            </div>

            <div className="flex items-center justify-center ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz">
                <div className="cbb0u">
                    {/* Posts */}
                    <div className="">
                        {/* Post */}
                        <article className="cxtep">
                            <div className="clxbf">
                                <div className="cghq3 cbw8w cfv15">
                                    <header>
                                        <div className="flex flex-col sm:flex-row items-center c8vtj ch3kz cai6b">
                                            <div className="flex items-center">
                                                <svg
                                                className="mr-2 cbm9w"
                                                width={16}
                                                height={16}
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path d="M15.686 5.708 10.291.313c-.4-.4-.999-.4-1.399 0s-.4 1 0 1.399l.6.6-6.794 3.696-1-1C1.299 4.61.7 4.61.3 5.009c-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 14.001 2 15.4l3.696-3.697L9.692 15.7c.5.5 1.199.2 1.398 0 .4-.4.4-1 0-1.4l-.999-.998 3.697-6.695.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499Zm-7.193 6.095L4.196 7.507l6.695-3.697 1.298 1.299-3.696 6.694Z" />
                                                </svg>
                                                {eventDetails.request_identity}
                                            </div>
                                            <div className="co1wq cdqku">·</div>
                                            {eventDetails.status === 0 ? (
                                                <div className="btn-xs text-violet-600 rounded-full ccjm9 cpw2l ch4gv c1iho cwn3v">
                                                    En cours de validation...
                                                </div>
                                            ) : (
                                                <div className="btn-xs cz4gk font-medium text-center px-3 py-1 rounded-full cx6qj inline-flex cpw2l c1iho">
                                                    Refus de publication
                                                </div>
                                            )}
                                        </div>
                                    </header>
                                    <RecapFirstContentTab title="Récapitulatif" event={eventDetails} responseDataActivities={responseDataActivities} is_certified={eventCertification} onCertificationUpdate={handleCertificationUpdate} />
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    )
}
