import React, { useState } from 'react'

export default function Testimoniales() {

    const imagePath = process.env.REACT_APP_IMAGE_PATH;
    const [currentIndex, setCurrentIndex] = useState(0);

    const testimonials = [
        { id: 1, name: "Kadie Elise", role: "Cliente", text: "Grâce à Events, j'ai pu assister au concert de mes rêves ! La réservation de mon billet a été rapide et simple. Une expérience client impeccable !" },
        { id: 2, name: "Fabrice YACE", role: "Client", text: "Je suis un habitué de cinema et d'événement culturel, et Events est de loin la meilleure plateforme de réservation que j'ai utilisée. L'interface est conviviale, les options de paiement sont sécurisées, et j'ai toujours trouvé les billets que je cherchais. Je recommande vivement !" },
        { id: 3, name: "N'goran Elysee", role: "Client", text: "Events m'a permis de vivre une expérience inoubliable en famille. Nous avons pu réserver nos billets pour un spectacle de magie en quelques clics seulement, et nous avons passé une soirée magique tous ensemble. Merci Events pour ces souvenirs précieux !" },
    ];

    // Fonction pour naviguer
    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <section className="flex items-center justify-center">
            <div className="back-ground-page-particules-fs" style={{ top: '-2', width: '50%', height: '734px', zIndex: '1', left: '11rem', position: 'absolute', backgroundImage: `url(${imagePath}images/particles.png)` }} />
            <div className="w-full max-w-6xl mx-auto">
                <div className="flex flex-col items-center lg:flex-row">
                    <div className="flex flex-col items-start justify-center w-full h-full sm:pr-8 mb-5 lg:mb-0 lg:w-1/2">
                        <div>
                            <p className="text-xs font-bold text-blue-600 uppercase sm:text-normal sm:font-bold">
                                Voici ce qu'ils disent
                            </p>
                            <h3 className="mt-1 font-bold text-3xl md:text-4xl lg:text-5xl sm:mx-0">
                            Ils en parlent, et ils adorent !
                            </h3>
                        </div>
                        <p className="my-5 text-lg text-gray-600 dark:text-gray-400">
                        Découvrez ce que nos utilisateurs pensent de Events. Leur enthousiasme et leurs expériences 
                        uniques témoignent de la simplicité, de l’efficacité et du plaisir d’utiliser notre plateforme. 
                        Laissez-vous convaincre par leurs mots !
                        </p>
                    </div>
                    <div className="w-full lg:w-1/2">
                        <blockquote className="flex items-center justify-between w-full col-span-1 border border-gray-100 p-3 mt-4 bg-white shadow-md rounded-3xl">
                            <div className="flex flex-col sm:pr-10 overflow-hidden">
                                <div className="relative pl-12">
                                    <svg
                                        className="absolute left-0 w-10 h-10 text-blue-600 fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 100 125"
                                    >
                                        <path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z" />
                                    </svg>
                                    <p className="mt-2 text-sm text-gray-900 sm:text-base lg:text-sm xl:text-base">
                                        {testimonials[currentIndex].text}
                                    </p>
                                </div>
                                <h3 className="pl-12 mt-3 text-sm font-medium leading-5 text-gray-600 truncate sm:text-base lg:text-base">
                                {testimonials[currentIndex].name}
                                    <span className="mt-1 text-sm leading-5 text-gray-400 dark:text-gray-300 truncate">
                                        {" "}
                                        - {testimonials[currentIndex].role}
                                    </span>
                                </h3>
                                <p className="mt-1 text-sm leading-5 text-slate-500 truncate" />
                            </div>
                        </blockquote>
                        
                        <div
                            className="nc-NextPrev z-10 relative flex items-center text-neutral-900 dark:text-neutral-300 justify-center mt-8"
                            data-nc-id="NextPrev"
                            data-glide-el="controls"
                            >
                            <button
                                className="w-10 h-10 mr-[6px] bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 focus:outline-none"
                                title="Prev"
                                data-glide-dir="◀"
                                onClick={goToPrevious}
                            >
                                <i className="las la-angle-left" />
                            </button>
                            <button
                                className="w-10 h-10 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 focus:outline-none"
                                title="Next"
                                data-glide-dir="▶"
                                onClick={goToNext}
                            >
                                <i className="las la-angle-right" />
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}
