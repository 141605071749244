// import resolveConfig from 'tailwindcss/resolveConfig'
// import tailwindConfigFile from '@tailwindConfig'

export const tailwindConfig = () => {
  return {
    themes: {
      // extend: {
        colors: {
          gray: {
            50: '#F9FAFB',
            100: '#F3F4F6',
            200: '#E5E7EB',
            300: '#BFC4CD',
            400: '#9CA3AF',
            500: '#6B7280',
            600: '#4B5563',
            700: '#374151',
            800: '#1F2937',
            900: '#111827',
            950: '#030712',
          },
          violet: {
            50: '#F1EEFF',
            100: '#E6E1FF',
            200: '#D2CBFF',
            300: '#B7ACFF',
            400: '#9C8CFF',
            500: '#8470FF',
            600: '#755FF8',
            700: '#5D47DE',
            800: '#4634B1',
            900: '#2F227C',
            950: '#1C1357',
          },
          sky: {
            50: '#E3F3FF',
            100: '#D1ECFF',
            200: '#B6E1FF',
            300: '#A0D7FF',
            400: '#7BC8FF',
            500: '#67BFFF',
            600: '#56B1F3',
            700: '#3193DA',
            800: '#1C71AE',
            900: '#124D79',
            950: '#0B324F',
          },
          green: {
            50: '#D2FFE2',
            100: '#B1FDCD',
            200: '#8BF0B0',
            300: '#67E294',
            400: '#4BD37D',
            500: '#3EC972',
            600: '#34BD68',
            700: '#239F52',
            800: '#15773A',
            900: '#0F5429',
            950: '#0A3F1E',
          },
          red: {
            50: '#FFE8E8',
            100: '#FFD1D1',
            200: '#FFB2B2',
            300: '#FF9494',
            400: '#FF7474',
            500: '#FF5656',
            600: '#FA4949',
            700: '#E63939',
            800: '#C52727',
            900: '#941818',
            950: '#600F0F',
          },
          yellow: {
            50: '#FFF2C9',
            100: '#FFE7A0',
            200: '#FFE081',
            300: '#FFD968',
            400: '#F7CD4C',
            500: '#F0BB33',
            600: '#DFAD2B',
            700: '#BC9021',
            800: '#816316',
            900: '#4F3D0E',
            950: '#342809',
          },
        },
        screens: {
          xs: '480px',
        },
        borderWidth: {
          3: '3px',
        },
        minWidth: {
          36: '9rem',
          44: '11rem',
          56: '14rem',
          60: '15rem',
          72: '18rem',
          80: '20rem',
        },
        maxWidth: {
          '8xl': '88rem',
          '9xl': '96rem',
        },
        zIndex: {
          60: '60',
        },
        width: {
          2: '0.5rem', // 8px
        },
        height: {
          2: '0.5rem', // 8px
        },
        margin: {
          1: '0.25rem', // 4px
        },
        borderRadius: {
          sm: '0.125rem', // 2px
        },
      // },
    },
  };
};
console.log(tailwindConfig().themes.colors.gray[400]);

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const formatThousands = (value) => Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);
