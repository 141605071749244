import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../api/axiosClient';

export default function ResetPasswordForm() {
    const location = useLocation();
    const email = location.state?.email;
    const action = location.state?.action;

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('')
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [successMessage, setSuccessMessage] = useState();

    useEffect(() => {
        // Si l'email est introuvable, rediriger vers la page de connexion
        if (!email) {
            navigate('/login');
        }
    }, [email, navigate]);

    const validateForm = () => {
        const newErrors = {};

        const trimmedPassword = newPassword.trim();
        const trimmedConfirmPassword = confirmPassword.trim();
        const minLength = 8;
        if (!trimmedPassword) {
            newErrors.newPassword = "Le mot de passe est requis";
        } else if (trimmedPassword.length < minLength) {
          newErrors.newPassword = "Le mot de passe doit contenir au moins 8 caractères";
        }

        const hasUpperCase = /[A-Z]/.test(newPassword);
        if (!hasUpperCase) {
            newErrors.newPassword = 'Le mot de passe doit contenir au moins une lettre majuscule.';
        }

        // Vérifier les règles de conformité des mots de passe
        if (!trimmedConfirmPassword) {
            newErrors.confirmPassword = "Veuillez confirmer votre nouveau mot de passe";
        } else 

        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Réinitialiser l'erreur de validation à chaque soumission
        setValidationError('');
        if (newPassword !== confirmPassword) {
            setValidationError('Les mots de passe ne correspondent pas.');
            return
        }

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            // Logique pour mettre à jour le mot de passe
            axiosClient.post('/request/user/password/reset', { newPassword, email, action })
            .then(({ data }) => {
                
                    
                if (data.status === 200 && data.mdp_reseted) {

                    setSuccessMessage(data.message +' Redirection en cours...');
                    
                    // Rediriger l'utilisateur vers la page de vérification du code
                    setTimeout(() => {

                        // Rediriger vers le tableau de bord ou la page de connexion après la vérification
                        navigate('/login');
                        // Recharger la page pour s'assurer que l'état est bien actualisé
                        window.location.reload();
                    }, 4000);
                } else {
                    setValidationError("Une erreur est survenue, veuillez réessayer.");
                    setTimeout(() => setValidationError(""), 7000);
                }
                setLoading(false);
                
            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        setValidationError(response.data.errors);
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Une erreur est survenue, veuillez réessayer.");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
        } 
            
        setErrors(validationErrors);
    };

    const isSubmitDisabled = () => {
        return loading || !newPassword || !confirmPassword;
    };

    return (
        <>
            <form className="grid grid-cols-1 gap-6">
                        
                <label className="block" htmlFor='newPassword'>
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm">
                        Nouveau mot de passe <span className="text-red-600">*</span>
                    </span>
                    <input
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        name='newPassword'
                        type="password"
                        // required
                        placeholder="Entrez votre nouveau mot de passe"
                        className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                    />
                    {errors.newPassword && (
                        <small className="text-red-600 mt-2">
                            {errors.newPassword}
                        </small>
                    )}
                </label>
                <label className="block" htmlFor='confirmPassword'>
                    <span className="text-neutral-800 dark:text-neutral-200 text-sm">
                        Confirmer le nouveau mot de passe <span className="text-red-600">*</span>
                    </span>
                    <input
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setconfirmPassword(e.target.value)}
                        type="password"
                        name='confirmPassword'
                        // required
                        placeholder="Confirmer votre nouveau mot de passe"
                        className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                    />
                    {errors.confirmPassword && (
                        <small className="text-red-600 mt-2">
                            {errors.confirmPassword}
                        </small>
                    )}
                </label>
                <div className='inline-flex items-center justify-center flex-col'>
                    {validationError && (
                        <div
                            className="px-2.5 py-1 p-3 mt-3 flex items-center justify-center nc-Badge rounded-full font-medium text-xs text-red-800 bg-red-100"
                            style={{width: "fit-content"}}
                        >
                            <span>
                                <svg
                                viewBox="0 0 24 24"
                                width={20}
                                height={20}
                                stroke="currentColor"
                                strokeWidth={2}
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="me-2"
                                >
                                <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                                <line x1={15} y1={9} x2={9} y2={15} />
                                <line x1={9} y1={9} x2={15} y2={15} />
                                </svg>
                            </span>
                            <span className="text-sm ml-3 mr-3">{validationError}</span>
                        </div>
                    )}
                    {successMessage && (
                        <div
                            className="px-2.5 py-1 mt-3 flex items-center justify-center nc-Badge rounded-full font-medium text-xs text-green-800 bg-green-100"
                            style={{width: "fit-content"}}
                        >
                            <span>
                                <svg
                                viewBox="0 0 24 24"
                                width={20}
                                height={20}
                                stroke="currentColor"
                                strokeWidth={2}
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="me-2"
                                >
                                <polyline points="9 11 12 14 22 4" />
                                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
                                </svg>
                            </span>
                            <span className="text-sm ml-3 mr-3">{successMessage}</span>
                        </div>
                    )}
                    <button
                        className="nc-Button relative w-full h-auto mt-4 inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none dark:focus:ring-offset-0"
                        onClick={handleSubmit}
                        disabled={isSubmitDisabled()}
                    >
                        {loading ? (
                            <>
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx={12}
                                        cy={12}
                                        r={10}
                                        stroke="currentColor"
                                        strokeWidth={3}
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                                Veuillez patienter...
                            </>
                        ) : (
                            <>Réinitialiser le mot de passe</>
                        )}
                    </button>
                </div>
            </form>
        </>
    )
}
