import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ForgotPasswordForm from '../../../components/Authentication/ForgotPasswordForm'

export default function IndexForgotPassword() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Mot de passe oublié - Events";
        
    }, []);

    return (
        <>
            <div className="nc-PageLogin " data-nc-id="PageLogin">
                <div className="container mb-24 lg:mb-32">
                    <h2 className="my-10 mb-5 flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-bold text-neutral-900 dark:text-neutral-100 justify-center">
                        Mot de passe oublié !
                    </h2>
                    <div className="relative text-center max-w-md mx-auto space-y-11">
                        <span className="relative inline-block px-4 text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                            Veuillez saisir l'adresse e-mail lié à votre compte afin de le réinitialiser.
                        </span>
                    </div>
                    <div className="max-w-md mx-auto space-y-6 mt-12">
                    
                        <ForgotPasswordForm />
                        <span className="block text-center text-neutral-700 dark:text-neutral-300">
                            Vous avez déjà un compte?{' '} <Link to="/login" className='font-medium text-primary-6000'>Connectez-vous ici</Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}
