import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import axiosClient from '../../../components/api/axiosClient';
import FormattedDate from '../../../components/Utils/FormattedDate';
import { formatNumber } from '../../../components/Utils/UtilsNumbersFormat';
import { formatPrice } from '../../../components/Utils/UtilsPricesFormat';

export default function ProfilCustomerDetails() {

    const { id } = useParams();  // Récupérer l'ID de l'événement à partir de l'URL
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [detailsBuyer, setDetailsBuyer] = useState(null);

    const fetchEventDetails = async () => {
        setError(null);   // Réinitialiser les erreurs
        setLoading(true); 
        // Appel API pour récupérer les événements en cours de validation
        try {
            const response = await axiosClient.get(`/dashboard/organizer/getParticipant/sales/details/p/${id}`);
            setDetailsBuyer(response.data.data); // Données de l'événement
        } catch (error) {
            if (error.response && error.response.data) {
                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Une erreur inconnue est survenue. Veuillez réessayer plus tard.',});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    useEffect(() => {
        if (id) {
            fetchEventDetails();
        }
    }, [id]);

    if(!id) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Aucun participant trouvé, impossible d'accéder à cette page.
                    </h4>
                    <Link to={'/dashboard/achats/tickets/clients-acheteurs'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                    Retour à la liste
                    </Link>
                </div>
            </div>
        )
    }

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Afficher une section dédiée en cas d'erreur
    if (error) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="w-3/4 cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Erreur
                    </h4>
                    <div className="mb-6 text-sm">
                    {error.message}
                    </div>
                    {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                        <Link to={'/dashboard/achats/tickets/clients-acheteurs'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Liste des participants
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="bg-sidebar-linkGroup cecbd h-32">
            </div>
            <header className="border-gray-200 cghq3 ca6f1 cobdn cydwr ctv3r cfv15">
                <div className="cnbwt cs7xl c6btv clbq0">
                    <div className="cbb0u clu2m">
                    {/* Avatar */}
                    <div className="c5ksg c6f83">
                        <div className="inline-flex c2rn6 cxx4k ccpi7">
                            <span className='rounded-full py-6 px-7 text-primary-600 font-extrabold text-xl bg-white border-primary-100 cdnc2 cv1so cmjfg'>
                            {detailsBuyer.participantInitialsName}
                            </span>
                        </div>
                    </div>
                    {/* Company name and info */}
                    <div className="cdiog">
                        <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb c6f83">
                        {detailsBuyer.participant.username}
                        </h2>
                        <p>
                        Vous avez la possibilité de voir le profil de ce participant avec un aperçu bref des tickets achetés.
                        </p>
                    </div>
                    {/* Meta */}
                    <div className="inline-flex flex-wrap justify-center cn439 cm4ey">
                        <div className="flex items-center">
                            <svg
                                className="cmpw7 cdqku cbm9w coqgc"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z" />
                            </svg>
                            <span className="text-sm text-gray-500 dark:text-gray-400 cq84g c1k3n c8bkw">
                            {detailsBuyer.participant.adresse}
                            </span>
                        </div>
                        <div class="co1wq cdqku mr-4">·</div>
                        <div className="flex items-center">
                            <span
                                className="text-sm text-gray-500 c5ylh cq84g c1k3n c8bkw"
                            >
                                <FormattedDate date={detailsBuyer.participant.created_at} />
                            </span>
                        </div>
                    </div>
                    </div>
                </div>
            </header>

            <div className="cnbwt cs7xl c6btv clbq0 cxsfz">
                <div className="cbb0u clu2m">
                    <h3 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cai6b">
                    Aperçu des tickets achetés
                    </h3>
                    {/* Job list */}
                    <div className="cmxzb">
                    {/* Group 1 */}
                    <div>
                        {detailsBuyer.eventList.length > 0 ? (

                            <div className="cweej">
                                {detailsBuyer.eventList.map(order => (
                                    <div className="bg-white c2vpa c1hly c5vqk cx3hp cz8qb">
                                        <div className="items-center cm3rx ccjpx ch3kz cr78y clgee">
                                            {/* Left side */}
                                            <div className="flex items-center cmxlj ce4zk cp3jk">
                                                <div className="coqgc ccwg3">
                                                <img
                                                    className="rounded-md w-16 h-12"
                                                    width={46}
                                                    height={46}
                                                    src={`data:image/jpeg;base64,${order.event_banner}`}
                                                    alt={order.event_name}
                                                />
                                                </div>
                                                <div>
                                                    <Link
                                                        className="block text-gray-800  dark:text-gray-100 cgulq"
                                                        to={`/dashboard/events/view-details/to/${order.event_id}`}
                                                    >
                                                        {order.event_name} 
                                                    </Link>
                                                    <div className="text-sm">Date de l'événement : {order.event_date} </div>
                                                </div>
                                            </div>
                                            {/* Right side */}
                                            <div className="flex sm:pl-6 sm:flex-row flex-col items-center cm4ey ca2zo">
                                                <div className="text-xs text-gray-500 dark:text-gray-400 cq84g caf78">
                                                {formatNumber(order.quantity)} ticket(s) {order.pass_name} - {formatPrice(order.price)}
                                                </div>
                                                {order.status === 'ACCEPTED' ? (

                                                    <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n ch4gv cdw1w">
                                                        {order.status}
                                                    </div>
                                                ) : order.status === 'REFUSED' ? (
                                                    
                                                    <div className='inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n ch4gv cdw1w'>
                                                        {order.status}
                                                    </div>
                                                    
                                                ) : (
                                                    <div className="inline-flex text-xs rounded-full cfts0 c5px7 cydwr c1k3n ch4gv cdw1w">
                                                        {order.status}
                                                    </div>
                                                )}
                                                
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="w-full h-full flex justify-center items-center cweej">
                                <div className="w-3/4 cydwr clbq0">
                                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path
                                                className="text-gray-500 co1wq"
                                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="dark:text-gray-400 ckbo4"
                                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="cmpw7 cdqku"
                                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                    Aucune données trouvées.
                                    </h4>
                                    <div className="mb-6 text-sm">
                                    {'Aucun achat valide trouvé pour ce participant.'}
                                    </div>
                                    <Link to={'/dashboard/achats/tickets/clients-acheteurs'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                                        Liste des participants
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                    </div>
                </div>
            </div>

        </>
    )
}
