import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useStateContext } from '../components/contexts/ContextProvider';
import LoadContentSpinner from '../components/Utils/LoadContentSpinner';

export default function InscriptionNewsLettersEvents() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Inscription à la Newsletter - Events";
        
    }, []);

    const { loading } = useStateContext();
    
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        domaine: '',
    });

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingBtn, setLoadingBtn] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const validate = () => {
        const errors = {};
        
        // Validation du nom
        const nomTrimmed = formData.nom.trim();
        if (!nomTrimmed) {
            errors.nom = 'Le nom est requis';
        } else if (!/^[A-Za-zÀ-ÖØ-ÿ\s]+$/.test(nomTrimmed)) {
            errors.nom = 'Le nom ne peut contenir que des lettres';
        }

        // Validation du prénom
        const prenomTrimmed = formData.prenom.trim();
        if (!prenomTrimmed) {
            errors.prenom = 'Le prénom est requis';
        } else if (!/^[A-Za-zÀ-ÖØ-ÿ\s]+$/.test(prenomTrimmed)) {
            errors.prenom = 'Le prénom ne peut contenir que des lettres';
        }

        // Validation de l'adresse e-mail
        const emailTrimmed = formData.email.trim();
        if (!emailTrimmed) {
            errors.email = 'L\'adresse e-mail est requise';
        } else if (!/\S+@\S+\.\S+/.test(emailTrimmed)) {
            errors.email = 'L\'adresse e-mail est invalide';
        }

        // Validation du numéro de téléphone
        const phoneTrimmed = formData.telephone ? formData.telephone.trim() : '';
        if (phoneTrimmed && !/^\+?\d[\d\s]*$/.test(phoneTrimmed)) {
            errors.telephone = 'Le numéro de téléphone est invalide';
        }

        // Validation du domaine d'activité
        const domaineTrimmed = formData.domaine.trim();
        if (domaineTrimmed && domaineTrimmed.length < 3) {
            errors.domaine = 'Veuillez renseigner correctement votre domaine d\'activité';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoadingBtn(true); // Activer le chargement
            try {
                axios.post("https://backoffice.app-events.com/api/clientAuth/newsletter/Events/subscribe/c2a22762-237a-4b58-adda-664827c60e0a/newReferralInfo/side", formData,{
                    headers: {
                        withCredentials: true,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    // Mise à jour du statut selon la réponse du serveur
                    setSuccessMessage(response.data.messages);
        
                    setLoadingBtn(false);
                    // Réinitialiser le formulaire après succès, si nécessaire
                    setFormData({
                        nom: '',
                        prenom: '',
                        email: '',
                        telephone: '',
                        domaine: '',
                    });
                    // Fermer le message
                    setTimeout(() => setSuccessMessage(""), 5000);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 422) {
                            // Mise à jour du statut en cas d'erreur de validation
                            const errorMessage = error.response.data.errors.email || 
                                     "Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !";
                            setErrorMessage(errorMessage);
                            setLoadingBtn(false);
                            // Fermer le message
                            setTimeout(() => setErrorMessage(""), 5000);
                        }else if (error.response.status === 500) {
                            setErrorMessage(error.response.data.errors);
                            setLoadingBtn(false);

                            // Fermer le message
                            setTimeout(() => setErrorMessage(""), 5000);
                        } else {
                            setErrorMessage("OUPS ! Une erreur est survenue, veuillez réessayer ultérieurement.");
                            
                            setLoadingBtn(false);
                            setTimeout(() => setErrorMessage(""), 5000);
                        }
                    } else {

                        setErrorMessage(
                            "OUPS ! Une erreur est survenu, Veuillez réessayer ulterieurement."
                        );
                        setLoadingBtn(false);
                        setTimeout(() => setErrorMessage(""), 5000);
                    }
                });
            } catch (err) {
                setErrorMessage("Erreur de traitement ! Veuillez réessayer s'il vous plait !");
                setLoadingBtn(false);
                // Fermer le message
                setTimeout(() => setErrorMessage(""), 5000);
            }
        }
    };

    if (loading) {
        return <LoadContentSpinner />;
    }

    return (
        <div className='bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'>
            <Helmet>
                <meta name="description" content="Abonnez-vous à notre newsletter pour recevoir les dernières actualités et offres spéciales d'Events. Découvrez les nouveautés, conseils exclusifs et promotions directement dans votre boîte mail. Inscrivez-vous maintenant pour ne rien manquer !" />
                <meta 
                    name="keywords" 
                    content="newsletter, inscription, abonnés, Events, Events App, actualités, offres spéciales, promotions" 
                />
            </Helmet>
            <div className="glow-container">
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
            </div>
            
            <div className="py-24 pt-10">
                <div className="nc-SectionHowItWork" style={{marginBottom: '5rem'}}>
                    <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-center justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50" style={{textAlign: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <div className="text-center w-full max-w-2xl mx-auto">
                            <h2 className="text-2xl md:text-2xl font-bold">Abonnez-vous à notre Newsletter</h2>
                        </div>
                        <span className="mt-2 px-2 md:mt-3 text-sm font-normal block text-neutral-500 dark:text-neutral-400 w79">
                            
                            Ne manquez aucune actualité et ne passez pas à côté des meilleurs événements ! En vous inscrivant à notre newsletter, vous recevrez directement dans votre boîte mail :
                        </span>
                        <ul className="space-y-4 mt-10  trext-sm px-2 md:mt-3 block text-neutral-500 dark:text-neutral-400">
                            <li className="flex items-center space-x-4 text-left">
                                <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100  relative">
                                01
                                </span>
                                <span className="font-medium  text-sm text-neutral-700 dark:text-neutral-300">
                                Les dernières nouveautés et fonctionnalités de l'application Events
                                </span>
                            </li>
                            <li className="flex items-center space-x-4 text-left">
                                <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-red-800 bg-red-100  relative">
                                02
                                </span>
                                <span className="font-medium  text-sm text-neutral-700 dark:text-neutral-300">
                                Des conseils exclusifs pour maximiser votre expérience de réservation
                                </span>
                            </li>
                            <li className="flex items-center space-x-4 text-left">
                                <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-800 bg-green-100  relative">
                                03
                                </span>
                                <span className="font-medium  text-sm text-neutral-700 dark:text-neutral-300">
                                Des offres spéciales et des promotions sur nos événements
                                </span>
                            </li>
                        </ul>
                        <span className="mt-8 px-2 md:mt-8 font-normal block text-sm text-neutral-500 dark:text-neutral-400 w79">
                            
                            Restez informé et soyez toujours au courant des événements les plus excitants près de chez vous.
                        </span>
                        
                    </div>
                </div>

                <main className="container relative z-10 mt-11 flex flex-col" style={{alignItems: 'center'}}>
                    
                    <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
                        <form onSubmit={handleSubmit}>
                            <div className="listingSection__wrap " style={{boxShadow: '0px 4px 6px 4px rgb(11 46 123 / 2%), 0px 10px 9px 4px rgb(83 133 243 / 1%)',border: 'none',}}>
                                
                                <div className="space-y-8">
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5'>

                                        <div className="">
                                            <label
                                                className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 "
                                            >
                                                Nom <span className="text-red-600">*</span>
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                required
                                                type="text"
                                                name='nom'
                                                value={formData.nom}
                                                onChange={handleChange}
                                                className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 "
                                                placeholder="Entrez votre nom"
                                                />
                                            </div>
                                            {errors.nom && 
                                                <span className="text-red-600 mt-2 text-xs">
                                                    {errors.nom}
                                                </span>
                                            }
                                        </div>
                                        <div className="">
                                            <label
                                                className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 "
                                                data-nc-id="Label"
                                            >
                                                Prénom <span className="text-red-600">*</span>
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                required
                                                type="text"
                                                name='prenom'
                                                value={formData.prenom}
                                                onChange={handleChange}
                                                className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 "
                                                placeholder="Entrez votre prénom"
                                                />
                                            </div>
                                            {errors.prenom && 
                                                <span className="text-red-600 mt-2 text-xs">
                                                    {errors.prenom}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5'>

                                        <div className="">
                                            <label
                                                className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 "
                                                data-nc-id="Label"
                                            >
                                                Adresse e-mail <span className="text-red-600">*</span>
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    required
                                                    type="email"
                                                    name='email'
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 "
                                                    placeholder="exemple: email@gmail.com"
                                                />
                                            </div>
                                            {errors.email && 
                                                <span className="text-red-600 mt-2 text-xs">
                                                    {errors.email}
                                                </span>
                                            }
                                        </div>
                                        <div className="">
                                            <label
                                                className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 "
                                                data-nc-id="Label"
                                            >
                                                Numéro de téléphone
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name='telephone'
                                                    value={formData.telephone}
                                                    onChange={handleChange}
                                                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 "
                                                    placeholder="exemple: +225 0100360010"
                                                />
                                            </div>
                                            {errors.telephone && 
                                                <span className="text-red-600 mt-2 text-xs">
                                                    {errors.telephone}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="">
                                        <label
                                            className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 "
                                            data-nc-id="Label"
                                        >
                                            Domaine d'activité
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                value={formData.domaine}
                                                onChange={handleChange}
                                                name='domaine'
                                                className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 "
                                                placeholder="Entrez votre demaine d'activité"
                                            />
                                        </div>
                                        {errors.domaine && 
                                            <span className="text-red-600 mt-2 text-xs">
                                                {errors.domaine}
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end space-x-5" style={{alignItems: 'flex-end'}}>
                                {successMessage && 
                                    <div
                                    className="px-2.5 py-1 mt-3 flex nc-Badge rounded-full font-medium text-xs text-green-800 bg-green-100"
                                    style={{width: "fit-content",alignItems: 'center',height: '34px'}}
                                >
                                    <span>
                                        <svg
                                        viewBox="0 0 24 24"
                                        width={20}
                                        height={20}
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="me-2"
                                        >
                                        <polyline points="9 11 12 14 22 4" />
                                        <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
                                        </svg>
                                    </span>
                                    <span className="text-sm ml-3 mr-3">{successMessage}</span>
                                    </div>
                                }
                                {errorMessage && 
                                    <div
                                    className="px-2.5 py-1 p-3 mt-3 flex nc-Badge rounded-full font-medium text-xs text-red-800 bg-red-100"
                                    style={{width: "fit-content",alignItems: 'center',height: '34px'}}
                                >
                                    <span>
                                        <svg
                                        viewBox="0 0 24 24"
                                        width={20}
                                        height={20}
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="me-2"
                                        >
                                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                                        <line x1={15} y1={9} x2={9} y2={15} />
                                        <line x1={9} y1={9} x2={15} y2={15} />
                                        </svg>
                                    </span>
                                    <span className="text-sm ml-3 mr-3">{errorMessage}</span>
                                    </div>
                                }
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className={`mt-4 nc-Button relative h-auto inline-flex items-center justify-center rounded-xl transition-colors text-sm sm:text-base font-medium px-4 py-2 sm:px-3 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${loadingBtn ? 'pointer-events-none opacity-50' : ''}`}
                                >
                                    {loadingBtn ? (
                                    <>
                                        <svg
                                            className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx={12}
                                                cy={12}
                                                r={10}
                                                stroke="currentColor"
                                                strokeWidth={3}
                                            />
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            />
                                        </svg>
                                        Inscription en cours...
                                    </>
                                ) : (
                                    <>
                                        Finaliser l'inscription
                                    </>
                                )}
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    )
}
