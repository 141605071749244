import React, { useEffect } from 'react'
import ResetPasswordForm from '../../../components/Authentication/ResetPasswordForm';

export default function IndexResetPassword() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Réinitialisation de mot de passe - Events";
        
    }, []);

    return (
        <>
            <div className="nc-PageLogin " data-nc-id="PageLogin">
                <div className="container mb-24 lg:mb-32">
                    <h2 className="my-10 mb-5 flex items-center text-2xl leading-[115%] md:text-2xl md:leading-[115%] font-bold text-neutral-900 dark:text-neutral-100 justify-center">
                        Réinitialisation du Mot de Passe
                    </h2>
                    {/* <div className="relative text-center max-w-md mx-auto space-y-11">
                        <span className="relative inline-block px-4 bg-white dark:text-neutral-400 dark:bg-neutral-900">
                            Veuillez saisir l'adresse e-mail lié à votre compte afin de le réinitialiser.
                        </span>
                    </div> */}
                    <div className="max-w-md mx-auto space-y-6 mt-12">
                        
                        <ResetPasswordForm />
                    </div>
                </div>
            </div>
        </>
    )
}
