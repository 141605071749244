import { Navigate } from "react-router-dom";
import { useStateContext } from "../components/contexts/ContextProvider";

const GuestRoute = ({ children }) => {
    const { token } = useStateContext(); // Vérifiez si le token est présent

    if (token) {
        // Si l'utilisateur est connecté, redirigez-le vers le tableau de bord
        return <Navigate to="/dashboard" replace />;
    }

    // Sinon, laissez l'utilisateur accéder à la route
    return children;
};

export default GuestRoute;
