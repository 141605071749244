import React, { useEffect, useState } from 'react'
import DateSelect from '../../../components/Dashboard/DateSelect'
import { Link } from 'react-router-dom'
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import { formatNumber } from '../../../components/Utils/UtilsNumbersFormat';
import { formatPrice } from '../../../components/Utils/UtilsPricesFormat';

export default function ViewListCustomers() {

    const [getUsers, setGetUsers] = useState([]); // Liste des acheteurs
    const [filteredUsers, setFilteredUsers] = useState([]); // Liste des acheteurs
    const [selectedDate, setSelectedDate] = useState(null); // Date sélectionnée
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchText, setSearchText] = useState(''); // État pour la recherche

    const fetchGetUsers = async (date) => {
        setError(null)
        setLoading(true)

        try {
            const response = await axiosClient.get(`/dashboard/organizer/fidelity-sales-customers/orders/get/global`, {
                params: {
                    date, // Envoyer la date comme paramètre
                },
            });
            setGetUsers(response.data.data); // Mettre à jour la liste des acheteurs
            setFilteredUsers(response.data.data); // Mettre à jour la liste des acheteurs
        } catch (error) {
            if (error.response && error.response.data) {

                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Erreur lors du chargement des ventes'});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    // Appeler l'API chaque fois que `selectedDate` change
    useEffect(() => {
        fetchGetUsers(selectedDate);
    }, [selectedDate]);

    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        // Filtrer les ventes par le texte de recherche
        const filtered = getUsers.filter((user) =>
            user.name.toLowerCase().includes(value) || // Exemple : filtrer par ID de facture
            user.address.toLowerCase().includes(value) // Exemple : filtrer par nom du client
        );
        setFilteredUsers(filtered);
    };

    return (
        <div className='ch3yp h-full cnbwt cs7xl clu2m c6btv clbq0 cxsfz'>
            {error ? (

                <div className="w-full h-full flex justify-center items-center cweej">
                    <div className="cydwr clbq0">
                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                            <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path
                                    className="text-gray-500 co1wq"
                                    d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                    fill="currentColor"
                                />
                                <path
                                    className="dark:text-gray-400 ckbo4"
                                    d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                    fill="currentColor"
                                />
                                <path
                                    className="cmpw7 cdqku"
                                    d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                        Erreur
                        </h4>
                        <div className="break-all mb-6 text-sm">
                        {error.message}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="c2g1r cwnq4 cnlq0 c6r0l">
                        {/* Left: Title */}
                        <div className="c2rn6 cdiog">
                            <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                            Liste des Participants
                            </h1>
                            <span class="text-sm">Visualisez la liste complete de tous vos participants (ayant acheté vos tickets événementiels) dépuis votre premier événement jusqu'au dernier</span>
                        </div>
                        <div className=" cm3b7 c51uw ccww4 csdex cbe1i c4sak">
                            <DateSelect onDateChange={(date) => setSelectedDate(date)} />
                        </div>
                    </div>
                    {loading ? (
                        <div className='w-full flex justify-center items-center'>
                            <LoadContentSpinner />
                        </div>
                    ) : getUsers.length > 0 ? (
                        <div className="bg-white c2vpa c1hly c5vqk">
                            <header className="flex items-center justify-between flex-col sm:flex-row cx3hp cz8qb">
                                <h2 className="text-gray-800 dark:text-gray-100 mb-2 sm:mb-0 cgulq">
                                Affichés <span className="cmpw7 cdqku c1k3n">{filteredUsers.length}</span>
                                </h2>
                                <div className="cm84d">
                                    <label htmlFor="action-search" className="cn8jz">
                                        Search
                                    </label>
                                    <input
                                        id="action-search"
                                        className="bg-white border-gray-100 focus:border-gray-200 focus:ring-transparent pl-9 c2vpa caqf9 ct9oo"
                                        type="search"
                                        placeholder="Cherchez un nom ou adresse"
                                        value={searchText}
                                        onChange={handleSearchChange}
                                    />
                                    <button onClick={(e) => e.preventDefault()} className="c29dn cqdkw cini7 cqogy" type="button" aria-label="Search">
                                        <svg
                                        className="ml-3 mr-2 cba8l c4it8 cmpw7 cdqku cbm9w coqgc"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                        </svg>
                                    </button>
                                </div>
                            </header>
                            <div>
                                {/* Table */}
                                <div className="cocyr">
                                <table className="c0zkc cn9pt c6btv">
                                    {/* Table header */}
                                    <thead className="text-gray-500 dark:text-gray-400 cghq3 cib75 cbv37 cgulq cgk1f c0ef0 ctv3r cr4kg c1iho">
                                    <tr>
                                        <th className="cq84g cyjcc cgn91 cn8zk c9hxi c72q5">
                                        <span className="cn8jz">Favourite</span>
                                        </th>
                                        <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                        <div className="cgulq c2hoo">Nom</div>
                                        </th>
                                        <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                        <div className="cgulq c2hoo">Adresse</div>
                                        </th>
                                        <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                        <div className="cgulq">Total Commandes</div>
                                        </th>
                                        <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                        <div className="cgulq c2hoo">ID dernier achat</div>
                                        </th>
                                        <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                        <div className="cgulq c2hoo">Total dépense</div>
                                        </th>
                                        <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                        <span className="cn8jz">Menu</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    {/* Table body */}
                                    <tbody className="text-sm ce8qq ca8s8 co0ms">
                                        {/* Row */}
                                        {filteredUsers.map(user => (

                                            <tr>
                                                <td className="cq84g cyjcc cgn91 cn8zk c9hxi c72q5">
                                                    <div className="flex items-center cm84d">
                                                        {!user ? (
                                                            <>...</>
                                                        ) : (
                                                            user.is_marked === 1 ? (

                                                                <button>
                                                                <svg
                                                                    className="cveo1 cbm9w coqgc"
                                                                    width={16}
                                                                    height={16}
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z" />
                                                                </svg>
                                                                </button>
                                                            ) : (
                                                                <button>
                                                                <svg
                                                                    className="co1wq ckbo4 cbm9w coqgc"
                                                                    width={16}
                                                                    height={16}
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z" />
                                                                </svg>
                                                                </button>
                                                            )
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="flex items-center">
                                                    {!user.name ? (
                                                        <>...</>
                                                    ) : (
                                                        <div className="text-gray-800 dark:text-gray-100 c1k3n">
                                                            {user.name}
                                                        </div>
                                                    )}
                                                </div>
                                                </td>
                                                <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    {!user.address ? (
                                                        <>...</>
                                                    ) : (
                                                        <div className="c2hoo">{user.address}</div>
                                                    )}
                                                </td>
                                                <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    {!user.total_orders ? (
                                                        <>...</>
                                                    ) : (
                                                        <div className="cydwr">{formatNumber(user.total_orders)}</div>
                                                    )}
                                                </td>
                                                <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    {!user.last_order_id ? (
                                                        <>...</>
                                                    ) : (
                                                        <div className="c740r c1k3n c2hoo">#{user.last_order_id.slice(-6)}</div>
                                                    )}
                                                </td>
                                                <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    {!user.total_spent ? (
                                                        <>...</>
                                                    ) : (
                                                        <div className="cdjj4 c1k3n c2hoo">{formatPrice(user.total_spent)}</div>
                                                    )}
                                                </td>
                                                <td className="cq84g cyjcc cgn91 cn8zk c9hxi c72q5">
                                                <Link to={`/dashboard/achats/tickets/clients/profil/p/${user.user_id}`} className="text-blue-500 c5ylh c1k3n">
                                                    Voir plus
                                                    <span className="c5fh5"> -&gt;</span>
                                                </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full flex justify-center items-center cweej">
                            <div className="w-3/4 cydwr clbq0">
                                <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                    <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                        <path
                                            className="text-gray-500 co1wq"
                                            d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="dark:text-gray-400 ckbo4"
                                            d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="cmpw7 cdqku"
                                            d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                            fill="currentColor"
                                        />
                                    </svg>

                                </div>
                                <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                Aucune données trouvées.
                                </h4>
                                <div className="mb-6 text-sm">
                                {'Aucun acheteur n\'a été trouvé pour vos événements publiés à cette période.'}
                                </div>
                            </div>
                        </div>
                    )}
                </>
                // <div className="cvxzw">
                //     <div className="flex c2g1r cwnq4 c6o0w cetff">
                //         <nav
                //         className="cafp8 c2rn6 cdiog"
                //         role="navigation"
                //         aria-label="Navigation"
                //         >
                //         <ul className="flex justify-center">
                //             <li className="ml-3 c77jh">
                //             <span className="btn bg-white border-gray-200 cghq3 co1wq c2vpa ckbo4">
                //                 &lt;- Previous
                //             </span>
                //             </li>
                //             <li className="ml-3 c77jh">
                //             <a
                //                 className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa"
                //                 href="#0"
                //             >
                //                 Next -&gt;
                //             </a>
                //             </li>
                //         </ul>
                //         </nav>
                //         <div className="text-sm text-gray-500 c2bzj cydwr">
                //         Showing <span className="c0zkc c1ukq c1k3n">1</span> to{" "}
                //         <span className="c0zkc c1ukq c1k3n">10</span> of{" "}
                //         <span className="c0zkc c1ukq c1k3n">467</span> results
                //         </div>
                //     </div>
                // </div>
            )}


        </div>
    )
}
