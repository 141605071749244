import React from 'react'
import { useSearchParams } from 'react-router-dom';
import VerifyEmailChangeForm from '../../../components/Authentication/VerifyEmailChangeForm';
import Error404 from '../../Error404';

export default function VerifyEmailChange() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token'); // Récupérer le token depuis l'URL


    if (!token) {
        return <Error404 />
    }

    return (
        <div className="">
                <div className=" p-4 mb-24 lg:mb-32">
                    <h3 className="my-10 mb-5 flex items-center text-center text-2xl leading-[115%] md:text-2xl md:leading-[115%] font-bold text-neutral-900 dark:text-neutral-100 justify-center">
                        Réinitialisation de l'adresse e-mail !
                    </h3>
                    <div className="relative text-center max-w-md mx-auto space-y-11">
                        <span className="relative inline-block px-4 bg-white text-sm dark:text-neutral-400 dark:bg-neutral-900">
                            Veuillez saisir votre nouvelle adresse e-mail et confirmer la liaison à votre compte afin de la réinitialiser.
                        </span>
                    </div>
                    <div className="max-w-md mx-auto space-y-6 mt-12">
                    <VerifyEmailChangeForm />
                    </div>
                </div>
            </div>
    );
}
