import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadContentSpinner from '../../components/Utils/LoadContentSpinner';
import axiosClient from '../../components/api/axiosClient';
import FormattedDate from '../../components/Utils/FormattedDate';
import { useError } from '../../components/contexts/ErrorContext';

export default function HomePageEventsValidations() {

    const [loading, setLoading] = useState(true);
    const [eventsValidator, setEventsValidator] = useState([]);
    const { handleError } = useError();

    useEffect(() => {
        // Appel API pour récupérer les événements en cours de validation
        axiosClient.get('/dashboard/event-validation/get-allEvents')
            .then(response => {
                setEventsValidator(response.data.data); // Mettez à jour la liste des événements
                setLoading(false);
            })
            .catch(error => {
                handleError('Erreur lors de la récupération des événements :', error);
                console.error('Erreur lors de la récupération des événements :', error);
                setLoading(false); // Arrêtez le chargement même en cas d'erreur
            });
    }, []);

    return (
        <>

            <div className="md:flex ccvqk cjjo9 ">
                {/* Content */}
                <div className="2xl:ml-[80px] cu5hl cnbwt ckrma cs7xl c8jy5 clbq0 cxsfz">
                    <div className="cbyrh cflvx">
                        {/* Cart items */}
                        <div className="cifdz cai6b">
                            
                            <header className="c6f83">
                                {/* Title */}
                                <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                                    Événements en Cours de Validation &nbsp;&nbsp;<span className='text-gray-400 dark:text-gray-400'>{loading ? "..." : eventsValidator.length}</span>
                                </h1>
                            </header>
                            {loading ? (
                                <div className='w-full mt-12 flex justify-center items-center'>
                                    <LoadContentSpinner />
                                </div>
                            ) : eventsValidator.length > 0 ? (
                                <ul>
                                    {/* Cart item */}
                                    {eventsValidator.map(event => (
                                        <li key={event.id} className="bg-white mb-4 items-center c2vpa c1hly c5vqk p-3 border-gray-200 cghq3 ctv3r cnlq0">
                                            <div className="sm:mr-4 block c4ued c2rn6 cenp8 cdiog">
                                                <img
                                                    className="rounded-md w-full sm:w-48"
                                                    width={200}
                                                    height={142}
                                                    alt={event.titre}
                                                    src={`data:image/jpeg;base64,${event.affiche}`}
                                                />
                                            </div>
                                            <div className="cbw8w">
                                                <Link className='inline-block' to={`/dashboard/events/validations/show/info-e/${event.id}`}>
                                                    <h3 className="text-gray-800 dark:text-gray-100 truncate w-96 cgulq c7x0x cu6vl">
                                                    {event.titre}
                                                    </h3>
                                                </Link>
                                                    
                                                {/* Product meta */}
                                                <div className="flex flex-wrap items-center cm3rx">
                                                    <footer className="flex flex-wrap text-sm">
                                                        <div className="flex items-center after:content-['·'] c8r8m cf5g2 cyxdt csars ch0co c35k6 crocq">
                                                            <div className="text-gray-500 c5ylh ceetm c1k3n">
                                                                <div className="flex items-center text-sm">
                                                                    <svg
                                                                        className="mr-2 cbm9w"
                                                                        width={16}
                                                                        height={16}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                    <path d="M15.686 5.708 10.291.313c-.4-.4-.999-.4-1.399 0s-.4 1 0 1.399l.6.6-6.794 3.696-1-1C1.299 4.61.7 4.61.3 5.009c-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 14.001 2 15.4l3.696-3.697L9.692 15.7c.5.5 1.199.2 1.398 0 .4-.4.4-1 0-1.4l-.999-.998 3.697-6.695.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499Zm-7.193 6.095L4.196 7.507l6.695-3.697 1.298 1.299-3.696 6.694Z" />
                                                                    </svg>
                                                                    {event.request_identity}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center cyxdt csars ch0co c35k6 crocq">
                                                            <span className="text-gray-500 text-sm"><b>publié le</b> <FormattedDate date={event.created_at} /></span>
                                                        </div>
                                                    </footer>

                                                    <div className="mt-4 coqgc c4tdj c8bkw flex w-full justify-end">
                                                        <Link className="text-blue-500 c5ylh c1k3n text-sm" to={`/dashboard/events/validations/show/info-e/${event.id}`}>
                                                            Plus de details<span className="c5fh5"> -&gt;</span>
                                                        </Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                ) : (
                                <div className="w-full mt-12 flex justify-center items-center cweej">
                                    <div className="cydwr clbq0">
                                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path
                                                className="text-gray-500 co1wq"
                                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="dark:text-gray-400 ckbo4"
                                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="cmpw7 cdqku"
                                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                fill="currentColor"
                                            />
                                        </svg>

                                        </div>
                                        <div className="mb-6">
                                        Aucun événement trouvé.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* Sidebar */}
                <div>
                    <div className="2xl:w-[calc(352px+80px)] ccwc2 cpfgt cghq3 cey82 c0zk3 cz8c3 c6fiw crhgx cktmc c1qvn cb265 cu49b cgiaj c8du9 c9s5x czebi cr4kg">
                        <div className="2xl:px-12 csot1 cs7xl clbq0 cxsfz">
                            <div className="ceu9r c91aq clu2m">
                            <div className="cpfgt c0zk3 cz8c3 cu49b c9s5x czebi">
                                <div className="ccvej">
                                {/* Button  */}
                                <div className="cai6b">
                                    <Link to={'/dashboard/events/registration'} className="btn bg-primary-600 hover:bg-primary-700 font-semibold text-white cdj8c cg0jr ch8z9 cilvw cyn7a c6btv">
                                    Ajouter un événement
                                    </Link>
                                </div>
                                {/* Blocks  */}
                                <div className="cr78y">
                                    {/* Block 1  */}
                                    <div className="bg-white c2vpa c1hly clxb7">
                                    <div className="cmpw7 cgulq cdqku c0ef0 c1iho cdiog">
                                        Processus de validation
                                    </div>
                                    <ul>
                                        
                                        <li className="cqir4 cm84d c1rbs">
                                        <div className="cpjsn">
                                            <div className="text-blue-600 c1k3n c0ef0 cjxkd c1iho">
                                                Etape 1
                                            </div>
                                            <div className="text-sm c6f83">
                                                <div
                                                    className="text-gray-800 dark:text-gray-100 cigpx c4t3r c1k3n"
                                                >
                                                    L'organisateur soumet un événement via la plateforme Events.
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div aria-hidden="true">
                                            <div className="dark:bg-gray-700 cvwbh cgfor coq0h cqdkw c2p40 cuehd ckocy" />
                                            <div className="w-3 h-3 rounded-full ctxup cznl2 cv1so cg3qz cqdkw c2p40 c45a5 ct7xr cu1dd" />
                                        </div>
                                        </li>
                                        {/* Event 2 * */}
                                        <li className="cqir4 cm84d c1rbs">
                                        <div className="cpjsn">
                                            <div className="text-blue-600 c1k3n c0ef0 cjxkd c1iho">
                                                Etape 2
                                            </div>
                                            <div className="text-sm c6f83">
                                                <div
                                                    className="text-gray-800 dark:text-gray-100 cigpx c4t3r c1k3n"
                                                >
                                                    Vérifications et Validation des conditions de publication et de la date de clôture de l'événement.
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* Timeline element * */}
                                        <div aria-hidden="true">
                                            <div className="dark:bg-gray-700 cvwbh cgfor coq0h cqdkw c2p40 cuehd ckocy" />
                                            <div className="w-3 h-3 rounded-full ctxup cznl2 cv1so cg3qz cqdkw c2p40 c45a5 ct7xr cu1dd" />
                                        </div>
                                        </li>
                                        {/* Event 3 * */}
                                        <li className="cqir4 cm84d c1rbs">
                                        <div className="cpjsn">
                                            <div className="text-blue-600 c1k3n c0ef0 cjxkd c1iho">
                                                Etape 3
                                            </div>
                                            <div className="text-sm c6f83">
                                                <div
                                                    className="text-gray-800 dark:text-gray-100 cigpx c4t3r c1k3n"
                                                >
                                                    Approbation finale par Events, suivie de la publication officielle de l'événement.
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                        {/* Timeline element * */}
                                        <div aria-hidden="true">
                                            <div className="dark:bg-gray-700 cvwbh cgfor coq0h cqdkw c2p40 cuehd ckocy" />
                                            <div className="w-3 h-3 rounded-full ctxup cznl2 cv1so cg3qz cqdkw c2p40 c45a5 ct7xr cu1dd" />
                                        </div>
                                        </li>
                                        
                                    </ul>
                                    
                                    </div>
                                    {/* Block 2 * */}
                                    <div className="bg-white c2vpa c1hly clxb7">
                                    <div className="cmpw7 cgulq cdqku c0ef0 c1iho cdiog">
                                        Bon à savoir
                                    </div>
                                    <ul className="cjav5">
                                        <li>
                                        <div className="text-sm cu6vl">
                                            <span className="text-gray-800 dark:text-gray-100 cigpx c4t3r c1k3n">
                                            Vos demandes seront acceptées lorsque vous aurez complété toutes les informations requises dans votre profil et signer le contrat de Events.
                                            </span>
                                        </div>
                                        <div className="text-gray-500 c1iho">
                                            <Link to={'/dashboard/account/administration-organizer'} className="text-blue-500 c5ylh c1k3n">
                                            Verifier mes informations
                                            </Link>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="text-sm cu6vl">
                                            <span className="text-gray-800 dark:text-gray-100 cigpx c4t3r c1k3n">
                                            Events s'assure que la date de clôture des ventes est définie correctement pour chaque vente afin de procéder au versement, conformément aux critères de la plateforme.
                                            </span>
                                        </div>
                                        <div className="text-gray-500 c1iho">
                                            <a className="text-blue-500 c5ylh c1k3n" href="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6">
                                            Charte des organisateurs
                                            </a>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="text-sm cu6vl">
                                            <a
                                            className="text-gray-800 dark:text-gray-100 cigpx c4t3r c1k3n"
                                            href="#0"
                                            >
                                            Après avoir validé toutes les informations, Events approuve l'événement et le rend disponible pour les utilisateurs de la plateforme. 📈
                                            </a>
                                        </div>
                                        </li>
                                        
                                    </ul>
                                    <div className="cfo3t">
                                        <Link to={"/dashboard/events/show-list"} className="bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa cnf4p c6btv">
                                        Mes événements
                                        </Link>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
