import React, { useEffect, useState } from "react";
// import Step1Form from "../components/Step1Form";
import Step2Form from "../components/Step2Form";
import Step3Form from "../components/Step3Form";
import Step4Form from "../components/Step4Form";
import axiosClient from "../components/api/axiosClient";
import LoadContentSpinner from "../components/Utils/LoadContentSpinner";
import CongratulateContent from "./CongratulateContent";

function WizardForm() {

  useEffect(() => {
    // Mettree à jour le titre de la page
    document.title = "Formulaire de demande de publication - Events";
  }, []);

  const [currentPage, setPage] = useState(2);
  const nextPage = () => {
    setPage((prev) => ++prev);
    scrollToTop();
  };
  const prevPage = () => {
    setPage((prev) => --prev);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [formData, setFormData] = useState({});
  const [tickets, setTickets] = useState([0]);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState();
  const [isSuccess, setIsSuccess] = useState(false);

  const [ticketsData, setTicketsData] = useState([
    {
      nom: String.prototype,
      prix_unitaire: Number.prototype,
      quantite: Number.prototype,
    },
  ]);

  const [eventData, setEventData] = useState({
    titre: "",
    acteur_principal: "",
    categorie: "",
    date_debut: null,
    date_fin: null,
    heure_debut: null,
    lieu: "",
    lien_gps: "",
    type_evenement: {
      populaire: false,
      vedette: false,
    },
    affiche: undefined,
    image_lieu_evenement: undefined,
    description: "",
    date_fin_cloture: null,
    heure_fin_cloture: null,
  });

  const [images, setImages] = useState({
    affiche: null,
    image_lieu_evenement: null,
  });

  const handleTicketsDataChange = (e, index) => {
    e.preventDefault();
    setTicketsData((prevData) => {
      const updatedTicketsData = [...prevData];
      updatedTicketsData[index] = {
        ...updatedTicketsData[index],
        [e.target.name]: e.target.value,
      };
      return updatedTicketsData;
    });

    // setFormData((prevData) => ({ ...prevData, tickets: ticketsData }));
  };

  const handleEventDataChange = (e) => {
    // e.preventDefault();
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setEventData((prevData) => {
      if (type === "checkbox") {
        return {
          ...prevData,
          type_evenement: {
            ...prevData.type_evenement,
            [name]: inputValue,
          },
        };
      }
      return {
        ...prevData,
        [name]: inputValue,
      };
    });

    if (type === "file") setImages({ ...images, [name]: e.target?.files[0] });

    // setFormData((prevData) => ({ ...prevData, event: eventData }));
  };

  const [formStatus, setFormStatus] = useState("");

  useEffect(() => {
    setFormData({
      ticketsData,
      eventData,
      affiche: images.affiche,
      image_lieu_evenement: images.image_lieu_evenement,
    });
  }, [
    eventData,
    ticketsData,
    images.affiche,
    images.image_lieu_evenement,
  ]);

  

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    setFormStatus("Envoi du formulaire en cours...");
    
    setFormData({
      ticketsData,
      eventData,
      affiche: images.affiche,
      image_lieu_evenement: images.image_lieu_evenement,
    });
    
    // console.log("formData: ", formData);
    
    try {
      axiosClient.post("/request/events/store", formData)
        .then((response) => {
          // Mise à jour du statut selon la réponse du serveur
          // console.log(response.data);
          // setFormStatus(response.data.messages);

          // const token = response.data.regToken;
          // const eventDetails = response.data.eventDetails;

          if (response.data.submited && response.data.status === 200) {
            setIsSuccess(true);
          }

          // Passer les détails de l'événement à la page de redirection
          // navigate(`/Congratulate/sender/${token}/71190cff-131a-69fce9a4f1b6`, {
          //   state: { eventDetails },
          // });

          setLoading(false);
          // Effacer l'état après un délai
          // setTimeout(() => setFormStatus(""), 500);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 422) {
              // Mise à jour du statut en cas d'erreur de validation
              // setFormStatus('Erreur de validation du formulaire');
              setValidationError(
                "Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !"
              );
              // console.log(error.response.errors);
              // console.log(error.response.data.errors);
              setLoading(false);
              // Effacer l'état après un délai
              // setTimeout(() => setFormStatus(""), 500);
              setTimeout(() => setValidationError(""), 7000);
            }
            if (error.response.status === 500) {
              // Mise à jour du statut en cas d'erreur serveur
              setValidationError(error.response.errors);
              // alert(error.response.data);
              setLoading(false);
              // Effacer l'état après un délai
              setTimeout(() => setFormStatus(""), 500);
            }
          }
          setValidationError(
            "Erreur de traitement ! Veuillez réessayer plus tard s'il vous plait !"
          );
          setLoading(false);
          setTimeout(() => setValidationError(""), 7000);
        });
    } catch (err) {
      setValidationError(
        "Erreur de traitement ! Veuillez réessayer s'il vous plait !"
      );
      setLoading(false);
      setTimeout(() => setValidationError(""), 7000);
    }
  };

  // Faire un render du formulaire selon la page
  const renderStep = () => {
    switch (currentPage) {
      // case 1:
      //   return (
      //     <Step1Form
      //       personalData={personalData}
      //       handlePersonalDataChange={handlePersonalDataChange}
      //       nextPage={nextPage}
      //     />
      //   );
      case 2:
        return (
          <Step2Form
            eventData={eventData}
            handleEventDataChange={handleEventDataChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        );
      case 3:
        return (
          <Step3Form
            ticketsData={ticketsData}
            setTicketsData={setTicketsData}
            formData={formData}
            setFormData={setFormData}
            setTickets={setTickets}
            tickets={tickets}
            handleTicketsDataChange={handleTicketsDataChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        );
      case 4:
        return (
          <Step4Form
            images={images}
            // personalData={personalData}
            eventData={eventData}
            ticketsData={ticketsData}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        );
      default:
        return null;
    }
  };
  if (loading) {
    return <LoadContentSpinner status={formStatus} />;
  }

  if (isSuccess) {
    return <CongratulateContent />
  }

  return (
    <div className='px-4 sm:px-6 lg:px-8 py-8 w-full'>
      <div className='grid grid-cols-12 gap-6'>
        <div className='col-span-full xl:col-span-12 bg-white dark:bg-gray-800 shadow-sm rounded-xl'>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="relative flex w-full justify-center">

            {validationError && (
              <div className="text-sm fixed mt-16 top-0 z-10 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                <div className="flex cm3rx ce4zk c6btv">
                  <div className="flex">
                    <svg
                        className="cbm9w czr3n cq1qg coqgc chfzq"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                    </svg>
                    <div>
                  {validationError}
                  </div>
                  </div>
                  </div>
              </div>
            )}
          </div>

            {renderStep()}
          </form>
          </div>
        </div>
    </div>
  );
}

export default WizardForm;
