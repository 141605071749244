import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import { formatPrice } from '../../../components/Utils/UtilsPricesFormat';

export default function SalesTicketsEvents() {

    const [filter, setFilter] = useState("all"); // État pour le filtre
    const [loading, setLoading] = useState(true); // État pour le chargement
    const [error, setError] = useState(null);
    const [dataEventSales, setDataEventSales] = useState([]);
    const [searchText, setSearchText] = useState(''); // État pour la recherche

    // Fonction pour charger les événements depuis l'API
    const fetchEventSales = async () => {
        setLoading(true);

        axiosClient.get('/dashboard/global/event/salesTo/filter', {
            params: { filter },
        })
            .then(response => {
                setDataEventSales(response.data.data); // Mettez à jour la liste des événements
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    // Récupérer les messages spécifiques de l'erreur backend
                    setError(error.response.data);
                } else {
                    setError({message: 'Erreur lors de la récupération des événements. Veuillez réessayer plus tard.',});
                }
                setLoading(false); // Arrêtez le chargement même en cas d'erreur
            });
    };

    // Charger les événements à chaque changement de filtre
    useEffect(() => {
        fetchEventSales();
    }, [filter]);

    // Gestion du changement de filtre
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    // Gestion de la recherche
    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    // Filtrer les données en fonction du texte de recherche
    const filteredData = dataEventSales.filter(event =>
        event.name.toLowerCase().includes(searchText.toLowerCase())
    );

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Afficher une section dédiée en cas d'erreur
    if (error) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="w-3/4 cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Erreur
                    </h4>
                    <div className="mb-6 text-sm">
                    {error.message}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz'>
            <div className='c2g1r cwnq4 cnlq0 c6r0l'>
                {/* Title */}
                <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb c2rn6 cdiog">
                    Mes événements &nbsp;&nbsp; <span className='text-gray-400'>{filteredData.length}</span>
                </h1>
                <form className="cm84d">
                    <label htmlFor="action-search" className="cn8jz">
                        Search
                    </label>
                    <input
                        id="action-search"
                        className="bg-white border-gray-100 focus:border-gray-200 focus:ring-transparent pl-9 c2vpa caqf9 ct9oo"
                        type="search"
                        placeholder="Quel événement cherchez-vous ?"
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                    <button className="c29dn cqdkw cini7 cqogy" type="button" aria-label="Search">
                        <svg
                        className="ml-3 mr-2 cba8l c4it8 cmpw7 cdqku cbm9w coqgc"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                        </svg>
                    </button>
                </form>
            </div>
            
            <div className="border-gray-200 cghq3 ctv3r cdiog">
                <ul className="text-sm flex c9zp2 cu49b c8vtj c1k3n c49xy csf8j chp98">
                    <li className="chndw cddmn cxjgq ci2ft ckjy4 cbhw6 c5ggj c3osb cphv1">
                    <button className={`cq84g ${
                        filter === "all" ? "text-blue-500 font-bold" : ""
                        }`}
                        onClick={() => handleFilterChange("all")}
                    >
                    Tous
                    </button>
                    </li>
                    <li className="chndw cddmn cxjgq ci2ft ckjy4 cbhw6 c5ggj c3osb cphv1">
                    <button
                        className={`cq84g ${
                        filter === "published" ? "text-blue-500 font-bold" : ""
                        }`}
                        onClick={() => handleFilterChange("published")}
                    >
                        Evénements en cours
                    </button>
                    </li>
                    <li className="chndw cddmn cxjgq ci2ft ckjy4 cbhw6 c5ggj c3osb cphv1">
                    <button
                        className={`cq84g ${
                        filter === "outdated" ? "text-blue-500 font-bold" : ""
                        }`}
                        onClick={() => handleFilterChange("outdated")}
                    >
                        Evénements passés
                    </button>
                    </li>
                </ul>
            </div>

            <div className="cvxzw">
                {filteredData.length > 0 ? (

                    <div className="c7nom ckn6o c4sak">
                        {/* Card 1 */}
                        {filteredData.map(event => (
                            <div key={event.id} className="bg-white h-fit c2vpa cxe43 ci500 cdjoa coz6m c1hly c5vqk">
                                <div className="flex cetff cav8x">
                                    {/* Image */}
                                    <div className="cm84d">
                                    <img
                                        className="c6btv"
                                        width={286}
                                        height={160}
                                        src={`data:image/jpeg;base64,${event.affiche}`}
                                        alt={event.name}
                                    />
                                    </div>
                                    {/* Card Content */}
                                    <div className="flex cetff cbw8w p-4">
                                    {/* Card body */}
                                    <div className="cbw8w">
                                        {/* Header */}
                                        <header className="cdiog">
                                        {event.annule ? (
                                            <div
                                                className="cz4gk ccqra cgulq c0ef0 c1iho cu6vl"
                                            >
                                                {'ANNULE'}
                                            </div>
                                        ) : (
                                            event.status === 'Disponible' ? (
                                                <div
                                                    className="ccqra c612e cgulq c0ef0 c1iho cu6vl"
                                                >
                                                    {'DISPONIBLE'}
                                                </div>
                                            ) : event.status === 'Disabled' ? (
                                                <div
                                                    className="cz4gk ccqra cgulq c0ef0 c1iho cu6vl"
                                                >
                                                    {'DESACTIVE'}
                                                </div>
                                            ) : (
                                                <div
                                                    className="ccqra c612e cgulq c0ef0 c1iho cu6vl"
                                                >
                                                    {event.status}
                                                </div>
                                            )
                                        )}
                                        <h3 className="text-gray-800 dark:text-gray-100 text-md font-bold cgulq cu6vl">
                                        {event.name}
                                        </h3>
                                        </header>
                                        {/* Info */}
                                        <hr className='mb-2' />
                                        <div className="c6r0l">
                                        <div className="flex text-xs cm3rx ch3kz c6f83">
                                            <div>
                                            <span className="text-gray-800 dark:text-gray-100 cgulq">
                                                {formatPrice(event.performanceData.totalSold)}
                                            </span>{" "}
                                            {/* <span className="caf78">raised</span> */}
                                            </div>
                                            <div className="text-gray-500 dark:text-gray-400">{event.performanceData.percentageSold}%</div>
                                        </div>
                                        {/* Bar */}
                                        <div className="h-2 rounded-full dark:bg-gray-700 cvwbh cm84d c6btv c6f83">
                                            <div
                                            className="bg-green-500 rounded-full max-w-full cqdkw cq5bq cu1dd cli41"
                                            style={{ width: `${event.performanceData.percentageSold}%` }}
                                            />
                                        </div>
                                        <div className="text-gray-500 dark:text-gray-400 c1k3n c1iho">
                                            Total vendus
                                        </div>
                                        </div>
                                    </div>
                                    {/* Card footer */}
                                    <div>
                                        <Link
                                        className="bg-primary-600 hover:bg-primary-700 text-white cdj8c cg0jr ch8z9 cilvw cyn7a cnf4p c6btv"
                                        to={`/dashboard/ventes/tickets-vendus/e/${event.id}`}
                                        >
                                        Détails des ventes
                                        </Link>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="w-full h-full flex justify-center items-center cweej">
                        <div className="w-3/4 cydwr clbq0">
                            <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                    <path
                                        className="text-gray-500 co1wq"
                                        d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="dark:text-gray-400 ckbo4"
                                        d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="cmpw7 cdqku"
                                        d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                            Rien à Afficher
                            </h4>
                            <div className="mb-6 text-sm">
                            {'Aucun événement disponible. Veuillez enregistrer maintenant !'}
                            </div>
                            <Link to={'/dashboard/events/registration'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Enregistrer un nouvel événement
                            </Link>
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}
