import React, { useEffect } from 'react'
import VerifyAuthCode from '../../../components/Authentication/VerifyAuthCode';
import { useLocation, useNavigate } from 'react-router-dom';

export default function IndexVerifyCodeAuth() {

    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Code authentification MFA - Events";

        // Si l'email est introuvable, rediriger vers la page de connexion
        if (!email) {
            navigate('/login');
        }
        
    }, [email, navigate]);


    const maskEmail = (email) => {
        const [localPart, domain] = email.split('@');
        const maskedLocalPart = localPart.slice(0, 2) + '***' + localPart.slice(-1);
        return `${maskedLocalPart}@${domain}`;
    };

    // Empêcher le rendu si l'email est absent pour éviter toute erreur
    if (!email) {
        return null;
    }

    return (
        <>
            <div className="nc-PageLogin " data-nc-id="PageLogin">
                <div className="container mb-24 lg:mb-32">
                    <h2 className="my-10 mb-5 text-center flex items-center text-2xl leading-[115%] md:text-2xl md:leading-[115%] font-bold text-neutral-900 dark:text-neutral-100 justify-center">
                        Vérification de l'authentification
                    </h2>
                    <div className="relative text-center max-w-md mx-auto space-y-11">
                        <span className="relative inline-block px-4 text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                            Veuillez entrer le code de vérification envoyé à l'adresse {''}
                            <span className='text-primary-6000 font-normal'>{maskEmail(email)}</span>.
                        </span>
                    </div>
                    <div className="max-w-md mx-auto space-y-6 mt-12">
                        
                        <VerifyAuthCode />
                    </div>
                </div>
            </div>
        </>
    )
}
