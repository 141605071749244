import React, { useEffect, useState } from 'react'
import InvoiceDetails from '../../../components/Dashboard/InvoiceDetails'
import { Link, useParams } from 'react-router-dom';
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import FormattedDate from '../../../components/Utils/FormattedDate';
import { formatPrice } from '../../../components/Utils/UtilsPricesFormat';

export default function ShowDetailsEventsInvoices() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { id } = useParams();  // Récupérer l'ID de l'événement à partir de l'URL
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [details, setDetails] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    const fetchEventDetails = async () => {
        setLoading(true);
        setError(null);   // Réinitialiser les erreurs
        // Appel API pour récupérer les événements en cours de validation
        try {
            const response = await axiosClient.get(`/dashboard/user/organizer/invoice/show-invoices/i/${id}`);
            setDetails(response.data.data); // Données de l'événement
        } catch (error) {
            if (error.response && error.response.data) {
                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Une erreur inconnue est survenue. Veuillez réessayer plus tard.',});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    useEffect(() => {
        if (id) {
            fetchEventDetails();
        }
    }, [id]);

    if(!id) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Aucun événement trouvé, impossible d'accéder à cette page.
                    </h4>
                    <Link to={'/dashboard/factures/events/invoices'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                    Retour aux événements
                    </Link>
                </div>
            </div>
        )
    }

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Fonction pour ouvrir le modal et définir les données de la facture
    const openModal = (invoice) => {
        setSelectedInvoice(invoice); // Stocke la facture sélectionnée
        setIsModalOpen(true); // Ouvrir le modal
    };

    // Fonction pour fermer le modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedInvoice(null); // Réinitialise la facture sélectionnée
    };

    return (
        <div className='ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz'>
            {error ? (
                <div className="w-full h-full flex justify-center items-center mt-12 cweej">
                    <div className="cydwr clbq0">
                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>

                        </div>
                        <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                        Erreur !
                        </h4>
                        <div className="break-all mb-6 text-sm">
                        {error.message}
                        </div>
                        <Link to={'/dashboard/factures/events/invoices'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Retour aux événements
                        </Link>
                    </div>
                </div>
            ) : (
                <>
                    <div className="c2g1r cwnq4 cnlq0 c6r0l">
                        {/* Left: Title */}
                        <div className="c2rn6 cdiog">
                            <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                                Factures pour l'événement <span className="cmpw7 cdqku c1k3n">#{details.event.event_code}</span>
                            </h1>
                            <span class="text-sm">
                            Voici la liste des factures associées à cet événement. Vous pouvez consulter les détails de chaque facture.
                            </span>
                        </div>
                    </div>
                    <div className="cweej">
                        {/* Job 1 */}
                        <div className="bg-white c2vpa c1hly c5vqk cx3hp cz8qb">
                            <div className="items-center cm3rx ccjpx ch3kz cr78y clgee">
                            {/* Left side */}
                            <div className="flex cmxlj ce4zk cp3jk">
                                <div className="coqgc ccwg3">
                                <img
                                    className="rounded-md h-12 w-16"
                                    width={46}
                                    height={46}
                                    src={`data:image/jpeg;base64,${details.event.affiche}`}
                                    alt={details.event.event_title}
                                />
                                </div>
                                <div>
                                <Link
                                    className="inline-flex text-gray-800 dark:text-gray-100 cgulq"
                                    to={`/dashboard/events/view-details/to/${details.event.id}`}
                                >
                                    {details.event.event_title}
                                </Link>
                                <div className="text-sm">Cloture des ventes : {details.event.cloture_vente ? (<FormattedDate date={details.event.cloture_vente} />) : (<>Non Défini</>)}</div>
                                </div>
                            </div>
                            {/* Right side */}
                            <div className="flex sm:pl-6 sm:flex-row flex-col items-center cm4ey ca2zo">
                                {details.event.annule ? (
                                    <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                        Evénement Annulé
                                    </div>
                                ) : (

                                    details.event.status === 'Disponible' ? (
                                        <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n cdw1w c9hxi">
                                            {details.event.status}
                                        </div>
                                    ) : details.event.status === 'Disabled' ? (
                                        <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                            Désactivé
                                        </div>
                                    ) : (
                                        <div className="inline-flex text-xs rounded-full clksd cydwr c1k3n cdw1w c9hxi">
                                            {details.event.status}
                                        </div>
                                    )
                                )}
                                
                            </div>
                            </div>
                        </div>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold my-4 ce5fk">
                        Toutes les factures <span className="cmpw7 cdqku c1k3n">{details.invoices.length}</span>
                    </h4>
                    {details.invoices.length > 0 ? (
                        <>
                            <div className="bg-white cecbd">
                                <div>
                                    
                                    {/* Table */}
                                    <div className="cocyr">
                                    <table className="c0zkc cn9pt c6btv">
                                        {/* Table header */}
                                        <thead className="text-gray-500 border-gray-200 cghq3 cgulq c0ef0 ctv3r cr4kg c1iho">
                                            <tr>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq c2hoo">Titre de la facture</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 cn8zk c9hxi c72q5">
                                                    <div className='cgulq c2hoo'>
                                                    Facture
                                                    </div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Date d'émission</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Statut</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq">Montant</div>
                                                </th>
                                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                    <div className="cgulq c2hoo">Actions</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        {/* Table body */}
                                        <tbody className="text-sm border-gray-200 ce8qq cghq3 ca8s8 co0ms ctv3r">
                                        {/* Row */}
                                        {details.invoices.map((invoice, index) => (

                                            <tr key={index}>
                                                <td className="cq84g cyjcc cgn91 c9hxi py-3">
                                                    {!invoice.invoice_title ? (
                                                        <>...</>
                                                    ) : (
                                                        <div className="text-gray-800 dark:text-gray-100 c1k3n w-full whitespace-break-spaces">
                                                        {invoice.invoice_title}
                                                        </div>
                                                    )}
                                                </td>
                                                <td className="cq84g cyjcc cgn91 c9hxi py-3">
                                                    {!invoice.invoice_number ? (
                                                        <>...</>
                                                    ) : (
                                                        <div className="c2hoo">{invoice.invoice_number}</div>
                                                    )}
                                                </td>
                                                <td className="cq84g cyjcc cgn91 c9hxi py-3">
                                                    {!invoice.invoice_date ? (
                                                        <>...</>
                                                    ) : (
                                                        <div className="c2hoo"><FormattedDate date={invoice.invoice_date} /></div>
                                                    )}
                                                </td>
                                                <td className="cq84g cyjcc cgn91 c9hxi py-3">
                                                    <div className="c2hoo">
                                                        {!invoice.status ? (
                                                            <>...</>
                                                        ) : (
                                                            invoice.status === 'overdue' ? (

                                                                <div className="text-xs inline-flex rounded-full cx6qj cz4gk cydwr c1k3n ch4gv cdw1w">
                                                                En retard
                                                                </div>
                                                            ) : invoice.status === 'paid' ? (
                                                                <div className="text-xs inline-flex rounded-full c1lu4 c19il cydwr c1k3n ch4gv cdw1w">
                                                                Paye
                                                                </div>
                                                            ) : invoice.status === 'unpaid' && (
                                                                <div className="text-xs inline-flex rounded-full cfts0 c5px7 cydwr c1k3n ch4gv cdw1w">
                                                                En attente
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="cq84g cyjcc cgn91 cn8zk c9hxi py-3">
                                                    {!invoice.to_pay ? (
                                                        <>...</>
                                                    ) : (
                                                        invoice.status === 'overdue' ? (
                                                            <div className="czr3n c1k3n">{formatPrice(invoice.to_pay)}</div>
                                                        ) : invoice.status === 'paid' ? (
                                                            <div className="cdjj4 c1k3n">{formatPrice(invoice.to_pay)}</div>
                                                        ) : invoice.status === 'unpaid' && (
                                                            <div className="cirto c1k3n">{formatPrice(invoice.to_pay)}</div>
                                                        )
                                                    )}
                                                </td>
                                                <td className="cq84g cyjcc cgn91 cn8zk c9hxi py-3">
                                                    <div className="cqkjy text-end">
                                                        <button className="rounded-full c3e4j cg12x cmpw7 cdqku" onClick={() => openModal(invoice)} title='Plus de détails'>
                                                            <span className="cn8jz">Plus de détails</span>
                                                            <svg className="cbm9w cue4z cmwfi" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                            <circle cx="12" cy="12" r="3"></circle>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                            {isModalOpen && (
                                <InvoiceDetails
                                    invoice={selectedInvoice}
                                    isModalOpen={isModalOpen}
                                    closeModal={closeModal}
                                />
                            )}
                        </>
                    ) : (
                        <div className="w-full flex justify-center items-center cweej">
                            <div className="w-3/4 cydwr clbq0">
                                <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                    <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                        <path
                                            className="text-gray-500 co1wq"
                                            d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="dark:text-gray-400 ckbo4"
                                            d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="cmpw7 cdqku"
                                            d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                            fill="currentColor"
                                        />
                                    </svg>

                                </div>
                                <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                Aucune Facture !
                                </h4>
                                <div className="mb-6 text-sm">
                                {'Aucune facture trouvée pour cette période. Votre événement peut ne pas être cloturé pour l\'instant.'}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
