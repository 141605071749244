import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useStateContext } from '../components/contexts/ContextProvider';
import LoadContentSpinner from '../components/Utils/LoadContentSpinner';

export default function OganizerPolicyEvents() {
    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Charte des organisateurs evenementiels - Events";
        
      }, []);

      const { loading } = useStateContext();

      if (loading) {
        return <LoadContentSpinner />;
      }
    return (
      <div className="nc-PageSingle pt-8 lg:pt-16 mb-5">
        <Helmet>
          <meta
            name="description"
            content="Découvrez la Charte des Organisateurs de Events - les règles et conditions pour publier, gérer et promouvoir vos événements sur notre plateforme. Assurez-vous de respecter les directives pour une collaboration réussie."
          />
          <meta
            name="keywords"
            content="Charte des Organisateurs, règles des organisateurs, conditions de publication, gestion d'événements, promotion d'événements, Events"
          />
        </Helmet>
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto space-y-5">
            <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-purple-800 bg-purple-100">
              Events rédactions
            </span>
            <h1
              className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
              title="CHARTE DES ORGANISATEURS EVENEMENTIELS DE EVENTS"
            >
              CHARTE DES ORGANISATEURS EVENEMENTIELS DE EVENTS
            </h1>

            <div
              className="flex flex-col items-baseline sm:flex-row sm:justify-between"
              style={{ marginTop: "2rem" }}
            >
              <div className="nc-PostMeta2 mb-5 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
                <div className="relative flex-shrink-0 flex items-center justify-center rounded-full w-8 h-8 sm:h-11 sm:w-11  flex-shrink-0">
                  <img
                    alt="John Doe"
                    loading="lazy"
                    decoding="async"
                    data-nimg={1}
                    className="inset-0  object-cover"
                    style={{ color: "transparent" }}
                    srcSet="../../assets/images/logo_events.png"
                    src="../../assets/images/logo_events.png"
                  />
                </div>
                <div className="ml-3">
                  <div className="flex items-center">
                    <span className="block font-semibold">Equipe Events</span>
                  </div>
                  <div className="text-xs mt-[6px]">
                    <span className="text-neutral-700 dark:text-neutral-300">
                      Mai 2024
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <nav
                  className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 "
                  data-nc-id="SocialsList"
                >
                  <Link
                    className="block"
                    to="https://www.facebook.com/profile.php?id=61558762937775"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Facebook"
                  >
                    <i className="lab la-facebook-square" />
                  </Link>
                  {/* <a
                                className="block"
                                href="#"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Twitter"
                                >
                                <i className="lab la-twitter" />
                                </a>
                                <a
                                className="block"
                                href="#"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Youtube"
                                >
                                <i className="lab la-youtube" />
                                </a> */}
                  <Link
                    className="block"
                    to="https://www.instagram.com/events.app225?igsh=YzljYTk1ODg3Zg=="
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Instagram"
                  >
                    <i className="lab la-instagram" />
                  </Link>
                  <Link
                    className="block"
                    to="https://wa.me/+2250717519518"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Whatsapp Support Events"
                  >
                    <i className="lab la-whatsapp" />
                  </Link>
                </nav>
              </div>
            </div>
            <div className="w-full border-b border-neutral-100 dark:border-neutral-800 mb-5" />
          </div>
        </header>
        <div className="nc-SingleContent container space-y-10 mt-5">
          <div className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark">
            <span className="text-neutral-700 dark:text-neutral-300">
              Dernière mise à jour de cette charte : 20 Mai 2024
            </span>
            <p>
              Cette présente charte des organisateurs (ci-après la{" "}
              <b>"Charte"</b>) définit les règles et les conditions que les
              organisateurs d'événements (ci-après <b>"Organisateurs"</b>)
              doivent respecter lors de l'utilisation des services de vente de
              la plateforme <strong>Events.</strong> <br />
              <br />
              En publiant et en vendant des billets via <strong>
                Events,
              </strong>{" "}
              l’Organisateur s’engage à respecter les termes de cette Charte
              ainsi que les Conditions Générales d'Utilisation (CGU) de
              l'Application et les Politique de confidentialité. <br />
              <br />
              Ce document renferme :
              <ul>
                <li>Les conditions de vente</li>
                <li>Les conditions tarifaires</li>
                <li>
                  Les termes du contrat des organisateurs avec{" "}
                  <strong>Events</strong>
                </li>
              </ul>
            </p>
            <h3>Définitions</h3>
            <p>
              Pour une meilleure compréhension de cette Charte, les termes
              suivants sont définis comme suit :
              <ul>
                <li>
                  <b>Events :</b> Désigne la plateforme de réservation et de
                  vente de billets d'événements.
                </li>
                <li>
                  <b>Organisateur(s) :</b> Toute personne physique ou morale qui
                  publie un événement et vend des billets via la plateforme{" "}
                  <strong>Events.</strong>
                </li>
                <li>
                  <b>Participant(s) :</b> Toute personne qui achète un billet
                  pour assister à un événement via la plateforme{" "}
                  <strong>Events.</strong>
                </li>
                <li>
                  <b>Événement :</b> Toute manifestation, conférence, concert,
                  spectacle ou autre activité publiée sur{" "}
                  <strong>Events</strong> et pour laquelle des billets sont
                  vendus.
                </li>
                <li>
                  <b>Billet :</b> Document ou enregistrement électronique
                  attestant le droit d'entrée à un événement.
                </li>
                <li>
                  <b>Frais de service :</b> Pourcentage du prix de vente de
                  chaque billet prélevé par
                  <strong> Events</strong> pour couvrir les coûts de traitement
                  et de maintenance de la plateforme.
                </li>
                <li>
                  <b>Versement :</b> Transfert des recettes des ventes de
                  billets (moins les frais de service) aux Organisateurs.
                </li>
                <li>
                  <b>Contrat :</b> désigne le présent document y compris le
                  préambule, ses annexes, ainsi que tout éventuel avenant
                  ultérieur.
                </li>
                <li>
                  <b>FCFA :</b> désigne le franc de la Communauté Financière
                  Africaine, unité monétaire de l’UMOA.
                </li>
                <li>
                  <b>Transaction :</b> désigne les opérations effectuées via le
                  service <strong>Events.</strong>
                </li>
              </ul>
            </p>
            <h3>ARTICLE 1 – REGLES DE PUBLICATION DES EVENEMENTS</h3>
            <ol>
              <li>
                <strong>Exactitude des Informations</strong>
              </li>
              <p>
                Les Organisateurs doivent fournir des informations exactes et
                complètes sur chaque événement publié sur{" "}
                <strong>Events.</strong> Cela inclut, mais n'est pas limité à :
                <ul>
                  <li>Le titre de l'événement</li>
                  <li>La description de l'événement</li>
                  <li>La date et l'heure de l'événement</li>
                  <li>Le lieu de l'événement</li>
                  <li>Les catégories de billets et les prix</li>
                  <li>Toute information pertinente pour les participants</li>
                </ul>
              </p>
              <li>
                <strong>Processus de Publication des Événements</strong>
              </li>
              <p>
                Pour publier un événement sur <strong>Events,</strong> les
                Organisateurs doivent suivre une procédure spécifique visant à
                garantir la qualité et la pertinence des événements listés sur
                notre plateforme. Voici les étapes détaillées du processus de
                publication:
                <h4>Demande de Publication</h4>
                <p>
                  Les Organisateurs doivent soumettre une demande de publication
                  via le site officiel de <strong>Events.</strong> Cette demande
                  doit inclure toutes les informations pertinentes sur
                  l'événement, telles que le titre, la description, la date,
                  l'heure, le lieu, et les catégories de billets avec leurs
                  prix.
                </p>
                <h4>Analyse de la Demande</h4>
                <p>
                  Une fois la demande soumise, elle fait l'objet d'une analyse
                  approfondie par notre équipe de modération. Cette étape vise à
                  vérifier l'exactitude des informations fournies et à s'assurer
                  que l'événement respecte nos critères de qualité et nos
                  politiques internes.
                </p>
                <h4>Communication avec l'Organisateur</h4>
                <p>
                  Pendant l'analyse, notre équipe peut être amenée à contacter
                  l'Organisateur pour obtenir des informations supplémentaires.
                  Cela peut inclure des détails sur l'Organisateur lui-même,
                  afin de compléter son profil pour les paiements, ou des
                  informations spécifiques sur l'événement (des documents légaux
                  qui atteste la tenue de l’événement, … etc.). La communication
                  se fera principalement par e-mail, mais dans certains cas, un
                  appel téléphonique pourra être effectué pour une clarification
                  plus rapide et efficace.
                </p>
                <h4>Processus de Signature du Contrat</h4>
                <p>
                  Afin de formaliser notre collaboration, le contrat des
                  organisateurs événementiels vous sera envoyé par courrier
                  électronique. Vous devrez imprimer le contrat, le signer, le
                  numériser, puis renvoyer la version numérisée par email. Ce
                  processus officialise votre engagement à respecter les termes
                  de cette Charte, vous permettant ainsi de publier et gérer vos
                  événements sur <strong>Events.</strong>
                </p>
                <h4>Validation et Publication</h4>
                <p>
                  Une fois toutes les informations vérifiées et validées,
                  l'événement sera publié sur l’application mobile{" "}
                  <strong>Events</strong> pour les ventes. Les Organisateurs
                  recevront une notification confirmant la publication de leur
                  événement. En cas de refus, une explication détaillée sera
                  fournie pour permettre à l'Organisateur de corriger les
                  éventuelles erreurs ou omissions.
                </p>
                <h4>Suivi et Assistance</h4>
                <p>
                  Après la publication, les Organisateurs peuvent consulter
                  l'état de leur événement et les ventes de billets directement
                  sur le site officiel ou en contactant notre support par
                  e-mail. L’organisateur doit utiliser le même e-mail qui a
                  servi à créer l'événement pour garantir la sécurité et
                  l'exactitude des informations. Notre équipe leur fournira un
                  bilan détaillé des ventes et restera disponible pour toute
                  assistance ou question supplémentaire.
                </p>
              </p>
              <li>
                <strong>Événements Particuliers</strong>
              </li>
              <p>
                Pour les événements qui ont des normes de prix hors Franc CFA,
                les Organisateurs doivent contacter l’équipe technique de{" "}
                <strong>Events</strong> afin que leur événement soit pris en
                compte pour la publication. Dans ces cas, la devise utilisée
                pour les prix des billets reste inchangée, mais elle peut être
                convertie dans la devise du <b>Franc CFA (XOF)</b> pour les
                achats.
              </p>
              <li>
                <strong>Conformité Légale</strong>
              </li>
              <p>
                Les Organisateurs doivent s'assurer que leurs événements et leur
                publication respectent toutes les lois et réglementations
                applicables, y compris, mais sans s'y limiter, les lois sur la
                propriété intellectuelle, les lois sur la protection des
                consommateurs, et les règlements locaux sur les événements
                publics.
              </p>
              <li>
                <strong>Contenu Interdit</strong>
              </li>
              <p>
                Les Organisateurs sont tenus de respecter des normes éthiques et
                légales élevées lors de la publication de leurs événements sur{" "}
                <strong>Events,</strong> Ainsi, les Organisateurs ne doivent en
                aucun cas publier de contenu qui :
                <ul>
                  <li>
                    <b>Est illégal, offensant, diffamatoire ou obscène :</b>{" "}
                    Tout contenu qui viole les lois locales, nationales ou
                    internationales, qui est considéré comme offensant,
                    diffamatoire, ou obscène est strictement interdit. Cela
                    inclut, mais n'est pas limité à, tout contenu à caractère
                    pornographique, violent, ou discriminatoire.
                  </li>
                  <li>
                    <b>
                      Enfreint les droits d'auteur, les marques ou d'autres
                      droits de propriété intellectuelle :
                    </b>{" "}
                    Les Organisateurs doivent respecter les droits d'auteur, les
                    marques déposées et autres droits de propriété
                    intellectuelle. Ils ne doivent pas publier de contenu qui
                    utilise des éléments protégés sans autorisation préalable ou
                    qui viole les droits de tiers.
                  </li>
                  <li>
                    <b>
                      Encourage la haine, la violence ou la discrimination :
                    </b>{" "}
                    Toute forme de contenu qui encourage la haine, la violence,
                    ou la discrimination basée sur la race, l'origine ethnique,
                    la religion, l'orientation sexuelle, l'identité de genre,
                    l'âge, ou tout autre critère protégé est strictement
                    prohibée. <strong>Events</strong> s'engage à promouvoir la
                    diversité, l'inclusion et le respect de tous les individus.
                  </li>
                </ul>
                <h4>Conséquences de la violation</h4>
                Toute violation de cette politique de contenu interdit peut
                entraîner des mesures disciplinaires, y compris la suppression
                immédiate de l'événement de la plateforme{" "}
                <strong>Events </strong>
                et la suspension ou la résiliation du compte de l'Organisateur.
                <strong> Events</strong> se réserve le droit de prendre toutes
                les mesures nécessaires pour préserver l'intégrité de la
                plateforme et assurer une expérience positive pour tous les
                utilisateurs.
              </p>
            </ol>
            <h3>ARTICLE 2 – REGLES DE VENTE DES BILLETS</h3>
            <ol>
              <li>
                <strong>Politique de Prix</strong>
              </li>
              <p>
                Les Organisateurs jouissent d'une liberté totale pour déterminer
                les prix des billets pour leurs événements. Toutefois, afin de
                garantir une transparence totale et de protéger les intérêts des
                participants, certaines directives doivent être respectées:
                <ul>
                  <li>
                    <b>Prix Minimum des Billets :</b> Le prix minimum pour un
                    billet est fixé à <b>500 F CFA(XOF)</b>. Cette mesure vise à
                    garantir l'accessibilité des événements à un large public et
                    à éviter toute forme d'exploitation financière.
                  </li>
                  <li>
                    <b>Devises Acceptées :</b> Les prix des billets sont
                    acceptés en devise du Franc CFA (XOF). Toutefois, les
                    Organisateurs peuvent spécifier d'autres devises acceptées
                    dans la mesure où cela est clairement indiqué lors de la
                    publication de l'événement.
                  </li>
                  <li>
                    <b>Transparence des Prix :</b> Il est essentiel que les prix
                    des billets reflètent la valeur de l'événement proposé tout
                    en restant raisonnables et compétitifs par rapport à
                    d'autres événements similaires. Les Organisateurs sont
                    encouragés à prendre en compte divers facteurs lors de la
                    fixation des prix, tels que les coûts de production, la
                    demande du marché et les attentes des participants.
                  </li>
                </ul>
                Les prix des différents billets événementiels sont les prix
                mentionnés par l'organisateur de l'événement.{" "}
                <strong>EVENTS</strong> ne fixe pas de prix des tickets des
                événements. <br />
                <br />
                <strong>EVENTS</strong> se réserve le droit de surveiller les
                prix des billets et de prendre des mesures en cas de
                tarification abusive ou de pratiques commerciales trompeuses.
                Tout Organisateur enfreignant cette politique peut être soumis à
                des sanctions, y compris la suppression de l'événement de la
                plateforme et la suspension du compte.
              </p>
              <li>
                <strong>Politique de Remboursement</strong>
              </li>
              <p>
                Les Organisateurs sont déchargés de la responsabilité de définir
                et de gérer la politique de remboursement pour leurs événements.
                En cas d'annulation de l'événement par les Organisateurs, la
                responsabilité du remboursement incombe entièrement à l'équipe
                de <strong>Events.</strong>
                <h4>Cas d'Annulation de l'Événement</h4>
                Si l'Organisateur se trouve dans l'obligation d'annuler un
                événement pour des raisons indépendantes de sa volonté, telles
                que des circonstances imprévues ou des contraintes logistiques,
                il est tenu d'en informer <strong>Events</strong> au plus tard
                une deux (02) semaine soit quatorze (14) jours avant la date de
                début de l’événement. Les participants ayant acheté des billets
                pour cet événement seront alors remboursés intégralement selon
                les modalités de traitement des remboursements fixées par{" "}
                <strong>Events.</strong>
                <h4>Modalités pour les Remboursements</h4>
                En cas d'annulation d'un événement, Events s'engage à rembourser
                les participants ayant acheté des billets. Tous les détails
                concernant les frais de traitement des remboursements et les
                éventuelles déductions seront déterminés par{" "}
                <strong>Events</strong> et notifiés aux participants lors de
                l'annulation de l'événement. Les participants seront informés de
                ces conditions et du montant exact remboursé au moment de la
                notification. Le solde restant sera remboursé sur le mode de
                paiement initial utilisé pour l'achat des billets, sauf
                indication contraire. <br />
                <br />
                Cette politique permet à <strong>Events</strong> de maintenir un
                service de qualité tout en assurant la satisfaction des
                participants.
                <h4>Responsabilité de Events pour les Remboursements</h4>
                L'équipe de <strong>Events</strong> est chargée de traiter les
                remboursements de manière rapide et efficace. Les fonds seront
                remboursés aux participants selon les modalités définies par{" "}
                <strong>Events.</strong> Cela peut inclure le remboursement sur
                le mode de paiement initial utilisé pour l'achat des billets ou
                d'autres méthodes de remboursement spécifiées.
                <h4>Communication avec les Participants</h4>
                Lorsqu'un événement est annulé, <strong>Events</strong>{" "}
                communiquera avec les participants affectés pour les informer de
                l'annulation et des modalités de remboursement. Un service
                client sera disponible pour répondre à toutes les questions ou
                préoccupations des participants concernant les remboursements.{" "}
                <br />
                <br />
                <strong>Events</strong> prendra toutes les mesures nécessaires
                pour garantir le respect des droits des participants et
                maintenir la confiance dans la plateforme.
              </p>
              <li>
                <strong>Frais de Service</strong>
              </li>
              <p>
                Des frais de service (taxes, frais de paiement, ...etc.), dont
                le pourcentage est indiqué dans l’onglet de paiement du billet
                événementiel dans l’application, peuvent s'ajouter au prix net
                fixé au départ par l’organisateur. Cependant,{" "}
                <strong>Events</strong> ne prélève aucun frais de service sur
                chaque billet vendu via l’application. <br />
                <br />
                <strong>EVENTS</strong> se réserve le droit de modifier à tout
                moment le pourcentage de ses frais de service, étant donné que
                le pourcentage (frais en %) figurant dans l’onglet de paiement
                du billet événementiel dans l’application sera le seul
                applicable au Client.
              </p>
            </ol>
            <h3>ARTICLE 3 – DISPONIBILITE ET CLOTURE DES VENTES DE BILLETS</h3>
            <ol>
              <li>
                <strong>Date de Clôture des Ventes</strong>
              </li>
              <p>
                Pour assurer une gestion efficace des événements et permettre
                aux participants de se préparer en conséquence, nous laissons à
                l'organisateur le libre choix de décider de la date de clôture
                des ventes de billets. Une fois cette date définie, elle reste
                inchangée. Cela garantit que tous les billets sont traités et
                que les informations pertinentes sont communiquées aux
                participants en temps opportun.
              </p>
              <li>
                <strong>Épuisement des Billets et Notification</strong>
              </li>
              <p>
                Lorsque le nombre de billets définis par l'Organisateur lors de
                l'enregistrement de l'événement est épuisé avant la date de
                clôture des ventes défini par l’organisateur,{" "}
                <strong>Events </strong>
                notifiera immédiatement l'Organisateur. Cette notification
                permet à l'Organisateur de décider s'il souhaite augmenter le
                nombre de billets disponibles pour l'événement.
              </p>
              <li>
                <strong>Augmentation du Nombre de Billets</strong>
              </li>
              <p>
                Si l'Organisateur souhaite augmenter le nombre de billets
                disponibles après l'épuisement initial, il doit contacter
                l'équipe de <strong>Events</strong> pour donner l'ordre
                d'ajouter des billets supplémentaires. Cette demande peut être
                faite par le support organisateur ou par téléphone, en utilisant
                les coordonnées fournies lors de la création de l'événement. Une
                fois la demande approuvée et traitée, les billets
                supplémentaires seront immédiatement mis en vente sur la
                plateforme.
              </p>
              <li>
                <strong>Clôture des Ventes et Marquage 'SOLD OUT'</strong>
              </li>
              <p>
                Si l'Organisateur souhaite ne pas ajouter de billets
                supplémentaires, <strong>Events </strong>
                procédera à la clôture des ventes pour cet événement.
                L'événement restera visible sur l'application de réservation,
                mais sera marqué comme <b>'SOLD OUT'.</b> Ce marquage informe
                les utilisateurs que tous les billets disponibles ont été vendus
                et qu'aucun billet supplémentaire ne sera mis en vente.
              </p>
              <li>
                <strong>Maintien de la Visibilité de l'Événement</strong>
              </li>
              <p>
                Même après la clôture des ventes, l'événement restera disponible
                sur l'application de réservation jusqu'à la date et l'heure de
                son déroulement. Cela permet aux utilisateurs de consulter les
                détails de l'événement, même s'ils ne peuvent plus acheter de
                billets. La visibilité continue de l'événement contribue à
                maintenir l'intérêt et la notoriété de l'Organisateur et de son
                événement auprès de la communauté des utilisateurs de{" "}
                <strong>Events.</strong>
              </p>
            </ol>
            <h3>
              ARTICLE 4 – PORCENTAGES LIES AUX PUBLICATIONS DES EVENEMENTS
            </h3>
            <ol>
              <li>
                <strong>Frais de Publication</strong>
              </li>
              <p>
                Lors de la mise en ligne des événements sur notre plateforme,
                des frais de publication sont appliqués pour couvrir les coûts
                de mise en ligne, de promotion et de gestion des événements. Ces
                frais sont calculés en pourcentage du prix total des billets
                vendus. <br />
                Les modalités spécifiques des frais de publication et leurs
                pourcentages seront communiqués directement aux organisateurs
                dans le cadre des accords contractuels correspondants.
              </p>
              <li>
                <strong>Ajustement des Frais</strong>
              </li>
              <p>
                <strong>EVENTS</strong> se réserve le droit d'ajuster les
                pourcentages de frais de publication et de service en fonction
                des conditions du marché et des exigences opérationnelles. Les
                Organisateurs seront informés de toute modification des frais au
                moins trente (30) jours avant leur mise en application. La
                continuation de l'utilisation des services après notification
                des modifications vaut acceptation de ces modifications.
              </p>
            </ol>
            <h3>ARTICLE 5 – REGLEMENT DES PAIEMENTS</h3>
            <ol>
              <li>
                <strong>Frais de Service</strong>
              </li>
              <p>
                Il existe deux types de frais de service applicables avec{" "}
                <strong>EVENTS</strong> :
                <ul>
                  <li>
                    <b>Frais de Service pour les Clients :</b> Ces frais sont
                    ajoutés au prix d'achat des billets par les clients. Ils
                    couvrent les coûts de traitement des transactions, de
                    gestion des billets, et des services associés à l'achat des
                    billets.
                  </li>
                  <li>
                    <b>Frais de Service pour les Organisateurs :</b> Ces frais
                    sont déduits des revenus générés par la vente des billets.
                    Ils couvrent les coûts de mise en ligne, de promotion, et de
                    gestion de l'événement sur la plateforme. Ces frais sont
                    appliqués sur chaque vente de billet et sont déduits avant
                    le versement des paiements aux Organisateurs.
                  </li>
                </ul>
                Les frais de service seront automatiquement déduits du montant
                total des ventes avant tout versement.
              </p>
              <li>
                <strong>Modalités de Paiement</strong>
              </li>
              <p>
                Les paiements aux organisateurs sont effectués en plusieurs
                étapes pour assurer la transparence et la clarté du processus.
                Les détails spécifiques concernant les modalités de paiement
                seront communiqués directement aux organisateurs dans le cadre
                des accords contractuels pertinents. <br />
                <br />
                Les paiements seront versés en plusieurs tranches, conformément
                au calendrier défini dans ces accords, et peuvent varier selon
                la nature et la portée de l'événement.
              </p>
              <li>
                <strong>Conditions de Versement</strong>
              </li>
              <p>
                Les versements seront effectués uniquement si toutes les
                informations de paiement fournies par l'Organisateur sont
                correctes et vérifiées. En cas de non-conformité des
                informations de paiement, le versement peut être retardé ou
                annulé jusqu'à ce que les informations correctes soient
                fournies. <br />
                <br />
                Les paiements aux Organisateurs seront réalisés exclusivement
                par virement bancaire, nécessitant la fourniture d'un Relevé
                d'Identité Bancaire (RIB), ou par chèque signé par{" "}
                <strong>Events.</strong> Il est impératif que les Organisateurs
                fournissent des informations bancaires précises et à jour lors
                de la validation de leur événement pour garantir la bonne
                réception des fonds. Tout manquement à cette obligation peut
                entraîner des retards ou des annulations de paiements jusqu'à la
                régularisation des informations fournies.
              </p>
            </ol>
            <h3>ARTICLE 6 – ACCEPTATION DES CONDITIONS</h3>
            <p>
              En publiant un événement sur <strong>Events,</strong> vous
              acceptez les termes de cette Charte et vous engagez à les
              respecter intégralement. Cette acceptation constitue un accord
              légal entre vous <b>("Organisateur") </b>et{" "}
              <strong>Events.</strong> En plus des termes de cette Charte, vous
              vous engagez à :
              <ul>
                <li>
                  <b>Respecter les Législations en Vigueur :</b> Vous vous
                  conformez à toutes les lois et régulations applicables à
                  l'organisation et à la promotion de vos événements.
                </li>
                <li>
                  <b>Fournir des Informations Véridiques et Complètes :</b>{" "}
                  Toutes les informations fournies lors de l'inscription et de
                  la publication d'un événement doivent être exactes, complètes
                  et à jour.
                </li>
                <li>
                  <b>Adhérer aux Politiques de Publication :</b> Vous vous
                  conformez aux directives de publication de{" "}
                  <strong>Events,</strong> y compris, mais sans s'y limiter, les
                  normes de contenu, les exigences d'information sur
                  l'événement, et les règles de tarification.
                </li>
                <li>
                  <b>Gérer les Relations avec les Participants :</b> Vous êtes
                  responsable de la sécurité et du bon déroulement de votre
                  événement. Vous devez disposer d'une assurance adéquate pour
                  couvrir les risques liés à votre événement et garantir une
                  indemnisation en cas de dommages ou de blessures.
                </li>
                <li>
                  <b>Respecter les Obligations Financières :</b> Vous acceptez
                  les modalités de paiement et les frais décrits dans cette
                  Charte. Vous vous engagez à fournir des informations de
                  paiement exactes pour garantir la réception des versements.
                </li>
                <li>
                  <b>Garantir la Conformité des Événements :</b> Vous vous
                  assurez que tous les événements que vous publiez respectent
                  les standards de sécurité, de légalité et de qualité requis
                  par <strong>Events.</strong>
                </li>
              </ul>
              En cas de non-respect de ces conditions, <strong>Events</strong>{" "}
              se réserve le droit de suspendre ou de supprimer vos événements,
              ainsi que de restreindre votre accès à la plateforme, sans
              préavis.
            </p>
            <h3>ARTICLE 7 – DUREE ET RESIALIATION</h3>
            <p>
              Ce document est valide jusqu'à ce que l'Organisateur décide de
              retirer son événement de la plateforme ou que{" "}
              <strong>Events</strong> décide de résilier le contrat en cas de
              non-respect des conditions énoncées dans cette Charte.
            </p>
            <h3>ARTICLE 8 – MODIFICATIONS DE LA CHARTE</h3>
            <p>
              <strong>EVENTS</strong> se réserve le droit de modifier cette
              Charte à tout moment. Les modifications seront publiées sur la
              plateforme et les Organisateurs seront informés par email. La
              continuation de l'utilisation de la plateforme après notification
              des modifications vaut acceptation de ces modifications.
            </p>
            <h3>ARTICLE 9 – LITIGES</h3>
            <p>
              Tout litige relatif à l'application de cette Charte sera soumis à
              la juridiction exclusive des tribunaux compétents. <br />
              <br />
              En cas de violation de cette charte, <strong>Events</strong> se
              réserve le droit de prendre des mesures appropriées, y compris la
              suppression de l'événement et la suspension du compte de
              l'organisateur. <br />
              <br />
              Cette Charte des Organisateurs est conçue pour garantir une
              utilisation équitable et efficace de la plateforme{" "}
              <strong>Events</strong> pour tous les participants. <br />
              <br />
              Nous vous remercions de votre collaboration et de votre engagement
              à fournir des événements de qualité à nos utilisateurs. Pour toute
              question ou assistance, veuillez contacter notre support client à{" "}
              <Link
                className="font-medium text-primary-6000"
                to="mailto:contact@app-events.com"
              >
                contact@app-events.com.
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
}
