import React from 'react'
import('../../../styles/da1csdf707QboRT71_ard.css');

export default function SuccessContent() {
    return (
        <div className="w-full h-full flex justify-center items-center">
            <div className="w-full">
                
                <div className="w-full flex flex-col justify-center sm:rounded-2xl space-y-10 px-0 sm:p-6 xl:p-8">
                    <div className="clbq0 cxsfz">
                        <div className="cg2bh clu2m">
                            <div className="cydwr">
                            <svg className="inline-flex cbm9w cai6b c3nk1 crzrx" viewBox="0 0 64 64">
                                <circle className="cpgwy" cx={32} cy={32} r={32} />
                                <path
                                className="c19il"
                                d="M37.22 26.375a1 1 0 1 1 1.56 1.25l-8 10a1 1 0 0 1-1.487.082l-4-4a1 1 0 0 1 1.414-1.414l3.21 3.21 7.302-9.128Z"
                                />
                            </svg>
                            <h3 className="text-xl text-gray-800 dark:text-gray-100 font-bold mb-3">
                            Succès !
                            </h3>
                            <span className="block text-sm mb-2 text-neutral-500 dark:text-neutral-400">
                            Votre adresse e-mail a été modifiée avec succès.
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
