import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useStateContext } from '../components/contexts/ContextProvider';
import LoadContentSpinner from '../components/Utils/LoadContentSpinner';

export default function CGU() {
    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Conditions Générales d'Utilisation - Events";
        
    }, []);

    const { loading } = useStateContext();

    if (loading) {
        return <LoadContentSpinner />;
    }
    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 mb-5">
            <Helmet>
                <meta name="description" content="Consultez les conditions d'utilisation de Events, notre plateforme dédiée à la gestion et à la promotion d'événements. Découvrez les droits, obligations et les règles que vous acceptez en utilisant nos services." />
                <meta 
                    name="keywords" 
                    content="Conditions d'utilisation, Events, termes et conditions, droits et obligations, règlement, politique de confidentialité, utilisation de la plateforme, organisateurs d'événements, participants à des événements" 
                />
            </Helmet>
            <header className="container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <span
                        className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-purple-800 bg-purple-100"
                        
                    >
                        Events rédactions
                    </span>
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="CONDITIONS GENERALES D’UTILISATION DE EVENTS"
                    >
                        CONDITIONS GENERALES D’UTILISATION DE EVENTS
                    </h1>
                
                
                    <div className="flex flex-col items-baseline sm:flex-row sm:justify-between" style={{marginTop: "2rem"}}>
                        <div className="nc-PostMeta2 mb-5 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
                            <div className="relative flex-shrink-0 flex items-center justify-center rounded-full w-8 h-8 sm:h-11 sm:w-11  flex-shrink-0">
                                <img
                                alt="Events"
                                loading="lazy"
                                decoding="async"
                                data-nimg={1}
                                className="inset-0  object-cover"
                                style={{ color: "transparent" }}
                                srcSet="../../assets/images/logo_events.png"
                                src="../../assets/images/logo_events.png"
                                />
                            </div>
                            <div className="ml-3">
                                <div className="flex items-center">
                                <span className="block font-semibold">
                                    Equipe Events
                                </span>
                                </div>
                                <div className="text-xs mt-[6px]">
                                <span className="text-neutral-700 dark:text-neutral-300">
                                    Mai 2024
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                            <nav
                                className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 "
                                data-nc-id="SocialsList"
                            >
                                <Link
                                className="block"
                                to="https://www.facebook.com/profile.php?id=61558762937775"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Facebook"
                                >
                                <i className="lab la-facebook-square" />
                                </Link>
                                {/* <a
                                className="block"
                                href="#"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Twitter"
                                >
                                <i className="lab la-twitter" />
                                </a>
                                <a
                                className="block"
                                href="#"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Youtube"
                                >
                                <i className="lab la-youtube" />
                                </a> */}
                                <Link
                                className="block"
                                to="https://www.instagram.com/events.app225?igsh=YzljYTk1ODg3Zg=="
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Instagram"
                                >
                                <i className="lab la-instagram" />
                                </Link>
                                <Link
                                className="block"
                                to="https://wa.me/+2250717519518"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Whatsapp Support Events"
                                >
                                <i className="lab la-whatsapp" />
                                </Link>
                            </nav>
                        </div>
                    </div>
                    <div className="w-full border-b border-neutral-100 dark:border-neutral-800 mb-5" />
                </div>
            </header>
            <div className="nc-SingleContent container space-y-10 mt-5">
                <div className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark">
                    <span className="text-neutral-700 dark:text-neutral-300">Dernière mise à jour de cette condition : 20 Mai 2024</span>
                    <h3>PRÉAMBULE</h3>
                <p>
                    Les présentes Conditions Générales d'Utilisation (ci-après dénommées les "CGU") 
                    régissent l'utilisation de l'application mobile de réservation de tickets événementiels
                    <strong> Events</strong> (ci-après dénommée "l'Application") y compris notre site Web Officiel fournie 
                    par l’équipe de développement <strong>Events.</strong> <br />
                    Ces conditions s’appliquent pleinement à l’utilisation de l'application mobile <strong>Events</strong> et aux services dérivés. <br /><br />
                    En téléchargeant, installant et utilisant l'Application, vous acceptez de vous 
                    conformer aux présentes CGU ainsi qu’à toutes les modifications ultérieures qui 
                    pourraient survenir au titre de l’utilisation des services. Vous ne devez pas utiliser 
                    l'Application <strong>Events</strong> si vous avez une objection à l’une de ces conditions.
                </p>
                <h3>DESCRIPTION DE L’APPLICATION EVENTS</h3>
                <p>
                    <strong>Events</strong> est une application mobile de réservation de tickets événementiels 
                    permettant aux utilisateurs de rechercher et acheter des tickets pour divers 
                    événements tels que des concerts, des spectacles, des festivals, etc.
                </p>
                <h3>UTILISATION DE L’APPLICATION</h3>
                <ol>
                    <li>
                        <strong>Restriction d’âge</strong> 
                    </li>
                    <p>
                        Vous devez avoir l'âge légal pour utiliser l'Application conformément à la législation en vigueur dans votre pays de résidence.
                    </p>
                    <li>
                        <strong>Compte Utilisateur</strong> 
                    </li>
                    <p>
                        <b>« Compte Client Events » ou « Compte »</b> désigne le compte utilisateur, ouvert au 
                        nom du client sur l’Application, afin de lui permettre d’utiliser les Services de <strong>Events.</strong> Pour utiliser certaines fonctionnalités de l'Application, vous devrez créer un compte 
                        utilisateur. <br />
                        Vous acceptez de fournir des informations exactes, complètes et à jour lors de votre 
                        inscription et de maintenir la précision de ces informations tout au long de votre 
                        utilisation de l'application. <br />
                        Vous êtes responsable de maintenir la confidentialité de votre compte et de votre 
                        mot de passe, et vous acceptez de ne pas partager ces informations avec des tiers.
                    </p>
                    <li>
                        <strong>Utilisation Conforme</strong>
                    </li>
                    <p>
                        L'Application est destinée à un usage personnel et non commercial. Vous vous 
                        engagez à utiliser l'Application de manière conforme à la loi et aux présentes CGU. 
                        Vous vous interdisez notamment de :
                        <ul>
                            <li>
                                Utiliser l'Application à des fins illicites ou interdites par les présentes CGU.
                            </li>
                            <li>
                                Porter atteinte à la sécurité de l'Application ou tenter d'accéder à des données non destinées à votre consultation.
                            </li>
                            <li>
                                Utiliser l'Application de manière à causer des dommages aux autres utilisateurs ou à des tiers.
                            </li>
                            <li>
                                Pirater le compte d'un autre utilisateur
                            </li>
                        </ul>
                    </p>
                </ol>

                <h3>RESERVATION DE TICKETS</h3>
                <ol>
                    <li>
                        <strong>Processus de Réservation</strong> 
                    </li>
                    <p>
                        L'Application permet aux utilisateurs de rechercher, sélectionner et acheter des 
                        tickets pour différents événements. Le processus de réservation est le suivant :
                        <ul>
                            <li>
                                <b>Recherche d'Événements :</b> Les utilisateurs peuvent parcourir les événements 
                                disponibles via l'Application en utilisant les filtres de recherche appropriés 
                                (type, catégorie, etc.).
                            </li>
                            <li>
                                <b>Sélection de Tickets :</b> Une fois l'événement choisi, les utilisateurs peuvent 
                                visualiser les détails de l'événement ainsi que les types de tickets disponibles et 
                                les prix.
                            </li>
                            <li>
                                <b>Paiement :</b> Une fois les tickets sélectionnés, les utilisateurs peuvent procéder au 
                                paiement via les méthodes de paiement acceptées par l'Application <strong>Events. </strong>
                                Les paiements effectués via l'Application sont sécurisés. En effectuant un 
                                paiement via l'Application, vous consentez à ce que vos informations de 
                                paiement soient traitées conformément à la politique de confidentialité dudit 
                                système.
                            </li>
                        </ul>
                        En effectuant une réservation, l'utilisateur accepte les conditions de vente de 
                        l'organisateur de l'événement ainsi de payer le prix indiqué pour le(s) ticket(s) 
                        réservé(s), ainsi que les éventuels frais de service et taxes applicables.
                    </p>
                    <li>
                        <strong>Conditions de Vente</strong>
                    </li>
                    <p>
                        En effectuant une réservation, l'utilisateur (acheteur) accepte les conditions de 
                        vente de l'organisateur de l'événement, qui peuvent inclure, sans s'y limiter :
                        <ul>
                            <li>
                                Conditions d'annulation et de remboursement
                            </li>
                            <li>
                                Âge minimum requis pour assister à l'événement
                            </li>
                            <li>
                                Règles de conduite et de sécurité
                            </li>
                            <li>
                                Politique d'utilisation des tickets (ex. : tickets électroniques)
                            </li>
                        </ul>
                    </p>
                    <li>
                        <strong>Confirmation de Réservation</strong>
                    </li>
                    <p>
                        Une fois le paiement effectué avec succès, l'utilisateur recevra une confirmation de 
                        sa réservation par e-mail ou via l'Application. Cette confirmation contiendra les détails de l'événement ainsi que les informations sur les tickets réservés (QR Code). 
                        Tous les paiements doivent être effectués en totalité avant de recevoir vos billets.
                    </p>
                </ol>
                <h3>ANNULATION ET REMBOURSEMENT</h3>
                <ol>
                    <li>
                        <strong>Annulation d'un événement</strong> 
                    </li>
                    <p>
                        En cas d'annulation d'un événement par l'organisateur, les Utilisateurs ayant acheté 
                        des billets pour cet événement seront remboursés selon les modalités définies par <strong>Events</strong> dans la charte des organisateurs. Les Utilisateurs seront informés de 
                        l'annulation de l'événement par courrier électronique à l'adresse fournie lors de 
                        l'inscription, notification push ou tout autre moyen de communication mis à 
                        disposition par l'Application.
                    </p>
                    <li>
                        <strong>Demande de remboursement</strong>
                    </li>
                    <p>
                        Si un Utilisateur souhaite obtenir un remboursement pour des billets achetés via 
                        l'Application il devra remplir un formulaire de demande de remboursement 
                        disponible sur dans les détails du ticket acheté en cas d’annulation. Peu importe le 
                        nombre de billets achetés pour un même événement ou un même Pass, l'Utilisateur 
                        devra soumettre un seul formulaire. <br />
                        L'Utilisateur devra fournir les informations correctes de la transaction effectuée (Avec SUCCES) pour le ticket en question.
                    </p>
                    <li>
                        <strong>Traitement de la demande de remboursement</strong>
                    </li>
                    <p>
                        Après la soumission du formulaire, l'administration de <strong>Events</strong> vérifiera la conformité 
                        des informations de paiement soumises. <br /><br />
                        Une fois la demande de remboursement envoyée, l'Utilisateur pourra suivre l'état de sa demande en se rendant dans la section <b>"Mon Profil"</b>
                        de son compte utilisateur. Il pourra consulter l'historique de ses demandes ainsi que leur statut. Pour ce faire, il lui 
                        suffira de cliquer sur le bouton <b>"Remboursement"</b> disponible dans cette section. <br /><br />
                        Si les informations fournies ne sont pas conformes ou si des éléments sont manquants, <strong>Events</strong> se réserve le droit d'annuler la demande de remboursement. Dans ce cas, 
                        l'Utilisateur sera informé des raisons de l'annulation de sa demande et pourra être 
                        invité à soumettre à nouveau le formulaire avec les informations correctes. <br /><br />
                        Une fois la demande de remboursement validée, <strong>Events</strong> procédera au 
                        remboursement. Les remboursements seront effectués dans un délai raisonnable, 
                        selon le mode de paiement initial utilisé par l'Utilisateur. <br /> Toutefois <strong>Events</strong> se réserve le droit de déduire les frais de service et de traitement des 
                        remboursements si nécessaire selon les modalités applicables. 
                    </p>
                    <li>
                        <strong>Absence de responsabilité de Events</strong>
                    </li>
                    <p>
                        En cas d'annulation d'un événement par l'organisateur, <strong>Events</strong> ne pourra en aucun 
                        cas être tenu responsable des dommages directs ou indirects subis par les Utilisateurs, 
                        tels que les frais de déplacement, d'hébergement ou tout autre préjudice. <br /><br />
                        <strong>Events</strong> n'est pas responsable des annulations d'événements par les organisateurs.
                    </p>
                </ol>
                
                <h3>RESPONSABILITES</h3>
                <ol>
                    <li>
                        <strong>Responsabilité de l'Utilisateur</strong> 
                    </li>
                    <p>
                        L'Utilisateur est seul responsable de l'utilisation qu'il fait de l'Application. En utilisant 
                        l'Application, l'Utilisateur s'engage à :
                        <ul>
                            <li>
                                Respecter la législation en vigueur dans son pays de résidence ainsi que dans 
                                le pays où se déroule l'événement pour lequel il achète des billets.
                            </li>
                            <li>
                                Utiliser l'Application de manière conforme aux présentes Conditions Générales 
                                d'Utilisation (CGU) ainsi qu'aux conditions spécifiques de chaque événement.
                            </li>
                            <li>
                                Ne pas utiliser l'Application à des fins illicites, frauduleuses ou contraires à l'ordre public.
                            </li>
                            <li>
                                Ne pas porter atteinte aux droits de propriété intellectuelle de <strong>Events</strong> ou de tout tiers.
                            </li>
                        </ul>
                        L'utilisateur est également responsable de la confidentialité de ses identifiants de 
                        connexion et de l'utilisation de son compte utilisateur. Il s'engage à informer immédiatement <strong>Events</strong> en cas d'utilisation non autorisée de son compte. <br /><br />
                        Les Clients sont responsables de leur Billet. En cas de perte ou de vol de téléphone et de l’application etc…, <strong>Events </strong> 
                        ne pourra pas garantir l’accès à l’événement sauf si un contrôle est fait dans la base de données de pour retrouver le ou les tickets perdus.
                    </p>
                    <li>
                        <strong>Responsabilité de Events</strong>
                    </li>
                    <p>
                        <strong>Events</strong> met tout en œuvre pour assurer la disponibilité, la sécurité et la fiabilité de l'Application. Cependant, <strong>Events </strong>
                        ne peut garantir l'absence d'erreurs, de virus ou autres éléments nuisibles. <strong>Events</strong> décline toute responsabilité en cas de dommages 
                        directs ou indirects causés à l'Utilisateur ou à des tiers, notamment :
                        <ul>
                            <li>
                                Pertes de données ou de revenus.
                            </li>
                            <li>
                                Interruptions ou indisponibilités de l'Application.
                            </li>
                            <li>
                                Utilisation frauduleuse de l'Application par des tiers.
                            </li>
                            <li>
                                Tout préjudice résultant de l'utilisation ou de l'impossibilité d'utiliser l'Application.
                            </li>
                        </ul>
                        En aucun cas, la responsabilité de <strong>Events</strong> ne pourra être engagée pour des 
                        dommages indirects, accessoires, particuliers ou consécutifs, même si <strong>Events</strong> a été informé de la possibilité de tels dommages.
                    </p>
                </ol>
                <h3>PROPRIETE INTELLECTUELLE</h3>
                <p>
                    L'Application, ainsi que tous les éléments qui la composent (notamment les textes, images, logos, marques, etc.), sont la propriété exclusive de 
                    <strong> Events</strong> ou de ses concédants. Toute reproduction, représentation, modification ou exploitation de tout ou partie de l'Application, sans l'autorisation préalable écrite de 
                    <strong> Events,</strong> est strictement interdite et <strong>Events</strong> se réserve le droit de demander des contreparties 
                    financières en cas de non-respect de ses règles et pourra donner lieu à des poursuites judiciaires.
                </p>

                <h3>MODIFICATION DES CGU</h3>
                <p>
                    <strong>Events</strong> se réserve le droit de modifier à tout moment les présentes CGU. Les Utilisateurs 
                    seront informés de toute modification des CGU par tout moyen jugé approprié par <strong>Events. </strong> 
                    Les Utilisateurs qui continuent à utiliser l'Application après la publication des modifications seront réputés avoir accepté les nouvelles CGU.
                </p>

                <h3>DROIT APPLICABLE ET JURIDICTION COMPETENTE</h3>
                <p>
                    Les présentes CGU sont régies par le droit Ivoirien. Tout litige relatif à l'interprétation ou à l'exécution des présentes CGU sera soumis aux tribunaux compétents.
                </p>
                </div>
               
            </div>
        </div>

    )
}
