import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import { useError } from '../../../components/contexts/ErrorContext';

export default function ShowEventsInvoices() {

    const [events, setEvents] = useState([]); // État pour les événements
    const [loading, setLoading] = useState(true); // État pour le chargement
    const [showInfoMessage, setShowInfoMessage] = useState(null);
    const { handleError } = useError();

    // Fonction pour charger les événements depuis l'API
    const fetchEvents = async () => {
        setLoading(true);

        axiosClient.get('/dashboard/user/organizer/invoice/list-events/getOfficial')
            .then(response => {
                setEvents(response.data.data); // Mettez à jour la liste des événements
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 404) {
                    // Afficher le message "Aucun événement accepté" dans une section spécifique
                    setShowInfoMessage(error.response.data.message);
                } else {
                    handleError("Erreur lors de la récupération des événements:", error);
                    console.error("Erreur lors de la récupération des événements:", error);
                }
                setLoading(false); // Arrêtez le chargement même en cas d'erreur
            });
    };

    // Charger les événements à chaque changement de filtre
    useEffect(() => {
        fetchEvents();
    }, []);

    if(loading) {
        return <LoadContentSpinner />
    }

    return (
        <div className='ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz'>
            {showInfoMessage ? (
                <div className="w-full h-full flex justify-center items-center mt-12 cweej">
                    <div className="cydwr clbq0">
                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>

                        </div>
                        <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                        OUPS ! Aucun événement enregistré
                        </h4>
                        <div className="break-all mb-6 text-sm">
                        {showInfoMessage}
                        </div>
                        <Link to={'/dashboard/events/registration'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Enregistrer un nouvel événement
                        </Link>
                    </div>
                </div>
            ) : (
                <>
                    <div className="c2g1r cwnq4 cnlq0 c6r0l">
                        {/* Left: Title */}
                        <div className="c2rn6 cdiog">
                            <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                            Mes événements publiés <span className="cmpw7 cdqku c1k3n">{events.length}</span>
                            </h1>
                            <span class="text-sm">
                            Ci-dessous, vous trouverez la liste de vos événements publiés. Cliquez sur le bouton "Voir les factures" pour accéder à la liste des factures associées à chaque événement.    
                            </span>
                        </div>
                    </div>
                    {events.length > 0 ? (

                        <div className="cvxzw">
                            {/* <h2 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu c6r0l">
                                Trending Now
                            </h2> */}
                            <div className="c7nom ckn6o c4sak">
                                {/* Card */}
                                {events.map(event => (
                                    <div key={event.id} className="bg-white cozqq c2vpa cxe43 ci500 cdjoa coz6m c1hly c5vqk cm84d">
                                    {/* Image */}
                                    <img
                                        className="cfwu1 cqdkw cav8x c6btv"
                                        width={286}
                                        height={160}
                                        src={`data:image/jpeg;base64,${event.affiche}`}
                                        alt={event.name}
                                    />
                                    {/* Gradient */}
                                    <div className="c7rlo cv9py c76xn cqdkw cini7" aria-hidden="true" />
                                    {/* Content */}
                                    <div className="flex justify-end cm84d cetff cav8x csusu">
                                        <h3 className="cgulq cpcyu cjxkd c7x0x cuifd">{event.code}</h3>
                                        <Link className="text-sm text-primary-600 hover:text-primary-700 czpp7 c1k3n" to={`/dashboard/factures/events/invoices/show-details/i/${event.id}`}>
                                        Voir les factures -&gt;
                                        </Link>
                                    </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="w-full flex justify-center items-center cweej">
                            <div className="cydwr clbq0">
                                <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                    <path
                                        className="text-gray-500 co1wq"
                                        d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="dark:text-gray-400 ckbo4"
                                        d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="cmpw7 cdqku"
                                        d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                        fill="currentColor"
                                    />
                                </svg>

                                </div>
                                <div className="mb-6">
                                Aucun événement trouvé.
                                </div>
                                <Link to={'/dashboard/events/registration'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                                    Enregistrer un nouvel événement
                                </Link>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
