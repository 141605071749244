import React from 'react'
import { createPortal } from 'react-dom';

export default function SpinnerLoadContent() {
    return createPortal(
        <div className="flex justify-center items-center h-screen w-full relative">
          <div className="relative w-[55px] h-[55px]">
            <div className="absolute w-full h-full rounded-full border-[3px] border-transparent border-l-[#5bcfc5] animate-rotate"></div>
            <div className="absolute w-full h-full rounded-full border-[3px] border-transparent border-l-[#5bcfc5] animate-rotateOpacity delay-[0.1s]"></div>
            <div className="absolute w-full h-full rounded-full border-[3px] border-transparent border-l-[#5bcfc5] animate-rotateOpacity delay-[0.2s]"></div>
          </div>
        </div>,
        document.body // Ceci est l'élément DOM valide
    );
}
