import React, { useEffect, useState } from 'react'
import axiosClient from '../../api/axiosClient';
import LoadContentSpinner from '../../Utils/LoadContentSpinner';
import { Link } from 'react-router-dom';
import FormattedDate from '../../Utils/FormattedDate';

export default function ListEventsComponentToAgentScanner() {

    // État pour contrôler l'ouverture ou la fermeture du dropdown
    const [isOpen, setIsOpen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [eventsToScanGetting, setEventsToScanGetting] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true); 
        // Appel API pour récupérer les événements en cours de validation
        axiosClient.get('/dashboard/organizer/getParticipant/scanner/details')
            .then(response => {
                setEventsToScanGetting(response.data.data); // Mettez à jour la liste des événements
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    // Récupérer les messages spécifiques de l'erreur backend
                    setError(error.response.data);
                } else {
                    setError({message: 'Erreur lors de la récupération des événements',});
                }
                setLoading(false); // Arrêtez le chargement même en cas d'erreur
            });
    }, []);

    if (loading) {
        return <LoadContentSpinner />
    }

    // Afficher une section dédiée en cas d'erreur
    if (error) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                        Erreur
                    </h4>
                    <div className="mb-6 text-sm break-all">
                        {error.message}
                    </div>
                    {error.errorCode === 'EVENT_EMPTY' && (
                        <Link to={'/dashboard/events/registration'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Enregistrer un nouvel événement
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    const toggleDropdown = (index) => {
        setIsOpen(isOpen.includes(index) ? [] : [index]);
    };

    return (
        <div className="cr78y cai6b">
            {eventsToScanGetting.length > 0 ? (
                
                <div className="cweej">
                    {/* Job 1 */}
                    {eventsToScanGetting.map((event, index) => (

                        <div key={index} className="bg-white c2vpa rounded-xl border-gray-100 border c5vqk px-5 py-4">
                            <div className="items-center cm3rx ccjpx ch3kz cr78y clgee">
                                {/* Left side */}
                                <div className="flex cmxlj ce4zk cp3jk">
                                    <div className="coqgc ccwg3">
                                        <img
                                            className="rounded-md h-12 w-16"
                                            width={46}
                                            height={46}
                                            src={`data:image/jpeg;base64,${event.affiche}`}
                                            alt={event.event_title}
                                        />
                                        </div>
                                    <div>
                                    <Link
                                        className="inline-flex text-gray-800 dark:text-gray-100 cgulq"
                                        to={`/dashboard/events/view-details/to/${event.id}`}
                                    >
                                        {event.event_title}
                                    </Link>
                                    <div className="text-sm">A lieu le : {event.default_date} </div>
                                    </div>
                                </div>
                                {/* Right side */}
                                <div className="flex sm:pl-6 sm:flex-row flex-col items-center cm4ey ca2zo">
                                    
                                    <button 
                                        aria-expanded={isOpen.includes(index) ? "true" : "false"}
                                        onClick={() => toggleDropdown(index)} 
                                        className="bg-sidebar-linkGroup rounded-full c3e4j cg12x cmpw7 cdqku c98dn">
                                        <span className="cn8jz">Menu</span>
                                        <svg className={`transition-transform transform cbm9w cue4z cmwfi ${isOpen ? 'rotate-180' : 'rotate-0'}`} viewBox="0 0 32 32">
                                            <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                                        </svg>
                                    </button>
                                    
                                </div>
                            </div>
                            {/* Content */}
                            {isOpen.includes(index) && (
                                <div className="bg-sidebar-linkGroup mt-4 rounded-md shadow-sm items-center flex-wrap -m-1 dark:text-gray-400 coulr c1f2y c9j8s">
                                    <ul>
                                        <li className="border-gray-200 cghq3 cfe7d cq3sl clgee c72q5">
                                            {/* Left */}
                                            <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                            Statut du compte agent
                                            </div>
                                            {/* Right */}
                                            <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                                                {event.events_data_scans.status ? (

                                                    event.events_data_scans.status === 1 ? (
                                                        <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n cdw1w c9hxi ch4gv c1iho cwn3v">
                                                            {'ACTIF'}
                                                        </div>
                                                    ) : event.events_data_scans.status === 0 && (
                                                        <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi ch4gv c1iho cwn3v">
                                                            {'EXPIRE'}
                                                        </div>
                                                    )
                                                    
                                                ) : (
                                                    <>...</>
                                                )}
                                            
                                            </div>
                                        </li>
                                        <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                                            {/* Left */}
                                            <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                            Code de l'agent
                                            </div>
                                            {/* Right */}
                                            <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                                                {event.events_data_scans.code_agent ? (

                                                    <span className="chfzq">{event.events_data_scans.code_agent}</span>
                                                ) : (
                                                    <>...</>
                                                )}
                                            </div>
                                        </li>
                                        <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                                            {/* Left */}
                                            <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                            Identifiant agent
                                            </div>
                                            {/* Right */}
                                            <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                                                {event.events_data_scans.agent_id ? (

                                                    <span className="chfzq">{event.events_data_scans.agent_id}</span>
                                                ) : (
                                                    <>...</>
                                                )}
                                            </div>
                                        </li>
                                        <li className="border-gray-200 cghq3 cfe7d cq3sl ctv3r clgee c72q5">
                                            {/* Left */}
                                            <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                            E-mail d'accès
                                            </div>
                                            {/* Right */}
                                            <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                                            {event.events_data_scans.email ? (

                                                <span className="chfzq">{event.events_data_scans.email}</span>
                                            ) : (
                                                <>...</>
                                            )}
                                            </div>
                                        </li>
                                        <li className="border-gray-200 cghq3 cfe7d cq3sl clgee c72q5">
                                            {/* Left */}
                                            <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                            Ajouté le
                                            </div>
                                            {/* Right */}
                                            <div className="text-sm dark:text-gray-400 c1ukq cfh3y">
                                            {event.events_data_scans.created_at ? (

                                                <span className="chfzq"><FormattedDate date={event.events_data_scans.created_at} /> </span>
                                            ) : (
                                                <>...</>
                                            )}
                                            
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    ))}
                    
                </div>
            )  : (
                <div className="w-full h-full flex justify-center items-center cweej">
                        <div className="w-3/4 cydwr clbq0">
                            <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                    <path
                                        className="text-gray-500 co1wq"
                                        d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="dark:text-gray-400 ckbo4"
                                        d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="cmpw7 cdqku"
                                        d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                            Rien à Afficher
                            </h4>
                            <div className="mb-6 text-sm">
                                {'Aucune données de connexion à Events Scan n\'est disponible pour vos événement pour le moment.'}
                            </div>
                        </div>
                    </div>
            )}
        </div>
    )
}
