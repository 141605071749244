import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import GeneralOrdersSection from '../../../components/Dashboard/ordersComponents/GeneralOrdersSection';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import axiosClient from '../../../components/api/axiosClient';
import ListBuyer from '../../../components/Dashboard/ordersComponents/ListBuyer';

export default function DetailsToEventsOrders() {

    // Étape 1 : Créer un état local pour l'option sélectionnée
    const [selectedOption, setSelectedOption] = useState('Section générale des ventes');
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [eventOrdersDetails, setEventOrdersDetails] = useState(null);

    const fetchEventOrdersDetails = async () => {
        setError(null);   // Réinitialiser les erreurs
        setLoading(true);   // Réinitialiser les erreurs
        // Appel API pour récupérer les événements en cours de validation
        try {
            const response = await axiosClient.get(`/dashboard/event/order/salesTo/e/${id}`);
            setEventOrdersDetails(response.data.data); // Données de l'événement
        } catch (error) {
            if (error.response && error.response.data) {
                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Une erreur inconnue est survenue. Veuillez réessayer plus tard.',});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    useEffect(() => {
        if (id) {
            fetchEventOrdersDetails();
        }
    }, [id]);

    if(!id) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="w-3/4 cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Aucun événement trouvé, impossible d'accéder à cette page.
                    </h4>
                    <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                    Retour aux événements
                    </Link>
                </div>
            </div>
        )
    }

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Afficher une section dédiée en cas d'erreur
    if (error) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="w-3/4 cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Erreur
                    </h4>
                    <div className="mb-6 text-sm">
                    {error.message}
                    </div>
                    {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                        <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Retour aux événements
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    // Étape 2 : Fonction pour gérer le changement d'option
    const handleOptionChange = (option) => {
        setSelectedOption(option);
        setIsOpen(false); // Ferme le dropdown après la sélection
    };

    // Liste des options pour le dropdown
    const options = [
        'Section générale des ventes',
        'Liste des acheteurs'
    ];

    return (
        <div className='ch3yp cnbwt cs7xl mx-auto w-full clbq0 py-8'>
            <div className="c2g1r cwnq4 cnlq0 c6r0l">
                {/* Left: Title */}
                <div className="c2rn6 cdiog">
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                    Détails des ventes pour : <span className='text-primary-800'>{eventOrdersDetails.eventGlobalSta.event_code}</span>
                    </h4>
                    <div className="c6r0l mb-2">
                        <span>Vous affichez </span>
                        <div className="inline-flex cm84d">
                            <button
                                className="inline-flex justify-center items-center cqogy"
                                aria-expanded={isOpen}
                                onClick={() => setIsOpen(!isOpen)} // Ouvrir ou fermer le dropdown
                            >
                            <div className="flex items-center c941w">
                                <span className="text-blue-500 conxo c1k3n c941w">
                                {selectedOption}
                                </span>
                                <svg
                                className="w-3 h-3 text-blue-400 cbm9w coqgc cpts2"
                                viewBox="0 0 12 12"
                                >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                            </div>
                            </button>

                            {isOpen && ( // Affiche le dropdown seulement si isOpen est vrai
                                <div className="bg-white border border-gray-200 w-max cghq3 c2vpa cbx8s cxe43 cb8zv ccwri cqdkw ctd47 c45yg cbxoy cu1dd cvggx ccwg3">
                                    <ul>
                                        {options.map(option => (
                                            <li key={option}>
                                                <button
                                                    className="text-sm flex items-center c196r cqahh c0zkc c1ukq c1k3n cb2br cwn3v"
                                                    onClick={() => handleOptionChange(option)} // Change l'option sélectionnée
                                                >
                                                    {option}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* Right: Actions */}
                <div className="cm3b7 c51uw ccww4 csdex cbe1i c4sak">
                    {/* Search form */}
                    
                    {/* Create invoice button */}
                    <button className="btn bg-primary-600 hover:bg-primary-700 text-white cdj8c cg0jr ch8z9 cilvw cyn7a">
                    <svg
                        className="cbm9w cbmv0 coqgc"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                    >
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="cyga5">Nouvel événement</span>
                    </button>
                </div>
            </div>
            {selectedOption === 'Section générale des ventes' && (
                <GeneralOrdersSection data={eventOrdersDetails} />
            )}
            {selectedOption === 'Liste des acheteurs' && (
                <ListBuyer event={eventOrdersDetails.eventGlobalSta.id} />
            )}
            
        </div>
    )
}
