import React from "react";
import { formatPrice } from "./Utils/UtilsPricesFormat";
import { formatNumber } from "./Utils/UtilsNumbersFormat";
import { Link } from "react-router-dom";

function EventDetails({
  images,
  formData,
  ticketsData,
  eventData,
  // personalData,
}) {
  const affiche = URL.createObjectURL(new Blob([images.affiche]));
  const image_lieu_evenement = URL.createObjectURL(
    new Blob([images.image_lieu_evenement])
  );
  const formatDate = (rawDate) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const dateObject = new Date(rawDate);
    return dateObject.toLocaleDateString("fr-FR", options);
  };

  const totalTicketPrice = ticketsData.reduce((total, ticket) => {
    const price = parseFloat(ticket.prix_unitaire);
    return total + price;
  }, 0);

  // Formatage de la somme des prix
  const formattedTotalTicketPrice = totalTicketPrice.toLocaleString("fr-FR", {
    style: "currency",
    currency: "XOF",
    minimumFractionDigits: 0,
    useGrouping: true,
  });

  const imagePath = process.env.REACT_APP_IMAGE_PATH;

  return (
    <div className="cnbwt cs7xl c6btv clbq0 cxsfz">
      <div className="flex w-full c7k8z c2eqw cxsy8 cbxj7 cetff clu2m">
        {/* Content */}
        <div>
        <div className="cai6b">
            <Link
              className="bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa cnf4p cb2br"
              to="/dashboard"
            >
              <svg
                className="mr-2 cmpw7 cdqku cbm9w"
                width={7}
                height={12}
                viewBox="0 0 7 12"
              >
                <path d="M5.4.6 6.8 2l-4 4 4 4-1.4 1.4L0 6z" />
              </svg>
              <span>Page d'accueil</span>
            </Link>
            
          </div>
          <header className="cdiog">
            {/* Title */}
            <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb c6f83">
              {eventData.titre}
            </h1>
            <div className="p-2 mb-4 cvwie cosgb c33r0 cgnhv cb8zv cm84d">
              <div className="cm84d">
                  <div className="text-sm text-gray-800 ctv3j c1k3n">
                  Les ventes de cet événement seront clôturées le <b>{eventData.date_fin_cloture} à {eventData.heure_fin_cloture}</b> .
                  </div>
              </div>
            </div>
            <div className="flex items-center">
              <svg
                className="cmpw7 cdqku cbm9w coqgc"
                width={16}
                height={16}
                viewBox="0 0 16 16"
              >
                <path d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z" />
              </svg>
                <span className="text-sm text-gray-500 dark:text-gray-400 cq84g c1k3n c8bkw"> {eventData.lieu}</span>
            </div>

          </header>
          {/* Meta */}
          <div className="c2g1r cwnq4 cld0c cjav5 cnlq0 cai6b">
            <div className="flex items-center ccduf">
              <div className="block mr-2 coqgc">
                <img
                  className="rounded-full"
                  srcSet={`${imagePath}images/4746070.png`}
                  src={`${imagePath}images/4746070.png`}
                  width={32}
                  height={32}
                  alt="Acteur"
                />
              </div>
              <div className="text-sm cq84g">
                Acteur{" "}
                <span className="text-gray-800 dark:text-gray-100 cgulq">
                  {eventData.acteur_principal}
                </span>
              </div>
            </div>
            {/* Right side */}
            <div className="flex flex-wrap items-center c51uw ch3kz">
              {/* Tags */}
              <div title="Classe événementielle" className="inline-flex items-center border border-gray-200 dark:text-gray-400 rounded-full cghq3 c1ukq cydwr c1k3n ch4gv c1iho cwn3v">
                <svg className="w-3 h-3 mr-1 cveo1 cbm9w coqgc" viewBox="0 0 12 12">
                  <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                </svg>
                <span>
                  {`${
                    eventData.type_evenement.vedette ? "VEDETTE; " : ""
                  } ${
                    eventData.type_evenement.populaire ? "POPULAIRE" : ""
                  }`}
                </span>
              </div>
              <div title="Catégorie événementielle" className="inline-flex rounded-full c1lu4 c19il cydwr c1k3n c0ef0 ch4gv c1iho cwn3v">
                {eventData.categorie}
              </div>
            </div>
          </div>
            
          {/* Image */}
          <figure className="cai6b">
            <img
              className="rounded-sm c6btv object-contain sm:rounded-xl"
              srcSet={affiche}
              src={affiche}
              style={{ color: "transparent", height: "26rem" }}
              // width={640}
              // height={360}
              title="affiche"
              alt="affiche de l\'evenement"
              loading="lazy"
              // width={1901}
              // height={960}
              decoding="async"
              data-nimg={1}
            />
          </figure>
          {/* Post content */}
          <div>
            <h2 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu c6f83">
              Description de l'événement
            </h2>
            <p className="cai6b">
              {eventData.description}
            </p>
            
          </div>
          <hr className="cghq3 cbv37 cr4kg cf7n6" />
          {/* Photos */}
          <div>
            <h2 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu c6f83">
              Images
            </h2>
            <div className="cdj80 cemhh cf7n6 c4sak">
              <a className="block" href="#0">
                <img
                  className="rounded-sm c6btv"
                  width={203}
                  height={152}
                  title="affiche"
                  alt="affiche de l\'evenement"
                  loading="lazy"
                  decoding="async"
                  data-nimg={1}
                  srcSet={affiche}
                  src={affiche}
                />
              </a>
              <a className="block" href="#0">
                <img
                  className="rounded-sm c6btv"
                  width={203}
                  height={152}
                  title="lieu de l'evenement"
                  alt="evenement_image"
                  loading="lazy"
                  decoding="async"
                  data-nimg={1}
                  srcSet={image_lieu_evenement}
                  src={image_lieu_evenement}
                />
              </a>
            </div>
          </div>
        </div>
        {/* Sidebar */}
        <div className="cr78y">
          <div className="bg-white c2vpa c1hly c5vqk cl6wk ch0sq csusu">
            <div className="text-gray-800 dark:text-gray-100 cgulq c6f83">
              Période & Date
            </div>
            <div className="mt-8 flex">
              {eventData.date_fin ? (
                <div className="flex-shrink-0 flex flex-col items-center py-2">
                  <span className="block w-6 h-6 rounded-full border border-neutral-400" />
                  <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                  <span className="block w-6 h-6 rounded-full border border-neutral-400" />
                </div>
              ) : (
                <div className="flex-shrink-0 flex flex-col items-center py-2">
                  <span className="block w-6 h-6 rounded-full border border-neutral-400" />
                </div>
              )}
              {eventData.date_fin ? (
                <div className="ml-4 space-y-14 text-sm">
                  <div className="flex flex-col space-y-2">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {formatDate(eventData.date_debut)} ·{" "}
                      {eventData.heure_debut}
                    </span>
                    <span className=" font-semibold">
                      Date de début de l'événement
                    </span>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {formatDate(eventData.date_fin)}
                    </span>
                    <span className="font-semibold">
                      Date de fin de l'événement
                    </span>
                  </div>
                </div>
              ) : (
                <div className="ml-4 space-y-14 text-sm">
                  <div className="flex flex-col space-y-2">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {formatDate(eventData.date_debut)} ·{" "}
                      {eventData.heure_debut}
                    </span>
                    <span className=" font-semibold">
                      Date de l'événement
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bg-white c2vpa c1hly c5vqk cl6wk ch0sq csusu">
            <div className="text-gray-800 dark:text-gray-100 cgulq c6f83">
              Billet & Prix
            </div>
            {/* Order details */}
            <ul className="cdiog">
              {ticketsData.map((ticket, index) => (
                <li key={index} className="text-sm flex border-gray-200 cghq3 cm3rx ctv3r c6btv c72q5">
                  <div>{ticket.nom} - {formatNumber(ticket.quantite)}{" "}
                                  <small
                                    className="font-semibold"
                                    style={{ fontSize: "13px" }}
                                  >
                                    Tickets
                                  </small>
                  </div>
                  <span>·</span>
                  <div className="text-gray-800 dark:text-gray-100 c1k3n">{formatPrice(ticket.prix_unitaire)}</div>
                </li>
              ))}


              <li className="text-sm font-semibold flex border-gray-200 cghq3 cm3rx ctv3r c6btv c72q5">
                <div>Prix de ticket (Cumul)</div>
                <div className="cdjj4 c1k3n font-semibold">{formattedTotalTicketPrice}</div>
              </li>
            </ul>
            <div className="cdiog">
              <button className="btn bg-primary-6000 hover:bg-primary-700 cdj8c cg0jr ch8z9 cilvw cyn7a c6btv" type="submit">
              Enregistrer le ticket
              </button>
            </div>
            <div className="text-gray-500 cydwr c1iho caf78">
              En cliquant sur le bouton de soumission ceci représente votre e-signature et vous confirmez que l'ensemble des informations que vous avez fournis dans le cadre de votre demande de publication d'événement sont véridiques et exacts. <br /><br />
              Veuillez noter que la signature électronique {"(e-signature)"} à la même valeur juridique qu'une signature manuscrite.
                
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventDetails;
