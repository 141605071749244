import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter} from "react-router-dom";
import { ContextProvider } from './components/contexts/ContextProvider';
import { ErrorProvider } from './components/contexts/ErrorContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ContextProvider>
      <ErrorProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorProvider>
  </ContextProvider>
);