import axios from 'axios'
import Cookies from 'js-cookie';

const axiosClient = axios.create({
    baseURL: 'https://backoffice.app-events.com/api',
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        "Content-Type": "multipart/form-data",
    }
});
axiosClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const xsrfToken = Cookies.get('XSRF-TOKEN');
    
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['X-XSRF-TOKEN'] = xsrfToken;
    
    return config
});


// Ajoutez un hook pour obtenir `handleError` (dans un fichier d'utilitaires si nécessaire)
let handleError;
export const setHandleError = (fn) => {
    handleError = fn;
};

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // try {

        //     const { response } = error;
        //     if (response.status === 401) {
        //         localStorage.removeItem('ACCESS_TOKEN')
        //     }
            
        // } catch (err) {
        //     console.log(err);
        // }
        // throw error;
        const { response } = error;
        if (handleError) {
            if (!response) {
                handleError("Erreur réseau : Veuillez vérifier votre connexion Internet.");
                // console.error("Erreur réseau : Veuillez vérifier votre connexion Internet.");
            } else if (response.status === 401) {
                handleError("Non autorisé : Veuillez vous reconnecter.");
                // console.error("Non autorisé : Veuillez vous reconnecter.");
                localStorage.removeItem('ACCESS_TOKEN');
            } 
            // else {
            //     handleError(response.data.message || "Une erreur inconnue est survenue.");
            //     // console.error(response.data.message || "Une erreur inconnue est survenue.");
            // }
        }
        return Promise.reject(error);
    },
); 

// Fonction pour obtenir le cookie CSRF avant les autres requêtes
export const getCsrfToken = () => {
    return axios.get('https://backoffice.app-events.com/sanctum/csrf-cookie', {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
    });
};

export default axiosClient;