import React from 'react'
import DoughnutChart from '../charts/DoughnutChart';

// Import utilities
import { tailwindConfig } from '../Utils/Utils';

export default function ResumeEventsChart({ all, published, unpublished, error }) {

    const chartData = {
        labels: ['Tous', 'Publiés', 'Non publiés'],
        datasets: [
            {
                label: 'Total',
                data: [
                    all, published, unpublished,
                ],
                backgroundColor: [
                    tailwindConfig().themes.colors.gray[300],
                    tailwindConfig().themes.colors.green[400],
                    tailwindConfig().themes.colors.red[500],
                ],
                hoverBackgroundColor: [
                    tailwindConfig().themes.colors.gray[300],
                    tailwindConfig().themes.colors.green[600],
                    tailwindConfig().themes.colors.red[800],
                ],
                borderWidth: 0,
            },
        ],
    };
    
    return (
        <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
          {/* <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60">
            <h2 className="font-semibold text-gray-800 dark:text-gray-100">Bilan</h2>
          </header> */}
          {/* Chart built with Chart.js 3 */}
          {/* Change the height attribute to adjust the chart height */}
          {error ? (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className=" cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-md c6f83">
                    Erreur de récupération
                    </h4>
                    <div className="mb-6 text-sm">
                    {error.message}
                    </div>
                </div>
            </div>
          ) : (

          <DoughnutChart data={chartData} width={389} height={260} />
          )}
        </div>
    );
}
