import React, { useState } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import LoadContentSpinner from '../../Utils/LoadContentSpinner';
import { Link } from 'react-router-dom';
import axiosClient from '../../api/axiosClient';

export default function PersonnalDocumentID() {

    const { user, loading, setUser } = useStateContext();
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [validationError, setValidationError] = useState('');
    const [successMessage, setSuccessMessage] = useState();
    const [frontFile, setFrontFile] = useState(null);
    const [backFile, setBackFile] = useState(null);
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

    // Handle file change
    const handleFileChange = (e, side) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                setErrors((prev) => ({
                    ...prev,
                    [side]: 'Le fichier doit être inférieur à 2 Mo.',
                }));
            } else if (!['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
                setErrors((prev) => ({
                    ...prev,
                    [side]: 'Format de fichier non supporté. Seuls JPG, PNG et PDF sont autorisés.',
                }));
            } else {
                setErrors((prev) => ({ ...prev, [side]: null })); // Clear errors
                side === 'front' ? setFrontFile(file) : setBackFile(file);
            }
        }
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!frontFile || !backFile) {
            const errorMessage = 'Veuillez télécharger les deux fichiers.';
            setValidationError(errorMessage);
            setTimeout(() => {
                setValidationError(null); // Clear error after 5 seconds
            }, 5000);
            return;
        }

        // Réinitialiser les erreurs au début de la soumission
        setErrors({});
        setValidationError(null);

        setLoading(true);
        const formData = new FormData();
        formData.append('frontFile', frontFile);
        formData.append('backFile', backFile);

        axiosClient.post('/request/user/manageAccount/uploadCardIdentity/profil', formData)
            .then(
                ({data}) => {
                    setSuccessMessage(data.message);
                    setLoading(false);
                    setTimeout(() => {
                        // refreshData(data.user);
                        setUser(data)
                    }, 4000);

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier vos fichiers puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        if (response.data.errors) {
                            // Erreurs spécifiques aux champs
                            setErrors(response.data.errors);
                        }
                        if (response.data.message) {
                            // Erreur générale
                            setValidationError(response.data.message);
                        }
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Erreur lors de l'envoi des documents.");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
    };

    if (loading) {
        return <LoadContentSpinner />;
    }

    const isSubmitDisabled = () => {
        return isLoading;
    };

    return (
        <div>
            <div className="cmxzb c41yb">
                <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb c6r0l">
                Mes Pièces jointes
                </h2>
                <p className='!m-0 text-sm'>
                Pour finaliser la configuration de votre profil d'organisateur, veuillez déposer une copie recto-verso de votre carte nationale d'identité. Cela permet d'assurer la sécurité et la conformité des informations sur la plateforme. <br />
                Vos informations seront traitées en toute confidentialité et utilisées uniquement pour vérifier votre identité.
                </p>
                {user.organizer_config.personal_documents_uploaded === 0 ? (
                    <section>
                        <div className='text-sm text-gray-800 dark:text-gray-100 cgulq c6f83'>Ajouter les fichiers ici</div>
                        {validationError && (
                            <div className="text-sm relative mt-4 mb-4 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                                <div className="flex cm3rx ce4zk c6btv">
                                    <div className="flex">
                                    <svg
                                        className="cbm9w czr3n cq1qg coqgc chfzq"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                                    </svg>
                                    <div>{validationError}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {successMessage && (
                            <div className="text-sm relative mt-4 mb-4 cf8dr cje53 cb8zv clbq0 cuvgf">
                                <div className="flex cm3rx ce4zk c6btv">
                                    <div className="flex">
                                    <svg
                                        className="c612e cbm9w cq1qg coqgc chfzq"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                                    </svg>
                                    <div>
                                    {successMessage}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit} className='personnal-document-identity'>
                            <div className="cgndh flex justify-between sm:flex-row sm:gap-8 flex-col">
                                <div className="rounded border cghq3 w-auto mb-4 c993f c4ppg cxxjf cyhlg cydwr cx3hp cxsfz">
                                    <svg
                                        className="inline-flex ca2tk cyq9w cxg65"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path d="M8 4c-.3 0-.5.1-.7.3L1.6 10 3 11.4l4-4V16h2V7.4l4 4 1.4-1.4-5.7-5.7C8.5 4.1 8.3 4 8 4ZM1 2h14V0H1v2Z" />
                                    </svg>
                                    <label
                                    htmlFor="frontUpload"
                                    className="block text-sm text-gray-500 dark:text-gray-400 caf78 cursor-pointer"
                                    >
                                    Ajouter le Recto du fichier ici au format (jpg, png, pdf).
                                    </label>
                                    <input 
                                        className="cn8jz" 
                                        id="frontUpload"
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'front')}
                                        accept=".jpg, .png, .pdf" 
                                        required
                                    />
                                    {errors.front && (
                                        <small className="text-red-600 mt-2">
                                            {errors.front}
                                        </small>
                                    )}
                                </div>
                                <div className="rounded border cghq3 w-auto mb-4 c993f c4ppg cxxjf cyhlg cydwr cx3hp cxsfz">
                                    <svg
                                    className="inline-flex ca2tk cyq9w cxg65"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path d="M8 4c-.3 0-.5.1-.7.3L1.6 10 3 11.4l4-4V16h2V7.4l4 4 1.4-1.4-5.7-5.7C8.5 4.1 8.3 4 8 4ZM1 2h14V0H1v2Z" />
                                    </svg>
                                    <label
                                    htmlFor="backUpload"
                                    className="block text-sm text-gray-500 dark:text-gray-400 caf78 cursor-pointer"
                                    >
                                    Ajouter le Verso du fichier ici au format (jpg, png, pdf).
                                    </label>
                                    <input 
                                        className="cn8jz" 
                                        id="backUpload"
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'back')}
                                        accept=".jpg, .png, .pdf" 
                                        required
                                    />
                                    {errors.back && (
                                        <small className="text-red-600 mt-2">
                                            {errors.back}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <footer>
                                <div className="flex border-gray-200 cghq3 cetff cr4kg cj8hp ckhro">
                                {/* {errors.form && (
                                    <small className="text-red-600 mt-2">
                                        {errors.form}
                                    </small>
                                )} */}
                                    <div className="c4tdj">
                                        <button disabled={isSubmitDisabled()} type='submit' className="btn bg-primary-600 hover:bg-primary-700 text-white ml-3 cdj8c cg0jr ch8z9 cilvw cyn7a">
                                        {isLoading ? (
                                            <>
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx={12}
                                                        cy={12}
                                                        r={10}
                                                        stroke="currentColor"
                                                        strokeWidth={3}
                                                    />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                </svg>
                                                Un instant...
                                            </>
                                        ) : (
                                            <>Soumettre pour vérification</>
                                        )}
                                        </button>
                                    </div>
                                </div>
                            </footer>
                        </form>
                    </section>
                ) : (
                    <div className="flex w-full items-end justify-center">
                        <div className="inline-flex text-sm bg-white border border-gray-200 dark:text-gray-400 cghq3 c2vpa c1ukq cb8zv c5vqk crwo8 cetff c6btv clbq0 cuvgf">
                            <div className="flex cm3rx ce4zk c6btv">
                                <div className="flex">
                                <svg
                                className="c612e cbm9w cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                                </svg>

                                <div>
                                    <div className="c612e dark:text-gray-100 c1k3n cu6vl">
                                    Felicitation ! Document Déjà Soumis
                                    </div>
                                    <div>
                                    Votre carte d'identité a déjà été envoyée avec succès et ne peut plus être modifiée. 
                                    Pour toute demande de modification ou réclamation, veuillez contacter l'administration.
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="chvik ccwg3">
                                <Link className="text-blue-500 c5ylh ceetm c1k3n" to="/dashboard/account/administration-organizer/contact/events-supprort-organizers">
                                Faire une reclamation -&gt;
                                </Link>
                            </div>
                        </div>
                    </div>

                )}
            </div>
        </div>
    )
}
