import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Testimoniales from "../components/publicWebAppContent/Testimoniales";
import { useStateContext } from "../components/contexts/ContextProvider";
import LoadContentSpinner from "../components/Utils/LoadContentSpinner";

export default function Index() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Accueil site officiel - Events";

    }, []);

    const imagePath = process.env.REACT_APP_IMAGE_PATH;
    const { loading } = useStateContext();

    if (loading) {
    return <LoadContentSpinner />;
    }

    return (
        <main className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <meta name="description" content="Bienvenue sur Events - La plateforme ultime pour la publication, la gestion et la réservation d'événements. Découvrez et participez à des événements passionnants, publiez et promouvez vos propres événements." />
                <meta
                    name="keywords"
                    content="Events, publication d'événements, gestion d'événements, réservation de billets, promotion d'événements, plateforme d'événements"
                />
            </Helmet>
            <div className="nc-BgGlassmorphism absolute inset-x-0 md:top-10 xl:top-40 min-h-0 pl-20 py-24 flex overflow-hidden z-0" data-nc-id="BgGlassmorphism">

                <span className="block bg-[#ef233c] w-72 h-72 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96" />
                <span className="block bg-[#04868b] w-72 h-72 -ml-20 mt-40 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96 nc-animation-delay-2000" />
            </div>
            <div className="container relative space-y-24 mb-10 lg:space-y-28 lg:mb-15">
                <div className="relative w-full pb-0 pt-16 lg:py-20 xl:py-24 2xl:py-32">
                    <div className="nc-BackgroundSection absolute inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 bg-gray-100 bg-opacity-80 dark:bg-opacity-100"
                        style={{ backgroundColor: "rgb(243 244 246 / 26%)", boxShadow: '0px 6px 31px 17px rgba(107, 126, 171, 0.06)' }}
                    />
                    
                    <div style={{ justifyContent: "center" }}
                        className="hidden lg:flex absolute right-4 bottom-20 max-w-md xl:!max-w-lg rounded-3xl overflow-hidden"
                    >
                        <img
                            style={{ color: "transparent" }}
                            srcSet={`${imagePath}images/Mobile_device_app.png`}
                            // src={`${imagePath}images/Mobile_device_app.png`}
                            alt=""
                        />
                    </div>
                    
                    <div className="relative flex md:mx-4 flex-col max-w-xl xl:max-w-2xl">
                        {/* <span className="nc-Badge px-2.5 py-1 -top-12 rounded-full font-medium text-sm absolute text-gray-50 bg-red-400">
                            
                        </span> */}
                        <h2 className="text-4xl md:text-5xl xl:text-5xl font-bold text-gray-800">
                            Transformez vos <span className="text-primary-1">moments</span> en expériences <span className="text-primary-1"> inoubliables</span> avec Events
                        </h2>
                        <span className="block mt-7 text-gray-500">
                            Achetez vos billets en un clic. Publiez vos événements en toute simplicité.
                            Découvrez l'application qui révolutionne la réservation d'événements : rapide, intuitive et conçue pour vous offrir une expérience exceptionnelle selon vos
                            préférences.
                        </span>
                        <div className="block lg:hidden mt-5 max-w-2xl rounded-3xl overflow-hidden">
                        {/* // style={{ justifyContent: "center",display: "flex",alignItems: "center" }} */}
                            <img
                                alt=""
                                loading="lazy"
                                style={{ color: "transparent" }}
                                srcSet={`${imagePath}images/Mobile_device_app.png`}
                                // src={`${imagePath}images/Mobile_device_app.png`}
                            />
                        </div>
                        <div className=" mt-10 sm:mt-14">
                            <h3 className="text-base font-bold text-gray-800 mb-2">Télécharger l’application Events :</h3>
                            <div className="flex space-x-3 ">

                                <Link to="https://play.google.com/store/apps/details?id=com.event.event_app" target="_blank" rel="noopener noreferrer">
                                    <img
                                        alt="Android logo"
                                        loading="lazy"
                                        width={160}
                                        height={60}
                                        style={{ color: "transparent", height: "80%" }}
                                        srcSet={`${imagePath}images/btn-android.webp`}
                                        // src={`${imagePath}images/btn-android.webp`}
                                    />
                                </Link>
                                <Link to="##" target="_blank" rel="noopener noreferrer">
                                    <img
                                        alt="APP Store logo"
                                        width={180}
                                        height={60}
                                        loading="lazy"
                                        style={{ color: "transparent", height: "80%" }}
                                        srcSet={`${imagePath}images/btn-ios.webp`}
                                        // src={`${imagePath}images/btn-ios.webp`}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between lg:mb-8 text-gray-900 dark:text-gray-50">
                        <div className="text-center w-full max-w-4xl mx-auto">
                            <h2 className="text-2xl md:text-3xl capitalize font-bold">
                            Simplifiez vos <span className="underlined">réservations de ticket</span> dans une seule <span className="underlined">application</span>
                            </h2>
                            <span className="mt-2 md:mt-3 font-normal block text-md text-gray-600 dark:text-gray-400">
                            Plongez dans un monde où chaque réservation est aussi fluide qu’un simple geste, et où votre passion est notre priorité. Plus besoin de courir après vos billets ou de craindre de les perdre. En quelques clics, tout est réglé, depuis le choix de vos places jusqu’au paiement, sécurisé et instantané. Vos billets vous attendent, prêts à être scannés, directement dans votre poche.
                        </span>
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                        <img
                            className="max-w-full sm:!max-w-lg"
                            alt=""
                            loading="lazy"
                            decoding="async"
                            style={{ color: "transparent" }}
                            // srcSet={`${imagePath}images/about_app_events.png`}
                            src="https://cdn.prod.website-files.com/61cee5eb4d566d3471eca114/6748651daa631078a69be228_Section-11-p-1080.jpg"
                        />
                    </div>
                </div>


                <div className="relative grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-16">
                    <div style={{ top: '-2', width: '67%', height: '734px', zIndex: '1', left: '11rem', position: 'absolute', backgroundImage: `url(${imagePath}images/particles.png)` }} />
                    <div className="flex-grow w-full"
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            className="max-w-full sm:!max-w-sm"
                            alt=""
                            loading="lazy"
                            style={{ color: "transparent", zIndex: '3' }}
                            srcSet={`${imagePath}images/banniere.png`}
                            width="600"
                            height="600"
                            data-nimg="1"
                        />
                    </div>
                    <div className="flex-shrink-0 lg:mt-0 lg:pl-8">
                        <ul className="space-y-10">
                            <li className="space-y-4">
                                <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100">
                                    <i class="text-sm las la-ticket-alt mr-1"></i>
                                    Gamification
                                </span>
                                <span className="block text-xl font-semibold">
                                    Découvrez l'Événementiel
                                </span>
                                <span className="block mt-5 text-gray-600 dark:text-gray-400">
                                    Explorez une variété d'événements, des concerts captivants aux
                                    conférences inspirantes, offrant une expérience complète et
                                    divertissante.
                                </span>
                            </li>
                            <li className="space-y-4">
                                <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-800 bg-green-100">
                                    <i class="text-sm las la-check mr-1"></i>
                                    Réservation Facile
                                </span>
                                <span className="block text-xl font-semibold">
                                    Simplicité de Réservation
                                </span>
                                <span className="block mt-5 text-gray-600 dark:text-gray-400">
                                    Plus bésoin de vous déplacer pour en bénéficier. <br />
                                    Réservez facilement en quelques clics. Un processus simplifié
                                    pour une expérience utilisateur fluide et rapide.
                                </span>
                            </li>
                            <li className="space-y-4">
                                <span className="nc-Badge items-center inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-red-800 bg-red-100 ">
                                    <i class="text-sm las la-lock mr-1"></i>
                                    Sécurité
                                </span>
                                <span className="block text-xl font-semibold">
                                    Sécurité intégrée
                                </span>
                                <span className="block mt-5 text-gray-600 dark:text-gray-400">
                                    Assurez-vous des réservations et des paiements en ligne en
                                    toute sécurité. L'appli <b>Events</b> offre une expérience
                                    simple et sécurisée.
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="relative grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-16 !mt-24">
                    <div className="flex-shrink-0 lg:mt-0 lg:pl-8">
                        <span className="uppercase text-sm text-gray-400 tracking-widest">
                        Avantages
                        </span>
                        <h2 className="font-semibold text-4xl mt-2">
                        Pourquoi choisir l’application mobile Events ?
                        </h2>
                        <span className="block mt-5 text-gray-700 dark:text-gray-400">
                            Publier vos événements n’a jamais été aussi simple. Notre plateforme vous permet de créer, personnaliser et partager vos événements en quelques clics. Vous bénéficiez d’une visibilité optimale auprès d’un public ciblé et motivé, prêt à acheter leurs billets. Avec des outils de suivi clairs et une gestion automatisée des ventes, vous gagnez du temps et augmentez vos revenus sans effort.
                        </span>
                        <span className="block mt-5 text-gray-700 dark:text-gray-400">
                            En tant que participant, Réservez vos billets en quelques secondes grâce à une application fluide et intuitive. 
                            Profitez d’offres exclusives et gardez tous vos billets à portée de main, directement dans l’application.
                            <br /><br />
                            Une expérience sans tracas, des fonctionnalités modernes et un service qui fait la différence, que vous soyez un organisateur ou un spectateur.
                        </span>
                        <Link to='/organisateurs/devenir-organisateur-events' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-gray-50 mt-6 sm:mt-6">
                            En savoir d'avantage
                        </Link>
                    </div>
                    <div className="flex-grow w-full"
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            className="max-w-full sm:!max-w-sm"
                            alt=""
                            loading="lazy"
                            style={{ color: "transparent", zIndex: '3' }}
                            srcSet={`${imagePath}images/banniere.png`}
                            width="600"
                            height="600"
                            data-nimg="1"
                        />
                    </div>
                    
                </div>

                <div className="relative py-16">
                    <div className="absolute blur-sm inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-lg z-0" style={{ background: 'linear-gradient(135deg, rgba(91, 207, 197, 1) 0%, rgba(56, 180, 165, 1) 50%, rgba(25, 135, 125, 1) 100%)' }} />
                    <div className="relative flex flex-col items-center sm:max-w-4xl mx-auto">
                        <div className="text-center mt-auto">
                            <h3 className="text-3xl text-gray-50 font-bold">
                            Avec <span className="underlined">Events</span>, organisez vos événements comme jamais auparavant.
                            </h3>
                            <span className="block text-md mt-5 text-gray-200 dark:text-gray-300">
                            Events est bien plus qu’une simple plateforme : c’est votre allié indispensable pour la gestion et la promotion de vos événements. Grâce à son interface moderne, intuitive et parfaitement adaptée aux appareils mobiles, chaque étape devient un jeu d’enfant.
                            {" "}
                            La création et la gestion de vos événements sont simplifiées à l’extrême, tout en vous offrant une personnalisation complète de vos programmes. En un seul endroit, vous pouvez organiser, promouvoir et suivre vos performances avec des outils pensés pour maximiser vos résultats.
                            </span>
                            <span className="block text-md mt-5 text-gray-200 dark:text-gray-300">
                            Que ce soit pour planifier, marketer ou exécuter vos événements, Events est l’outil puissant et fiable dont vous avez besoin. Une fois que vous aurez essayé, vous vous demanderez comment vous avez pu organiser des événements sans nous.
                            </span>
                        </div>
                    </div>
                    <div className="flex mt-2 justify-center items-center">
                        <Link to='/login' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary bg-primary-6000 hover:bg-primary-700 text-white mt-6 sm:mt-6">
                            Commencer maintenant
                        </Link>
                    </div>
                </div>

                <div className="relative grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-16 !mt-24">
                    <div
                        className="flex-grow w-full"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            className="max-w-full sm:!max-w-sm"
                            alt=""
                            loading="lazy"
                            width={825}
                            height={820}
                            decoding="async"
                            data-nimg={1}
                            style={{ color: "transparent",  }}
                            srcSet={`${imagePath}images/Mobile_pay.png`}
                            src={`${imagePath}images/Mobile_pay.png`}
                        />
                    </div>
                    <div className="flex-shrink-0 lg:mt-0 lg:pl-8">
                        <h2 className="font-bold text-4xl mt-3 mb-4">
                            {" "}
                            Gardez le contrôle{" "}
                        </h2>
                        <span className="text-gray-700 dark:text-gray-300">
                        Avec Events, vous êtes toujours informé et en pleine maîtrise de vos tickets. 
                        Suivez vos paiements en toute sérénité grâce à des notifications instantanées 
                        qui confirment chaque transaction.
                        </span>
                        <div className="w-14 border-b border-gray-200 dark:border-gray-700 mt-3" />
                        <nav className="space-y-4 mb-8 mt-3">
                            <li className="flex items-center">
                                <span className="mr-4 inline-flex flex-shrink-0 ">
                                    <i class="las la-check-circle text-2xl font-bold"></i>
                                </span>
                                <span className="text-gray-700 font-semibold dark:text-gray-300">
                                    Personnalisez votre expérience en choisissant le type de
                                    billet qui correspond à vos préférences.
                                </span>
                            </li>
                            <li className="flex items-center">
                                <span className="mr-4 inline-flex flex-shrink-0 ">
                                    <i class="las la-check-circle text-2xl font-bold"></i>
                                </span>
                                <span className="text-gray-700 font-semibold dark:text-gray-300">
                                    Procédez à un processus d'achat sans tracas grâce à des
                                    passerelles de paiement sécurisées.
                                </span>
                            </li>
                            <li className="flex items-center">
                                <span className="mr-4 inline-flex flex-shrink-0 ">
                                    <i class="las la-check-circle text-2xl font-bold"></i>
                                </span>
                                <span className="text-gray-700 font-semibold dark:text-gray-300">
                                Evénement annulé, pas de panique : demandez votre remboursement en quelques secondes 
                                directement depuis l’application.
                                </span>
                            </li>
                            <li className="flex items-center">
                                <span className="mr-4 inline-flex flex-shrink-0 ">
                                    <i class="las la-check-circle text-2xl font-bold"></i>
                                </span>
                                <span className="text-gray-700 font-semibold dark:text-gray-300">
                                    Profitez d'une assistance en
                                    temps réel pour une tranquillité d'esprit totale.
                                </span>
                            </li>
                        </nav>
                    </div>
                </div>

                <div className="relative py-16 shadow-sm">
                    <div className="absolute inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 glass-card dark:bg-black/20"/>
                    <div className="">
                        <div className="relative grid md:grid-cols-3 gap-12">
                            <div className="relative flex flex-col items-center max-w-xs mx-auto">
                                <div className="text-center mt-auto">
                                    <h3 className="text-xl font-semibold">
                                        Events à portée de main
                                    </h3>
                                    <span className="block mt-5 text-gray-600 dark:text-gray-400">
                                        Téléchargez l'application Events en un clic. La création de
                                        compte est rapide et simple. Commencez votre aventure dès
                                        maintenant !
                                    </span>
                                </div>
                            </div>
                            <div className="relative flex flex-col items-center max-w-xs mx-auto">
                                <div className="text-center mt-auto">
                                    <h3 className="text-xl font-semibold">
                                        Explorez, Choisissez, Réservez
                                    </h3>
                                    <span className="block mt-5 text-gray-600 dark:text-gray-400">
                                        Explorez une variété d'événements passionnants. Faites votre
                                        choix et réservez vos billets en quelques étapes simples.
                                        L'expérience Events est conçue pour votre facilité.
                                    </span>
                                </div>
                            </div>
                            <div className="relative flex flex-col items-center max-w-xs mx-auto">
                                <div className="text-center mt-auto">
                                    <h3 className="text-xl font-semibold">
                                        Vivez l'Événement en Toute Sérénité
                                    </h3>
                                    <span className="block mt-5 text-gray-600 dark:text-gray-400">
                                        Présentez votre billet électronique à l'entrée. Profitez de
                                        l'événement sans tracas. Events simplifie votre expérience,
                                        de l'achat au scan du ticket.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex relative mt-6 justify-center items-center">
                            <div className="flex space-x-3 z-10">

                                <Link to="https://play.google.com/store/apps/details?id=com.event.event_app" target="_blank" rel="noopener noreferrer">
                                    <img
                                        alt="Android logo"
                                        loading="lazy"
                                        width={160}
                                        height={60}
                                        style={{ color: "transparent", height: "80%" }}
                                        srcSet={`${imagePath}images/btn-android.webp`}
                                        // src={`${imagePath}images/btn-android.webp`}
                                    />
                                </Link>
                                <Link to="##" target="_blank" rel="noopener noreferrer">
                                    <img
                                        alt="APP Store logo"
                                        width={180}
                                        height={60}
                                        loading="lazy"
                                        style={{ color: "transparent", height: "80%" }}
                                        srcSet={`${imagePath}images/btn-ios.webp`}
                                        // src={`${imagePath}images/btn-ios.webp`}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <Testimoniales />
            </div>
        </main>
    );
}
