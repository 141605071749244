import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import { useError } from '../../../components/contexts/ErrorContext';

export default function ListEvents() {

    const [events, setEvents] = useState([]); // État pour les événements
    const [filter, setFilter] = useState("all"); // État pour le filtre
    const [loading, setLoading] = useState(true); // État pour le chargement
    const [showInfoMessage, setShowInfoMessage] = useState(null);
    const { handleError } = useError();

    // Fonction pour charger les événements depuis l'API
    const fetchEvents = async () => {
        setLoading(true);

        axiosClient.get('/dashboard/event-official/organizer/events', {
            params: { filter },
        })
            .then(response => {
                setEvents(response.data.data); // Mettez à jour la liste des événements
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 404) {
                    // Afficher le message "Aucun événement accepté" dans une section spécifique
                    setShowInfoMessage(error.response.data.message);
                } else {
                    handleError("Erreur lors de la récupération des événements:", error);
                    console.error("Erreur lors de la récupération des événements:", error);
                }
                setLoading(false); // Arrêtez le chargement même en cas d'erreur
            });
    };

    // Charger les événements à chaque changement de filtre
    useEffect(() => {
        fetchEvents();
    }, [filter]);

    // Gestion du changement de filtre
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    return (
        <>
            <div className="ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz">
                {/* Page header */}
                <div className="c2g1r cwnq4 cnlq0 c6r0l">
                    {/* Left: Title */}
                    <div className="c2rn6 cdiog">
                        <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                        Liste des événements enregistrés &nbsp;&nbsp;<span className='text-gray-400 dark:text-gray-400'>{loading ? "..." : events.length}</span>
                        </h1>
                    </div>
                    {/* Right: Actions */}
                    <div className="cm3b7 c51uw ccww4 csdex cbe1i c4sak">
                        {/* Create Events button */}
                        <Link to={'/dashboard/events/registration'} className="btn bg-primary-600 hover:bg-primary-700 text-white cdj8c cg0jr ch8z9 cilvw cyn7a">
                            <svg
                                className="cbm9w cbmv0 coqgc"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                            </svg>
                            <span className="cyga5">Ajouter un événement</span>
                        </Link>
                    </div>
                </div>

                {/* CTA */}
                {/* <div className="cs838 coz6m c9p4u">
                    <div className="bg-gray-900 cf10p c2vpa c340b cfcf6 cydwr cb8zv clxbf cav8x c41yb">
                    <div className="cgwj2 cehv3 cdiog">
                        <div className="text-gray-50 cgulq clctu cu6vl">
                            Offre exceptionnelle 🎁
                        </div>
                        <div className="text-sm cdqku ct8yz">
                        Jusqu'au 22 Janvier Events baisse le taux pour les frais de prise en charge de -2%, tous vos evenement enregistrés dans le caneva de cette periode seront facture a 8% au lieu de 10%
                        </div>
                    </div>
                    <Link to={'/dashboard/events/registration'} className="btn rounded-full px-3 py-1 text-gray-800 cmilp cyhlg coqgc">
                        J'en profite!
                    </Link>
                    </div>
                </div> */}

                {/* Page content */}
                {showInfoMessage ? (
                    <div className="w-full flex justify-center items-center mt-12 cweej">
                        <div className="cydwr clbq0">
                            <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                            <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path
                                    className="text-gray-500 co1wq"
                                    d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                    fill="currentColor"
                                />
                                <path
                                    className="dark:text-gray-400 ckbo4"
                                    d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                    fill="currentColor"
                                />
                                <path
                                    className="cmpw7 cdqku"
                                    d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                    fill="currentColor"
                                />
                            </svg>

                            </div>
                            <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                            📋 Aucun événement enregistré
                            </h4>
                            <div className="mb-6 text-sm">
                            {showInfoMessage}
                            </div>
                            <Link to={'/dashboard/events/validations'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Suivre mes événements
                            </Link>
                        </div>
                    </div>
                    ) : (
                        <div className="flex cx7pd cw25k cld0c cdz3p ccvcq cw24w c6o0w cozvx cqp75 cyy58 cetff ck8qf">
                            {/* Sidebar */}
                            <div>
                                <div className="bg-white c2vpa c1hly c5vqk cnuel csusu">
                                    <div className="cd42m c82w2 ckn6o c4sak">
                                        {/* Group 1 */}
                                        <div>
                                            <div className="text-sm text-gray-800 dark:text-gray-100 cgulq cxg65">
                                                Filtres des événements
                                            </div>
                                            <ul className="text-sm c1k3n cweej">
                                            <li>
                                                <button className={`block w-full text-left ${
                                                    filter === "all" ? "text-blue-500 font-bold" : ""
                                                    }`}
                                                    onClick={() => handleFilterChange("all")}
                                                >
                                                Voir tous
                                                </button>
                                            </li>
                                            <li>
                                                <button className={`block w-full text-left ${
                                                filter === "published" ? "text-blue-500 font-bold" : ""
                                                }`}
                                                onClick={() => handleFilterChange("published")}>
                                                Evénements en vente
                                                </button>
                                            </li>
                                            <li>
                                                <button className={`block w-full text-left ${
                                                filter === "outdated" ? "text-blue-500 font-bold" : ""
                                                }`}
                                                onClick={() => handleFilterChange("outdated")}>
                                                Evénements obsolètes
                                                </button>
                                            </li>
                                            <li>
                                                <button className={`block w-full text-left ${
                                                filter === "cancelled" ? "text-blue-500 font-bold" : ""
                                                }`}
                                                onClick={() => handleFilterChange("cancelled")}>
                                                Annulés / Désactivés
                                                </button>
                                            </li>
                                            <li>
                                                <button className={`block w-full text-left ${
                                                filter === "sold-out" ? "text-blue-500 font-bold" : ""
                                                }`}
                                                onClick={() => handleFilterChange("sold-out")}>
                                                Evénements sold-out
                                                </button>
                                            </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Content */}
                            
                            {loading ? (
                                <div className='w-full flex justify-center items-center'>
                                    <LoadContentSpinner />
                                </div>
                            ) : events.length > 0 ? (
                                <div>
                                    {/* Cards 1 (Video Courses) */}
                                    <div>
                                        <div className="c7nom ckn6o c4sak">
                                            
                                            {/* Card 1 */}
                                            {events.map(event => (
                                                <div key={event.id} className="bg-white h-fit c2vpa cxe43 cdwnl c18r2 coz6m c1hly c5vqk">
                                                    <div className="flex cetff cav8x">
                                                        {/* Image */}
                                                        <div className="cm84d">
                                                            <img
                                                            className="h-32 object-cover c6btv"
                                                            width={301}
                                                            height={226}
                                                            src={`data:image/jpeg;base64,${event.affiche}`}
                                                            alt={event.name}
                                                            />
                                                            
                                                        </div>
                                                        {/* Card Content */}
                                                        <div className="flex cetff cbw8w p-4">
                                                            {/* Card body */}
                                                            <div className="">
                                                                <header className="c6f83">
                                                                    <Link to={`/dashboard/events/view-details/to/${event.id}`}>
                                                                        <h3 className="text-gray-800 dark:text-gray-100 text-md font-bold cgulq cu6vl">
                                                                        {event.name}
                                                                        </h3>
                                                                    </Link>
                                                                </header>
                                                                <ul className="text-sm c0zkc cweej c6r0l">
                                                                    <li className="flex items-center">
                                                                        <svg
                                                                        className="cmpw7 cdqku cbm9w coqgc chfzq"
                                                                        width={16}
                                                                        height={16}
                                                                        viewBox="0 0 16 16"
                                                                        >
                                                                        <path d="M5 4a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H5Z" />
                                                                        <path d="M4 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4ZM2 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4Z" />
                                                                        </svg>
                                                                        <div className="">
                                                                            {event.date} 
                                                                        </div>
                                                                    </li>
                                                                    <li className="flex items-center">
                                                                        <svg
                                                                            className="cmpw7 cdqku cbm9w coqgc chfzq"
                                                                            width={16}
                                                                            height={16}
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path d="M3.886 1.137A8 8 0 1 1 .08 9.142a1 1 0 0 1 1.98-.284 6 6 0 1 0 3.314-6.256l.844.83a1 1 0 0 1-.53 1.698l-3.745.653a1 1 0 0 1-1.16-1.142L1.38.887A1 1 0 0 1 3.07.331l.817.806ZM9 7.586l1.707 1.707a1 1 0 1 1-1.414 1.414l-2-2A1 1 0 0 1 7 8V5a1 1 0 1 1 2 0v2.586Z" />
                                                                        </svg>
                                                                        <div>à {event.hour} {'GMT'}</div>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            {/* Rating and price */}
                                                            <div className="flex flex-wrap items-center cm3rx c6r0l mb-0">
                                                                <div className="flex items-center ch3kz">
                                                                    {event.annule ? (
                                                                        <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                                                            Evénement Annulé
                                                                        </div>
                                                                    ) : (

                                                                        event.status === 'Disponible' ? (
                                                                            <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n cdw1w c9hxi">
                                                                                {event.status}
                                                                            </div>
                                                                        ) : event.status === 'Disabled' ? (
                                                                            <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                                                                Désactivé
                                                                            </div>
                                                                        ) : (
                                                                            <div className="inline-flex text-xs rounded-full clksd cydwr c1k3n cdw1w c9hxi">
                                                                                {event.status}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className='mt-6'>
                                                                <Link
                                                                    className="bg-primary-600 hover:bg-primary-700 cdj8c cg0jr ch8z9 cilvw cyn7a cnf4p c6btv"
                                                                    to={`/dashboard/events/view-details/to/${event.id}`}
                                                                >
                                                                    Voir plus
                                                                </Link>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                            
                                        </div>
                                    
                                    </div>
                                    {/* Pagination */}
                                    {/* <div className="cgndh">
                                        <div className="flex c2g1r cwnq4 c6o0w cetff">
                                        <nav
                                            className="cafp8 c2rn6 cdiog"
                                            role="navigation"
                                            aria-label="Navigation"
                                        >
                                            <ul className="flex justify-center">
                                            <li className="ml-3 c77jh">
                                                <span className="btn bg-white border-gray-200 cghq3 co1wq c2vpa ckbo4">
                                                &lt;- Previous
                                                </span>
                                            </li>
                                            <li className="ml-3 c77jh">
                                                <a
                                                className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa"
                                                href="#0"
                                                >
                                                Next -&gt;
                                                </a>
                                            </li>
                                            </ul>
                                        </nav>
                                        <div className="text-sm text-gray-500 c2bzj cydwr">
                                            Showing <span className="c0zkc c1ukq c1k3n">1</span> to{" "}
                                            <span className="c0zkc c1ukq c1k3n">10</span> of{" "}
                                            <span className="c0zkc c1ukq c1k3n">467</span> results
                                        </div>
                                        </div>
                                    </div> */}
                                </div>
                                
                            ) : (
                                <div className="w-full flex justify-center items-center cweej">
                                    <div className="cydwr clbq0">
                                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path
                                                className="text-gray-500 co1wq"
                                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="dark:text-gray-400 ckbo4"
                                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="cmpw7 cdqku"
                                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                fill="currentColor"
                                            />
                                        </svg>

                                        </div>
                                        {/* <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb c6f83">
                                        Pay your bills in just a few clicks
                                        </h2> */}
                                        <div className="mb-6">
                                        Aucun événement trouvé.
                                        </div>
                                        {/* <button className="btn bg-gray-900 cdj8c cg0jr ch8z9 cilvw cyn7a">
                                        Add Event
                                        </button> */}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
            </div>

        </>
    )
}
