import React, { useEffect } from 'react'
import LoadContentSpinner from '../../components/Utils/LoadContentSpinner';
import { useStateContext } from '../../components/contexts/ContextProvider';

export default function EventsBilleterie() {
    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Page de la Billetterie événementiels - Events";
        
    }, []);
    const { loading } = useStateContext();
    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    if (loading) {
        return <LoadContentSpinner />;
    }

    return (
        <div className='nc-PageHome3 relative overflow-hidden -mt-2 mb-24 lg:mb-32'>
            {/* <Helmet>
                <meta name="description" content="Obtenez de l'aide et du support pour tous vos besoins d'organisation d'événements sur Events. Trouvez des réponses à vos questions et contactez notre équipe d'assistance dédiée." />
                <meta 
                    name="keywords" 
                    content="assistance aux organisateurs, support, aide, organisation d'événements, Events, questions fréquentes, contacter l'assistance" 
                />
            </Helmet> */}
            <div className="pt-6 pb-48 border-neutral-200 bg-gray-800 bg-logo">
                <div className="nc-Section-Heading relative flex flex-col mb-10 lg:mb-10 text-neutral-900 dark:text-neutral-50 p-4 px-4 sm:p-6 xl:p-8" style={{textAlign: 'center', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="relative flex flex-col items-center sm:max-w-4xl mx-auto">
                        <div className="text-center mt-auto px-4">
                            <span className="nc-Badge inline-flex px-2.5 py-1 mb-4 rounded-full font-medium text-xs relative text-white bg-gray-600">
                                <i class="text-sm las la-ticket-alt mr-1"></i>
                                Billetterie
                            </span>
                            <h3 className="text-3xl text-gray-50 font-bold">
                                Vendez des billets d’événements en ligne avec la billetterie de Events
                            {/* Avec <span className="underlined">Events</span>, organisez vos événements comme jamais auparavant. */}
                            </h3>
                            <span className="block text-md mt-5 text-gray-200 dark:text-gray-300">
                                La plateforme de billetterie d’événements par excellence pour les organisateurs de toutes tailles ! Préparez-vous à une expérience de billetterie transparente et entièrement personnalisable qui vous garantit un événement à guichets fermés et des bénéfices accrus. Rejoignez Events dès aujourd’hui et regardez votre événement prospérer !
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col space-x-6 space-y-2 sm:space-y-0 sm:flex-row items-center mt-10 text-base md:text-lg text-neutral-50 dark:text-neutral-400">
                        <li className="flex items-center">
                            <span className="mr-2 inline-flex flex-shrink-0 text-green-400 font-bold">
                                <i class="las la-check-circle text-2xl font-bold"></i>
                            </span>
                            <span className="text-gray-50 font-medium text-sm dark:text-gray-300">
                            Entièrement gratuit
                            </span>
                        </li>
                        <li className="flex items-center">
                            <span className="mr-2 inline-flex flex-shrink-0 text-green-400 font-bold">
                                <i class="las la-check-circle text-2xl font-bold"></i>
                            </span>
                            <span className="text-gray-50 font-medium text-sm dark:text-gray-300">
                            Commencez à vendre instantanément
                            </span>
                        </li>
                        <li className="flex items-center">
                            <span className="mr-2 inline-flex flex-shrink-0 text-green-400 font-bold">
                                <i class="las la-check-circle text-2xl font-bold"></i>
                            </span>
                            <span className="text-gray-50 font-medium text-sm dark:text-gray-300">
                            30% d’augmentation des ventes
                            </span>
                        </li>
                    </div>

                    <div className="flex mt-2 justify-center items-center">
                        <a href='/create-account' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary bg-primary-6000 hover:bg-primary-700 text-white mt-6 sm:mt-6">
                            S'inscrire et commencer
                        </a>
                    </div>
                </div>
            </div>
            <div className='relative'>
                <div className="w-full flex justify-center -mt-52">
                    <img
                        className="max-w-full sm:!max-w-xl"
                        alt=""
                        loading="lazy"
                        decoding="async"
                        style={{ color: "transparent" }}
                        // srcSet={`${imagePath}images/about_app_events.png`}
                        srcSet="https://britetodo.com/img/main_page.png"
                    />
                </div>
            </div>
            
            <div className="relative my-24 p-4">

                <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between lg:mb-8 text-gray-900 dark:text-gray-50">
                    <div className="text-center w-full max-w-4xl mx-auto">

                        <h2 className="text-2xl md:text-3xl font-semibold">
                            Augmentez vos <span className='underlined'>ventes de 30%</span> grâce à la billetterie événementielle d'Events !
                        </h2>
                        <span className="mt-2 md:mt-3 font-normal block text-md text-gray-700 dark:text-gray-400">
                            Sur notre plateforme Events, nous nous engageons à améliorer constamment les fonctionnalités, 
                            l’expérience utilisateur et les outils de promotion. Notre mission est claire : vous aider 
                            à optimiser vos ventes de billets et à garantir le succès de chacun de vos événements.
                        </span>
                    </div>
                </div>
            </div>

            <div className="relative grid grid-cols-1 gap-10 p-4 lg:grid-cols-2 lg:gap-16 !mb-8">
                <div className="flex-shrink-0 lg:mt-0 lg:pl-8 sm:mx-8">
                    <h2 className="font-semibold text-3xl mt-3 mb-4">
                    <span className='underlined'>Assurez le succès </span> de votre événement avec notre système de billetterie innovant
                    </h2>
                    <span className="text-gray-700 dark:text-gray-300">
                    Notre billetterie en ligne est entièrement gratuite pour les organisateurs. 
                    Grâce à un tableau de bord intuitif et facile à utiliser, vous pouvez lancer tous 
                    types d’événements en un clin d’œil. Offrez à vos participants une expérience d’achat 
                    optimisée, pensée pour 
                    booster vos ventes et maximiser vos revenus grâce à des outils marketing intégrés.
                    </span>
                </div>
                <div
                    className="flex-grow w-full"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <img
                        className="max-w-full sm:!max-w-sm h-fit rounded-xl"
                        alt=""
                        loading="lazy"
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent",  }}
                        srcSet={`${imagePath}images/QR-Code_feature-image.webp`}
                        // src={`${imagePath}images/Mobile_pay.png`}
                    />
                </div>
            </div>

            <div className="relative grid grid-cols-1 gap-10 p-4 lg:grid-cols-2 lg:gap-16 !mb-8">
                <div
                    className="flex-grow w-full"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <img
                        className="max-w-full sm:!max-w-sm h-fit rounded-xl"
                        alt=""
                        loading="lazy"
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent",  }}
                        srcSet={`${imagePath}images/charts-and-graphic-data-visualization-illustration-vector.jpg`}
                        // src={`${imagePath}images/Mobile_pay.png`}
                    />
                </div>
                <div className="flex-shrink-0 lg:mt-0 lg:pl-8 sm:mx-8">
                    <h2 className="font-semibold text-3xl mt-3 mb-4">
                    Stimulez la vente de billets d’événements avec <span className='underlined'>Events</span>
                    </h2>
                    <span className="text-gray-700 dark:text-gray-300">
                    Avec Events, le montant payé par vos clients est toujours le prix initial de votre billet. 
                    Notre plateforme élimine les frais excessifs souvent ajoutés par d'autres services, 
                    réduisant ainsi le coût des billets. Résultat : plus d’accessibilité pour vos participants et une augmentation significative de vos ventes.
                    </span>
                </div>
                
            </div>

            <div className="relative grid grid-cols-1 gap-10 p-4 lg:grid-cols-2 lg:gap-16 !mb-8">
                <div className="flex-shrink-0 lg:mt-0 lg:pl-8 sm:mx-8">
                    <h2 className="font-semibold text-3xl mt-3 mb-4">
                        La billetterie d’événements en ligne élargit votre portée et <span className="underlined">Favorise Votre événement</span>
                    </h2>
                    <span className="text-gray-700 dark:text-gray-300">
                        Events vous propose des solutions de marketing avancées pour promouvoir et développer vos événements 
                        rapidement. Exploitez des outils puissants comme ceux de Google, Facebook, TikTok, et bien d'autres. 
                        En plus de cela, nous assurons la visibilité de vos événements à travers nos propres canaux médiatiques, pour vous aider à atteindre un public encore plus large.
                    </span>
                    <div className="w-14 border-b border-gray-200 dark:border-gray-700 mt-3" />
                    <nav className="space-y-4 mb-8 mt-3">
                        <li className="flex items-center">
                            <span className="mr-4 inline-flex flex-shrink-0 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    data-slot="icon"
                                    className="w-5 h-5 text-primary-1 ml-3"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                                        clipRule="evenodd"
                                    />
                                </svg>

                            </span>
                            <span className="text-gray-700 font-semibold dark:text-gray-300">
                            Faire la promotion de vos événements sur les réseaux sociaux.
                            </span>
                        </li>
                        <li className="flex items-center">
                            <span className="mr-4 inline-flex flex-shrink-0 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    data-slot="icon"
                                    className="w-5 h-5 text-primary-1 ml-3"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                                        clipRule="evenodd"
                                    />
                                </svg>

                            </span>
                            <span className="text-gray-700 font-semibold dark:text-gray-300">
                            Envoyer des e-mails et des newsletters directement aux participants.
                            </span>
                        </li>
                        
                    </nav>
                    <a href='/dashboard/events/registration' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary bg-primary-6000 hover:bg-primary-700 text-white mt-3 sm:mt-3">
                        Commencer A Publier
                    </a>
                </div>
                <div
                    className="flex-grow w-full"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <img
                        className="max-w-full sm:!max-w-sm h-fit rounded-xl"
                        alt=""
                        loading="lazy"
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent",  }}
                        srcSet={`${imagePath}images/marketing.webp`}
                        // src={`${imagePath}images/Mobile_pay.png`}
                    />
                </div>
                
            </div>

            <div className="relative my-10 p-4">

                <div className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between lg:mb-8 text-gray-900 dark:text-gray-50">
                    <div className="text-center w-full max-w-4xl mx-auto">

                        <h2 className="text-2xl md:text-3xl font-semibold">
                        Déjà actif en ligne ? <span className="underlined">Boostez</span> vos ventes avec Events !
                        </h2>
                        <span className="mt-2 md:mt-3 font-normal block text-md text-gray-700 dark:text-gray-400">
                        Si vous vendez déjà des billets en ligne, vous pouvez toujours créer une page d’événement sur Events. Ne vous limitez pas à une seule source de revenus ! Avec Events, profitez d’une plateforme 
                        innovante pour maximiser la portée de vos événements. Intégrez nos outils puissants et notre 
                        large audience pour compléter vos canaux existants et augmenter significativement vos ventes. 
                        Publier sur Events, 
                        c’est accéder à une communauté active prête à réserver ses billets en quelques clics !
                        </span>
                    </div>
                </div>
            </div>
            
            <div className="container">

                <div className="relative py-16 max-w-7xl glass-card bg-logo rounded-3xl">
                    <div className="relative flex flex-col items-center sm:max-w-4xl mx-auto">
                        <div className="text-center mt-auto px-4">
                            <h3 className="text-4xl text-gray-900 font-bold">
                            Frais transparents et un partenariat clair pour vos événements
                            {/* Avec <span className="underlined">Events</span>, organisez vos événements comme jamais auparavant. */}
                            </h3>
                            <span className="block text-md font-medium mt-5 text-gray-800 dark:text-gray-300">
                            Avec Events, tout est simple et sans surprise. Nous offrons des frais fixes pour votre 
                            billetterie et établissons un partenariat clair via un contrat, conçu pour protéger vos intérêts. 
                            Rejoignez une plateforme qui combine transparence et professionnalisme, 
                            tout en vous permettant de vous concentrer pleinement sur la réussite de vos événements.
                            </span>
                        </div>
                        <h2 className='!leading-tight font-bold text-primary-6000 mb-4 text-5xl dark:text-neutral-100'>10%</h2>

                    </div>
                    <div className="flex justify-center items-center">
                        <a href='/create-account' className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors text-sm sm:text-sm font-medium px-4 py-2 sm:px-4 ttnc-ButtonPrimary bg-primary-6000 hover:bg-primary-700 text-white mt-6 sm:mt-6">
                            Devenir organisateur chez Events
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}
