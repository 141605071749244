import React, { useEffect, useState } from 'react'
import DateSelect from '../../../components/Dashboard/DateSelect'
import InvoiceDetails from '../../../components/Dashboard/InvoiceDetails';
import { Link } from 'react-router-dom';
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import { formatPrice } from '../../../components/Utils/UtilsPricesFormat';
import { formatDateToBar } from '../../../components/Utils/UtilsNumbersFormat';

// Définition des statuts personnalisés
const STATUSES = {
    paid: {
        label: 'Payé',
    },
    unpaid: {
        label: 'En attente',
    },
    overdue: {
        label: 'En retard',
    },
};

export default function OrganizerInvoicesList() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null); // Date sélectionnée
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('Tous');
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // État pour la recherche
    const [invoices, setInvoices] = useState([]); // Liste des acheteurs
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [validationError, setValidationError] = useState('');

    // Fonction pour charger les données en fonction de la date
    const fetchInvoices = async (date) => {
        setError(null)
        setLoading(true)

        try {
            const response = await axiosClient.get(`/dashboard/user/organizer/sales/invoice/list`, {
                params: {
                    date, // Envoyer la date comme paramètre
                },
            });
            setInvoices(response.data.data); // Mettre à jour la liste des acheteurs
            setFilteredInvoices(response.data.data);
        } catch (error) {
            if (error.response && error.response.data) {

                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Erreur lors de la récupération des acheteurs.',});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    // Fonction de filtrage basée sur le statut
    const filterInvoicesByStatus = (status) => {
        setSelectedStatus(status);
        let filtered = invoices;
        if (status !== 'Tous') {
            filtered = invoices.filter((invoice) => invoice.status === status);
        }
        // Appliquer également le filtre de recherche
        if (searchQuery) {
            filtered = filtered.filter((invoice) =>
                invoice.get_events_request_invoice.event_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                invoice.invoice_number.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        setFilteredInvoices(filtered);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // Filtrer les acheteurs en fonction du statut et de la recherche
        let filtered = invoices.filter((invoice) =>
            invoice.get_events_request_invoice.event_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invoice.invoice_number.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (selectedStatus !== 'Tous') {
            filtered = filtered.filter((invoice) => invoice.status === selectedStatus);
        }
        setFilteredInvoices(filtered);
    };

    // Appeler l'API chaque fois que `selectedDate` change
    useEffect(() => {
        fetchInvoices(selectedDate);
    }, [selectedDate]);

    // Fonction pour ouvrir le modal et définir les données de la facture
    const openModal = (invoice) => {
        setSelectedInvoice(invoice); // Stocke la facture sélectionnée
        setIsModalOpen(true); // Ouvre le modal
    };

    // Fonction pour fermer le modal
    const closeModal = () => {
        setIsModalOpen(false); // Ferme le modal
        setSelectedInvoice(null); // Réinitialise la facture sélectionnée
    };

    const downloadInvoice = async (invoiceId) => {
        setLoadingBtn((prevState) => ({ ...prevState, [invoiceId]: true }));
    
        try {
            const response = await axiosClient.get(`/dashboard/user/organizer/invoice/download/f/${invoiceId}`);
            
            const { invoice_number, file } = response.data;

            // Convertir le fichier base64 en Blob
            const pdfBlob = new Blob([Uint8Array.from(atob(file), (c) => c.charCodeAt(0))], { type: 'application/pdf' });

            // Créer un lien de téléchargement
            const fileName = `Facture-${invoice_number}.pdf`;
            const url = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            if (error.response && error.response.data.message) {
                setValidationError(error.response.data.message);
            } else {
                setValidationError('Erreur lors du téléchargement de la facture.');
            }
            setTimeout(() => setValidationError(""), 7000);
        } finally {
            setLoadingBtn((prevState) => ({ ...prevState, [invoiceId]: false }));
        }
    };
    

    // const isSubmitDisabled = () => {
    //     return loadingBtn;
    // };

    return (
        <div className="relative h-full">
            
            {error ? (

                <div className="w-full h-full flex justify-center items-center cweej">
                    <div className="w-3/4 cydwr clbq0">
                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                            <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path
                                    className="text-gray-500 co1wq"
                                    d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                    fill="currentColor"
                                />
                                <path
                                    className="dark:text-gray-400 ckbo4"
                                    d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                    fill="currentColor"
                                />
                                <path
                                    className="cmpw7 cdqku"
                                    d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                        Erreur
                        </h4>
                        <div className="break-all mb-6 text-sm">
                        {error.message}
                        </div>
                        {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                            <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Retour aux événements
                            </Link>
                        )}
                        {error.errorCode === 'EVENT_EMPTY' && (
                            <Link to={'/dashboard/events/registration'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Enregistrer un nouvel événement
                            </Link>
                        )}
                    </div>
                </div>
            ) : (
                <div className="ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz">
                    <div className='flex justify-center'>

                        {validationError && (
                            <div className="text-sm fixed top-0 z-[61] mt-4 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                                <div className="flex cm3rx ce4zk c6btv">
                                    <div className="flex">
                                    <svg
                                        className="cbm9w czr3n cq1qg coqgc chfzq"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                                    </svg>
                                    <div>{validationError}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Page header */}
                    <div className="c2g1r cwnq4 cnlq0 c6r0l">
                        {/* Left: Title */}
                        <div className="c2rn6 cdiog">
                        <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                        Mes Factures de Paiement
                        </h1>
                        </div>
                        {/* Right: Actions */}
                        <div className="cm3b7 c51uw ccww4 csdex cbe1i c4sak">
                        
                        {/* Create invoice button */}
                        <Link to={'/dashboard/events/registration'} className="btn bg-primary-600 hover:bg-primary-700 text-white cdj8c cg0jr ch8z9 cilvw cyn7a">
                            <svg
                            className="cbm9w cbmv0 coqgc"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            >
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                            </svg>
                            <span className="cyga5">Nouvel événement</span>
                        </Link>
                        </div>
                    </div>
                    {/* More actions */}
                    <div className="c2g1r cwnq4 cnlq0 c6r0l">
                        {/* Left side */}
                        <div className="c2rn6 cdiog">
                            <ul className="flex flex-wrap -m-1">
                            {['Tous', 'paid', 'unpaid', 'overdue'].map((status) => (
                                <li className="m-1" key={status}>
                                    <button 
                                        className={`inline-flex items-center justify-center text-sm rounded-full border border-gray-200 ${selectedStatus === status ? 'bg-gray-900 text-white' : 'bg-white text-gray-500 dark:text-gray-400'} cc0oq cghq3 cspbm c2vpa c1k3n cxxol cdzfq c5vqk cb2br cwn3v cc0oq`}
                                        onClick={() => filterInvoicesByStatus(status)}
                                    >
                                        {status === 'Tous' ? 'Tous' : STATUSES[status]?.label}{' '}
                                            <span className="cmpw7 cdqku cpts2">
                                            {invoices.filter(
                                                (buyer) =>
                                                    status === 'Tous' ||
                                                    buyer.status === status
                                            ).length}
                                                {/* {status === 'Tous' ? Invoices.length : Invoices.filter((b) => b.status === status).length} */}
                                            </span>
                                    </button>
                                </li>
                            ))}
                            </ul>
                        </div>
                        {/* Right side */}
                        <div className="cm3b7 c51uw ccww4 csdex cbe1i c4sak">
                            
                            <DateSelect onDateChange={(date) => setSelectedDate(date)} />
                        </div>
                    </div>
                    {/* Table */}
                    {loading ? (
                        <div className='w-full flex justify-center items-center'>
                            <LoadContentSpinner />
                        </div>
                    ) : invoices.length > 0 ? (
                        <div className="bg-white c2vpa c1hly c5vqk cgd7w">
                            <header className="flex items-center justify-between flex-col sm:flex-row cx3hp cz8qb">
                                <h2 className="text-gray-800 dark:text-gray-100 mb-2 sm:mb-0 cgulq">
                                Factures <span className="cmpw7 cdqku c1k3n">{filteredInvoices.length}</span>
                                </h2>
                                <div className="cm84d">
                                    <label htmlFor="action-search" className="cn8jz">
                                        Search
                                    </label>
                                    <input
                                        id="action-search"
                                        className="bg-white border-gray-100 focus:border-gray-200 focus:ring-transparent pl-9 c2vpa caqf9 ct9oo"
                                        type="search"
                                        placeholder="Cherchez un événement ou un numéro de facture"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    <button className="c29dn cqdkw cini7 cqogy" type="button" aria-label="Search">
                                        <svg
                                        className="ml-3 mr-2 cba8l c4it8 cmpw7 cdqku cbm9w coqgc"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                        </svg>
                                    </button>
                                </div>
                            </header>
                            <div>
                                {/* Table */}
                                <div className="cocyr">
                                    <table className="c0zkc cn9pt c6btv">
                                        {/* Table header */}
                                        <thead className="text-gray-500 dark:text-gray-400 cghq3 cib75 cbv37 cgulq cgk1f c0ef0 ctv3r cr4kg c1iho">
                                            <tr>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Numéro de Facture</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Montant</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">État du Paiement</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Événement</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Facture du</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Payé le</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Actions</div>
                                            </th>
                                            </tr>
                                        </thead>
                                        {/* Table body */}
                                        <tbody className="text-sm ce8qq ca8s8 co0ms">
                                            {/* Row */}
                                            {filteredInvoices.map((invoice, index) => (

                                                <tr key={index}>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!invoice.invoice_number ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="c740r c1k3n">{invoice.invoice_number}</div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!invoice.to_pay ? (
                                                            <>...</>
                                                        ) : (
                                                            invoice.status === 'overdue' ? (
                                                                <div className="czr3n c1k3n">{formatPrice(invoice.to_pay)}</div>
                                                            ) : invoice.status === 'paid' ? (
                                                                <div className="cdjj4 c1k3n">{formatPrice(invoice.to_pay)}</div>
                                                            ) : invoice.status === 'unpaid' && (
                                                                <div className="cirto c1k3n">{formatPrice(invoice.to_pay)}</div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!invoice.status ? (
                                                            <>...</>
                                                        ) : (
                                                            invoice.status === 'overdue' ? (

                                                                <div className="inline-flex rounded-full cx6qj cz4gk cydwr c1k3n ch4gv cdw1w">
                                                                En retard
                                                                </div>
                                                            ) : invoice.status === 'paid' ? (
                                                                <div className="inline-flex rounded-full c1lu4 c19il cydwr c1k3n ch4gv cdw1w">
                                                                Paye
                                                                </div>
                                                            ) : invoice.status === 'unpaid' && (
                                                                <div className="inline-flex rounded-full cfts0 c5px7 cydwr c1k3n ch4gv cdw1w">
                                                                En attente
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className=" cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!invoice.get_events_request_invoice ? (
                                                            <>...</>
                                                        ) : (
                                                            <Link to={`/dashboard/events/view-details/to/${invoice.get_events_request_invoice.id}`}>
                                                                <div className="text-gray-800 dark:text-gray-100 truncate w-40 c1k3n">
                                                                {invoice.get_events_request_invoice.event_title}
                                                                </div>
                                                            </Link>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!invoice.invoice_date ? (
                                                            <>...</>
                                                        ) : (
                                                            <div>{formatDateToBar(invoice.invoice_date)}</div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!invoice.organizer_invoice_payments ? (
                                                            <>...</>
                                                        ) : (
                                                            <>
                                                            {formatDateToBar(invoice.organizer_invoice_payments.payment_date)}
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 cn8zk c9hxi c72q5">
                                                        <div className="flex justify-center items-baseline cqkjy">
                                                            <button onClick={() => openModal(invoice)} className="rounded-full c3e4j cg12x cmpw7 cdqku" title='Plus de détails'>
                                                                <span className="cn8jz">Plus de détails</span>
                                                                <svg className="cbm9w cue4z cmwfi" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                                <circle cx="12" cy="12" r="3"></circle>
                                                                </svg>
                                                            </button>
                                                            <button onClick={() => downloadInvoice(invoice.id)} disabled={loading[invoice.id]} className="rounded-full c3e4j cg12x cmpw7 cdqku" title='Télécharger'>
                                                                {loadingBtn[invoice.id] ? (
                                                                    <svg
                                                                        className="animate-spin mb-[5px] -ml-1 mr-3 h-5 w-5 ml-3"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <circle
                                                                            className="opacity-25"
                                                                            cx={12}
                                                                            cy={12}
                                                                            r={10}
                                                                            stroke="currentColor"
                                                                            strokeWidth={3}
                                                                        />
                                                                        <path
                                                                            className="opacity-75"
                                                                            fill="currentColor"
                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                        />
                                                                    </svg>
                                                                ) : (
                                                                    <>
                                                                    <span className="cn8jz">Télécharger</span>
                                                                    <svg className="cbm9w cue4z cmwfi" viewBox="0 0 32 32">
                                                                    <path d="M16 20c.3 0 .5-.1.7-.3l5.7-5.7-1.4-1.4-4 4V8h-2v8.6l-4-4L9.6 14l5.7 5.7c.2.2.4.3.7.3zM9 22h14v2H9z" />
                                                                    </svg>
                                                                    </>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {isModalOpen && (
                                <InvoiceDetails
                                    invoice={selectedInvoice}
                                    isModalOpen={isModalOpen}
                                    closeModal={closeModal}
                                />
                            )}
                        </div>
                        
                    ) : (
                        <div className="w-full flex justify-center items-center cweej">
                            <div className="w-3/4 cydwr clbq0">
                                <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                    <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                        <path
                                            className="text-gray-500 co1wq"
                                            d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="dark:text-gray-400 ckbo4"
                                            d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="cmpw7 cdqku"
                                            d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                            fill="currentColor"
                                        />
                                    </svg>

                                </div>
                                <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                Aucune Facture.
                                </h4>
                                <div className="mb-6 text-sm">
                                {'Aucune facture trouvée pour cette période. Vos événements peuvent ne pas être cloturé pour l\'heure.'}
                                </div>
                            </div>
                        </div>
                    )}
                    
                </div>
            )}
        </div>

    )
}
