import React, { createContext, useContext, useState } from 'react';

// Créer le contexte
const ErrorContext = createContext();

// Fournisseur du contexte
export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(null);

    // Fonction pour définir une erreur
    const handleError = (message) => {
        setError(message);

        // Efface l'erreur après 5 secondes (optionnel)
        setTimeout(() => {
            setError(null);
        }, 5000);
    };

    return (
        <ErrorContext.Provider value={{ error, handleError }}>
            {children}
        </ErrorContext.Provider>
    );
};

// Hook personnalisé pour utiliser le contexte
export const useError = () => useContext(ErrorContext);
