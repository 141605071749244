// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStateContext } from '../components/contexts/ContextProvider';

const PrivateRoute = ({ children }) => {
    const { token } = useStateContext();

    // Vérifie si le token est présent pour l'authentification
    if (!token) {
        // Redirige vers la page de connexion si l'utilisateur n'est pas authentifié
        return <Navigate to="/login" />;
    }

    // Si l'utilisateur est authentifié, affiche les enfants (composants protégés)
    return children;
};

export default PrivateRoute;
