import { React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

export default function CongratulateContent() {
    const [isOpen, setIsOpen] = useState(false);
    const handleOptionChange = () => {
        setIsOpen(false); // Ferme le dropdown après la sélection
    };

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Congratulate for submited - Events";
        
      }, []);

    return (
        <div className="nc-PayPage">
            <div className="w-full">
                
                <div className="w-full flex flex-col justify-center sm:rounded-2xl space-y-10 px-0 sm:p-6 xl:p-8">
                    <div className="clbq0 cxsfz">
                        <div className="cg2bh clu2m">
                            <div className="cydwr">
                            <svg className="inline-flex cbm9w cai6b c3nk1 crzrx" viewBox="0 0 64 64">
                                <circle className="cpgwy" cx={32} cy={32} r={32} />
                                <path
                                className="c19il"
                                d="M37.22 26.375a1 1 0 1 1 1.56 1.25l-8 10a1 1 0 0 1-1.487.082l-4-4a1 1 0 0 1 1.414-1.414l3.21 3.21 7.302-9.128Z"
                                />
                            </svg>
                            <h1 className="text-3xl text-gray-800 dark:text-gray-100 font-bold mb-5">
                            Félicitation 🙌
                            </h1>
                            <span className="block text-sm mb-6 text-neutral-500 dark:text-neutral-400">
                                Votre événement a bien été enregistrée avec succès. Vous êtes prié de patienter le temps que votre demande soit traité et vous recevrez un e-mail de confirmation de notre part.
                            </span>
                            <div className="inline-flex cm84d">
                            <button
                                className="btn sm:mb-0 mb-4 bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa"
                                aria-expanded={isOpen}
                                onClick={() => setIsOpen(!isOpen)}
                            >
                            <div className="flex items-center c941w">
                                <span className="text-gray-600 conxo cxyvv c1k3n c941w">
                                Plus d'option
                                </span>
                                <svg
                                className="w-3 h-3 text-gray-600 cbm9w coqgc cpts2"
                                viewBox="0 0 12 12"
                                >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                            </div>
                            </button>

                            {isOpen && (
                                <div className="bg-white w-max absolute border border-gray-200 cghq3 c2vpa cbx8s cxe43 cb8zv ccwri ctd47 c45yg cbxoy cu1dd cvggx ccwg3">
                                    <ul>
                                        <Link to="/dashboard">
                                            <span
                                                className="text-sm flex items-center c196r cqahh c0zkc c1ukq c1k3n cb2br cwn3v"
                                            >
                                                Tableau de bord
                                            </span>
                                        </Link>
                                        <Link onClick={() => window.location.reload()}>
                                            <span
                                                className="text-sm flex items-center c196r cqahh c0zkc c1ukq c1k3n cb2br cwn3v"
                                            >
                                                Créer un autre événement
                                            </span>
                                        </Link>
                                    </ul>
                                </div>
                            )}
                        </div>
                            <Link to='/dashboard/events/validations' className="btn bg-primary-600 items-center justify-center hover:bg-primary-700 text-white ml-3 cdj8c cg0jr ch8z9 cilvw cyn7a">
                                Voir la validation -&gt;
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
