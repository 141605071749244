import React, { useEffect, useRef, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useThemeProvider } from '../Utils/ThemeContext';
import { chartColors } from './ChartjsConfig';
import { tailwindConfig } from '../Utils/Utils';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data, width, height }) => {
  const legendRef = useRef(null);
  const chartRef = useRef(null); // Reference for the chart instance
  const { currentTheme } = useThemeProvider();
  const darkMode = currentTheme === 'light';
  const {
    tooltipTitleColor,
    tooltipBodyColor,
    tooltipBgColor,
    tooltipBorderColor,
  } = chartColors;

  // Use useMemo to memorize chartData
  const chartData = useMemo(() => ({
    ...data,
  }), [data]);

  const chartOptions = useMemo(() => ({
    cutout: '80%',
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 24,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleColor: darkMode ? tooltipTitleColor.dark : tooltipTitleColor.light,
        bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
        backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
        borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    animation: {
      duration: 500,
    },
  }), [darkMode, tooltipTitleColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor]);

  useEffect(() => {
    if (!legendRef.current || !chartRef.current) return;

    // Update custom legend
    const ul = legendRef.current;
    ul.innerHTML = ''; // Clear existing items

    const chart = chartRef.current; // Access the chart instance
    const items = chartData.labels.map((label, index) => {
      const color = chartData.datasets[0].backgroundColor[index];
      return { text: label, fillStyle: color, index };
    });

    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.margin = tailwindConfig().themes.margin[1];

      // Button element
      const button = document.createElement('button');
      button.classList.add('btn-xs', 'bg-white', 'dark:bg-gray-700', 'text-gray-500', 'dark:text-gray-400', 'shadow-sm', 'shadow-black/[0.08]', 'rounded-full');
      button.style.opacity = chart.getDatasetMeta(0).data[item.index].hidden ? '.3' : '';
      button.onclick = () => {
        chart.toggleDataVisibility(item.index);
        chart.update();
      };

      // Color box
      const box = document.createElement('span');
      box.style.display = 'block';
      box.style.width = tailwindConfig().themes.width[2];
      box.style.height = tailwindConfig().themes.height[2];
      box.style.backgroundColor = item.fillStyle;
      box.style.borderRadius = tailwindConfig().themes.borderRadius.sm;
      box.style.marginRight = tailwindConfig().themes.margin[1];
      box.style.pointerEvents = 'none';

      // Label
      const label = document.createElement('span');
      label.style.display = 'flex';
      label.style.alignItems = 'center';
      const labelText = document.createTextNode(item.text);
      label.appendChild(labelText);

      li.appendChild(button);
      button.appendChild(box);
      button.appendChild(label);
      ul.appendChild(li);
    });
  }, [chartData, darkMode]);

  // Update tooltip colors based on the current theme
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    if (darkMode) {
      chart.options.plugins.tooltip.titleColor = tooltipTitleColor.dark;
      chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.dark;
      chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.dark;
      chart.options.plugins.tooltip.borderColor = tooltipBorderColor.dark;
    } else {
      chart.options.plugins.tooltip.titleColor = tooltipTitleColor.light;
      chart.options.plugins.tooltip.bodyColor = tooltipBodyColor.light;
      chart.options.plugins.tooltip.backgroundColor = tooltipBgColor.light;
      chart.options.plugins.tooltip.borderColor = tooltipBorderColor.light;
    }
    
    chart.update('none'); // Update without animation
  }, [currentTheme, darkMode, tooltipBgColor, tooltipBodyColor, tooltipBorderColor, tooltipTitleColor]);

  return (
    <div className="grow flex flex-col justify-center items-center">
      <div style={{ width, height }}>
        <Doughnut
          ref={chartRef} // Set the ref for the chart instance
          data={chartData}
          options={chartOptions}
        />
      </div>
      <div className="px-5 pt-2 pb-6">
        <ul ref={legendRef} className="flex flex-wrap justify-center -m-1"></ul>
      </div>
    </div>
  );
};

export default DoughnutChart;
