import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ProfilInformations from '../../../components/Dashboard/accountManage/ProfilInformations';
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import PersonnalDocumentID from '../../../components/Dashboard/accountManage/PersonnalDocumentID';
import PartnersContent from '../../../components/Dashboard/accountManage/PartnersContent';
import { useError } from '../../../components/contexts/ErrorContext';

export default function IndexAccountSetting() {

    const location = useLocation();
    const [activeTab, setActiveTab] = useState('profil_informations');
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const ongletActif = queryParams.get('activeTab') || 'profil_informations';
        setActiveTab(ongletActif);
        // const queryParams = new URLSearchParams(location.search);
        // const ongletActif = queryParams.get('activeTab') || 'profil_informations';
    }, [location])

     // État pour gérer l'onglet actif
    // Initialiser les états pour chaque case à cocher sur "désactivé" (true)
    const [emailNotifications, setEmailNotifications] = useState(true);
    const [twoFactorAuth, setTwoFactorAuth] = useState(true);
    const [invoiceAvailability, setInvoiceAvailability] = useState(true);
    const [supportNotifications, setSupportNotifications] = useState(true);
    const [profilData, setProfilData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { handleError } = useError();

    // Fonction pour changer l'onglet actif
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        window.history.pushState(null, '', `?activeTab=${tab}`);
    };

    // recuperation des informations profil
        // Fonction de récupération des données
    const fetchProfilData = async () => {
        setLoading(true);
        setError(null);

        try {
            const { data } = await axiosClient.get('/request/user/getInformations/accountOrganizer');
            setProfilData(data.user);
        } catch (error) {
            handleError(error.message || 'Erreur survenue, veuillez réesayer !')
            console.error("Erreur Axios capturée :", error.message);
            // setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Charger les données une fois au montage
    useEffect(() => {
        // Recharger les données uniquement si l'onglet actif est "profil_informations"
        if (activeTab === 'profil_informations') {
            fetchProfilData();
        }
    }, [activeTab]);

    if (loading) {
        return <LoadContentSpinner />;
    }

    return (
        <div className="ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz">
            {/* Page header */}
            <div className="cgd7w">
                {/* Title */}
                <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                Paramètres du Compte
                </h1>
                
            </div>

            <div className="bg-white c2vpa c1hly c5vqk cgd7w">
                <div className="flex c2poy cwner cetff">
                    {/* Sidebar */}
                    <div className="flex border-gray-200 cghq3 c9zp2 cjq31 colxp cschv cu49b cws0k c8vtj cyqqd cnuel ctv3r cb2br cwlwv">
                        {/* Group 1 */}
                        <div>
                            <div className="cmpw7 cgulq cdqku c0ef0 c1iho cxg65">
                            Menu des paramètres
                            </div>
                            <ul className="flex c8vtj cyqqd cri0s chfzq">
                                <li className="cved1 cri0s ce4il" key="info">
                                <button
                                    className={`flex items-center ${activeTab === 'profil_informations' ? 'bg-sidebar-linkGroup': ''} cosgb cq84g cb8zv ch4gv cuvgf w-full`}
                                    onClick={() => handleTabClick('profil_informations')}
                                >
                                    <svg
                                    className={`${activeTab === 'profil_informations' ? 'text-primary-600': ''} mr-2 cbm9w coqgc`}
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    >
                                    <path d="M8 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-5.143 7.91a1 1 0 1 1-1.714-1.033A7.996 7.996 0 0 1 8 10a7.996 7.996 0 0 1 6.857 3.877 1 1 0 1 1-1.714 1.032A5.996 5.996 0 0 0 8 12a5.996 5.996 0 0 0-5.143 2.91Z" />
                                    </svg>
                                    <span className={`${activeTab === 'profil_informations' ? 'text-primary-600': 'c18od'} text-sm csvw7 c1k3n`}>
                                    Mes Informations
                                    </span>
                                </button>
                                </li>
                                <li className="cved1 cri0s ce4il" key="notifications">
                                <button
                                    className={`flex items-center ${activeTab === 'notifications' ? 'bg-sidebar-linkGroup': ''} cq84g cb8zv ch4gv cuvgf w-full`}
                                    onClick={() => handleTabClick('notifications')}
                                >
                                    <svg
                                    className={`${activeTab === 'notifications' ? 'text-primary-600': ''} mr-2 cmpw7 cdqku cbm9w coqgc`}
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    >
                                    <path d="m9 12.614 4.806 1.374a.15.15 0 0 0 .174-.21L8.133 2.082a.15.15 0 0 0-.268 0L2.02 13.777a.149.149 0 0 0 .174.21L7 12.614V9a1 1 0 1 1 2 0v3.614Zm-1 1.794-5.257 1.503c-1.798.514-3.35-1.355-2.513-3.028L6.076 1.188c.791-1.584 3.052-1.584 3.845 0l5.848 11.695c.836 1.672-.714 3.54-2.512 3.028L8 14.408Z" />
                                    </svg>
                                    <span className={`${activeTab === 'notifications' ? 'text-primary-600': 'c18od'} text-sm c196r c0zkc c1ukq c1k3n`}>
                                    Notifications
                                    </span>
                                </button>
                                </li>
                                <li className="cved1 cri0s ce4il" key="partnership">
                                <button
                                    className={`flex items-center ${activeTab === 'partnership' ? 'bg-sidebar-linkGroup': ''} cq84g cb8zv ch4gv cuvgf w-full`}
                                    onClick={() => handleTabClick('partnership')}
                                >
                                    <svg
                                    className={`${activeTab === 'partnership' ? 'text-primary-600': ''} mr-2 cmpw7 cdqku cbm9w coqgc`}
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    >
                                    <path d="M8 3.414V6a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2H9.414l6.293 6.293a1 1 0 1 1-1.414 1.414L8 3.414Zm0 9.172V10a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2h2.586L.293 7.707a1 1 0 0 1 1.414-1.414L8 12.586Z" />
                                    </svg>
                                    <span className={`${activeTab === 'partnership' ? 'text-primary-600': 'c18od'} text-sm c196r c0zkc c1ukq c1k3n`}>
                                    Mon Partenariat
                                    </span>
                                </button>
                                </li>
                            </ul>
                        </div>
                        {/* Group 2 */}
                        <div>
                        <div className="cmpw7 cgulq cdqku c0ef0 c1iho cxg65">Configurations</div>
                            <ul className="flex c8vtj cyqqd cri0s chfzq">
                                <li className="cved1 cri0s ce4il" key={'doc'}>
                                <button
                                    className={`flex items-center ${activeTab === 'doc' ? 'bg-sidebar-linkGroup': ''} cq84g cb8zv ch4gv cuvgf w-full`}
                                    onClick={() => handleTabClick('doc')}
                                >
                                    <svg
                                        className={`${activeTab === 'doc' ? 'text-primary-600': ''} mr-2 cmpw7 cdqku cbm9w coqgc`}
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        >
                                        <path d="M0 4a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Zm2 0v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Zm9 1a1 1 0 0 1 0 2H5a1 1 0 1 1 0-2h6Zm0 4a1 1 0 0 1 0 2H5a1 1 0 1 1 0-2h6Z" />
                                    </svg>
                                    <span className={`${activeTab === 'doc' ? 'text-primary-600': 'c18od'} text-sm c196r c0zkc c1ukq c1k3n`}>
                                    Pièces jointes
                                    </span>
                                </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* Panel */}
                    <div className="cbw8w">
                        {/* Panel body */}
                        {activeTab === 'profil_informations' && (
                            <ProfilInformations profilData={profilData} refreshData={fetchProfilData} />
                        )}
                        {activeTab === 'notifications' && (
                            <div>
                                <div className="cmxzb c41yb">
                                    <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb c6r0l">
                                        Notifications
                                    </h2>
                                    {/* General */}
                                    <section>
                                        <ul>
                                            <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                                {/* Left */}
                                                <div>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq">
                                                Notifications par e-mail
                                                </div>
                                                <div className="text-sm">
                                                Recevez des mises à jour importantes et des alertes directement dans votre boîte de réception pour rester informé de toutes les actualités et activités liées à votre compte.
                                                </div>
                                                </div>
                                                {/* Right */}
                                                <div className="flex items-center cfh3y">
                                                <div
                                                    className="text-sm mr-2 cmpw7 cdqku caf78"
                                                >
                                                    On
                                                </div>
                                                <div className="c1ivc">
                                                    <input
                                                    type="checkbox"
                                                    checked={emailNotifications}
                                                    onChange={() => setEmailNotifications(true)} // Toujours désactivé
                                                    className="cn8jz"
                                                    disabled
                                                    />
                                                    <label className="dark:bg-gray-700 cg3qz">
                                                    <span className="bg-white c5vqk" aria-hidden="true" />
                                                    <span className="cn8jz">Enable smart sync</span>
                                                    </label>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                                {/* Left */}
                                                <div>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq">Authentification à deux facteurs (2FA)</div>
                                                <div className="text-sm">
                                                Une couche de sécurité supplémentaire pour protéger votre compte en exigeant une vérification supplémentaire lors de la connexion ou tout autres actions d'authenticité.
                                                </div>
                                                </div>
                                                {/* Right */}
                                                <div className="flex items-center cfh3y">
                                                <div
                                                    className="text-sm mr-2 cmpw7 cdqku caf78"
                                                >
                                                    On
                                                </div>
                                                <div className="c1ivc">
                                                    <input
                                                    type="checkbox"
                                                    checked={twoFactorAuth}
                                                    onChange={() => setTwoFactorAuth(true)}
                                                    className="cn8jz"
                                                    disabled
                                                    />
                                                    <label className="dark:bg-gray-700 cg3qz">
                                                    <span className="bg-white c5vqk" aria-hidden="true" />
                                                    <span className="cn8jz">Enable smart sync</span>
                                                    </label>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                                {/* Left */}
                                                <div>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq">Disponibilite des factures</div>
                                                <div className="text-sm">
                                                Soyez informé dès qu'une nouvelle facture est disponible pour consultation ou téléchargement sur votre compte.
                                                </div>
                                                </div>
                                                {/* Right */}
                                                <div className="flex items-center cfh3y">
                                                <div
                                                    className="text-sm mr-2 cmpw7 cdqku caf78"
                                                >
                                                    On
                                                </div>
                                                <div className="c1ivc">
                                                    <input
                                                    type="checkbox"
                                                    checked={invoiceAvailability}
                                                    onChange={() => setInvoiceAvailability(true)}
                                                    className="cn8jz"
                                                    disabled
                                                    />
                                                    <label className="dark:bg-gray-700 cg3qz">
                                                    <span className="bg-white c5vqk" aria-hidden="true" />
                                                    <span className="cn8jz">Enable smart sync</span>
                                                    </label>
                                                </div>
                                                </div>
                                            </li>
                                            <li className="flex items-center cghq3 cm3rx c72q5">
                                                {/* Left */}
                                                <div>
                                                <div className="text-gray-800 dark:text-gray-100 cgulq">Notification du support</div>
                                                <div className="text-sm">
                                                Recevez des notifications de l'équipe de support pour des mises à jour sur les tickets ou les demandes d'assistance en cours.
                                                </div>
                                                </div>
                                                {/* Right */}
                                                <div className="flex items-center cfh3y">
                                                <div
                                                    className="text-sm mr-2 cmpw7 cdqku caf78"
                                                >
                                                    On
                                                </div>
                                                <div className="c1ivc">
                                                    <input
                                                    type="checkbox"
                                                    checked={supportNotifications}
                                                    onChange={() => setSupportNotifications(true)}
                                                    className="cn8jz"
                                                    disabled
                                                    />
                                                    <label className="dark:bg-gray-700 cg3qz">
                                                    <span className="bg-white c5vqk" aria-hidden="true" />
                                                    <span className="cn8jz">Enable smart sync</span>
                                                    </label>
                                                </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                        )}
                        {activeTab === 'partnership' && (
                            <PartnersContent />
                        )}
                        {activeTab === 'doc' && (
                            <PersonnalDocumentID />
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}
