import { Routes, Route, useLocation } from "react-router-dom";
import Hero from "./pages/Hero";
import Error404 from "./pages/Error404";
import WizardForm from './pages/WizardForm'
import ContactPage from "./pages/ContactPage";
import AboutUs from "./pages/AboutUs";
import CGU from "./pages/CGU";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FAQs from "./pages/FAQs";
import MainPage from "./pages/OrganizerFolder/MainPage";
import useTrackVisitor from "./hooks/useTrackVisitor";
import InscriptionNewsLettersEvents from "./pages/InscriptionNewsLettersEvents";
import Index from "./pages/Authentication/Login/Index";
import OganizerPolicyEvents from "./pages/OganizerPolicyEvents";
import IndexSignUp from "./pages/Authentication/SignUp/IndexSignUp";
import IndexForgotPassword from "./pages/Authentication/Forget-password/IndexForgotPassword";
import IndexResetPassword from "./pages/Authentication/Reset-password/IndexResetPassword";
import IndexVerifyCodeAuth from "./pages/Authentication/Auth-code/IndexVerifyCodeAuth";
import HomeToDashboard from "./pages/Dashboard/HomeToDashboard";
import React, { Suspense, useEffect } from "react";
import SpinnerLoadContent from "./components/SpinnerLoadContent";
import ListEvents from "./pages/Dashboard/evenementsFolder/ListEvents";
import ViewDetailsToEvents from "./pages/Dashboard/evenementsFolder/ViewDetailsToEvents";
import HomePageEventsValidations from "./pages/Dashboard/HomePageEventsValidations";
import ViewDetails from "./pages/Dashboard/validationsEvents/ViewDetails";
import StatistiqueOrders from "./pages/Dashboard/ordersFolder/StatistiqueOrders";
import SalesTicketsEvents from "./pages/Dashboard/ordersFolder/SalesTicketsEvents";
import DetailsToEventsOrders from "./pages/Dashboard/ordersFolder/DetailsToEventsOrders";
import ReceivedOrdersEvents from "./pages/Dashboard/ordersFolder/ReceivedOrdersEvents";
import ViewListCustomers from "./pages/Dashboard/customersFolder/ViewListCustomers";
import ProfilCustomerDetails from "./pages/Dashboard/customersFolder/ProfilCustomerDetails";
import OrganizerInvoicesList from "./pages/Dashboard/factures-organisateurs/OrganizerInvoicesList";
import ShowEventsInvoices from "./pages/Dashboard/factures-organisateurs/ShowEventsInvoices";
import ShowDetailsEventsInvoices from "./pages/Dashboard/factures-organisateurs/ShowDetailsEventsInvoices";
import IndexEventsValidations from "./pages/Dashboard/eventsScannerFolder/IndexEventsValidations";
import IndexAccountSetting from "./pages/Dashboard/accountsManage/IndexAccountSetting";
import OrganizerCards from "./pages/Dashboard/accountsManage/OrganizerCards";
import ContactToOrganizerSupport from "./pages/Dashboard/accountsManage/ContactToOrganizerSupport";
import PrivateRoute from "./auth/PrivateRoute";
import GuestRoute from "./auth/GuestRoute";
import VerifyEmailChange from "./pages/Authentication/email-request/VerifyEmailChange";
import SuccessContent from "./pages/Authentication/email-request/SuccessContent";
import { useError } from "./components/contexts/ErrorContext";
import { setHandleError } from "./components/api/axiosClient";
import ErrorDisplay from "./components/Utils/ErrorDisplay";
import EventsBilleterie from "./pages/OrganizerFolder/EventsBilleterie";

// Chargement dynamique des pages
const PublicLayout = React.lazy(() => import('./components/Layouts/Public/LayoutPublic'));
const DashboardLayout = React.lazy(() => import('./components/Layouts/Private/LayoutDashboard'));

function App() {
    
    useTrackVisitor();

    const { handleError } = useError();
    const location = useLocation();

    useEffect(() => {
      if (location.pathname.startsWith('/dashboard')) {
        import('./styles/da1csdf707QboRT71_ard.css');
      } else {
        import('./styles/css/main.579c8386.css');
        import('./styles/css/GlowEffect.css');
      }

    }, [location]);

    
    // Liaison du gestionnaire d'erreurs à Axios
    useEffect(() => {
      setHandleError(handleError); // Assurez-vous que `setHandleError` est correctement importé
    }, [handleError]);
    
    return (
      <>
        <ErrorDisplay />
        <Suspense fallback={<SpinnerLoadContent />}>
          <Routes>
              <Route element={<PublicLayout />}>
                  <Route index element={<Hero />} />
                  <Route path="*" element={<Error404 />} />
                  <Route path="/nous-contacter" element={<ContactPage />} />
                  <Route path="/a-propos-de-l'appli-events" element={<AboutUs />} />
                  <Route path="/Events-usedFolder/conditions-generales-utilisations" element={<CGU />} />
                  <Route path="/Events-usedFolder/politique-confidentielle" element={<PrivacyPolicy />} />
                  <Route path="/Events-usedFolder/FAQs-events" element={<FAQs />} />
                  <Route path="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6" element={<OganizerPolicyEvents />} />
                  
                  {/* // ROUTED DES PAGES DES ORGANISATEURS */}
                  <Route path="/organisateurs/devenir-organisateur-events" element={<MainPage />} />
                  <Route path="/Organisateurs/events/systeme-billetterie" element={<EventsBilleterie />} />
                  {/* ROUTE DE NEWSLETTER */}
                  <Route path="/events-App/newsletter-inscription/r/" element={<InscriptionNewsLettersEvents />} />

                  {/* ROUTE AUTHENTIFICATION */}
                  <Route path="/login" element={<GuestRoute><Index /></GuestRoute>} />
                  <Route path="/create-account" element={<GuestRoute><IndexSignUp /></GuestRoute>} />
                  <Route path="/forgot-password" element={<GuestRoute><IndexForgotPassword /></GuestRoute>} />
                  <Route path="/reset-password" element={<GuestRoute><IndexResetPassword /></GuestRoute>} />
                  <Route path="/verify-auth-code" element={<GuestRoute><IndexVerifyCodeAuth /></GuestRoute>} />
              </Route>

              {/* ROUTES DU TABLEAU DE BORD */}
              <Route path="/dashboard/user/administration/verify-email-change" element={<VerifyEmailChange />} />
              <Route path="/success" element={<SuccessContent />} />
              <Route element={
                <PrivateRoute>
                  <DashboardLayout />
                </PrivateRoute>
                }>
                <Route path="/dashboard/*" element={<HomeToDashboard />} />
                {/* Routes evenementielles */}
                <Route path="/dashboard/events/registration" element={<WizardForm />} />
                <Route path="/dashboard/events/show-list" element={<ListEvents />} />
                <Route path="/dashboard/events/view-details/to/:id" element={<ViewDetailsToEvents />} />
                <Route path="/dashboard/events/validations" element={<HomePageEventsValidations />} />
                <Route path="/dashboard/events/validations/show/info-e/:id" element={<ViewDetails />} />
                {/* Routes des ventes */}
                <Route path="/dashboard/ventes/statistiques" element={<StatistiqueOrders />} />
                <Route path="/dashboard/ventes/tickets-vendus/showAll" element={<SalesTicketsEvents />} />
                <Route path="/dashboard/ventes/tickets-vendus/e/:id" element={<DetailsToEventsOrders />} />
                <Route path="/dashboard/ventes/tickets/paiements/mes-paiements" element={<ReceivedOrdersEvents />} />
                {/* Routes des acheteurs */}
                <Route path="/dashboard/achats/tickets/clients-acheteurs" element={<ViewListCustomers />} />
                <Route path="/dashboard/achats/tickets/clients/profil/p/:id" element={<ProfilCustomerDetails />} />
                {/* Routes de composant de validation (Events Scanner) */}
                <Route path="/dashboard/achats/events/validation/events-scan" element={<IndexEventsValidations />} />
                {/* Routes des factures organisateurs */}
                <Route path="/dashboard/factures/mes-factures/liste" element={<OrganizerInvoicesList />} />
                <Route path="/dashboard/factures/events/invoices" element={<ShowEventsInvoices />} />
                <Route path="/dashboard/factures/events/invoices/show-details/i/:id" element={<ShowDetailsEventsInvoices />} />
                {/* Routes de gestion de compte */}
                <Route path="/dashboard/account/administration-organizer" element={<IndexAccountSetting />} />
                <Route path="/dashboard/account/administration-organizer/mes-cartes" element={<OrganizerCards />} />
                <Route path="/dashboard/user/administration/verify-email-change" element={<VerifyEmailChange />} />
                <Route path="/dashboard/account/administration-organizer/contact/events-supprort-organizers" element={<ContactToOrganizerSupport />} />
              </Route>
          </Routes>
        </Suspense>
      </>
    );
}

export default App;
