import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../../components/contexts/ContextProvider';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import validator from 'validator';
import axiosClient from '../../../components/api/axiosClient';

export default function ContactToOrganizerSupport() {
    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Contacter le support - Events";
        
    }, []);
    
    const { user, loading } = useStateContext();
    useEffect(() => {
        if (user) {
            setFormValue((prev) => ({
                ...prev,
                email: user.email_account || ''
            }));
        }
    }, [user]);

    const [errors, setErrors] = useState({});
    const [formValue, setFormValue] = useState({
        objet: '', 
        email: '', // Initialisé à une chaîne vide par défaut
        message: ''
    });
    const [isSubmit, setIsSubmit] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [validationError, setValidationError] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Créez une fonction pour réinitialiser les états du formulaire
    const resetForm = () => {
        setFormValue({ objet: '', email: user.email_account || '', message: '' }); // Réinitialise les valeurs des champs à vide
        setErrors({}); // Efface les éventuelles erreurs de validation
        setIsSubmit(false); // Réinitialise l'état de soumission à false
    };

    const validateForm = (value) => {
        const newErrors = {};

        // Validation du champ objet
        const trimmedObjet = value.objet.trim();
        if (!trimmedObjet) {
            newErrors.objet = "Ce champ est requis";
        } else if (!validator.isLength(trimmedObjet, { min: 10 })) {
            newErrors.objet = "Veuillez rédiger correctement votre objet de contact";
        }

        // Validation du champ email
        const trimmedEmail = value.email.trim();
        if (!trimmedEmail) {
            newErrors.email = "L'e-mail est requis";
        } else if (!validator.isEmail(trimmedEmail)) {
            newErrors.email = "L'adresse e-mail n'est pas valide";
        }

        // Validation du champ message
        const trimmedMessage = value.message.trim();
        if (!trimmedMessage) {
            newErrors.message = "Ce champ est requis";
        } else if (!validator.isLength(trimmedMessage, { min: 15 })) {
            newErrors.message = "Veuillez rédiger correctement votre préoccupation";
        }

        return newErrors;
    };
    
    useEffect(() => {
        // Si le formulaire est soumis et qu'il n'y a pas d'erreurs, effectuez une action
        if (Object.keys(errors).length === 0 && isSubmit) {
            // console.log(formValue);
        }
    }, [errors, formValue, isSubmit]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formValue)
        // Valider le formulaire avant de passer à l'étape suivante
        const formErrors = validateForm(formValue);

        // Vérifier si le formulaire est valide
        if (Object.keys(formErrors).length === 0) {
            setLoadingBtn(true); // Activer le chargement
            
            try {
                axiosClient.post("/request/user/organizer/support/send-message", formValue)
                .then((response) => {
                    // Mise à jour du statut selon la réponse du serveur
                    setSuccessMessage(response.data.messages);
        
                    setLoadingBtn(false);
                    resetForm(); // Réinitialise le formulaire
                    // Fermer le message
                    setTimeout(() => setSuccessMessage(""), 3000);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 422) {
                            // Mise à jour du statut en cas d'erreur de validation
                            setValidationError(
                                "Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !"
                            );
                            setLoadingBtn(false);
                            // Fermer le message
                            setTimeout(() => setValidationError(""), 3000);
                        }else if (error.response.status === 500) {
                            setValidationError(error.response.data.message);
                            setLoadingBtn(false);

                            // Fermer le message
                            setTimeout(() => setValidationError(""), 3000);
                        } else {
                            setValidationError(
                                "OUPS ! Une erreur est survenu, Veuillez réessayer ulterieurement."
                            );
                            setLoadingBtn(false);
                            setTimeout(() => setValidationError(""), 3000);
                        }
                    } else{

                        setValidationError(
                            "OUPS ! Une erreur est survenu, Veuillez réessayer ulterieurement."
                        );
                        setLoadingBtn(false);
                        setTimeout(() => setValidationError(""), 3000);
                    }
                });
            } catch (err) {
                setValidationError("Erreur de traitement ! Veuillez réessayer s'il vous plait !");
                setLoadingBtn(false);
                // Fermer le message
                setTimeout(() => setValidationError(""), 3000);
            }
        }

        // Mettre à jour les erreurs après la validation
        setErrors(formErrors);
        
        // Définir isSubmit à true pour activer useEffect
        setIsSubmit(true);
    };

    if (loading) {
        return <LoadContentSpinner />;
    }

    return (
        <div className='ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz'>
            <div className='relative flex justify-center'>

                {validationError && (
                    <div className="text-sm fixed z-10 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                        <div className="flex cm3rx ce4zk c6btv">
                            <div className="flex">
                            <svg
                                className="cbm9w czr3n cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                            </svg>
                            <div>{validationError}</div>
                            </div>
                        </div>
                    </div>
                )}
                {successMessage && (
                    <div className="text-sm fixed z-10 cf8dr cje53 cb8zv clbq0 cuvgf">
                        <div className="flex cm3rx ce4zk c6btv">
                            <div className="flex">
                            <svg
                                className="c612e cbm9w cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                            </svg>
                            <div>
                            {successMessage}
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="cbw8w bg-white rounded-xl">
                {/* Panel body */}
                <div className="cmxzb c41yb">
                    <div>
                    <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb cdiog">
                    Contacter le Support
                    </h2>
                    <div className="text-sm">
                    Si vous rencontrez des problèmes ou avez des questions, n'hésitez pas à contacter notre support. Nous sommes là pour vous aider !
                    </div>
                    </div>
                    {/* Tell us in words */}
                    <section>
                        <div className='w-full grid sm:grid-cols-2 coah6 c4sak'>
                            <div>
                                <h3 className="text-gray-800 dark:text-gray-100 text-base mb-2 font-bold ce5fk clctu c6r0l">
                                    Objet du message
                                </h3>
                                <input
                                    id="confirm_new_password"
                                    className="caqf9 c6btv c9hxi cwn3v focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    required=""
                                    placeholder="Entrer l'objet du votre message ici"
                                    onChange={handleChange}
                                    name="objet"
                                    value={formValue.objet}
                                />
                                {errors.objet && (
                                    <small className="text-red-600 mt-2">
                                        {errors.objet}
                                    </small>
                                )}
                            </div>
                            <div>
                                <h3 className="text-gray-800 dark:text-gray-100 text-base mb-2 font-bold ce5fk clctu c6r0l">
                                    E-mail de l'organisateur
                                </h3>
                                <input
                                    id="confirm_new_password"
                                    className="czsk6 caqf9  c6btv c9hxi cwn3v focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    required=""
                                    placeholder="Votre email ici"
                                    disabled
                                    readOnly
                                    value={formValue.email}
                                    onChange={handleChange}
                                    name="email"
                                />
                                {errors.email && (
                                <small className="text-red-600 mt-2">
                                    {errors.email}
                                </small>
                                )}
                            </div>

                        </div>
                    <h3 className="text-gray-800 dark:text-gray-100 text-base mb-2 mt-6 font-bold ce5fk clctu c6r0l">
                    Contenu du message
                    </h3>
                    {/* Form */}
                    {/* <label className="cn8jz" htmlFor="feedback">
                        Leave a feedback
                    </label> */}
                    <textarea
                        id="feedback"
                        className="cn9r8 c071z c6btv"
                        rows={4}
                        placeholder="Saisir le message ici"
                        onChange={handleChange}
                        name="message"
                        value={formValue.message}
                    />
                    {errors.message && (
                        <small className="text-red-600 mt-2">
                            {errors.message}
                        </small>
                    )}
                    </section>
                </div>
                {/* Panel footer */}
                <footer>
                    <div className="flex border-gray-200 cghq3 cetff cr4kg cj8hp ckhro">
                    <div className="flex c4tdj">
                        <button onClick={resetForm} className="btn border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Annuler
                        </button>
                        <button onClick={handleSubmit} type='submit' className="btn bg-primary-600 hover:bg-primary-700 text-white ml-3 cdj8c cg0jr ch8z9 cilvw cyn7a">
                        {loadingBtn ? (
                            <>
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx={12}
                                        cy={12}
                                        r={10}
                                        stroke="currentColor"
                                        strokeWidth={3}
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                                Envoi en cours...
                            </>
                        ) : (
                         'Envoyer'
                        )}
                        </button>
                    </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}
