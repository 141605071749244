import React, { useState } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import { Link } from 'react-router-dom';
import axiosClient from '../../api/axiosClient';

export default function PartnersContent() {

    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    const handleDownload = () => {
        // Vous pouvez utiliser window.open pour ouvrir le fichier dans une nouvelle fenêtre ou un nouvel onglet
        window.open(`${imagePath}DocumentsFolder/contrat-organisateur-events.pdf`, "_blank");
    };

    const { user, setUser } = useStateContext();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [validationError, setValidationError] = useState('');
    const [successMessage, setSuccessMessage] = useState();
    const [file, setFile] = useState(null);
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

    // Gestion du changement de fichier
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > MAX_FILE_SIZE) {
                setErrors({ file: 'Le fichier doit être inférieur à 2 Mo.' });
            } else if (selectedFile.type !== 'application/pdf') {
                setErrors({ file: 'Format de fichier non supporté. Seul le PDF est autorisé.' });
            } else {
                setErrors({}); // Clear errors if valid
                setFile(selectedFile);
            }
        }
    };

    // Gestion de la soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Vérifier si le fichier est téléchargé
        if (!file) {
            setValidationError('Veuillez télécharger le fichier PDF du contrat.');
            setTimeout(() => setValidationError(null), 5000);
            return;
        }

        // Réinitialiser les erreurs au début de la soumission
        setErrors({});
        setValidationError(null);

        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);

        // Envoyer le fichier via l'API
        axiosClient.post('/request/user/manageAccount/upload-contract', formData)
            .then(
                ({data}) => {
                    setSuccessMessage(data.message);
                    setLoading(false);
                    setTimeout(() => {
                        // refreshData(data.user);
                        setUser(data)
                    }, 4000);

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier votre fichier puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        if (response.data.errors) {
                            // Erreurs spécifiques aux champs
                            setErrors(response.data.errors);
                        }
                        if (response.data.message) {
                            // Erreur générale
                            setValidationError(response.data.message);
                        }
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Erreur lors de l'envoi des documents.");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
    };

    const isSubmitDisabled = () => {
        return loading || !file;
    };

    return (
        <div>
            <div className="cmxzb c41yb">
                <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb c6r0l">
                Partenariat avec Events
                </h2>
                <p className='!m-0 text-sm'>Bienvenue dans l'espace partenariat. Ici, vous pouvez télécharger le contrat d'organisateur, le signer, puis le soumettre pour validation. Le processus est simple et rapide.</p>
                {user.organizer_config.terms_signed === 0 ? (
                    <>
                        <section>
                            <div className="cvwie cosgb c33r0 cgnhv cf10p c340b coaix cfcf6 cydwr cb8zv clxbf cx3hp c72q5">
                                <div className="text-gray-900 dark:text-gray-100 text-left cgulq cgwj2 c6f83">
                                    Bon à savoir
                                    <p className='text-sm text-gray-800 font-normal mt-2'>
                                    <ul className='cjqz5 cektu c1647 cai6b mt-2'>
                                        <li className='leading-normal'><b>Téléchargez le contrat d'organisateur :</b> Cliquez sur le bouton ci-dessous pour télécharger le contrat. Prenez le temps de le lire attentivement avant de le signer.</li>
                                        <li className='leading-normal'><b>Signez le contrat :</b> Une fois téléchargé et imprimé, signez le contrat version papier. Assurez-vous que toutes les informations nécessaires sont bien complétées.</li>
                                        <li className='leading-normal'><b>Soumettez pour validation :</b> Une fois le contrat signé, scanné le contrat entier, cliquez sur "Déposer le contrat signé" pour finaliser le processus. Assurez vous le document scané soit bien lisible. Notre équipe examinera le contrat et vous tiendra informé de la suite.</li>
                                    </ul>
                                    </p>
                                    <button onClick={handleDownload} className="btn border-gray-200 text-gray-800 bg-white cc0oq cghq3 cspbm c0zkc c2vpa">
                                    Télécharger mon contrat
                                    </button>

                                </div>
                            </div>
                        </section>
                        <section>
                            <h3 className="text-gray-800 dark:text-gray-100 mb-2 font-bold ce5fk clctu cai6b">Avez-vous fini de signer votre contrat avec Events ?</h3>
                            <p className='!m-0 text-sm'>Le contrat entre l'organisateur et Events définit les conditions de collaboration, les modalités de paiement, et les responsabilités pour gérer les événements sur la plateforme.</p>
                                {validationError && (
                                    <div className="text-sm relative mt-4 mb-4 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                                        <div className="flex cm3rx ce4zk c6btv">
                                            <div className="flex">
                                            <svg
                                                className="cbm9w czr3n cq1qg coqgc chfzq"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                                            </svg>
                                            <div>{validationError}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {successMessage && (
                                    <div className="text-sm relative mt-4 mb-4 cf8dr cje53 cb8zv clbq0 cuvgf">
                                        <div className="flex cm3rx ce4zk c6btv">
                                            <div className="flex">
                                            <svg
                                                className="c612e cbm9w cq1qg coqgc chfzq"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                                            </svg>
                                            <div>
                                            {successMessage}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <form onSubmit={handleSubmit} className='terme-signed'>
                                    <div className="cgndh flex justify-center">
                                        <div className="rounded border cghq3 w-3/5 mb-4 c993f c4ppg cxxjf cyhlg cydwr cx3hp cxsfz">
                                            <svg
                                            className="inline-flex ca2tk cyq9w cxg65"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path d="M8 4c-.3 0-.5.1-.7.3L1.6 10 3 11.4l4-4V16h2V7.4l4 4 1.4-1.4-5.7-5.7C8.5 4.1 8.3 4 8 4ZM1 2h14V0H1v2Z" />
                                            </svg>
                                            <label
                                            htmlFor="upload"
                                            className="block text-sm text-gray-500 dark:text-gray-400 caf78 cursor-pointer"
                                            >
                                            Cliquez ici pour télécharger votre contrat PDF.
                                            </label>
                                            <input className="cn8jz" id="upload" type="file"
                                            accept=".pdf"
                                            onChange={handleFileChange} 
                                            />
                                            {errors.file && (
                                                <small className="text-red-600 mt-2">
                                                    {errors.file}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <footer>
                                        <div className="flex border-gray-200 cghq3 cetff cr4kg cj8hp ckhro">
                                            <div className="c4tdj">
                                            <button type='submit' disabled={isSubmitDisabled()} className="btn bg-primary-600 hover:bg-primary-700 text-white ml-3 cdj8c cg0jr ch8z9 cilvw cyn7a">
                                                {loading ? (
                                                    <>
                                                        <svg
                                                            className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx={12}
                                                                cy={12}
                                                                r={10}
                                                                stroke="currentColor"
                                                                strokeWidth={3}
                                                            />
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            />
                                                        </svg>
                                                        Envoi en cours...
                                                    </>
                                                ) : (
                                                    <>Déposer mon contrat signé</>
                                                )}
                                           
                                            </button>
                                            </div>
                                        </div>
                                    </footer>
                                </form>
                        </section>
                    </>
                ) : (
                    <div className="flex w-full items-end justify-center">
                        <div className="inline-flex text-sm bg-white border border-gray-200 dark:text-gray-400 cghq3 c2vpa c1ukq cb8zv c5vqk crwo8 cetff c6btv clbq0 cuvgf">
                            <div className="flex cm3rx ce4zk c6btv">
                                <div className="flex">
                                <svg
                                className="c612e cbm9w cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                                </svg>

                                <div>
                                    <div className="c612e dark:text-gray-100 c1k3n cu6vl">
                                    Merci d’avoir soumis votre contrat
                                    </div>
                                    <div>
                                    Pour toute demande de modification ou réclamation, veuillez contacter l'administration.
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="chvik ccwg3">
                                <Link className="text-blue-500 c5ylh ceetm c1k3n" to="/dashboard/account/administration-organizer/contact/events-supprort-organizers">
                                Faire une reclamation -&gt;
                                </Link>
                            </div>
                        </div>
                    </div>

                )}
            </div>
        </div>
    )
}
