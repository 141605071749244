import React, { useState } from 'react'
import axiosClient from '../../api/axiosClient';
import validator from 'validator';
import { useStateContext } from '../../contexts/ContextProvider';
import { Link } from 'react-router-dom';

export default function ProfilInformations({ profilData, refreshData }) {

    // Champs de profil initialisés avec les données reçues en props
    const [formData, setFormData] = useState({
       name: profilData.full_name || '',
       tel: profilData.tel || '',
       location: profilData.location || '',
       organization_name: profilData.organization_name || '',
       organization_adresse: profilData.organization_adresse || '',
       organization_website: profilData.organization_website || '',
    });

    const [passwordFormData, setPasswordFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [errors, setErrors] = useState({});
    const [validationError, setValidationError] = useState('');
    const [successMessage, setSuccessMessage] = useState()
    const [successChangeAddress, setSuccessChangeAddress] = useState(false);
    const [errorChangeAddress, setErrorChangeAddress] = useState(false);
    const { setUser } = useStateContext();
    const [passwordErrors, setPasswordErrors] = useState({});
    const [passwordValidationError, setPasswordValidationError] = useState('');
    const [passwordSuccessMessage, setPasswordSuccessMessage] = useState();
    const [passwordFormloading, setPasswordFormLoading] = useState(false);

    // Fonction pour ouvrir le modal
    const openFormModal = () => {
        setIsFormModalOpen(true);
    };

    // Fonction pour fermer le modal
    const closeFormModal = () => {
        setIsFormModalOpen(false);
    };

    const validateForm = () => {
        const newErrors = {};
    
        const trimmedLabelName = formData.organization_name.trim();
        if (!trimmedLabelName.trim()) {
          newErrors.organization_name = "Ce champ est obligatoire";
        } else if (!validator.isLength(trimmedLabelName, { min: 3 })) {
            newErrors.organization_name =
              "Veuillez saisir le nom de votre organisation correctement";
        }

        const trimmedNomComplet = formData.name.trim();
        if (!trimmedNomComplet) {
          newErrors.name = "Le nom et prénom sont requis";
        } else if (!validator.isLength(trimmedNomComplet, { min: 6 })) {
          newErrors.name =
            "Veuillez saisir votre nom et prénom";
        } else if (!/^[a-zA-Z\sÀ-ÿ'-]+$/.test(trimmedNomComplet)) {
          newErrors.name =
            "Le nom et prénom ne doivent contenir que des lettres";
        }

        const trimmedNumero = formData.tel.trim();
        if (!trimmedNumero) {
          newErrors.tel = "Le numéro de téléphone est requis";
        }

        // Validation de l'adresse de localisation (optionnel)
        const trimmedLocation = formData.location.trim();
        if (trimmedLocation && trimmedLocation.length < 5) {
            newErrors.location = "Entrez une adresse de localisation valide";
        }

        // Validation de l'adresse de l'organisation (optionnel)
        const trimmedOrganizationAdresse = formData.organization_adresse.trim();
        if (trimmedOrganizationAdresse && trimmedOrganizationAdresse.length < 5) {
            newErrors.organization_adresse = "L'adresse de l'entreprise n'est pas valide";
        }

        // Validation du site web de l'organisation (optionnel)
        const trimmedOrganizationWebsite = formData.organization_website.trim();
        if (trimmedOrganizationWebsite && !validator.isURL(trimmedOrganizationWebsite)) {
            newErrors.organization_website = "URL du site web invalide";
        }
    
        return newErrors;
    };

    
    // Fonction pour gérer les changements dans les champs du formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Fonction pour gérer les changements dans les champs du formulaire
    const handleChangePasswordForm = (e) => {
        const { name, value } = e.target;
        setPasswordFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Fonction pour soumettre le formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            // Soumission des données ou logique d'inscription
            setLoading(true); // Activer le chargement

            axiosClient.post('/request/user/manageAccount/updateProfil', formData)
            .then(
                ({data}) => {
                    setSuccessMessage(data.message);
                    setLoading(false);
                    setTimeout(() => {
                        refreshData(data.user);
                        setUser(data)
                    }, 4000);

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        if (response.data.errors) {
                            // Erreurs spécifiques aux champs
                            setErrors(response.data.errors);
                        }
                        if (response.data.message) {
                            // Erreur générale
                            setValidationError(response.data.message);
                        }
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Erreur lors de la mise à jour du profil.");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
        }

        // Mettre à jour les erreurs après la validation
        setErrors(validationErrors);
    };

    // Validation et soumission pour la réinitialisation de mot de passe
    const validatePasswordForm = () => {
        const errorsPasswordForm = {};

        if (passwordFormData.newPassword !== passwordFormData.confirmPassword) {
            errorsPasswordForm.confirmPassword = "Les mots de passe ne correspondent pas";
        }

        const trimmedCurrentPassword = passwordFormData.currentPassword.trim();
        const trimmedNewPassword = passwordFormData.newPassword.trim();
        const trimmedConfirmPassword = passwordFormData.confirmPassword.trim();
        const minLength = 8;

        if (!trimmedCurrentPassword) {
            errorsPasswordForm.currentPassword = "Entrez votre mot de passe actuel";
        }

        if (!trimmedNewPassword) {
            errorsPasswordForm.newPassword = "Entrez un nouveau mot de passe";
        } else if (trimmedNewPassword.length < minLength) {
          errorsPasswordForm.newPassword = "Le mot de passe doit contenir au moins 8 caractères";
        }

        const hasUpperCase = /[A-Z]/.test(passwordFormData.newPassword);
        if (!hasUpperCase) {
            errorsPasswordForm.newPassword = 'Le mot de passe doit contenir au moins une lettre majuscule.';
        }
        
        if (!trimmedConfirmPassword) {
            errorsPasswordForm.confirmPassword = "La confirmation du nouveau mot de passe est requise";
        }

        // Autres validations...
        return errorsPasswordForm;
    };

    // Fonction de soumission de reinitialisation de mot de passe
    const handlePasswordSubmit  = async (e) => {
        e.preventDefault();

        const validationPasswordErrors = validatePasswordForm();
        if (Object.keys(validationPasswordErrors).length === 0) {
            // Soumission des données ou logique d'inscription
            setPasswordFormLoading(true); // Activer le chargement

            axiosClient.post('/request/user/manageAccount/updatePassword/secured-route', passwordFormData)
            .then(
                ({data}) => {
                    setPasswordSuccessMessage(data.message);
                    setPasswordFormLoading(false);
                    setTimeout(() => {
                        refreshData(data.user);
                        closeFormModal();
                    }, 4000);

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setPasswordValidationError("Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        if (response.data.errors) {
                            // Erreurs spécifiques aux champs
                            setPasswordErrors(response.data.errors);
                        }
                        if (response.data.message) {
                            // Erreur générale
                            setPasswordValidationError(response.data.message);
                        }
                    }
                    setPasswordFormLoading(false);
                    setTimeout(() => setPasswordValidationError(""), 7000);
                } else {
                    setPasswordValidationError("Erreur lors de la mise à jour du profil.");
                    setPasswordFormLoading(false);
                    setTimeout(() => setPasswordValidationError(""), 7000);
                }
            })
        }

        // Mettre à jour les erreurs après la validation
        setPasswordErrors(validationPasswordErrors);
    };

    const handleChangeMailAddress = async () => {
        setLoadingBtn(true);
        setErrorChangeAddress(null)
        setSuccessChangeAddress(null)

        try {
            const response = await axiosClient.get('/dashboard/user/manageAccount/change-email/request');
            // console.log(response.data);
            setSuccessChangeAddress(response.data.message);
            setTimeout(() => setSuccessChangeAddress(""), 7000);
        } catch (error) {
            setErrorChangeAddress("Erreur, veuillez s'il vous plait !");
            setTimeout(() => setErrorChangeAddress(""), 7000);
        } finally {
            setLoadingBtn(false);
        }
    };

    const isSubmitDisabled = () => {
        return loading;
    };

    const isPasswordFormSubmitDisabled = () => {
        return passwordFormloading;
    }

    const isChangeAddressSubmitDisabled = () => {
        return loadingBtn;
    }

    return (
        <div>
            <div className='relative flex justify-center'>
                {validationError && (
                    <div className="text-sm fixed z-10 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                        <div className="flex cm3rx ce4zk c6btv">
                            <div className="flex">
                            <svg
                                className="cbm9w czr3n cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                            </svg>
                            <div>{validationError}</div>
                            </div>
                            {/* <button className="ml-3 cmsx4 cq1qg cnyvm">
                            <div className="cn8jz">Close</div>
                            <svg className="cbm9w" width={16} height={16} viewBox="0 0 16 16">
                                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                            </svg>
                            </button> */}
                        </div>
                    </div>
                )}
                {successMessage && (
                    <div className="text-sm fixed z-10 cf8dr cje53 cb8zv clbq0 cuvgf">
                        <div className="flex cm3rx ce4zk c6btv">
                            <div className="flex">
                            <svg
                                className="c612e cbm9w cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                            </svg>
                            <div>
                            {successMessage}
                            </div>
                            </div>
                            {/* <button className="ml-3 cmsx4 cq1qg cnyvm">
                            <div className="cn8jz">Close</div>
                            <svg className="cbm9w" width={16} height={16} viewBox="0 0 16 16">
                                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                            </svg>
                            </button> */}
                        </div>
                    </div>
                )}
            </div>

            <form onSubmit={handleSubmit} className="profile-user">
                <div className="cmxzb c41yb">
                    <h2 className="text-gray-800 dark:text-gray-100 mb-2 font-bold cbtcb c6r0l">
                        Profil personnel & Entreprise
                    </h2>
                    <p className='!m-0'>Gérez vos informations personnelles et les paramètres de votre compte business.</p>
                    
                    <div className="cvwie cosgb c33r0 cgnhv cb8zv cm84d csusu">
                        <div className="cqdkw cq5bq cqb5u">
                            <svg
                            width={44}
                            height={42}
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                            <defs>
                                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ill-b">
                                <stop stopColor="#B7ACFF" offset="0%" />
                                <stop stopColor="#9C8CFF" offset="100%" />
                                </linearGradient>
                                <linearGradient x1="50%" y1="24.537%" x2="50%" y2="100%" id="ill-c">
                                <stop stopColor="#4634B1" offset="0%" />
                                <stop stopColor="#4634B1" stopOpacity={0} offset="100%" />
                                </linearGradient>
                                <path id="ill-a" d="m20 0 20 40-20-6.25L0 40z" />
                            </defs>
                            <g
                                transform="scale(-1 1) rotate(-51 -11.267 67.017)"
                                fill="none"
                                fillRule="evenodd"
                            >
                                <mask id="ill-d" fill="#fff">
                                <use xlinkHref="#ill-a" />
                                </mask>
                                <use fill="url(#ill-b)" xlinkHref="#ill-a" />
                                <path
                                fill="url(#ill-c)"
                                mask="url(#ill-d)"
                                d="M20.586-7.913h25v47.5h-25z"
                                />
                            </g>
                            </svg>
                        </div>
                        <div className="cm84d">
                            <div className="text-sm text-gray-800 ctv3j c1k3n c6f83">
                                Votre compte a été créé le <b>{profilData.created_at}</b>. <br />
                                Vous avez actuellement <b>{profilData.count_post}</b> événement(s) enregistré(s) sur notre plateforme.
                            </div>
                            <div className="chvik">
                                <Link className="text-sm text-blue-500 ceetm c1k3n" to={'/dashboard/events/registration'}>
                                    Ajouter un événement -&gt;
                                </Link>
                            </div>
                        </div>
                    </div>

                    <section>
                        <h3 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl">
                            Profil Personnel
                        </h3>
                        <div className="cwnq4 cmv64 cld0c cr78y cnlq0 cv2no">
                            <div className='w-full grid sm:grid-cols-2 coah6 c4sak'>
                                <div className="">
                                    <label className="block text-sm c1k3n cu6vl" htmlFor="full_name">
                                    Nom et prénom <span className='required'>*</span>
                                    </label>
                                    <input
                                    id="full_name"
                                    className="caqf9 c6btv focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    placeholder='Ex: ALLA Elysee'
                                    />
                                    {errors.name && (
                                        <small className="text-red-600 mt-2">
                                            {errors.name}
                                        </small>
                                    )}
                                </div>

                                <div className="">
                                    <label
                                    className="block text-sm c1k3n cu6vl"
                                    htmlFor="tel"
                                    >
                                    Numéro de téléphone <span className='required'>*</span>
                                    </label>
                                    <input
                                    id="tel"
                                    className="caqf9 c6btv focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    name='tel'
                                    value={formData.tel}
                                    onChange={handleChange}
                                    required
                                    placeholder='Ex: +225 0700001111'
                                    />
                                    {errors.tel && (
                                        <small className="text-red-600 mt-2">
                                            {errors.tel}
                                        </small>
                                    )}
                                </div>
                                <div className="">
                                    <label
                                    className="block text-sm c1k3n cu6vl"
                                    htmlFor="location"
                                    >
                                    Adresse de localisation
                                    </label>
                                    <input
                                    id="location"
                                    className="caqf9 c6btv focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    value={formData.location}
                                    onChange={handleChange}
                                    name='location'
                                    placeholder='Ex: Abidjan, Rue 2201 CI'
                                    />
                                    {errors.location && (
                                        <small className="text-red-600 mt-2">
                                            {errors.location}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Business Profile */}
                    <section>
                        <h3 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl">
                        Informations sur l'Entreprise
                        </h3>
                        <div className="text-sm">
                        Mettez à jour les informations de votre entreprise afin de refléter les données les plus récentes.
                        </div>
                        <div className="cwnq4 cmv64 cld0c cr78y cnlq0 cv2no">
                            <div className='w-full grid sm:grid-cols-2 coah6 c4sak'>
                                <div className="">
                                    <label className="block text-sm c1k3n cu6vl" htmlFor="name">
                                    Nom de l'organisation / entrerpise <span className='required'>*</span>
                                    </label>
                                    <input
                                    id="name"
                                    className="caqf9 c6btv focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    value={formData.organization_name}
                                    onChange={handleChange}
                                    required
                                    name='organization_name'
                                    placeholder='Ex: Events Global Manager'
                                    />
                                    {errors.organization_name && (
                                        <small className="text-red-600 mt-2">
                                            {errors.organization_name}
                                        </small>
                                    )}
                                </div>
                                <div className="">
                                    <label
                                    className="block text-sm c1k3n cu6vl"
                                    htmlFor="location_business"
                                    >
                                    Adresse de l'entreprise
                                    </label>
                                    <input
                                    id="location_business"
                                    className="caqf9 c6btv focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    value={formData.organization_adresse}
                                    onChange={handleChange}
                                    name='organization_adresse'
                                    placeholder="Ex: Rue dubois, Plateau Cote d'ivoire"
                                    />
                                    {errors.organization_adresse && (
                                        <small className="text-red-600 mt-2">
                                            {errors.organization_adresse}
                                        </small>
                                    )}
                                </div>
                                <div className="">
                                    <label
                                    className="block text-sm c1k3n cu6vl"
                                    htmlFor="link"
                                    >
                                    Site Web ou lien de page social
                                    </label>
                                    <input
                                    id="link"
                                    className="caqf9 c6btv focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    value={formData.organization_website}
                                    onChange={handleChange}
                                    name='organization_website'
                                    placeholder='Ex: https://app-events.com/'
                                    />
                                    {errors.organization_website && (
                                        <small className="text-red-600 mt-2">
                                            {errors.organization_website}
                                        </small>
                                    )}
                                </div>
                                <div className="">
                                    <label
                                    className="block text-sm c1k3n cu6vl"
                                    htmlFor="business_id"
                                    >
                                    Id compte business
                                    </label>
                                    <input
                                    id="business_id"
                                    className="caqf9 c6btv focus:border-gray-200 focus:ring-transparent"
                                    type="text"
                                    defaultValue={profilData.Organizer_id}
                                    disabled
                                    readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Email */}
                    <section>
                        <h3 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl">
                        E-mail
                        </h3>
                        <div className="text-sm">
                        Si vous modifiez votre adresse email, une vérification sera requise pour valider la nouvelle adresse. Tant que la vérification n'est pas effectuée, votre ancienne adresse restera active.
                        </div>
                        <div className="flex flex-wrap cv2no">
                            <div className="mr-2 w-2/3">
                                <label className="cn8jz" htmlFor="email">
                                    Adresse e-mail de connexion
                                </label>
                                <input
                                id="email"
                                disabled
                                readOnly
                                className="caqf9 c6btv focus:border-gray-200 focus:ring-transparent"
                                defaultValue={profilData.email}
                                />
                            </div>
                            <button onClick={handleChangeMailAddress} disabled={isChangeAddressSubmitDisabled()} type='button' className="btn border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                                {loadingBtn ? (
                                    <svg
                                        className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx={12}
                                            cy={12}
                                            r={10}
                                            stroke="currentColor"
                                            strokeWidth={3}
                                        />
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                ) : (
                                    <>Changer</>
                                )}
                            </button>
                        </div>
                        {successChangeAddress && (
                            <small className="text-green-500 mt-2">
                                {successChangeAddress}
                            </small>
                        )}
                        {errorChangeAddress && (
                            <small className="text-red-600 mt-2">
                                {errorChangeAddress}
                            </small>
                        )}
                    </section>
                    {/* Password */}
                    <section>
                        <h3 className="text-gray-800 dark:text-gray-100 font-bold ce5fk clctu cu6vl">
                            Mot de passe
                        </h3>
                        <div className="text-sm">
                        Pour garantir la sécurité de votre compte le mot de passe actuel est requis avant toute modification, Cette étape permet de sécuriser votre compte en s'assurant que vous êtes bien l'utilisateur actuel.
                        </div>
                        <div className="cv2no">
                        <button onClick={openFormModal} type='button' className="btn border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Changer le mot de passe
                        </button>
                        </div>
                    </section>
                </div>
                {/* Panel footer */}
                <footer>
                    <div className="flex border-gray-200 cghq3 cetff cr4kg cj8hp ckhro">
                        <div className="flex c4tdj">
                        <button type='button' onClick={refreshData} className="btn border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Annuler
                        </button>
                        <button type='submit' disabled={isSubmitDisabled()} className="btn bg-primary-600 items-center justify-center hover:bg-primary-700 text-white ml-3 cdj8c cg0jr ch8z9 cilvw cyn7a">
                            {loading ? (
                                <>
                                    <svg
                                        className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx={12}
                                            cy={12}
                                            r={10}
                                            stroke="currentColor"
                                            strokeWidth={3}
                                        />
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                    Enregistrement en cours...
                                </>
                            ) : (
                                <>Enregistrer les Modifications</>
                            )}
                        </button>
                        </div>
                    </div>
                </footer>
            </form>
            {/* Modal for change password */}
            {isFormModalOpen && (
                <div className='inset-0 z-10 w-screen overflow-y-auto fixed bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center' style={{
                    zIndex: 100,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    overflowX: "hidden",
                    overflowY: "auto",
                    outline: 0,
                    left: 0,
                    margin: 0,
                }}>

                    <div
                        id="feedback-modal"
                        className="flex items-center justify-center cxe43 cnbwt cini7 cjxg0 cys4p codu7 clbq0"
                        role="dialog"
                        aria-modal="true">
                        
                        {passwordValidationError && (
                            <div className="text-sm fixed mt-4 top-0 z-10 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                                <div className="flex cm3rx ce4zk c6btv">
                                    <div className="flex">
                                    <svg
                                        className="cbm9w czr3n cq1qg coqgc chfzq"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                                    </svg>
                                    <div>{passwordValidationError}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {passwordSuccessMessage && (
                            <div className="text-sm fixed mt-4 top-0 z-10 cf8dr cje53 cb8zv clbq0 cuvgf">
                                <div className="flex cm3rx ce4zk c6btv">
                                    <div className="flex">
                                    <svg
                                        className="c612e cbm9w cq1qg coqgc chfzq"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                                    </svg>
                                    <div>
                                    {passwordSuccessMessage}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="bg-white c2vpa co669 caufm cb8zv ccwri crwo8 c6btv">
                            <form onSubmit={handlePasswordSubmit} className='user-password-manage'>

                                {/* Modal header */}
                                <div className="border-gray-200 cghq3 ctv3r cx3hp c72q5">
                                    <div className="flex items-center cm3rx">
                                        <div className="text-gray-800 dark:text-gray-100 cgulq">
                                        Changer mon mot de passe
                                        </div>
                                        <button onClick={closeFormModal} className="c3e4j cg12x cmpw7 cdqku">
                                            <div className="cn8jz">Fermer</div>
                                            <svg className="cbm9w" width={16} height={16} viewBox="0 0 16 16">
                                                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                {/* Modal content */}
                                <div className="cx3hp cz8qb">
                                    <div className="cjav5">
                                        <div className='mb-8'>
                                            <label className="block text-sm c1k3n cu6vl" htmlFor="current_password">
                                                Mot de passe actuel <span className="czr3n">*</span>
                                            </label>
                                            <input
                                                id="current_password"
                                                className="caqf9 c6btv c9hxi cwn3v focus:border-gray-200 focus:ring-transparent"
                                                type="password"
                                                required
                                                name='currentPassword'
                                                value={passwordFormData.currentPassword}
                                                onChange={handleChangePasswordForm}
                                                placeholder="Entrez votre mot de passe actuel"
                                            />
                                            {passwordErrors.currentPassword && (
                                                <small className="text-red-600 mt-2">
                                                    {passwordErrors.currentPassword}
                                                </small>
                                            )}
                                        </div>
                                        <div className='!mb-8'>
                                            <label className="block text-sm c1k3n cu6vl" htmlFor="new_password">
                                                Nouveu mot de passe <span className="czr3n">*</span>
                                            </label>
                                            <input
                                                id="new_password"
                                                className="caqf9 c6btv c9hxi cwn3v focus:border-gray-200 focus:ring-transparent"
                                                type="password"
                                                required
                                                value={passwordFormData.newPassword}
                                                name='newPassword'
                                                onChange={handleChangePasswordForm}
                                                placeholder="Entrez le mot de passe que vous souhaitez"
                                            />
                                            {passwordErrors.newPassword && (
                                                <small className="text-red-600 mt-2">
                                                    {passwordErrors.newPassword}
                                                </small>
                                            )}
                                        </div>
                                        <div className='mb-8'>
                                            <label className="block text-sm c1k3n cu6vl" htmlFor="confirm_new_password">
                                                Répétez le nouveau mot de passe <span className="czr3n">*</span>
                                            </label>
                                            <input
                                                id="confirm_new_password"
                                                className="caqf9 c6btv c9hxi cwn3v focus:border-gray-200 focus:ring-transparent"
                                                type="password"
                                                required
                                                value={passwordFormData.confirmPassword}
                                                onChange={handleChangePasswordForm}
                                                name='confirmPassword'
                                                placeholder="Veuillez répétez le nouveau mot de passe"
                                            />
                                            {passwordErrors.confirmPassword && (
                                                <small className="text-red-600 mt-2">
                                                    {passwordErrors.confirmPassword}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Modal footer */}
                                <div className="border-gray-200 cghq3 cr4kg cx3hp cz8qb">
                                    <div className="flex flex-wrap justify-end ch3kz">
                                        <button onClick={closeFormModal} className="border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc cnf4p">
                                        Annuler
                                        </button>
                                        <button type='submit' disabled={isPasswordFormSubmitDisabled()} className="bg-primary-6000 cdj8c cg0jr ch8z9 cilvw cyn7a cnf4p hover:bg-primary-700">
                                            {passwordFormloading ? (
                                                <>
                                                    <svg
                                                        className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx={12}
                                                            cy={12}
                                                            r={10}
                                                            stroke="currentColor"
                                                            strokeWidth={3}
                                                        />
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        />
                                                    </svg>
                                                    Sauvegarde en cours...
                                                </>
                                            ) : (
                                                <>Sauvegarder</>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
