import React, { useEffect, useState } from 'react'
import FormModalToAddCard from '../../../components/Dashboard/FormModalToAddCard';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import axiosClient from '../../../components/api/axiosClient';
import FormattedDate from '../../../components/Utils/FormattedDate';
import FormModalEditCard from '../../../components/Dashboard/FormModalEditCard';
import ModalConfirmDeletedCardPayment from '../../../components/Dashboard/ModalConfirmDeletedCardPayment';
import { useError } from '../../../components/contexts/ErrorContext';

export default function OrganizerCards() {

    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [isFormToAddCardModalOpen, setIsFormToAddCardModalOpen] = useState(false);
    const [isFormToEditCardModalOpen, setIsFormToEditCardModalOpen] = useState(false);
    const [isModalDeleteCardOpen, setIsModalDeleteCardOpen] = useState(false);
    const [cards, setCards] = useState([]); 
    const [loading, setLoading] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [successMessage, setSuccessMessage] = useState();
    const { handleError } = useError();

    const fetchCards  = async () => {
        setLoading(true);
        try {
            const response = await axiosClient.get('/dashboard/user/payments/cards');
            const cardsData = response.data;
            setCards(cardsData);

            // Set the default card as selected
            const defaultCard = cardsData.find(card => card.is_default === 1);
            if (defaultCard) {
                setSelectedCard(defaultCard);
            }
        } catch (error) {
            handleError("Erreur lors du chargement des cartes:", error)
            console.error("Erreur lors du chargement des cartes:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCards();
    }, []);

    // Fonction pour gérer la sélection d'une carte
    const handleCardSelect = (card) => {
        setSelectedCard(card);
    };

    const statusLabels = {
        can_receive_pay: 'Peut recevoir des paiements',
        cant_receive_pay: 'Ne peut pas recevoir des paiements',
        en_examen: 'En examen'
    };

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Fonction pour ouvrir le modal
    const openInfoModal = () => {
        setIsInfoModalOpen(true);
    };

    // Fonction pour fermer le modal
    const closeFormModal = () => {
        setIsInfoModalOpen(false);
    };

    // Fonction pour ouvrir le modal
    const openFormToAddCardModal = () => {
        setIsFormToAddCardModalOpen(true);
    };

    // Fonction pour fermer le modal
    const closeFormToAddCardModal = () => {
        setIsFormToAddCardModalOpen(false);
    };

    // Fonction pour ouvrir le modal
    const openFormToEditCardModal = (card) => {
        setIsFormToEditCardModalOpen(true);
        setSelectedCard(card);
    };

    // Fonction pour fermer le modal
    const closeFormToEditCardModal = () => {
        setIsFormToEditCardModalOpen(false);
    };


    // Fonction pour ouvrir le modal
    const openDeleteCardModal = (card) => {
        setIsModalDeleteCardOpen(true);
        setSelectedCard(card);
    };

    // Fonction pour fermer le modal
    const closeDeleteCardModal = () => {
        setIsModalDeleteCardOpen(false);
    };

    const handleSuccess = (message) => {
        setSuccessMessage(message); // Afficher le message de succès
        setTimeout(() => setSuccessMessage(""), 5000);
        setIsFormToEditCardModalOpen(false); // Fermer le modal
        fetchCards()
    };

    return (
        <>
        <div className="relative flex w-full justify-center">

            {successMessage && (
                <div className="text-sm fixed mt-4 z-10 cf8dr cje53 cb8zv clbq0 cuvgf">
                    <div className="flex cm3rx ce4zk c6btv">
                        <div className="flex">
                            <svg
                                className="c612e cbm9w cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                            </svg>
                            <div>
                                {successMessage}
                            </div>  
                        </div>
                    </div>
                </div>
            )}
        </div>
            <div className="ccvqk sm:flex cjjo9">
                    
                {/* Content */}
                <div className="md:w-3/5 lg:w-full ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz">
                
                    {/* Page header */}
                    <div className="c2g1r cwnq4 cnlq0 c6r0l">
                        
                        {/* Left: Title */}
                        <div className="c2rn6 cdiog flex items-center">
                            <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                            Cartes de Paiement
                            </h1>
                            <button
                                className="flex items-center ml-2 justify-center rounded-full cukve cvdqj cw5z1 c76um cue4z cmwfi"
                                onClick={openInfoModal}
                                >
                                <span className="cn8jz">Info</span>
                                <svg
                                    className="cp14x ch0mp cbm9w"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M9 7.5a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0v-4ZM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                                    <path
                                    fillRule="evenodd"
                                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm6-8A6 6 0 1 1 2 8a6 6 0 0 1 12 0Z"
                                    />
                                </svg>
                            </button>

                        </div>
                        {/* Add card button */}
                        <button onClick={openFormToAddCardModal} className="btn bg-primary-600 hover:bg-primary-700 text-white cdj8c cg0jr ch8z9 cilvw cyn7a">
                            Ajouter une carte
                        </button>
                    </div>
                    {cards.length === 0 ? (
                        <div className="cweej">
                            <div className="cydwr clbq0">
                                <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                    <path
                                        className="text-gray-500 co1wq"
                                        d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="dark:text-gray-400 ckbo4"
                                        d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="cmpw7 cdqku"
                                        d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                        fill="currentColor"
                                    />
                                </svg>

                                </div>
                                {/* <h2 className="text-gray-800 dark:text-gray-100 font-bold cbtcb c6f83">
                                Pay your bills in just a few clicks
                                </h2> */}
                                <div className="mb-6">
                                    Aucune carte de paiement n'est disponible.
                                </div>
                                {/* <button className="btn bg-gray-900 cdj8c cg0jr ch8z9 cilvw cyn7a">
                                Add Event
                                </button> */}
                            </div>
                        </div>
                    
                    ) : (
                        <div className="cweej">
                            {cards.map((card) => (
                                <label key={card.id} onClick={() => handleCardSelect(card)} className="block c5flv c2hoo cm84d c6btv">
                                    <input
                                    type="radio"
                                    name="radio-buttons"
                                    className="cn8jz cp59z"
                                    defaultChecked=""
                                    // onChange={() => handleCardSelection(card.id)}
                                    />
                                    <div className="border border-gray-200 cc0oq cghq3 cspbm c2vpa cb8zv cxxol c5vqk clxb7">
                                        <div className="flex justify-between items-center c7nom czohk">
                                            {/* Card */}
                                            <div className="flex items-center ccrq2 c6z41 cehrd c5l47 cjac8 cm4ey c7xaj">
                                                <svg
                                                    className="coqgc"
                                                    width={32}
                                                    height={24}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <defs>
                                                    <linearGradient
                                                        x1="1.829%"
                                                        y1="100%"
                                                        x2="100%"
                                                        y2="2.925%"
                                                        id="c1-a"
                                                    >
                                                        <stop stopColor="#5bcfc5" offset="0%" />
                                                        <stop stopColor="#1F2937" offset="100%" />
                                                        <stop stopColor="#5bcfc5" offset="100%" />
                                                    </linearGradient>
                                                    </defs>
                                                    <g fill="none" fillRule="evenodd">
                                                    <rect fill="url(#c1-a)" width={32} height={24} rx={3} />
                                                    <ellipse
                                                        fill="#E61C24"
                                                        fillRule="nonzero"
                                                        cx="12.522"
                                                        cy={12}
                                                        rx="5.565"
                                                        ry="5.647"
                                                    />
                                                    <ellipse
                                                        fill="#F99F1B"
                                                        fillRule="nonzero"
                                                        cx="19.432"
                                                        cy={12}
                                                        rx="5.565"
                                                        ry="5.647"
                                                    />
                                                    <path
                                                        d="M15.977 7.578A5.667 5.667 0 0 0 13.867 12c0 1.724.777 3.353 2.11 4.422A5.667 5.667 0 0 0 18.087 12a5.667 5.667 0 0 0-2.11-4.422Z"
                                                        fill="#F26622"
                                                        fillRule="nonzero"
                                                    />
                                                    </g>
                                                </svg>
                                                <div>
                                                    <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                                        {card.bank_name}
                                                    </div>
                                                    <div className="c1iho">{card.account_number}</div>
                                                </div>
                                            </div>
                                            {/* Card status */}
                                            <div className="mr-2 cs2n8 c851m cehrd cjcps cjac8 chvik c8zuu">
                                            
                                                {card.is_default === 1 ? (
                                                    <div className="inline-flex rounded-full c1lu4 c19il cydwr c1k3n ch4gv c1iho cwn3v">
                                                        Active
                                                    </div>
                                                ): (
                                                    <div className="inline-flex rounded-full cfts0 c5px7 cydwr c1k3n ch4gv c1iho cwn3v">
                                                    Inactive
                                                    </div>
                                                )}
                                            </div>
                                                

                                        </div>
                                    </div>
                                    <div
                                    className="pointer-events-none c1bd4 cw723 c1xby cb8zv cqdkw ct7xr cini7"
                                    aria-hidden="true"
                                    />
                                </label>
                            ))}
                        </div>
                    )}
                </div>
                {/* Sidebar */}
                {selectedCard ? (
                    <div className='md:w-80 lg:w-auto'>
                        <div className="border-gray-200 cpfgt cghq3 cey82 c0zk3 cz8c3 ciumw c6nvv cng2d cb265 ccpww cu49b cgiaj c8du9 c9s5x czebi cr4kg ckxlc">
                        <div className="cs7xl clbq0 cxsfz">
                            <div className="ceu9r c91aq clu2m">
                            <div className="text-gray-800 dark:text-gray-100 cgulq cydwr cai6b">
                                {selectedCard.account_name}
                            </div>
                            {/* Credit Card */}
                            <div className="cz0zi cpome cxe43 card-events crn59 cimsy c1hly cm84d csusu">
                                <div className="flex cm3rx cm84d cetff cav8x">
                                {/* Logo on card */}
                                <svg
                                    width={32}
                                    height={32}
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                    <defs>
                                    <linearGradient
                                        x1="50%"
                                        y1="0%"
                                        x2="50%"
                                        y2="100%"
                                        id="icon1-b"
                                    >
                                        <stop stopColor="#5bcfc5" offset="0%" />
                                        <stop stopColor="#5bcfc5" offset="100%" />
                                    </linearGradient>
                                    <linearGradient
                                        x1="50%"
                                        y1="24.537%"
                                        x2="50%"
                                        y2="99.142%"
                                        id="icon1-c"
                                    >
                                        <stop stopColor="#374151" offset="0%" />
                                        <stop stopColor="#374151" stopOpacity={0} offset="100%" />
                                    </linearGradient>
                                    <path id="icon1-a" d="M16 0l16 32-16-5-16 5z" />
                                    </defs>
                                    <g transform="rotate(90 16 16)" fill="none" fillRule="evenodd">
                                    <mask id="icon1-d" fill="#fff">
                                        <use xlinkHref="#icon1-a" />
                                    </mask>
                                    <use fill="url(#icon1-b)" xlinkHref="#icon1-a" />
                                    <path
                                        fill="url(#icon1-c)"
                                        mask="url(#icon1-d)"
                                        d="M16-6h20v38H16z"
                                    />
                                    </g>
                                </svg>
                                {/* Card number */}
                                <div className="flex font-bold cm3rx ccqra c079l cy3ej c7x0x">
                                    <span>****</span>
                                    <span>****</span>
                                    <span>****</span>
                                    <span>{selectedCard.account_number.slice(-4)}</span>
                                </div>
                                {/* Card footer */}
                                <div className="flex items-center cm3rx cm84d cjxkd cvggx">
                                    {/* Card expiration */}
                                    <div className="text-sm font-bold ccqra c079l cy3ej cp3jk">
                                    <span>{selectedCard.bank_name}</span>
                                    {/* <span>CVC ***</span> */}
                                    </div>
                                </div>
                                {/* Mastercard logo */}
                                <svg
                                    className="cqdkw cq5bq cgky2"
                                    width={48}
                                    height={28}
                                    viewBox="0 0 48 28"
                                >
                                    <circle
                                    fill="#F0BB33"
                                    cx={34}
                                    cy={14}
                                    r={14}
                                    fillOpacity=".8"
                                    />
                                    <circle
                                    fill="#FF5656"
                                    cx={14}
                                    cy={14}
                                    r={14}
                                    fillOpacity=".8"
                                    />
                                </svg>
                                </div>
                            </div>
                            {/* Details */}
                            <div className="cgndh">
                                <div className="text-sm text-gray-800 dark:text-gray-100 cgulq cu6vl">
                                Détails
                                </div>
                                <ul>
                                {/*  */}
                                <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                    <div className="text-sm">Etat de la carte</div>
                                    <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n c8bkw">
                                    {statusLabels[selectedCard.status] || 'Statut inconnu'}
                                    </div>
                                </li>
                                <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                    <div className="text-sm">Fichier joint</div>
                                    <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n c8bkw">
                                        {selectedCard.rib_document === 1 ? (
                                            <div className='flex justify-center'>
                                                <>OUI</>
                                                <svg className="ml-2 cbm9w coqgc ci53t" width={14} height={14} viewBox="0 0 14 14">
                                                    <path d="M11.6 1.2a3.3 3.3 0 0 1 4.6 4.7L6.5 15.6a3.5 3.5 0 0 1-5-5L8.7 3.4a2.1 2.1 0 0 1 3 3L5.4 12.8c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L10.4 5a.5.5 0 0 0-.7-.7L3.3 10.7a2.5 2.5 0 0 0 3.5 3.5l9.6-9.6a2.5 2.5 0 0 0-3.5-3.5L5.7 8a3.5 3.5 0 1 0 5 5l7.2-7.2a.5.5 0 0 0-.7-.7L10.6 8.6c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l3.4-3.4z" />
                                                </svg>
                                            </div>
                                        ) : (
                                            <div className='flex justify-center'>
                                                <>NON</>
                                                <svg className="ml-2 cbm9w coqgc ci53t" width={14} height={14} viewBox="0 0 14 14">
                                                    <path d="M11.6 1.2a3.3 3.3 0 0 1 4.6 4.7L6.5 15.6a3.5 3.5 0 0 1-5-5L8.7 3.4a2.1 2.1 0 0 1 3 3L5.4 12.8c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L10.4 5a.5.5 0 0 0-.7-.7L3.3 10.7a2.5 2.5 0 0 0 3.5 3.5l9.6-9.6a2.5 2.5 0 0 0-3.5-3.5L5.7 8a3.5 3.5 0 1 0 5 5l7.2-7.2a.5.5 0 0 0-.7-.7L10.6 8.6c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l3.4-3.4z" />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                </li>
                                <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                    <div className="text-sm">Nom de la carte</div>
                                    <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n c8bkw">
                                    {selectedCard.account_name}
                                    </div>
                                </li>
                                <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                    <div className="text-sm">Nom de la banque</div>
                                    <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n c8bkw">
                                    {selectedCard.bank_name}
                                    </div>
                                </li>
                                <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                    <div className="text-sm">Date de création</div>
                                    <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n c8bkw">
                                    <FormattedDate date={selectedCard.created_at} />
                                    </div>
                                </li>
                                <li className="flex items-center border-gray-200 cghq3 cm3rx ctv3r c72q5">
                                    <div className="text-sm">Statut</div>
                                    <div className="flex items-center cq84g">
                                    {selectedCard.is_default === 1 ? (
                                        <>
                                            <div className="w-2 h-2 rounded-full bg-green-500 mr-2" />
                                            <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                                Active
                                            </div>
                                        </>
                                    ): (
                                        <>
                                            <div className="w-2 h-2 rounded-full bg-amber-500 mr-2" />
                                            <div className="text-sm text-gray-800 dark:text-gray-100 c1k3n">
                                                Inactive
                                            </div>
                                        </>
                                    )}
                                    </div>
                                    
                                </li>
                                </ul>
                            </div>
                            {/* Edit / Delete */}
                            <div className="flex items-center cp3jk cgndh">
                                <div className="c539s">
                                <button onClick={() => openFormToEditCardModal(selectedCard)} className="btn border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c6btv">
                                    <svg
                                    className="cmpw7 cdqku cbm9w coqgc"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    >
                                    <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                                    </svg>
                                    <span className="c8bkw">Modifier</span>
                                </button>
                                </div>
                                <div className="c539s">
                                    {selectedCard.is_default === 1 ? (
                                        <button className="btn border-gray-200 cursor-not-allowed opacity-50 cc0oq cghq3 cspbm czr3n c6btv">
                                            <svg
                                                className="cbm9w coqgc"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                            >
                                            <path d="M6 2V1c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v1h4c.6 0 1 .4 1 1s-.4 1-1 1h-1v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V4H1c-.6 0-1-.4-1-1s.4-1 1-1h4zm2 0H8v1h2V2H8zM5 6c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1V7c0-.6.4-1 1-1zm3 0c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1V7c0-.6.4-1 1-1zm3 0c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1V7c0-.6.4-1 1-1z" />
                                            </svg>
                                            <span className="c8bkw">Supprimer</span>
                                        </button>
                                    ) : (
                                        <button onClick={() => openDeleteCardModal(selectedCard)} className="btn border-gray-200 cc0oq cghq3 cspbm czr3n c6btv">
                                            <svg
                                                className="cbm9w coqgc"
                                                width={16}
                                                height={16}
                                                viewBox="0 0 16 16"
                                            >
                                            <path d="M6 2V1c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v1h4c.6 0 1 .4 1 1s-.4 1-1 1h-1v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V4H1c-.6 0-1-.4-1-1s.4-1 1-1h4zm2 0H8v1h2V2H8zM5 6c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1V7c0-.6.4-1 1-1zm3 0c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1V7c0-.6.4-1 1-1zm3 0c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1V7c0-.6.4-1 1-1z" />
                                            </svg>
                                            <span className="c8bkw">Supprimer</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                ): (
                    <div className="cweej">
                            <div className="cydwr clbq0">
                                <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                    <path
                                        className="text-gray-500 co1wq"
                                        d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="dark:text-gray-400 ckbo4"
                                        d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                        fill="currentColor"
                                    />
                                    <path
                                        className="cmpw7 cdqku"
                                        d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                        fill="currentColor"
                                    />
                                </svg>

                                </div>
                                <div className="mb-6">
                                    Aucune carte par défaut n'a été trouvée.
                                </div>
                            </div>
                        </div>
                )}

                {/* modal info */}
                {isInfoModalOpen && (
                    <div className='inset-0 z-10 w-screen overflow-y-auto fixed bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center' style={{
                        zIndex: 100,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        overflowX: "hidden",
                        overflowY: "auto",
                        outline: 0,
                        left: 0,
                        margin: 0,
                    }}>

                        <div
                            id="feedback-modal"
                            className="flex items-center justify-center cxe43 cnbwt cini7 cjxg0 cys4p codu7 clbq0"
                            role="dialog"
                            aria-modal="true">
                            <div className="bg-white c2vpa co669 caufm cb8zv ccwri crwo8 c6btv">
                                {/* Modal header */}
                                <div className="border-gray-200 cghq3 ctv3r cx3hp c72q5">
                                <div className="flex items-center cm3rx">
                                    <div className="text-gray-800 dark:text-gray-100 cgulq">
                                    Mes cartes de Paiement
                                    </div>
                                    <button onClick={closeFormModal} className="c3e4j cg12x cmpw7 cdqku">
                                        <div className="cn8jz">Fermer</div>
                                        <svg className="cbm9w" width={16} height={16} viewBox="0 0 16 16">
                                            <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                                        </svg>
                                    </button>
                                </div>
                                </div>
                                {/* Modal content */}
                                <div className="csusu">
                                    {/* Modal header */}
                                    <div className="c6f83">
                                        <div className="cxg65">
                                            <div className="btn-xs border-gray-200 text-gray-800 rounded-full cc0oq cghq3 cspbm c0zkc cpw2l ch4gv c1iho cwn3v">
                                            Restez informé
                                            </div>
                                        </div>
                                    </div>
                                    {/* Modal content */}
                                    <div className="text-sm c6r0l">
                                        <div className="cweej">
                                        <p>
                                        Gérez les cartes de paiement associées à vos événements pour faciliter les transactions financières. Cette section vous permet d'ajouter les coordonnées de paiement pour le règlement des factures et le versement des gains liés à vos événements. Il est essentiel de saisir des informations exactes et à jour afin de garantir un suivi précis et des paiements rapides.
                                        </p>
                                        <p>
                                            Les cartes ajoutées serviront de référence pour les transferts, 
                                            garantissant des transactions sécurisées et un suivi simplifié de vos recettes.
                                        </p>
                                        <p>
                                        Vous avez la possibilité de gérer les cartes enregistrées à tout moment en les ajoutant, en modifiant les informations ou en les désactivant si nécessaire. Vous pouvez activer ou désactiver une carte selon les besoins, assurant ainsi une flexibilité dans la gestion des paiements pour un meilleur contrôle de vos transactions.
                                        </p>
                                        </div>
                                    </div>
                                    {/* Modal footer */}
                                    <div className="flex flex-wrap justify-end ch3kz">
                                        <button onClick={closeFormModal} className="bg-primary-6000 hover:bg-primary-700 text-white cdj8c cg0jr ch8z9 cilvw cyn7a cnf4p">
                                        Cool, j'ai compris
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                )}
                {/* Modal to add card */}
                <FormModalToAddCard 
                    isFormToAddCardModalOpen={isFormToAddCardModalOpen}
                    closeModal={closeFormToAddCardModal}
                    refreshCards={fetchCards}
                />
                <FormModalEditCard
                    isFormToEditCardModalOpen={isFormToEditCardModalOpen}
                    closeModal={closeFormToEditCardModal}
                    refreshCards={fetchCards}
                    card={selectedCard}
                    onSuccess={handleSuccess}
                />

                <ModalConfirmDeletedCardPayment 
                    isModalDeleteCardOpen={isModalDeleteCardOpen}
                    closeModal={closeDeleteCardModal}
                    card={selectedCard}
                    onSuccess={handleSuccess}
                />
            </div>
        </>
    );
}
