import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadContentSpinner from '../../Utils/LoadContentSpinner';
import axiosClient from '../../api/axiosClient';
import { formatPrice } from '../../Utils/UtilsPricesFormat';
import FormattedDate from '../../Utils/FormattedDate';
import { formatNumber } from '../../Utils/UtilsNumbersFormat';

export default function EventsRecapOrdersComponent({ event }) {

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saleSummary, setSaleSummary] = useState(true);
    const [error, setError] = useState(null);
    
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    const fetchSalesSummary  = async () => {
        setError(null);   // Réinitialiser les erreurs
        // Appel API pour récupérer les événements en cours de validation
        try {
            const response = await axiosClient.get(`/dashboard/event/details/e/orders/summary/${event}`);
            setSaleSummary(response.data.data); // Données de l'événement
            // console.log(response.data.data); // Données de l'événement 
        } catch (error) {
            if (error.response && error.response.data) {
                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Une erreur inconnue est survenue. Veuillez réessayer plus tard.',});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    useEffect(() => {
        if (event) {
            fetchSalesSummary ();
        }
    }, [event]);

    
    if(!event) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Aucun événement trouvé, impossible d'accéder à cette page.
                    </h4>
                    <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                    Retour aux événements
                    </Link>
                </div>
            </div>
        )
    }

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Afficher une section dédiée en cas d'erreur
    if (error) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Erreur
                    </h4>
                    <div className="mb-6 text-sm">
                    {error.message}
                    </div>
                    {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                        <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Retour aux événements
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="h-full cqp75 cetff">
            {/* <h2 class="text-gray-800 dark:text-gray-100 cgulq c6f83">Récapitulatif des ventes</h2> */}
            <div className='c7nom ckn6o c4sak ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz'>
                <div className="flex bg-white relative border-gray-100 shadow-sm c2vpa ci500 cdjoa coz6m c1hly c5vqk cetff pb-4">
                    <div className="cx3hp cfkjc">
                        <header>
                        <h3 className="text-sm text-gray-500 cgulq c0ef0 cu6vl">
                            <span className="text-gray-800 dark:text-gray-100">Paiement</span> -
                            Réussi
                        </h3>
                        </header>
                        <div className="font-bold text-gray-800 dark:text-gray-100 cbtcb cu6vl">
                            {formatNumber(saleSummary.success)}
                        </div>
                        <span className='c612e cydwr absolute right-3 bottom-px text-sm font-semibold'>+{formatPrice(saleSummary.successAmount)}</span>
                    </div>
                </div>
                <div className="flex bg-white relative border-gray-100 shadow-sm c2vpa ci500 cdjoa coz6m c1hly c5vqk cetff pb-4">
                    <div className="cx3hp cfkjc">
                        <header>
                        <h3 className="text-sm text-gray-500 cgulq c0ef0 cu6vl">
                            <span className="text-gray-800 dark:text-gray-100">Paiement</span> -
                            En attente
                        </h3>
                        </header>
                        <div className="font-bold text-gray-800 dark:text-gray-100 cbtcb cu6vl">
                        {formatNumber(saleSummary.pending)}
                        </div>
                        <span className='csh63 cydwr absolute right-3 bottom-px text-sm font-semibold'>{formatPrice(saleSummary.pendingAmount)}</span>
                    </div>
                </div>
                <div className="flex bg-white relative border-gray-100 shadow-sm c2vpa ci500 cdjoa coz6m c1hly c5vqk cetff pb-4">
                    <div className="cx3hp cfkjc">
                        <header>
                        <h3 className="text-sm text-gray-500 cgulq c0ef0 cu6vl">
                            <span className="text-gray-800 dark:text-gray-100">Paiement</span> -
                            Echoué
                        </h3>
                        </header>
                        <div className="font-bold text-gray-800 dark:text-gray-100 cbtcb cu6vl">
                        {formatNumber(saleSummary.failed)}
                        </div>
                        <span className='cz4gk cydwr absolute right-3 bottom-px text-sm font-semibold'>-{formatPrice(saleSummary.failedAmount)}</span>
                    </div>
                </div>
                <div className="flex bg-white border-gray-100 shadow-sm c2vpa ci500 cdjoa coz6m c1hly c5vqk cetff pb-4">
                    <div className="cx3hp cfkjc">
                        <header>
                        <h3 className="text-sm text-gray-500 cgulq c0ef0 cu6vl">
                            <span className="text-gray-800 dark:text-gray-100">Total</span> -
                            Restant
                        </h3>
                        </header>
                        <div className="font-bold text-gray-800 dark:text-gray-100 cbtcb cu6vl">
                        {formatNumber(saleSummary.remaining)}
                        </div>
                    </div>
                </div>
            </div>

            <div className='c2g1r cwnq4 cnlq0 mb-8 mt-4'>
                <h2 class="text-gray-800 dark:text-gray-100 cgulq c6f83">10 derniers achats récents</h2>
                <div className="cm3b7 c51uw ccww4 csdex cbe1i c4sak">
                    {/* Dropdown */}
                    <div className="cm84d">
                        <button
                            className="btn bg-white border-gray-200 cc0oq c29yw cghq3 cspbm cqahh c0zkc c2vpa cm3rx c1ukq c45yg"
                            aria-haspopup="true"
                            aria-expanded={isOpen}
                            onClick={toggleDropdown}
                        >
                            <span className="flex items-center">
                                <svg
                                className="mr-2 cmpw7 cdqku cbm9w coqgc"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                >
                                <path d="M5 4a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H5Z" />
                                <path d="M4 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4ZM2 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4Z" />
                                </svg>
                                <span x-text="$refs.options.children[selected].children[1].innerHTML">
                                Plus d'action
                                </span>
                            </span>
                            <svg
                                className={`ml-2 transition-transform transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                                width={11}
                                height={7}
                                viewBox="0 0 11 7"
                            >
                                <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
                            </svg>
                        </button>
                        <div
                            className={`z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 transition-all ${
                                isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
                            }`} style={{ display: isOpen ? 'block' : 'none' }}
                            >
                            <div className="text-sm c0zkc c1ukq c1k3n" x-ref="options">
                                <Link
                                    to={`/dashboard/ventes/tickets-vendus/e/${event}`}
                                    className="flex items-center c35tg csd0k c5flv c6btv cb2br cwn3v"
                                >
                                    <span>Liste complète</span>
                                </Link>
                                <Link className="font-medium text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 flex py-1 px-3" to={`/dashboard/factures/events/invoices/show-details/i/${event}`}>
                                    Consulter mes Factures
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {saleSummary.recentBuyers.length > 0 ? (

                <div className="overflow-x-auto mb-8">
                    <table className="table-auto w-full dark:text-gray-300">
                        {/* Table header */}
                        <thead className="text-xs uppercase text-gray-400 dark:text-gray-500 bg-gray-50 dark:bg-gray-700 dark:bg-opacity-50 rounded-sm">
                            <tr>
                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                    <div className="cgulq c2hoo">Client</div>
                                </th>
                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                    <div className="cgulq c2hoo">Adresse</div>
                                </th>
                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                    <div className="cgulq c2hoo">Date</div>
                                </th>
                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                    <div className="cgulq c2hoo">Total</div>
                                </th>
                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                    <div className="cgulq c2hoo">Statut</div>
                                </th>
                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                    <div className="cgulq">Qté</div>
                                </th>
                                <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                    <div className="cgulq c2hoo">Payment type</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-sm font-medium divide-y divide-gray-100 dark:divide-gray-700/60">
                            {/* Row */}
                            {saleSummary.recentBuyers.map(data => (
                                <tr key={data.id}>
                                    <td className='cq84g cyjcc cgn91 c9hxi c72q5'>
                                        {!data.username ? (
                                            <>...</>
                                        ) : (
                                            <Link to={`/dashboard/achats/tickets/clients/profil/p/${data.id}`}>
                                                <div className="text-gray-800 dark:text-gray-100 c1k3n">{data.username}</div>
                                            </Link>
                                        )}
                                    </td>
                                    <td className='cq84g cyjcc cgn91 c9hxi c72q5'>
                                        {!data.adresse ? (
                                            <>...</>
                                        ) : (
                                            <div className="text-gray-800 dark:text-gray-100 c1k3n">{data.adresse}</div>
                                        )}
                                    </td>
                                    <td className='cq84g cyjcc cgn91 c9hxi c72q5'>
                                        {!data.date ? (
                                            <>...</>
                                        ) : (
                                            <div><FormattedDate date={data.date} /></div>
                                        )}
                                    </td>
                                    <td className='cq84g cyjcc cgn91 c9hxi c72q5'>
                                        {!data.price ? (
                                            <>...</>
                                        ) : (
                                            <div className="cdjj4 c1k3n c2hoo">{formatPrice(data.price)}</div>
                                        )}
                                    </td>
                                    <td className='cq84g cyjcc cgn91 c9hxi c72q5'>
                                        {!data.status ? (
                                            <>...</>
                                        ) : (
                                            data.status === 'ACCEPTED' ? (

                                                <div className="inline-flex rounded-full c1lu4 c19il cydwr c1k3n ch4gv cdw1w">
                                                    {data.status}
                                                </div>
                                            ) : data.status === 'REFUSED' ? (
                                                
                                                <div className='inline-flex rounded-full cx6qj cz4gk cydwr c1k3n ch4gv cdw1w'>
                                                    {data.status}
                                                </div>
                                                
                                            ) : (
                                                <div className="inline-flex rounded-full cfts0 c5px7 cydwr c1k3n ch4gv cdw1w">
                                                    {data.status}
                                                </div>
                                            )
                                        )}
                                    </td>
                                    <td className='cq84g cyjcc cgn91 c9hxi c72q5'>
                                        {!data.quantity ? (
                                            <>...</>
                                        ) : (
                                            <div className="cydwr">{formatNumber(data.quantity)}</div>
                                        )}
                                    </td>
                                    <td className='cq84g cyjcc cgn91 c9hxi c72q5'>
                                        {!data.paymentMethod ? (
                                            <>...</>
                                        ) : (
                                            <div className="flex items-center">
                                                {data.paymentMethod}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Rien à Afficher
                    </h4>
                    <div className="mb-6 text-sm">
                    Pas de données disponible pour les 10 derniers achats récents !
                    </div>
                </div>
            </div>
            )}
        </div>
    )
}
