import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import SignupForm from '../../../components/Authentication/SignupForm';

export default function IndexSignUp() {

    useEffect(() => {
        // Mettre à jour le titre de la page
        document.title = "Création de compte organisateur - Events";
        
    }, []);

    return (
        <>
            <div className="nc-PageLogin " data-nc-id="PageLogin">
                <div className="container mb-24 lg:mb-32">
                    <h2 className="my-10 mb-5 flex items-center text-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        Devenir organisateur Events
                    </h2>
                    <div className="relative text-center max-w-3xl mx-auto space-y-11">
                        <span className="relative inline-block px-4 text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                            Accédez à des outils performants pour publier et gérer vos événements en toute simplicité. Suivez vos réservations, consultez vos statistiques en temps réel, et profitez d'une gestion optimisée de vos événements.
                        </span>
                    </div>
                    <div className="max-w-3xl mx-auto space-y-6 mt-12">

                    <SignupForm />
                        
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        Déjà un compte?{' '} <Link to="/login" className='font-medium text-primary-6000'>Connectez-vous ici</Link>
                    </span>
                    </div>
                </div>
            </div>
        </>
    )
}
