import React, { useState } from 'react'
import { format, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import axiosClient from '../api/axiosClient';

export default function RecapFirstContentTab({ event, responseDataActivities, is_certified, onCertificationUpdate }) {

    // Fonction pour formater la date en fonction de la différence en secondes, minutes, heures et jours
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffSeconds = differenceInSeconds(now, date);
        const diffMinutes = differenceInMinutes(now, date);
        const diffHours = differenceInHours(now, date);
        const diffDays = differenceInDays(now, date);

        if (diffSeconds < 60) {
            return `Il y a environ ${diffSeconds} seconde${diffSeconds > 1 ? 's' : ''}`;
        } else if (diffMinutes < 60) {
            return `Il y a environ ${diffMinutes} minute${diffMinutes > 1 ? 's' : ''}`;
        } else if (diffHours < 24) {
            return `Il y a environ ${diffHours} heure${diffHours > 1 ? 's' : ''}`;
        } else if (diffDays <= 3) {
            return `Il y a environ ${diffDays} jour${diffDays > 1 ? 's' : ''}`;
        } else {
            return format(date, 'dd/MM/yyyy à HH:mm', { locale: fr });
        }
    };

    // Fonction pour afficher une activité en fonction de son statut
    // a detacher
    const renderActivity = (activity) => {
        const { status, created_at, validations_task } = activity;
        const formattedDate = formatDate(created_at);
        const taskName = validations_task.name;
        const taskDescription = validations_task.description;

        let iconClassName, bgColor, textColor, message;

        switch (status) {
            case 'success':
                iconClassName = 'las la-check';
                bgColor = 'bg-green-100';
                textColor = 'rgb(51 78 62 / 98%)';
                message = `Bravo ! "${taskName}" éffctué(e) avec succès.`;
                break;
            case 'failure':
                iconClassName = 'las la-info';
                bgColor = 'bg-red-100';
                textColor = 'rgb(122 75 75)';
                message = `Désolé ! "${taskName}" échoué(e).`;
                break;
            default:
                bgColor = 'dark:bg-neutral-800';
                textColor = 'text-neutral-700';
                message = `Votre "${taskName}" est à l'étape de validation. Merci de bien vouloir patienter.`;
                break;
        }

        return (
            <div key={activity.id} className="flex my-5 md:my-5 w-full">
                <div className="flex-shrink-0 flex flex-col items-center py-2">
                    <span className={`block w-6 h-6 rounded-full border border-neutral-400 ${iconClassName} leading-none text-2xl`} />
                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                </div>
                <div className="ml-4 space-y-10 text-sm w-full">
                    <div className={`flex flex-col space-y-1 rounded-lg ${bgColor} px-4 py-3`} style={{ color: textColor }}>
                        <span className="text-neutral-500 dark:text-neutral-400">
                            {formattedDate}
                        </span>
                        <span className="font-semibold">
                            {message}
                        </span>
                        <p>{taskDescription}</p>
                    </div>
                </div>
            </div>
        );
    };

    const [validationError, setValidationError] = useState('');
    const [successMessage, setSuccessMessage] = useState();
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const [loadingBtn, setLoadingBtn] = useState(false);

    const handleFileChange = async (e) => {
        const file = e.target.files[0]; // Récupérer le fichier sélectionné
        // if (!file) return; // Vérifier si aucun fichier n'est sélectionné
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                setValidationError('Le fichier doit être inférieur à 2 Mo.');
                setTimeout(() => setValidationError(null), 5000);
                return
            } else if (!['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
                setValidationError('Format de fichier non supporté. Seul le PDF est autorisé.');
                setTimeout(() => setValidationError(null), 5000);
                return
            } else {
                setValidationError({}); // Clear errors if valid
            }
        }
        setValidationError(null);

        setLoadingBtn(true);

        const formData = new FormData();
        formData.append('event_id', event.id);
        formData.append('file', file);

        // Envoyer le fichier via l'API
        axiosClient.post('/request/user/event-validation/certifications/upload', formData)
            .then(
                ({data}) => {
                    setSuccessMessage(data.message);
                    setLoadingBtn(false);
                    setTimeout(() => {
                        onCertificationUpdate(data.eventCertification);
                    }, 1000);
                    setTimeout(() => setSuccessMessage(""), 4000);

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier le fichier puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        // Erreur générale
                        setValidationError(response.data.message);
                    }
                    setLoadingBtn(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Erreur lors de l'envoi du fichier.");
                    setLoadingBtn(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
    };

    const isSubmitDisabled = () => {
        return loadingBtn;
    };

    return (
        <div className="max-w-4xl mx-auto nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden space-y-6">
            <div className='relative flex justify-center'>
                {validationError && (
                    <div className="text-sm absolute z-10 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                        <div className="flex cm3rx ce4zk c6btv">
                            <div className="flex">
                            <svg
                                className="cbm9w czr3n cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                            </svg>
                            <div>{validationError}</div>
                            </div>
                        </div>
                    </div>
                )}
                {successMessage && (
                    <div className="text-sm absolute z-10 cf8dr cje53 cb8zv clbq0 cuvgf">
                        <div className="flex cm3rx ce4zk c6btv">
                            <div className="flex">
                            <svg
                                className="c612e cbm9w cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                            </svg>
                            <div>
                            {successMessage}
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="flex flex-col space-y-1 rounded-lg text-gray-800 cvwie cosgb c33r0 cgnhv cb8zv cm84d dark:bg-neutral-800 px-4 py-3" >
                
                <span className="text-sm mb-3">
                Nous vérifions chaque détail avec soin pour assurer la qualité et la conformité de votre demande.
                </span>
                <div className="bg-white c2vpa c1hly c5vqk p-3">
                    {/* Body */}
                    <div className="cxg65">
                        {/* Title */}
                        <h2 className="text-gray-800 dark:text-gray-100 cgulq cu6vl">
                        Ajout d’une Attestation de Tenue d’Événement
                        </h2>
                        {/* Content */}
                        <div>
                        <div className="text-sm">
                            Pour garantir la validation et l'authenticité de votre événement, vous pouvez ajouter un document officiel certifiant sa tenue. Il peut s’agir d’une autorisation préfectorale, municipale, ou d’un permis/licence d’organisation.
                        </div>
                        </div>
                    </div>
                    {/* Footer */}
                    {/* <div className="flex items-center justify-between sm:flex-row flex-col"> */}
                        {/* Left side */}
                        {!is_certified && (
                            <label disabled={isSubmitDisabled()} className="rounded-full cursor-pointer sm:mb-0 float-start mb-2 flex items-center bg-white border border-gray-200 text-gray-500 p-1 text-sm cc0oq cghq3 cspbm c2vpa cxxol coqgc c27zx">
                                
                                {loadingBtn ? (
                                    <svg
                                        className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx={12}
                                            cy={12}
                                            r={10}
                                            stroke="currentColor"
                                            strokeWidth={3}
                                        />
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                ) : (
                                    <>
                                        <span className="text-xs text-gray-700 font-semibold mr-2">
                                            Ajouter une Attestation
                                        </span>
                                        
                                        <svg className="w-3 h-3 cbm9w" viewBox="0 0 12 12">
                                            <path d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 002 0V7h4a1 1 0 000-2z" />
                                        </svg>
                                        <input 
                                            className="cn8jz" 
                                            id="frontUpload"
                                            type="file"
                                            onChange={handleFileChange}
                                            accept=".jpg, .png, .pdf" 
                                            required
                                        />
                                    </>
                                )}
                            </label>
                        )}
                        {/* Right side */}
                        {is_certified && (
                            <div className="ml-3 float-end">
                                {is_certified.status === 'pending' ? (

                                    <span className="flex font-semibold items-center cghq3">
                                        <svg className="w-3 h-3 mr-2 cbm9w coqgc" viewBox="0 0 12 12">
                                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                        </svg>
                                        <div className="text-sm cmpw7">Certification envoyée</div>
                                    </span>
                                ) : is_certified.status === 'accepted' ? (

                                    <span className="flex font-semibold items-center cghq3">
                                        <svg className="w-3 h-3 mr-2 c612e cbm9w coqgc" viewBox="0 0 12 12">
                                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                        </svg>
                                        <div className="text-sm c612e cmpw7">Certification validée</div>
                                    </span>
                                ) : (

                                    <span className="flex font-semibold items-center cz4gk cghq3">
                                        <svg className="w-3 h-3 mr-2 cbm9w coqgc" viewBox="0 0 12 12">
                                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                        </svg>
                                        <div className="text-sm cmpw7">Certification rejetée</div>
                                    </span>
                                )}
                            
                            </div>
                        )}
                    {/* </div> */}
                    </div>

            </div>
            {/* ancien code a reutiliser */}
            <div >
                {/* <div className="flex-shrink-0 flex flex-col items-center py-2">
                    <span className="block w-6 h-6 rounded-full border border-neutral-400 las la-info text-2xl" />
                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                    <span className="block w-6 h-6 rounded-full border border-neutral-400" />
                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1" />
                    <span className="block w-6 h-6 rounded-full border border-neutral-400 las la-check text-2xl" />
                </div> */}
                
                {/* Affichez chaque activité */}
                {/* A detacher */}
                {responseDataActivities.map(activity => renderActivity(activity))}
                    
                {/* </div> */}
            </div>
        </div>
    )
}
