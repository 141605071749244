import React, { useEffect, useState } from 'react'
import DateSelect from '../../../components/Dashboard/DateSelect'
import axiosClient from '../../../components/api/axiosClient';
import { Link } from 'react-router-dom';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import FormattedDate from '../../../components/Utils/FormattedDate';
import { formatPrice } from '../../../components/Utils/UtilsPricesFormat';
import { formatNumber } from '../../../components/Utils/UtilsNumbersFormat';

export default function ReceivedOrdersEvents() {

    const [openDescriptions, setOpenDescriptions] = useState([]);
    const [sales, setSales] = useState([]); // Liste des acheteurs
    const [selectedDate, setSelectedDate] = useState(null); // Date sélectionnée
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchText, setSearchText] = useState(''); // État pour la recherche
    const [filteredSales, setFilteredSales] = useState([]); // Données filtrées

    const fetchSales = async (date) => {
        setError(null)
        setLoading(true)

        try {
            const response = await axiosClient.get(`/dashboard/events/orders/sales/global`, {
                params: {
                    date, // Envoyer la date comme paramètre
                },
            });
            setSales(response.data.data); // Mettre à jour la liste des acheteurs
            setFilteredSales(response.data.data); // Mettre à jour la liste des acheteurs
        } catch (error) {
            if (error.response && error.response.data) {

                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Erreur lors du chargement des ventes'});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    // Appeler l'API chaque fois que `selectedDate` change
    useEffect(() => {
        fetchSales(selectedDate);
    }, [selectedDate]);

    // Fonction pour gérer l'ouverture/fermeture de la description
    const toggleDescription = (index) => {
        setOpenDescriptions(openDescriptions.includes(index) ? [] : [index]);
    };

    // Gestion de la recherche
    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        // Filtrer les ventes par le texte de recherche
        const filtered = sales.filter((sale) =>
            sale.transaction_id.toLowerCase().includes(value) || // Exemple : filtrer par ID de facture
            sale.get_datas_to_order_customer.username.toLowerCase().includes(value) // Exemple : filtrer par nom du client
        );
        setFilteredSales(filtered);
    };

    return (
        <div className='h-full ch3yp cnbwt cs7xl clu2m c6btv clbq0 cxsfz'>
            {error ? (

                <div className="w-full h-full flex justify-center items-center cweej">
                    <div className="cydwr clbq0">
                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                            <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path
                                    className="text-gray-500 co1wq"
                                    d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                    fill="currentColor"
                                />
                                <path
                                    className="dark:text-gray-400 ckbo4"
                                    d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                    fill="currentColor"
                                />
                                <path
                                    className="cmpw7 cdqku"
                                    d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                        Erreur
                        </h4>
                        <div className="break-all mb-6 text-sm">
                        {error.message}
                        </div>
                        {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                            <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Retour aux événements
                            </Link>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <div className="c2g1r cwnq4 cnlq0 c6r0l">
                        {/* Left: Title */}
                        <div className="c2rn6 cdiog">
                            <h1 className="text-gray-800 dark:text-gray-100 font-bold c459m cbtcb">
                            Liste des Paiements Reçus <span className="cmpw7 cdqku c1k3n">{sales.length}</span>
                            </h1>
                            <span class="text-sm">Visualisez tous vos paiements enregistrés avec les details dépuis votre premier événement jusqu'au dernier</span>
                        </div>
                        
                        <div className='inline-flex cm84d'>
                            <DateSelect onDateChange={(date) => setSelectedDate(date)} />
                        </div>
                    </div>
                    {loading ? (
                        <div className='w-full flex justify-center items-center'>
                            <LoadContentSpinner />
                        </div>
                    ) : sales.length > 0 ? (
                        <div className="bg-white c2vpa c1hly c5vqk cgd7w">
                            <header className="flex items-center justify-between flex-col sm:flex-row cx3hp cz8qb">
                                <h2 className="text-gray-800 dark:text-gray-100 mb-2 sm:mb-0 cgulq">
                                Affichés <span className="cmpw7 cdqku c1k3n">{filteredSales.length}</span>
                                </h2>
                                <div className="cm84d">
                                    <label htmlFor="action-search" className="cn8jz">
                                        Search
                                    </label>
                                    <input
                                        id="action-search"
                                        className="bg-white border-gray-100 focus:border-gray-200 focus:ring-transparent pl-9 c2vpa caqf9 ct9oo"
                                        type="search"
                                        placeholder="Chercher un acheteur ou un ID de paiement"
                                        value={searchText}
                                        onChange={handleSearchChange}
                                    />
                                    <button onClick={(e) => e.preventDefault()} className="c29dn cqdkw cini7 cqogy" type="button" aria-label="Search">
                                        <svg
                                        className="ml-3 mr-2 cba8l c4it8 cmpw7 cdqku cbm9w coqgc"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                        </svg>
                                    </button>
                                </div>
                            </header>
                            <div>
                                {/* Table */}
                                <div className="cocyr">
                                <table className="ce8qq c0zkc ca8s8 cn9pt co0ms c6btv">
                                    {/* Table header */}
                                    <thead className="text-gray-500 dark:text-gray-400 cghq3 cib75 cbv37 cgk1f c0ef0 cr4kg c1iho">
                                        <tr>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                            <div className="cgulq c2hoo">ID du Paiement</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                            <div className="cgulq c2hoo">Date de Réception</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                            <div className="cgulq c2hoo">Méthode de Paiement</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                            <div className="cgulq c2hoo">Prix</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                            <div className="cgulq c2hoo">Statut</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                            <span className="cn8jz">Menu</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    {/* Table body */}
                                    <tbody className="text-sm">
                                        {/* Row */}
                                        {filteredSales.map((sale, index) => (
                                            <React.Fragment key={index}>

                                                <tr>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!sale.transaction_id ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="flex items-center text-gray-800">
                                                                <div className="c740r c1k3n">#{sale.transaction_id.slice(-6)}</div>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!sale.date_payment ? (
                                                            <>...</>
                                                        ) : (
                                                            <div><FormattedDate date={sale.date_payment} /> </div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!sale.payment_method ? (
                                                            <>...</>
                                                        ) : (

                                                            <div className="text-gray-800 dark:text-gray-100 c1k3n">
                                                            {sale.payment_method}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!sale.total_price ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="cdjj4 c1k3n c2hoo">{formatPrice(sale.total_price)}</div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!sale.status ? (
                                                            <>...</>
                                                        ) : (
                                                            sale.status === 'ACCEPTED' ? (

                                                                <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n ch4gv cdw1w">
                                                                    {sale.status}
                                                                </div>
                                                            ) : sale.status === 'REFUSED' ? (
                                                                
                                                                <div className='inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n ch4gv cdw1w'>
                                                                    {sale.status}
                                                                </div>
                                                                
                                                            ) : (
                                                                <div className="inline-flex text-xs rounded-full cfts0 c5px7 cydwr c1k3n ch4gv cdw1w">
                                                                    {sale.status}
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 cn8zk c9hxi c72q5">
                                                        <div className="flex items-center">
                                                            <button
                                                                className="bg-white border-2 border-gray-50 rounded-full text-gray-900 font-bold c3e4j cg12x cmpw7 cdqku c98dn"
                                                                aria-expanded={openDescriptions.includes(index) ? "true" : "false"}
                                                                onClick={() => toggleDescription(index)}
                                                            >
                                                                <span className="cn8jz">Menu</span>
                                                                <svg className="cbm9w cue4z cmwfi" viewBox="0 0 32 32">
                                                                    <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {openDescriptions.includes(index) && (
                                                    <tr id="description-01">
                                                        <td colSpan={10} className="cyjcc cgn91 c9hxi c72q5">
                                                        <div className="bg-sidebar-linkGroup rounded-t-md flex items-center justify-between flex-wrap -m-1 dark:text-gray-400 coulr c1f2y c9j8s">
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                    {!sale.pivot_to_order.findthislabel.title ? (
                                                                        <>...</>
                                                                    ) : (
                                                                        sale.pivot_to_order.findthislabel.title
                                                                    )}
                                                                </h3>
                                                                <div>Type de Ticket</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">·</div>
                                                            <div className="text-sm mr-4">
                                                                {!sale.quantity ? (
                                                                    <>...</>
                                                                ) : (
                                                                    <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">{formatNumber(sale.quantity)}</h3>
                                                                )}
                                                                
                                                                <div>Quantité</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">·</div>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                    {!sale.get_datas_to_order_customer.adresse ? (
                                                                        <>...</>
                                                                    ) : (
                                                                        sale.get_datas_to_order_customer.adresse
                                                                    )}
                                                                </h3>
                                                                <div>Adresse</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">·</div>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                    {!sale.get_datas_to_order_customer.username ? (
                                                                        <>...</>
                                                                    ) : (
                                                                        <Link to={`/dashboard/achats/tickets/clients/profil/p/${sale.get_datas_to_order_customer.id}`}>
                                                                            {sale.get_datas_to_order_customer.username}
                                                                        </Link>
                                                                    )}
                                                                </h3>
                                                                <div>Acheteur</div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className='bg-sidebar-linkGroup rounded-b-md flex items-center justify-between flex-wrap -m-1 border-t pt-4 border-gray-200 dark:text-gray-400 coulr c1f2y c9j8s'>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                    {!sale.get_datas_to_order_event.event_title ? (
                                                                        <>...</>
                                                                    ) : (
                                                                        <Link to={`/dashboard/events/view-details/to/${sale.get_datas_to_order_event.id}`}>
                                                                            {sale.get_datas_to_order_event.event_title}
                                                                        </Link>
                                                                    )}
                                                                </h3>
                                                                <div>Evénement</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">·</div>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                    {!sale.get_datas_to_order_event.event_code ? (
                                                                        <>...</>
                                                                    ) : (
                                                                        sale.get_datas_to_order_event.event_code
                                                                    )}
                                                                </h3>
                                                                <div>Code</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">.</div>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                    {!sale.get_datas_to_order_event.created_at ? (
                                                                        <>...</>
                                                                    ) : (

                                                                        <FormattedDate date={sale.get_datas_to_order_event.created_at} />
                                                                    )}
                                                                </h3>
                                                                <div>Date d'enregistrement</div>
                                                            </div>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    
                    ) : (
                        <div className="w-full flex justify-center items-center cweej">
                            <div className="w-3/4 cydwr clbq0">
                                <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                    <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                        <path
                                            className="text-gray-500 co1wq"
                                            d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="dark:text-gray-400 ckbo4"
                                            d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="cmpw7 cdqku"
                                            d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                            fill="currentColor"
                                        />
                                    </svg>

                                </div>
                                <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                Aucune données trouvées.
                                </h4>
                                <div className="mb-6 text-sm">
                                {'Aucune vente trouvée pour cette période. Vos événements peuvent ne pas avoir reçu de vente a cette période.'}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
