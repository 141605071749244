import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import validator from 'validator';

export default function ForgotPasswordForm() {

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const validateForm = () => {
        const newErrors = {};
        
        const trimmedEmail = email.trim();
        if (!trimmedEmail) {
            newErrors.email = "Ce champ est obligatoire";
        }
        if (!validator.isEmail(trimmedEmail)) {
            newErrors.email = "L'adresse e-mail n'est pas valide";
        }
                    
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            // Soumission des données ou logique d'inscription
            setLoading(true);
        
            
            // Logique d'envoi de l'e-mail de réinitialisation
            axiosClient.post('/request/user/password/forgot', {email: email})
            .then(({ data }) => {
                if (data.status === 200 && data.authorized_to_verify_code) {

                    // Rediriger vers le tableau de bord ou la page de connexion après la vérification
                    navigate(`/verify-auth-code`, { 
                        state: { email: email, action: 'password_reset' } // Passer l'email à la page suivante
                    });
                } else {

                    setValidationError("Une erreur est survenue, veuillez réessayer.");
                    setTimeout(() => setValidationError(""), 7000);
                }
                setLoading(false);
                
            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier votre email puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        setValidationError(response.data.errors);
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Une erreur est survenue, veuillez réessayer.");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
        }

        // Mettre à jour les erreurs après la validation 
        setErrors(validationErrors);
    };

    const isSubmitDisabled = () => {
        return loading || !email;
    };

    return (
        <>
            <form className="grid grid-cols-1 gap-6">
                <label htmlFor='email' className="block">
                    <span  className="text-neutral-800 dark:text-neutral-200 text-sm">
                        Adresse e-mail <span className="text-red-600">*</span>
                    </span>
                    <input
                        type="email"
                        className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                        placeholder="example@example.com"
                        id="email"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                    {errors.email && (
                        <small className="text-red-600 mt-2">
                            {errors.email}
                        </small>
                    )}
                </label>
                <div className='inline-flex items-center justify-center flex-col'>
                    {validationError && (
                        <div
                            className="px-2.5 py-1 p-3 mt-3 mb-6 flex items-center justify-center nc-Badge rounded-full font-medium text-xs text-red-800 bg-red-100"
                            style={{width: "fit-content"}}
                        >
                            <span>
                                <svg
                                viewBox="0 0 24 24"
                                width={20}
                                height={20}
                                stroke="currentColor"
                                strokeWidth={2}
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="me-2"
                                >
                                <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                                <line x1={15} y1={9} x2={9} y2={15} />
                                <line x1={9} y1={9} x2={15} y2={15} />
                                </svg>
                            </span>
                            <span className="text-sm ml-3 mr-3">{validationError}</span>
                        </div>
                    )}
                    <button
                    className="nc-Button inline-flex items-center w-full justify-center relative h-auto rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none dark:focus:ring-offset-0"
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled()}
                    >
                        {loading ? (
                            <>
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx={12}
                                        cy={12}
                                        r={10}
                                        stroke="currentColor"
                                        strokeWidth={3}
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                                Veuillez patienter...
                            </>
                        ) : (
                            <>Envoyer le code de réinitialisation</>
                        )}
                    </button>
                </div>
            </form>
        </>
    )
}
