import React, { useEffect, useState } from 'react'
import axiosClient from '../api/axiosClient';

export default function FormModalEditCard({ isFormToEditCardModalOpen, closeModal, refreshCards, card, onSuccess }) {

    // Définir les champs du formulaire avec useState
    const [cardName, setCardName] = useState("");
    const [agency, setAgency] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [useForPayments, setUseForPayments] = useState(false);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const [errors, setErrors] = useState({});
    const [validationError, setValidationError] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState();

    // Mettre à jour les valeurs du formulaire avec les informations de la carte sélectionnée
    useEffect(() => {
        if (card) {
            setCardName(card.account_name || "");  // Assurez-vous de gérer les valeurs nulles
            setAgency(card.bank_name || "");
            setCardNumber(card.account_number || "");
            setUseForPayments(card.is_default ? 1 : 0)
        }
    }, [card]);

    // Réinitialiser le formulaire lorsque le modal se ferme
    useEffect(() => {
        if (!isFormToEditCardModalOpen) {
            resetForm();
        }
    }, [isFormToEditCardModalOpen]);

    // Fonction pour réinitialiser le formulaire
    const resetForm = () => {
        setErrors({});
        setFile(null);
        setFileError("");
        setUseForPayments()
        setValidationError('');
        setSuccessMessage('');
    };

    // Fonction pour gérer la sélection de fichier et vérifier la taille
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 2 * 1024 * 1024) { // 2 Mo = 2 * 1024 * 1024 octets
                setFileError("La taille du fichier ne doit pas dépasser 2 Mo.");
                setFile(null); // Réinitialiser le fichier sélectionné
            } else if (!['image/jpeg', 'image/png', 'application/pdf'].includes(selectedFile.type)) {
                setFileError("Format de fichier non supporté. Seuls JPG, PNG et PDF sont autorisés.");
                setFile(null);
            } else {
                setFile(selectedFile);
                setFileError("");
            }
        }
    };

    // Fonction de validation
    const validateFields = () => {

        const validationErrors = {};
        const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]{3,}$/; // Lettres uniquement, minimum 3 caractères

        const TrimedCardName = cardName.trim();
        if (!TrimedCardName) {
            validationErrors.cardName = 'Le nom de la carte est requis';
        } else if (!nameRegex.test(TrimedCardName)) {
            validationErrors.cardName = 'Le nom de la carte doit contenir au moins 3 lettres sans caractères spéciaux';
        }

        const TrimedAgency = agency.trim();
        if (!TrimedAgency) {
            validationErrors.agency = "L'agence est requise";
        } else if (!nameRegex.test(TrimedAgency)) {
            validationErrors.agency = "L'agence doit contenir au moins 3 lettres sans caractères spéciaux";
        }

        return validationErrors;
    };

    // Fonction de soumission pour sauvegarder les changements
    const handleSubmit = (e) => {
        e.preventDefault();

        if (fileError) return;

        const validationErrors = validateFields();

        setErrors(validationErrors);

        // Si des erreurs existent, on arrête l'exécution
        if (Object.keys(validationErrors).length > 0) return;

        // Création des données de formulaire
        const formData = new FormData();
        formData.append('cardName', cardName);
        formData.append('agency', agency);
        formData.append('useForPayments', useForPayments ? 1 : 0);
        if (file) formData.append('file', file);

        setLoading(true);
        // Logique pour sauvegarder les modifications (par exemple, appeler une API avec les nouvelles données)
        axiosClient.post('/request/user/manageAccount/payement/card/edit/c' + card.id, formData)
            .then(
                ({data}) => {
                    onSuccess(data.message);
                    setLoading(false);
                    closeModal();
                    refreshCards();

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        if (response.data.errors) {
                            // Erreurs spécifiques aux champs
                            setErrors(response.data.errors);
                        }
                        if (response.data.message) {
                            // Erreur générale
                            setValidationError(response.data.message);
                        }
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Erreur lors de lors de l'ajout de la carte.");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
    };

    const isSubmitDisabled = () => {
        return loading;
    };
    
    return (
        isFormToEditCardModalOpen && (
            <div className='inset-0 z-10 w-screen overflow-y-auto fixed bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center' style={{
                zIndex: 100,
                top: 0,
                width: "100%",
                height: "100%",
                overflowX: "hidden",
                overflowY: "auto",
                outline: 0,
                left: 0,
                margin: 0,
            }}>
                
                <div
                    id="feedback-modal"
                    className="flex items-center justify-center cxe43 cnbwt cini7 cjxg0 cys4p codu7 clbq0"
                    role="dialog"
                    aria-modal="true">
                    {validationError && (
                        <div className="text-sm fixed mt-4 top-0 z-10 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                            <div className="flex cm3rx ce4zk c6btv">
                                <div className="flex">
                                <svg
                                    className="cbm9w czr3n cq1qg coqgc chfzq"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                                </svg>
                                <div>{validationError}</div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="bg-white c2vpa co669 caufm cb8zv ccwri crwo8 c6btv">
                        {/* Modal header */}
                        <div className="border-gray-200 cghq3 ctv3r cx3hp c72q5">
                        <div className="flex items-center cm3rx">
                            <div className="text-gray-800 dark:text-gray-100 cgulq">
                            Modifier une Carte de Paiement
                            </div>
                            <button onClick={closeModal} className="c3e4j cg12x cmpw7 cdqku">
                                <div className="cn8jz">Fermer</div>
                                <svg className="cbm9w" width={16} height={16} viewBox="0 0 16 16">
                                    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                                </svg>
                            </button>
                        </div>
                        </div>
                        <form onSubmit={handleSubmit} className='add-payement-informations'>
                            {/* Modal content */}
                            <div className="cx3hp cz8qb">
                                <div className="cjav5">
                                    <div className='mb-6'>
                                        <label className="block text-sm c1k3n cu6vl" htmlFor="cardName">
                                            Nom de la carte <span className="czr3n">*</span>
                                        </label>
                                        <input
                                            id="cardName"
                                            className="caqf9 c6btv c9hxi cwn3v focus:border-gray-200 focus:ring-transparent"
                                            type="text"
                                            required
                                            value={cardName}
                                            onChange={(e) => setCardName(e.target.value)}
                                            placeholder="Ajouter un nom à votre carte"
                                        />
                                        {errors.cardName && (
                                            <small className="text-red-600 mt-2">
                                                {errors.cardName}
                                            </small>
                                        )}
                                    </div>
                                    <div className='!mb-6'>
                                        <label className="block text-sm c1k3n cu6vl" htmlFor="cardNumber">
                                        Numéro de compte(RIB) <span className="czr3n">*</span>
                                        </label>
                                        <input
                                            id="cardNumber"
                                            className="caqf9 select-none cursor-not-allowed !bg-gray-200 c6btv c9hxi cwn3v focus:border-gray-200 focus:ring-transparent"
                                            type="text"
                                            required
                                            value={cardNumber}
                                            // onChange={(e) => setCardNumber(e.target.value)}
                                            placeholder="Entrez votre RIB ici"
                                            readOnly
                                            disabled
                                        />
                                        {/* {errors.cardNumber && (
                                            <small className="text-red-600 mt-2">
                                                {errors.cardNumber}
                                            </small>
                                        )} */}
                                    </div>
                                    <div className='!mb-6'>
                                        <label className="block text-sm c1k3n cu6vl" htmlFor="agency">
                                            Agence <span className="czr3n">*</span>
                                        </label>
                                        <input
                                            id="agency"
                                            className="caqf9 c6btv c9hxi cwn3v focus:border-gray-200 focus:ring-transparent"
                                            type="text"
                                            required
                                            value={agency}
                                            onChange={(e) => setAgency(e.target.value)}
                                            placeholder="Entrer le nom de la banque"
                                        />
                                        {errors.agency && (
                                            <small className="text-red-600 mt-2">
                                                {errors.agency}
                                            </small>
                                        )}
                                    </div>
                                    {useForPayments === 1 ? (
                                        <div className='!mb-6 c1hs5 rounded-lg px-4 py-2 text-sm cf8dr'>
                                            <label className="flex items-center">
                                                <input type="checkbox" className="crgcy" checked={useForPayments} 
                                                    value={useForPayments}
                                                    // onChange={(e) => setUseForPayments(e.target.checked)}
                                                />
                                                <span className="text-sm c8bkw">Cette carte est utilisée pour les paiements de vos recettes. Pour la désactiver veuillez sélectionner une autre carte et la modifier en lui attribuant l'option par défaut de paiement.</span>
                                            </label>
                                        </div>
                                    ) : (
                                        <div className='!mb-6'>
                                            <label className="flex items-center">
                                                <input type="checkbox" className="crgcy" checked={useForPayments} 
                                                    value={useForPayments}
                                                    onChange={(e) => setUseForPayments(e.target.checked)}
                                                />
                                                <span className="text-sm c8bkw">Utiliser cette carte maintenant pour les paiements</span>
                                            </label>
                                        </div>
                                    )}
        
                                    <div className='text-sm text-gray-800 dark:text-gray-100 cgulq c6f83'>Vous pouvez joindre un fichier</div>
                                    <div className="cgndh flex justify-center">
                                        <div className="h-16 rounded border cghq3 w-full p-px flex items-center justify-center flex-col mb-4 c993f c4ppg cxxjf cyhlg cydwr cx3hp cxsfz">
                                            <svg
                                            className="inline-flex m-0 ca2tk cyq9w cxg65"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path d="M8 4c-.3 0-.5.1-.7.3L1.6 10 3 11.4l4-4V16h2V7.4l4 4 1.4-1.4-5.7-5.7C8.5 4.1 8.3 4 8 4ZM1 2h14V0H1v2Z" />
                                            </svg>
                                            <label
                                                htmlFor="upload"
                                                className="block text-sm text-gray-500 dark:text-gray-400 caf78 cursor-pointer"
                                                style={{fontSize: '0.85rem'}}
                                            >
                                            Télécharger un fichier de mon RIB PDF, JPEG, PNG.
                                            </label>
                                            <input className="cn8jz" id="upload" type="file" accept='.jpg, .png, .pdf' onChange={handleFileChange} />
                                            {fileError && (
                                                <small className="text-red-600 mt-2">
                                                    {fileError}   
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Modal footer */}
                            <div className="border-gray-200 cghq3 cr4kg cx3hp cz8qb">
                            <div className="flex flex-wrap justify-end ch3kz">
                                <button onClick={closeModal} className="border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc cnf4p">
                                Annuler
                                </button>
                                <button type='submit' disabled={isSubmitDisabled()} className="bg-primary-6000 cdj8c cg0jr ch8z9 cilvw cyn7a cnf4p hover:bg-primary-700">
                                    {loading ? (
                                        <>
                                            <svg
                                                className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx={12}
                                                    cy={12}
                                                    r={10}
                                                    stroke="currentColor"
                                                    strokeWidth={3}
                                                />
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                />
                                            </svg>
                                            Un instant...
                                        </>
                                    ) : (
                                        <>Ajouter la carte</>
                                    )}
                                </button>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    )  
}
