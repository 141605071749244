import React from 'react';

export default function FormattedDate({ date }) {
    if (!date) return null;

    const dateObj = new Date(date);

    // Options pour formater la date
    const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };

    // Formater la date en utilisant Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(dateObj);

    // Remplacer la virgule par un point pour correspondre au format demandé
    return <span>{formattedDate.replace(',', ' .')}</span>;
}
