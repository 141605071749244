import React from 'react'

export default function LoadContentSpinner() {
  // h-screen
  return (
    <div className="flex justify-center items-center h-full w-full relative">
        <div className="relative w-[55px] h-[55px]">
          <div className="absolute w-full h-full rounded-full border-[3px] border-transparent border-l-[#5bcfc5] animate-rotate"></div>
          <div className="absolute w-full h-full rounded-full border-[3px] border-transparent border-l-[#5bcfc5] animate-rotateOpacity delay-[0.1s]"></div>
          <div className="absolute w-full h-full rounded-full border-[3px] border-transparent border-l-[#5bcfc5] animate-rotateOpacity delay-[0.2s]"></div>
        </div>
      </div>
  )
}
