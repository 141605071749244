import { createContext, useContext, useEffect, useState } from "react";
import axiosClient from "../api/axiosClient";

const stateContext = createContext({
    loading: true,
    user: null,
    token: null,
    setUser: () => {},
    setToken: () => {},
    fetchUserProfile: () => {},
});

export const ContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));
    const [loading, setLoading] = useState(true);

    const setToken = (token) => {
        _setToken(token);
        if (token) {
            localStorage.setItem('ACCESS_TOKEN', token);
        } else{
            localStorage.removeItem('ACCESS_TOKEN');
        }
    }

    // Fonction pour récupérer le profil utilisateur depuis l'API
    const fetchUserProfile = async () => {
        if (!token) {
            setLoading(false);
            return;
        }

        try {
            const { data } = await axiosClient.get('/user');

            // Vérification du statut de l'utilisateur
            if (data.status_email_confirmation === 0 || data.status_account === 0) {
                // console.warn("Utilisateur désactivé. Déconnexion automatique.");
                setToken(null); // Supprime le token
                setUser(null); // Réinitialise l'utilisateur
                return; // Arrête l'exécution
            }

            setUser(data); // Définit l'utilisateur sur les données récupérées
        } catch (error) {
            console.error("Erreur lors de la récupération du profil utilisateur :", error);
            setToken(null); // Réinitialise le token en cas d'erreur
            setUser(null); // Réinitialise l'utilisateur
        } finally {
            setLoading(false); // Définit le chargement sur faux une fois terminé
        }
    };

    // Utiliser useEffect pour récupérer les informations utilisateur au chargement si le token est disponible
    useEffect(() => {
        if (token) {
            fetchUserProfile();
        } else {
            setLoading(false);
        }
    }, [token]);

    return (
        <stateContext.Provider value={{
            loading,
            user,
            token,
            setUser,
            setToken,
            fetchUserProfile,
        }}>
            {children}
        </stateContext.Provider>
    )
    
}

export const useStateContext = () => useContext(stateContext);