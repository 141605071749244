import React, { useEffect, useState } from 'react'
import DateSelect from '../DateSelect'
import axiosClient from '../../api/axiosClient';
import { Link } from 'react-router-dom';
import LoadContentSpinner from '../../Utils/LoadContentSpinner';
import FormattedDate from '../../Utils/FormattedDate';
import { formatPrice } from '../../Utils/UtilsPricesFormat';
import { formatNumber } from '../../Utils/UtilsNumbersFormat';

// Définition des statuts personnalisés
const STATUSES = {
    ACCEPTED: {
        label: 'Acceptés',
    },
    PENDING: {
        label: 'En attente',
    },
    REFUSED: {
        label: 'Échoués',
    },
};

export default function ListBuyer({ event }) {

    const [openDescriptions, setOpenDescriptions] = useState([]);
    const [buyers, setBuyers] = useState([]); // Liste des acheteurs
    const [selectedDate, setSelectedDate] = useState(null); // Date sélectionnée
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('Tous');
    const [filteredBuyers, setFilteredBuyers] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // État pour la recherche

    // Fonction pour charger les données en fonction de la date
    const fetchBuyers = async (date) => {
        setError(null)
        setLoading(true)

        try {
            const response = await axiosClient.get(`/dashboard/event/order/salesTo/filter/customerBuyers/e/${event}`, {
                params: {
                    date, // Envoyer la date comme paramètre
                },
            });
            setBuyers(response.data.data); // Mettre à jour la liste des acheteurs
            setFilteredBuyers(response.data.data);
            // console.log(response.data.data); // Mettre à jour la liste des acheteurs
        } catch (error) {
            if (error.response && error.response.data) {

                // Récupérer les messages spécifiques de l'erreur backend
                setError(error.response.data);
            } else {
                setError({message: 'Erreur lors de la récupération des acheteurs.',});
            }
        } finally {
            setLoading(false); // Désactiver le spinner
        }
    };

    // Fonction de filtrage basée sur le statut
    const filterBuyersByStatus = (status) => {
        setSelectedStatus(status);
        let filtered = buyers;
        if (status !== 'Tous') {
            filtered = buyers.filter((buyer) => buyer.status === status);
        }
        // Appliquer également le filtre de recherche
        if (searchQuery) {
            filtered = filtered.filter((buyer) =>
                buyer.get_datas_to_order_customer.username.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        setFilteredBuyers(filtered);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // Filtrer les acheteurs en fonction du statut et de la recherche
        let filtered = buyers.filter((buyer) =>
            buyer.get_datas_to_order_customer.username.toLowerCase().includes(query.toLowerCase())
        );
        if (selectedStatus !== 'Tous') {
            filtered = filtered.filter((buyer) => buyer.status === selectedStatus);
        }
        setFilteredBuyers(filtered);
    };

    // Appeler l'API chaque fois que `selectedDate` change
    useEffect(() => {
        fetchBuyers(selectedDate);
    }, [selectedDate]);

    // Fonction pour gérer l'ouverture/fermeture de la description
    const toggleDescription = (index) => {
        setOpenDescriptions(openDescriptions.includes(index) ? [] : [index]);
    };

    if(!event) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="w-3/4 cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Aucun événement trouvé, impossible d'accéder à cette page.
                    </h4>
                    <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                    Retour aux événements
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div className='h-full'>
            {error ? (

                <div className="w-full h-full flex justify-center items-center cweej">
                    <div className="w-3/4 cydwr clbq0">
                        <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                            <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path
                                    className="text-gray-500 co1wq"
                                    d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                    fill="currentColor"
                                />
                                <path
                                    className="dark:text-gray-400 ckbo4"
                                    d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                    fill="currentColor"
                                />
                                <path
                                    className="cmpw7 cdqku"
                                    d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                        Erreur
                        </h4>
                        <div className="mb-6 text-sm">
                        {error.message}
                        </div>
                        {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                            <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                            Retour aux événements
                            </Link>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <div className='c2g1r cwnq4 cnlq0 c6r0l'>
                        <div className="c2rn6 cdiog">
                            <ul className="flex flex-wrap -m-1">
                                {['Tous', 'ACCEPTED', 'PENDING', 'REFUSED'].map((status) => (
                                <li className="m-1" key={status}>
                                    <button 
                                        className={`inline-flex items-center justify-center text-sm rounded-full border border-gray-200 ${selectedStatus === status ? 'bg-gray-900 text-white' : 'bg-white text-gray-500 dark:text-gray-400'} cc0oq cghq3 cspbm c2vpa c1k3n cxxol cdzfq c5vqk cb2br cwn3v cc0oq`}
                                        onClick={() => filterBuyersByStatus(status)}
                                    >
                                        {status === 'Tous' ? 'Tous' : STATUSES[status]?.label}{' '}
                                            <span className="cmpw7 cdqku cpts2">
                                            {buyers.filter(
                                                (buyer) =>
                                                    status === 'Tous' ||
                                                    buyer.status === status
                                            ).length}
                                                {/* {status === 'Tous' ? buyers.length : buyers.filter((b) => b.status === status).length} */}
                                            </span>
                                    </button>
                                </li>
                                ))}
                            </ul>
                        </div>
                        
                        <DateSelect onDateChange={(date) => setSelectedDate(date)} />
                    </div>

                    {/* // Afficher une section dédiée en cas d'erreur */}
                
                    {loading ? (
                        <div className='w-full flex justify-center items-center'>
                            <LoadContentSpinner />
                        </div>
                    ) : buyers.length > 0 ? (

                        <div className="bg-white c2vpa c1hly c5vqk cgd7w">
                            <header className="flex items-center justify-between flex-col sm:flex-row cx3hp cz8qb">
                                <h2 className="text-gray-800 dark:text-gray-100 mb-2 sm:mb-0 cgulq">
                                Tous les achats <span className="cmpw7 cdqku c1k3n">{buyers.length}</span>
                                </h2>
                                <div className="cm84d">
                                    <label htmlFor="action-search" className="cn8jz">
                                        Search
                                    </label>
                                    <input
                                        id="action-search"
                                        className="bg-white border-gray-100 focus:border-gray-200 focus:ring-transparent pl-9 c2vpa caqf9 ct9oo"
                                        type="search"
                                        placeholder="Rechercher un acheteur"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                    <button className="c29dn cqdkw cini7 cqogy" type="button" aria-label="Search">
                                        <svg
                                        className="ml-3 mr-2 cba8l c4it8 cmpw7 cdqku cbm9w coqgc"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                        </svg>
                                    </button>
                                </div>
                            </header>
                            <div>
                                {/* Table */}
                                <div className="cocyr">
                                    <table className="ce8qq c0zkc ca8s8 cn9pt co0ms c6btv">
                                        {/* Table header */}
                                        <thead className="text-gray-500 dark:text-gray-400 cghq3 cib75 cbv37 cgk1f c0ef0 cr4kg c1iho">
                                        <tr>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">##</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Date d'achat</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Acheteur</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Prix</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq c2hoo">Statut</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <div className="cgulq">Quantité</div>
                                            </th>
                                            <th className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                <span className="cn8jz">Menu</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        {/* Table body */}
                                        <tbody className="text-sm">
                                        {/* Row */}
                                        {filteredBuyers.map((buyer, index) => (
                                            <React.Fragment key={index}>

                                                <tr>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!buyer.transaction_id ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="flex items-center text-gray-800">
                                                                <div className="c740r c1k3n">#{buyer.transaction_id.slice(-6)}</div>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!buyer.date_payment ? (
                                                            <>...</>
                                                        ) : (
                                                            <div><FormattedDate date={buyer.date_payment} /> </div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!buyer.get_datas_to_order_customer.username ? (
                                                            <>...</>
                                                        ) : (
                                                            <Link to={`/dashboard/achats/tickets/clients/profil/p/${buyer.get_datas_to_order_customer.id}`}>

                                                                <div className="text-gray-800 dark:text-gray-100 c1k3n">
                                                                {buyer.get_datas_to_order_customer.username}
                                                                </div>
                                                            </Link>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!buyer.total_price ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="cdjj4 c1k3n c2hoo">{formatPrice(buyer.total_price)}</div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!buyer.status ? (
                                                            <>...</>
                                                        ) : (
                                                            buyer.status === 'ACCEPTED' ? (

                                                                <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n ch4gv cdw1w">
                                                                    {buyer.status}
                                                                </div>
                                                            ) : buyer.status === 'REFUSED' ? (
                                                                
                                                                <div className='inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n ch4gv cdw1w'>
                                                                    {buyer.status}
                                                                </div>
                                                                
                                                            ) : (
                                                                <div className="inline-flex text-xs rounded-full cfts0 c5px7 cydwr c1k3n ch4gv cdw1w">
                                                                    {buyer.status}
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 c9hxi c72q5">
                                                        {!buyer.quantity ? (
                                                            <>...</>
                                                        ) : (
                                                            <div className="cydwr">{formatNumber(buyer.quantity)}</div>
                                                        )}
                                                    </td>
                                                    <td className="cq84g cyjcc cgn91 cn8zk c9hxi c72q5">
                                                        <div className="flex items-center">
                                                            <button
                                                            className="bg-white border-2 border-gray-50 rounded-full text-gray-900 font-bold cxxol c5vqk c3e4j cg12x cmpw7 cdqku c98dn"
                                                            aria-expanded={openDescriptions.includes(index) ? "true" : "false"}
                                                            onClick={() => toggleDescription(index)}
                                                            >
                                                            <span className="cn8jz">Menu</span>
                                                            <svg className="cbm9w cue4z cmwfi" viewBox="0 0 32 32">
                                                                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                                                            </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {openDescriptions.includes(index) && (
                                                    <tr id="description-01">
                                                        <td colSpan={10} className="cyjcc cgn91 c9hxi c72q5">
                                                        <div className="bg-sidebar-linkGroup rounded-md animate flex items-center flex-wrap -m-1 dark:text-gray-400 coulr c1f2y c9j8s">
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                {!buyer.pivot_to_order.findthislabel.title ? (
                                                                    <>...</>
                                                                ) : (
                                                                    buyer.pivot_to_order.findthislabel.title
                                                                )}
                                                                </h3>
                                                                <div>Type de Ticket</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">·</div>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                {!buyer.payment_method ? (
                                                                    <>...</>
                                                                ) : (
                                                                    buyer.payment_method
                                                                )}
                                                                </h3>
                                                                <div>Payé par</div>
                                                            </div>
                                                            <div class="co1wq cdqku mr-4">·</div>
                                                            <div className="text-sm mr-4">
                                                                <h3 className="text-gray-800 font-semibold dark:text-gray-100 c1k3n">
                                                                {!buyer.get_datas_to_order_customer.adresse ? (
                                                                    <>...</>
                                                                ) : (
                                                                    buyer.get_datas_to_order_customer.adresse
                                                                )}
                                                                </h3>
                                                                <div>Adresse</div>
                                                            </div>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full flex justify-center items-center cweej">
                            <div className="w-3/4 cydwr clbq0">
                                <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                    <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                        <path
                                            className="text-gray-500 co1wq"
                                            d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="dark:text-gray-400 ckbo4"
                                            d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                            fill="currentColor"
                                        />
                                        <path
                                            className="cmpw7 cdqku"
                                            d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                            fill="currentColor"
                                        />
                                    </svg>

                                </div>
                                <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                Aucune données trouvées.
                                </h4>
                                <div className="mb-6 text-sm">
                                {'Aucune vente trouvée pour cette période. Votre événement peut ne pas être disponible.'}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

        </div>
    )
}
