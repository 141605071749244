import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axiosClient from '../api/axiosClient';
import LoadContentSpinner from '../Utils/LoadContentSpinner';
import { useError } from '../contexts/ErrorContext';

export default function DashboardConfigTaskModal({ isTaskModalOpen, closeModal, onAllTasksCompleted  }) {
    
    const { handleError } = useError();
    const [uncompletedTasks, setUncompletedTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Appel à l’API pour récupérer les tâches de l’utilisateur
        const fetchTasks = async () => {
            setLoading(true);
            try {
                const response = await axiosClient.get('/dashboard/tasks/getConfig');
                // Vérifier si toutes les tâches sont complétées
                if (response.data.uncompletedTasks.length === 0) {
                    onAllTasksCompleted();
                }
                setUncompletedTasks(response.data.uncompletedTasks);
                setCompletedTasks(response.data.completedTasks);

            } catch (error) {
                
                handleError("Erreur lors du chargement des tâches:", error);
                console.error("Erreur lors du chargement des tâches:", error);
            } finally {
                setLoading(false);
            }
        };

        if (isTaskModalOpen) {
            fetchTasks();
        }
    }, [isTaskModalOpen, onAllTasksCompleted]);

    return (
        isTaskModalOpen && (
            <div className='inset-0 z-10 w-screen overflow-y-auto fixed bg-gray-900 bg-opacity-30 backdrop-blur-sm flex justify-center items-center' style={{
                zIndex: 100,
                top: 0,
                width: "100%",
                height: "100%",
                overflowX: "hidden",
                overflowY: "auto",
                outline: 0,
                left: 0,
                margin: 0,
            }}>

                <div
                    id="feedback-modal"
                    className="flex items-center justify-center cxe43 cnbwt cini7 cjxg0 cys4p codu7 clbq0"
                    role="dialog"
                    aria-modal="true">
                    <div className="bg-white max-w-3xl c2vpa co669 caufm cb8zv ccwri crwo8 c6btv">
                        {/* Modal header */}
                        <div className="border-gray-200 cghq3 ctv3r cx3hp c72q5">
                        <div className="flex items-center cm3rx">
                            <div className="text-gray-800 dark:text-gray-100 cgulq">
                            Lister les tâches à compléter
                            </div>
                            <button onClick={closeModal} className="c3e4j cg12x cmpw7 cdqku">
                                <div className="cn8jz">Fermer</div>
                                <svg className="cbm9w" width={16} height={16} viewBox="0 0 16 16">
                                    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                                </svg>
                            </button>
                        </div>
                        </div>
                        {/* Modal content */}
                        <div className="csusu">
                            {loading ? (
                                <LoadContentSpinner />
                            ) : (
                                <div className="cmxzb">
                                    {/* Group 1 */}
                                    <div>
                                        <h2 className="text-gray-800 dark:text-gray-100 cgulq c941w cdiog cbw8w">
                                        En attente
                                        </h2>
                                        {uncompletedTasks.length > 0 ? (
                                            <div className="cweej">
                                                {/* Task */}
                                                {uncompletedTasks.map((task, index) => (
                                                    <div key={index} className="bg-white shadow-sm border-gray-100 c2vpa c1hly c5vqk clxb7" style={{borderWidth: '1px'}}>
                                                        <div className="c2g1r cv0ns cnlq0">
                                                            {/* Left side */}
                                                            <div className="cjav5 c2rn6 cz7b0 cxg65 cbw8w">
                                                                <div className="flex items-center">
                                                                    {/* Checkbox button */}
                                                                    <div className="flex items-center">
                                                                        <span className="text-gray-800 dark:text-gray-100 text-sm c225f c1k3n c8bkw">
                                                                        {task.description}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Right side */}
                                                            <div className="flex items-center justify-end cp3jk">
                                                                <Link to={task.link} className="text-sm text-sky-500 hover:text-sky-500 ceetm c1k3n">
                                                                    Effecter -&gt;
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="text-sm c9v1h cf8dr cb8zv clbq0 cuvgf">
                                                <div className="flex cm3rx ce4zk c6btv">
                                                    <div className="flex">
                                                    <svg
                                                        className="text-violet-500 cbm9w cq1qg coqgc chfzq"
                                                        width={16}
                                                        height={16}
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
                                                    </svg>
                                                    <div>Bravo ! Toutes les configurations sont terminées.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* Group 3 */}
                                    {completedTasks.length > 0 && (
                                        <div>
                                            <h2 className="text-gray-800 dark:text-gray-100 cgulq c941w cdiog cbw8w">
                                            Déjà terminé
                                            </h2>
                                            <div className="cweej">
                                                {/* Task */}
                                                {completedTasks.map((task, index) => (
                                                    <div key={index} className="bg-white shadow-sm border-gray-100 cnyvm c2vpa c1hly c5vqk clxb7 select-none" style={{borderWidth: '1px'}}>
                                                        <div className="c2g1r cv0ns cnlq0">
                                                        {/* Left side */}
                                                        <div className="cjav5 c2rn6 cz7b0 cxg65 cbw8w">
                                                            <div className="flex items-center">
                                                            {/* Checkbox button */}
                                                            <label className="flex items-center">
                                                                <input
                                                                type="checkbox"
                                                                className="rounded-full crgcy cp59z cf3id cye3x"
                                                                defaultChecked="1"
                                                                disabled
                                                                readOnly
                                                                />
                                                                <span className="text-gray-800 dark:text-gray-100 c225f c1k3n c8bkw">
                                                                {task.description}
                                                                </span>
                                                            </label>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}
