import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import validator from 'validator';
import axiosClient from '../api/axiosClient';

export default function SignupForm() {

    const [formData, setFormData] = useState({
        label_name: '',
        full_name: '',
        number_phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false,
    });

    
    const [errors, setErrors] = useState({});
    const [validationError, setValidationError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };
    
    const validateForm = () => {
        const newErrors = {};
    
        const trimmedLabelName = formData.label_name.trim();
        if (!trimmedLabelName.trim()) {
          newErrors.label_name = "Ce champ est obligatoire";
        } else if (!validator.isLength(trimmedLabelName, { min: 3 })) {
            newErrors.label_name =
              "Veuillez saisir le nom de votre organisation correctement";
          }

        const trimmedNomComplet = formData.full_name.trim();
        if (!trimmedNomComplet) {
          newErrors.full_name = "Le nom et prénom sont requis";
        } else if (!validator.isLength(trimmedNomComplet, { min: 6 })) {
          newErrors.full_name =
            "Veuillez saisir votre nom et prénom";
        } else if (!/^[a-zA-Z\sÀ-ÿ'-]+$/.test(trimmedNomComplet)) {
          newErrors.full_name =
            "Le nom et prénom ne doivent contenir que des lettres";
        }
    
        const trimmedEmail = formData.email.trim();
        if (!validator.isEmail(trimmedEmail)) {
          newErrors.email = "L'adresse e-mail n'est pas valide";
        } else if (!trimmedEmail) {
          newErrors.email = "L'e-mail est requis";
        }

        const trimmedNumero = formData.number_phone.trim();
        if (!trimmedNumero) {
          newErrors.number_phone = "Le numéro de téléphone est requis";
        }
        
        const trimmedPassword = formData.password.trim();
        const trimmedConfirmPassword = formData.confirmPassword.trim();
        const minLength = 8;
        if (!trimmedPassword) {
            newErrors.password = "Le mot de passe est requis";
        } else if (trimmedPassword.length < minLength) {
          newErrors.password = "Le mot de passe doit contenir au moins 8 caractères";
        }

        const hasUpperCase = /[A-Z]/.test(formData.password);
        if (!hasUpperCase) {
            newErrors.password = 'Le mot de passe doit contenir au moins une lettre majuscule.';
        }
    
        if (!formData.acceptTerms) {
          newErrors.acceptTerms = "Vous devez accepter les conditions d'utilisation";
        }
        
        if (!trimmedConfirmPassword) {
            newErrors.confirmPassword = "Veuillez confirmer votre mot de passe";
        }
        
    
        return newErrors;
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Réinitialiser l'erreur de validation à chaque soumission
        setValidationError('');
        // Vérifier les règles de conformité des mots de passe
        if (formData.password !== formData.confirmPassword) {
            setValidationError('Les mots de passe ne correspondent pas.');
            return
        }

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length === 0) {
            // Soumission des données ou logique d'inscription
            setLoading(true); // Activer le chargement

            axiosClient.post('/request/user/organizer/registration', formData)
            .then(
                ({data}) => {
                    
                    if (data.CanConfirmeAccount) {
                        // Rediriger l'utilisateur vers la page de vérification du code
                        navigate('/verify-auth-code', { 
                            state: { email: formData.email, action: 'account_verification' } // Passer l'email à la page suivante
                        });
                    } else {
                        setValidationError("Une erreur est survenue, veuillez réessayer.");
                        setTimeout(() => setValidationError(""), 7000);
                    }
                    setLoading(false);

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez vérifier vos informations puis réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        setValidationError(response.data.errors);
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Une erreur est survenue, veuillez réessayer ulterieurement");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
        } 

        // Mettre à jour les erreurs après la validation
        setErrors(validationErrors);
    };

    const isSubmitDisabled = () => {
        return loading;
    };

    return (
        <>
            <form className="grid grid-cols-1 gap-6">
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5'>
                    <label htmlFor='label_name' className="block">
                        <span  className="text-neutral-800 dark:text-neutral-200 text-sm">
                            Nom de l’organisation/Entreprise <span className="text-red-600">*</span>
                        </span>
                        <input
                            type="text"
                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                            placeholder="Nom de votre entreprise"
                            id="label_name"
                            value={formData.label_name}
                            name='label_name'
                            onChange={handleChange}
                            required
                        />
                        {errors.label_name && (
                            <small className="text-red-600 mt-2">
                                {errors.label_name}
                            </small>
                        )}
                    </label>
                    <label htmlFor='full_name' className="block">
                        <span  className="text-neutral-800 dark:text-neutral-200 text-sm">
                            Nom et Prénom <span className="text-red-600">*</span>
                        </span>
                        <input
                            type="text"
                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                            placeholder="(ex): John Doe ALLA"
                            id="full_name"
                            value={formData.full_name}
                            name='full_name'
                            onChange={handleChange}
                            required
                        />
                        {errors.full_name && (
                            <small className="text-red-600 mt-2">
                                {errors.full_name}
                            </small>
                        )}
                    </label>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5'>
                    <label htmlFor='number_phone' className="block">
                        <span  className="text-neutral-800 dark:text-neutral-200 text-sm">
                            Numéro de téléphone <span className="text-red-600">*</span>
                        </span>
                        <input
                            type="text"
                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                            placeholder="Ex: +225 0102025400"
                            id="number_phone"
                            value={formData.number_phone}
                            name='number_phone'
                            onChange={handleChange}
                            required
                        />
                        {errors.number_phone && (
                            <small className="text-red-600 mt-2">
                                {errors.number_phone}
                            </small>
                        )}
                    </label>

                    <label htmlFor='email' className="block">
                        <span  className="text-neutral-800 dark:text-neutral-200 text-sm">
                            Adresse e-mail <span className="text-red-600">*</span>
                        </span>
                        <input
                            type="email"
                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                            placeholder="example@example.com"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            name='email'
                            required
                        />
                        {errors.email && (
                            <small className="text-red-600 mt-2">
                                {errors.email}
                            </small>
                        )}
                    </label>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5'>

                    <label className="block" htmlFor='password'>
                        <span className="text-neutral-800 dark:text-neutral-200 text-sm">
                            Mot de passe <span className="text-red-600">*</span>
                        </span>
                        <input
                            id="password"
                            value={formData.password}
                            name='password'
                            onChange={handleChange}
                            type="password"
                            required
                            placeholder="Entrez votre mot de passe"
                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                        />
                        {errors.password && (
                            <small className="text-red-600 mt-2">
                                {errors.password}
                            </small>
                        )}
                    </label>
                    <label className="block" htmlFor='confirmPassword'>
                        <span className="text-neutral-800 dark:text-neutral-200 text-sm">
                            Confirmer le mot de passe <span className="text-red-600">*</span>
                        </span>
                        <input
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            name='confirmPassword'
                            type="password"
                            required
                            placeholder="Confirmer votre mot de passe"
                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                        />
                        {errors.confirmPassword && (
                            <small className="text-red-600 mt-2">
                                {errors.confirmPassword}
                            </small>
                        )}
                    </label>
                </div>
                <div className="flex text-sm sm:text-base mt-4">
                    <input
                        onChange={handleChange}
                        checked={formData.acceptTerms}
                        id="checkboxConditions"
                        name="acceptTerms"
                        type="checkbox"
                        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                        required
                    />
                    <label htmlFor="checkboxConditions" className="ml-3.5 flex flex-col flex-1 justify-center">
                        <span className="text-neutral-900 dark:text-neutral-100 text-sm">En vous inscrivant vous acceptez les <Link to="/Events-usedFolder/conditions-generales-utilisations" style={{color: 'rgb(0, 0, 217)'}}>Conditions d'Utilisations</Link> y compris la <Link to="/Events-usedFolder/politique-confidentielle" style={{color: 'rgb(0, 0, 217)'}}>Politique de Confidentialité</Link> et règles enoncées dans la <Link to="/charte-des-organisateurs-evenementiels/lens-69fce9a4f1b6" style={{color: 'rgb(0, 0, 217)'}}>Charte des organisateurs</Link>.</span>
                    </label>
                </div>
                {errors.acceptTerms && (
                    <small className="text-red-600 mt-2">
                        {errors.acceptTerms}
                    </small>
                )}
                <div className='inline-flex items-center justify-center flex-col'>
                    {validationError && (
                        <div
                            className="px-2.5 py-1 p-3 mt-3 flex items-center justify-center nc-Badge rounded-full font-medium text-xs text-red-800 bg-red-100"
                            style={{width: "fit-content"}}
                        >
                            <span>
                                <svg
                                viewBox="0 0 24 24"
                                width={20}
                                height={20}
                                stroke="currentColor"
                                strokeWidth={2}
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="me-2"
                                >
                                <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                                <line x1={15} y1={9} x2={9} y2={15} />
                                <line x1={9} y1={9} x2={15} y2={15} />
                                </svg>
                            </span>
                            <span className="text-sm ml-3 mr-3">{validationError}</span>
                        </div>
                    )}
                    <button
                        className="nc-Button inline-flex items-center justify-center mt-6 relative h-auto rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none dark:focus:ring-offset-0"
                        onClick={handleSubmit} disabled={isSubmitDisabled()} style={{width: 'fit-content'}}>
                        {loading ? (
                                <>
                                    <svg
                                        className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx={12}
                                            cy={12}
                                            r={10}
                                            stroke="currentColor"
                                            strokeWidth={3}
                                        />
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                    Un instant...
                                </>
                            ) : (

                            <>Créer un compte</>
                        )}
                    </button>
                </div>
            </form>
        </>
    )
}
