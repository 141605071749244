import React, { useState } from 'react'
import axiosClient from '../api/axiosClient';

export default function ModalConfirmDeletedCardPayment({ isModalDeleteCardOpen, closeModal, card, onSuccess }) {
    
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState('');

    // Fonction pour supprimer la carte
    const handleDeleteCard = (e) => {
        e.preventDefault();

        setLoading(true);
        // Logique pour sauvegarder les modifications (par exemple, appeler une API avec les nouvelles données)
        axiosClient.post('/request/user/manageAccount/payement/card/delete/d' + card.id)
            .then(
                ({data}) => {
                    onSuccess(data.message);
                    setLoading(false);
                    closeModal();

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setValidationError("Erreur ! Veuillez réessayer s'il vous plait !");
                    }else if (response.status === 500) {
                        // Erreur générale
                        setValidationError(response.data.message);
                    }
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                } else {
                    setValidationError("Erreur lors de lors de la suppression de la carte.");
                    setLoading(false);
                    setTimeout(() => setValidationError(""), 7000);
                }
            })
    };

    const isSubmitDisabled = () => {
        return loading;
    };
    
    return (
        isModalDeleteCardOpen && (
            <div className='inset-0 z-10 w-screen overflow-y-auto fixed bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center' style={{
                zIndex: 100,
                top: 0,
                width: "100%",
                height: "100%",
                overflowX: "hidden",
                overflowY: "auto",
                outline: 0,
                left: 0,
                margin: 0,
            }}>
                <div
                    className="flex items-center justify-center cxe43 cnbwt cini7 cjxg0 cys4p codu7 clbq0"
                    role="dialog"
                    aria-modal="true">
                    {validationError && (
                        <div className="text-sm fixed mt-4 top-0 z-10 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                            <div className="flex cm3rx ce4zk c6btv">
                                <div className="flex">
                                <svg
                                    className="cbm9w czr3n cq1qg coqgc chfzq"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                                </svg>
                                <div>{validationError}</div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="bg-white rounded-lg max-w-96 flex cm4ey csusu">
                        {/* Icon */}
                        <div className="rounded-full flex items-center justify-center dark:bg-gray-700 cyhlg coqgc cr0m4 c59cs">
                            <svg
                            className="cbm9w czr3n coqgc"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            >
                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                            </svg>
                        </div>
                        {/* Content */}
                        <div>
                            {/* Modal header */}
                            <div className="c6f83">
                            <div className="text-gray-800 dark:text-gray-100 cgulq c7x0x">
                            Supprimer cette carte ? 
                            </div>
                            </div>
                            {/* Modal content */}
                            <div className="text-sm ckdp3">
                            <div className="cweej">
                                <p>
                                Êtes-vous sûr de vouloir supprimer cette carte ? Cette action est irréversible.
                                </p>
                            </div>
                            </div>
                            {/* Modal footer */}
                            <div className="flex flex-wrap justify-end ch3kz">
                            <button onClick={closeModal} className="border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc cnf4p">
                            Annuler
                            </button>
                            <button onClick={handleDeleteCard} disabled={isSubmitDisabled()} className="text-white c8ham bg-red-500 cpcyu cnf4p">
                                {loading ? (
                                    <>
                                        <svg
                                            className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx={12}
                                                cy={12}
                                                r={10}
                                                stroke="currentColor"
                                                strokeWidth={3}
                                            />
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            />
                                        </svg>
                                        Un instant...
                                    </>
                                    ) : (
                                    <>Oui, Supprimer</>
                                )}
                            </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    )
}
