import React from 'react';
import { useError } from '../contexts/ErrorContext';

export default function ErrorDisplay() {
    const { error } = useError();

    if (!error) return null;

    return (
        <div style={{
            position: 'fixed',
            top: '10px',
            right: '10px',
            backgroundColor: 'rgb(255 209 209/1)',
            color: '#842029',
            padding: '0.5rem 1rem',
            marginLeft: '0.5rem',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            borderRadius: '5px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
        }}>
            {error}
        </div>
    );
}
