import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Tooltip from '../../../components/Dashboard/Tooltip'
import axiosClient from '../../../components/api/axiosClient';
import LoadContentSpinner from '../../../components/Utils/LoadContentSpinner';
import { formatPrice } from '../../../components/Utils/UtilsPricesFormat';
import { formatNumber, formatPerformNumber } from '../../../components/Utils/UtilsNumbersFormat';

export default function StatistiqueOrders() {

    const [loading, setLoading] = useState(true); // État pour le chargement
    const [salesData, setSalesData] = useState(null); // État pour les événements
    const [error, setError] = useState(null); // État pour gérer les erreurs

    const fetchSalesStatistics = async () => {
        // Appel API pour récupérer les événements en cours de validation
        setLoading(true);
        setError(null);
        axiosClient.get('/dashboard/global/organizer/sales-statistics')
            .then(response => {
                setSalesData(response.data.data); // Mettez à jour la liste des événements
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    // Récupérer les messages spécifiques de l'erreur backend
                    setError(error.response.data);
                } else {
                    setError({message: 'Impossible de récupérer les statistiques des ventes.',});
                }
                setLoading(false); // Arrêtez le chargement même en cas d'erreur
            });
    };

    // Utilisez useEffect pour appeler fetchSalesStatistics au montage du composant
    useEffect(() => {
        fetchSalesStatistics();
    }, []);

    if (loading) {
        return <LoadContentSpinner />;
    }

    // Afficher une section dédiée en cas d'erreur
    if (error) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="w-3/4 cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Erreur de récupération
                    </h4>
                    <div className="mb-6 text-sm">
                    {error.message}
                    </div>
                    {error.errorCode === 'EVENT_ACCESS_DENIED' && (
                        <Link to={'/dashboard/events/registration'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                        Publier mon événement
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">Statistiques des Ventes</h1>
                </div>
                <div className="grid sm:grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    
                    <button onClick={fetchSalesStatistics} className="bg-white border w-fit border-gray-200 py-2 px-3 cc0oq cghq3 cspbm c2vpa cb8zv c5vqk coqgc c8bkw">
                        <svg className="cmpw7 cdqku cbm9w" width={16} height={16} viewBox="0 0 16 16">
                        <path d="M4.3 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.1.4 5.7.4 2.9 3.1L.7.9 0 7.3l6.4-.7-2.1-2.1zM15.6 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2 1.8 0 3.6-.7 4.9-2l2.2 2.2.8-6.4z" />
                        </svg>
                    </button>

                    {/* Add view button */}
                    <Link to={'/dashboard/events/registration'} className="btn bg-primary-600 hover:bg-primary-700 text-white dark:bg-primary-600 dark:text-white dark:hover:bg-primary-700">
                        <svg className="fill-current shrink-0 xs:hidden" width="16" height="16" viewBox="0 0 16 16">
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span className="max-xs:sr-only ml-2">Ajouter un nouvel événement</span>
                    </Link>                
                </div>
            </div>

            <div className="grid grid-cols-12 gap-6">
                
                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
                    <div className="px-5 pt-5">
                        <header className="flex items-start mb-2">
                            <div className="text-sm text-gray-500 dark:text-gray-500 mb-1">Total des revenus générés</div>
                            <Tooltip className="ml-2" size="lg" position={'bottom'}>
                                <div className="text-sm">Le revenu généré correspond au montant total des ventes des tickets avant toute déduction.</div>
                            </Tooltip>
                        </header>
                        <div className="flex items-start pb-4">
                            <div className="text-xl font-semibold text-gray-800 dark:text-gray-100 mr-2">{formatPrice(salesData.grossRevenue)}</div>
                            {/* <div className="text-sm font-medium text-green-700 px-1.5 bg-green-500/20 rounded-full">+49%</div> */}
                        </div>
                </div>
                </div>

                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
                    <div className="px-5 pt-5">
                        <div className="text-sm text-gray-500 dark:text-gray-500 mb-1">Nombre total d'événements</div>
                        <div className="flex items-start pb-4">
                            <div className="text-xl font-semibold text-gray-800 dark:text-gray-100 mr-2">{formatNumber(salesData.total_events)}</div>
                            {/* <div className="text-sm font-medium text-red-700 px-1.5 bg-red-500/20 rounded-full">-14%</div> */}
                        </div>
                    </div>
              
                </div>

                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
                    <div className="px-5 pt-5">
                        <div className="text-sm text-gray-500 dark:text-gray-500 mb-1">Total des tickets vendus</div>
                        <div className="flex items-start pb-4">
                            <div className="text-xl font-semibold text-gray-800 dark:text-gray-100 mr-2">{formatNumber(salesData.totalTicketsSold)}</div>
                            {/* <div className="text-sm font-medium text-green-700 px-1.5 bg-green-500/20 rounded-full">+49%</div> */}
                        </div>
                    </div>
                </div>
                <div className="col-span-full xl:col-span-8 bg-white h-fit dark:bg-gray-800 shadow-sm rounded-xl">
                    <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60">
                        <h2 className="font-semibold text-gray-800 dark:text-gray-100">Liste des 10 événements récents</h2>
                    </header>
                    <div className="p-3">
                        {/* Table */}
                        {salesData.eventsList.length > 0 ? (

                            <div className="overflow-x-auto">
                                <table className="table-auto w-full dark:text-gray-300">
                                    {/* Table header */}
                                    <thead className="text-xs uppercase text-gray-400 dark:text-gray-500 bg-gray-50 dark:bg-gray-700 dark:bg-opacity-50 rounded-sm">
                                        <tr>
                                            <th className="p-2">
                                            <div className="font-semibold text-left">A Propos</div>
                                            </th>
                                            <th className="p-2">
                                            <div className="font-semibold text-center w-fit">Total en vente</div>
                                            </th>
                                            <th className="p-2" style={{ textAlign: '-webkit-center' }}>
                                            <div className="font-semibold text-center w-fit">Vendus</div>
                                            </th>
                                            <th className="p-2">
                                            <div className="font-semibold text-center w-fit">Restants</div>
                                            </th>
                                            <th className="p-2">
                                            <div className="font-semibold text-center">statut</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    {/* Table body */}
                                    <tbody className="text-sm font-medium divide-y divide-gray-100 dark:divide-gray-700/60">
                                    {/* Row */}
                                    {salesData.eventsList.map(event => (
                                        <tr key={event.id}>
                                            <td className="p-2">
                                                <div className="flex items-center whitespace-normal truncate w-60">
                                                    <img 
                                                        src={`data:image/jpeg;base64,${event.event_banner}`}
                                                        alt={event.name} className="w-16 rounded-lg shrink-0 mr-2 sm:mr-3"
                                                    />
                                                    <div className="text-gray-800 dark:text-gray-100">{event.event_name}</div>
                                                </div>
                                            </td>
                                            <td className="p-2">
                                            <div className="text-center">{formatNumber(event.total_tickets)}</div>
                                            </td>
                                            <td className="p-2">
                                            <div className="text-center text-green-500">{formatPrice(event.tickets_sold)}</div>
                                            </td>
                                            <td className="p-2">
                                            <div className="text-center">{formatNumber(event.tickets_remaining)}</div>
                                            </td>
                                            <td className="p-2">
                                                {event.annule ? (
                                                    <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                                        Annulé
                                                    </div>
                                                ) : (

                                                    event.status === 'Disponible' ? (
                                                        <div className="inline-flex text-xs rounded-full c1lu4 c19il cydwr c1k3n cdw1w c9hxi">
                                                            {event.status}
                                                        </div>
                                                    ) : event.status === 'Disabled' ? (
                                                        <div className="inline-flex text-xs rounded-full cx6qj cz4gk cydwr c1k3n cdw1w c9hxi">
                                                            Désactivé
                                                        </div>
                                                    ) : (
                                                        <div className="inline-flex text-xs rounded-full clksd cydwr c1k3n cdw1w c9hxi">
                                                            {event.status}
                                                        </div>
                                                    )
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="w-full h-full flex justify-center items-center cweej">
                                <div className="w-3/4 cydwr clbq0">
                                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path
                                                className="text-gray-500 co1wq"
                                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="dark:text-gray-400 ckbo4"
                                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="cmpw7 cdqku"
                                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                                    Aucune Données
                                    </h4>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex bg-white h-fit c2vpa ci500 c18r2 coz6m c1hly c5vqk cetff">
                    <header className="cghq3 cbv37 ctv3r cx3hp cz8qb">
                        <h2 className="text-gray-800 dark:text-gray-100 cgulq">Ventes par type de ticket</h2>
                    </header>
                    <div className="cbw8w c9j8s">
                        {salesData.ticketsPerformance.length > 0 ? (
                            <div className="flex cetff cav8x">
                                {/* Card content */}
                                <div className="cbw8w">
                                    <ul className="flex cmpw7 cm3rx cgulq cdqku ch3kz c0ef0 c1iho c9hxi">
                                        <li>Ticket</li>
                                        <li>Ventes réalisées</li>
                                    </ul>
                                    <ul className="text-sm text-gray-800 dark:text-gray-100 c1647 cdiog c7gr8">
                                        {/* Item */}
                                        {salesData.ticketsPerformance.map(ticket => (
                                            <li key={ticket} className="cm84d c9hxi cwn3v">
                                                <div
                                                className="cxutl cw69r cjnvs absolute max-w-full cini7"
                                                aria-hidden="true"
                                                style={{ width: `${ticket.percentageSold}%` }}
                                                />
                                                <div className="flex cm3rx ch3kz cm84d">
                                                <div>{ticket.ticketType}</div>
                                                <div className="c1k3n">{formatPerformNumber(ticket.totalSold)}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full h-full flex justify-center items-center cweej">
                                <div className="w-3/4 cydwr clbq0">
                                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                            <path
                                                className="text-gray-500 co1wq"
                                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="dark:text-gray-400 ckbo4"
                                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                                fill="currentColor"
                                            />
                                            <path
                                                className="cmpw7 cdqku"
                                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <div className="mb-6 text-sm">
                                    {"Aucune Données. Pas de chiffre d'affaire !"}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}
