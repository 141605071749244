import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axiosClient from '../../api/axiosClient';

export default function DoEventActions({ event, fetchEventDetails }) {

    const [activeAction, setActiveAction] = useState(null);
    const [loading, setLoading] = useState({
        delete: false,
        modify: false,
        cancel: false,
    });
    // const [validationError, setValidationError] = useState('');
    const [isModalDeleteCardOpen, setIsModalDeleteCardOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState();
    const [error, setError] = useState(null);
    const [errorSection, setErrorSection] = useState(null);
    const [reason, setReason] = useState(null);

    // Fonction pour ouvrir le modal
    const openDeleteCardModal = () => {
        setIsModalDeleteCardOpen(true);
    };

    // Fonction pour fermer le modal
    const closeDeleteCardModal = () => {
        setIsModalDeleteCardOpen(false);
    };

    // Fonction pour changer l'onglet actif
    const handleTabClick = (tab) => {
        setActiveAction(tab);
    };

    // Fonction pour gérer les actions
    const handleAction = async (action) => {

        if (!action) {
        setErrorSection("Action requise !.");
        setTimeout(() => setErrorSection(null), 5000);
        return;
        }

        // Préparer l'objet "demande" en fonction de l'action
        const demande = {};
        if (action === "cancel") {
            if (!reason) {
            setErrorSection("Une raison est nécessaire pour annuler cet événement.");
            setTimeout(() => setErrorSection(null), 5000);
            return;
            }
            demande.reason = reason; // Ajouter la raison pour l'annulation
        } else if (action === "modify") {
            if (!reason) {
            setErrorSection("Une description est nécessaire pour modifier cet événement.");
            setTimeout(() => setErrorSection(null), 5000);
            return;
            }
            demande.description = reason; // Ajouter une description pour la modification
        }
        

        // Activer le loader pour l'action en cours
        setLoading((prev) => ({ ...prev, [action]: true }));
        setError(null);
        setErrorSection(null)

        const formData = {
            action,      // Type d'action
            event_id: event, // ID de l'événement
            demande,     // Objet regroupant "reason" ou "description"
        }

        // Envoyer le fichier via l'API
        axiosClient.post('/request/user/event/do/action', formData)
            .then(
                ({data}) => {
                    setSuccessMessage(data.message);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    setTimeout(() => setSuccessMessage(""), 2000);
                    setLoading((prev) => ({ ...prev, [action]: false }));
                    if (action === 'delete') {
                        
                        setIsModalDeleteCardOpen(false)
                    }

            }).catch(err => {
                const response = err.response;
                if (response) {
                    // console.log("Erreur de l'API :", response);
                    if (response.status === 422) {
                        setErrorSection(response.data.message);
                    }else if (response.status === 500) {
                        // Erreur générale 
                        setErrorSection(response.data.message);
                    }
                    setLoading((prev) => ({ ...prev, [action]: false }));
                    setTimeout(() => setErrorSection(""), 7000);
                    if (action === 'delete') {
                        
                        setIsModalDeleteCardOpen(false)
                    }
                } else {
                    setErrorSection("Une erreur est survenue lors de l'exécution de l'action.");
                    setLoading((prev) => ({ ...prev, [action]: false }));
                    setTimeout(() => setErrorSection(""), 7000);
                    if (action === 'delete') {
                        
                        setIsModalDeleteCardOpen(false)
                    }
                }
            })
    };

    if(!event) {
        return (
            <div className="w-full h-full flex justify-center items-center cweej">
                <div className="cydwr clbq0">
                    <div className="inline-flex items-center justify-center rounded-full c1qph c3x05 c7rlo ce36h cbcgo cdiog c3nk1 crzrx">
                        <svg className="cbm9w ctt6r cye3x" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path
                                className="text-gray-500 co1wq"
                                d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                fill="currentColor"
                            />
                            <path
                                className="dark:text-gray-400 ckbo4"
                                d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                fill="currentColor"
                            />
                            <path
                                className="cmpw7 cdqku"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <h4 className="text-gray-800 dark:text-gray-100 font-bold text-lg c6f83">
                    Aucun événement trouvé, impossible d'accéder à cette page.
                    </h4>
                    <Link to={'/dashboard/events/show-list'} className="btn bg-white border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc c2vpa">
                    Retour aux événements
                    </Link>
                </div>
            </div>
        )
    }


    const isSubmitDisabled = (action) => {
        if (action === "cancel" && !reason) return true; // Vérifier le motif pour l'annulation
        return loading.delete || loading.modify || loading.cancel; // Désactiver si une action est en cours
      };

    return (
        <div>
            <div className="flex czdd1 c20xe cfjy9 cz9ag cetff cbw8w translate-x-0">
                
                {/* Header sticky top-16 */}
                <div className="relative">
                    <div className="dark:text-gray-400 pb-0 coulr cgk1f c1f2y c9j8s">
                        <div className="text-sm cxg65">
                            <div className="text-gray-800 dark:text-gray-100 c1k3n cu6vl">
                            Actions Sensibles sur l'Événement
                            </div>
                            <div>
                            Les actions telles que la modification, l'annulation ou la suppression de l'événement nécessitent une validation. Cela garantit la sécurité et l'intégrité de vos événements.
                            </div>
                        </div>
                    </div>
                    <div className="pb-4 items-center border-gray-200 c0sx7 cghq3 cn67g c5x21 cpawk cm3rx c7pl5 c8rmw ctv3r cnbwt c7uj9 clbq0">
                    {/* Buttons on the left side */}
                    <div className="flex">
                        <button onClick={openDeleteCardModal} title="Supprimer l'événement" className="bg-white text-red-500 border flex items-center font-semibold border-gray-200 cc0oq cghq3 cspbm c2vpa cb8zv c5vqk coqgc c1ga4 c8bkw">
                            <svg
                                className="cmpw7 cbm9w"
                                width={14}
                                height={14}
                                viewBox="0 0 16 16"
                            >
                                <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                            </svg>
                        </button>

                        <button title="Modifier l'événement" onClick={() => setActiveAction('modify')} className="bg-white border flex items-center font-semibold border-gray-200 cc0oq cghq3 cspbm c2vpa cb8zv c5vqk coqgc c1ga4 c8bkw">
                            <svg className="mr-2 cmpw7 cdqku cbm9w" width={14} height={14} viewBox="0 0 16 16">
                                <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                            </svg>
                            <span className='text-xs'>Modifier</span>
                        </button>
                        <button title="Annuler l'événement" onClick={() => setActiveAction('cancel')} className="bg-white border flex items-center font-semibold border-gray-200 cc0oq cghq3 cspbm c2vpa cb8zv c5vqk coqgc c1ga4 c8bkw">
                            <svg
                                className="mr-2 cmpw7 cdqku cbm9w"
                                width={14}
                                height={14}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                            </svg>
                            <span className='text-xs'>Annuler</span>
                        </button>
                    </div>
                    </div>
                </div>
                {/* Body */}
                {errorSection && (
                    <div className="text-sm mt-4 cf8dr cb8zv cvkq6 clbq0 cuvgf">
                        <div className="flex cm3rx ce4zk c6btv">
                            <div className="flex">
                            <svg
                                className="cbm9w czr3n cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
                            </svg>
                            <div>{errorSection}</div>
                            </div>
                        </div>
                    </div>
                )}
                {successMessage && (
                    <div className="text-sm mt-4 cf8dr cje53 cb8zv clbq0 cuvgf">
                        <div className="flex cm3rx ce4zk c6btv">
                            <div className="flex">
                            <svg
                                className="c612e cbm9w cq1qg coqgc chfzq"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                            </svg>
                            <div>
                            {successMessage}
                            </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="cnbwt c7uj9 cbw8w clbq0 cz8qb">
                    {/* Mail subject */}
                    {activeAction === 'cancel' ? (
                        <header className="">
                            <h1 className="text-gray-800 dark:text-gray-100 font-bold ce5fk c2rn6 clctu cu6vl">
                            Motif d'annulation
                            </h1>
                            <span className='text-sm'>
                            Pour annuler un événement, un motif est requis. Assurez-vous de fournir des explications claires afin que votre demande soit traitée rapidement.
                            </span>
                        </header>
                    ) : activeAction === 'modify' && (
                        <header className="">
                            <h1 className="text-gray-800 dark:text-gray-100 font-bold ce5fk c2rn6 clctu cu6vl">
                                Description
                            </h1>
                            <span className='text-sm'>
                                Si vous souhaitez modifier les informations de votre événement, veuillez soumettre une demande en précisant les changements souhaités. Un administrateur les examinera et vous tiendra informé.
                            </span>
                        </header>
                    )}
                    {/* Messages box */}
                    <div className="ce8qq c2vpa c6sh9 c1hly co0ms">
                        {/* Mail */}
                        {activeAction && (

                            <div className="cwlwv">
                                <div className="cbw8w">
                                    <textarea
                                    id="comment"
                                    className="cn9r8 c071z c6btv"
                                    rows={4}
                                    placeholder={
                                        activeAction === "cancel"
                                            ? "Entrez la raison de l'annulation"
                                            : activeAction === "modify" && "Écrivez les informations qui doivent être modifier ici"
                                        }
                                    onChange={(e) => setReason(e.target.value)}
                                    />
                                </div>
                                <div className="text-right">
                                    {activeAction === 'cancel' ? (
                                        <button
                                            onClick={() => handleAction("cancel")}
                                            disabled={isSubmitDisabled()}
                                            className="bg-gray-900 mt-4 cdj8c cg0jr ch8z9 cq84g cilvw cyn7a cnf4p"
                                        >
                                            {loading.cancel ? (
                                            <>
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx={12}
                                                        cy={12}
                                                        r={10}
                                                        stroke="currentColor"
                                                        strokeWidth={3}
                                                    />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                </svg>
                                                En cours...
                                            </>
                                            ) : (
                                                <>Demander une annulation</>
                                            )}
                                        </button>
                                    ) : activeAction === 'modify' && (
                                        <button
                                            onClick={() => handleAction("modify")}
                                            disabled={isSubmitDisabled()}
                                            className="bg-gray-900 mt-4 cdj8c cg0jr ch8z9 cq84g cilvw cyn7a cnf4p"
                                        >
                                            {loading.modify ? (
                                            <>
                                                <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx={12}
                                                        cy={12}
                                                        r={10}
                                                        stroke="currentColor"
                                                        strokeWidth={3}
                                                    />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                </svg>
                                                Un instant...
                                            </>
                                            ) : (
                                                <>Demander des modifications</>
                                            )}
                                        </button>
                                    )}
                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* Modal suppression */}
            {isModalDeleteCardOpen && (
                <div className='inset-0 z-10 w-screen overflow-y-hidden fixed bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center' style={{
                    zIndex: 100,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    overflowX: "hidden",
                    overflowY: "hidden",
                    outline: 0,
                    left: 0,
                    margin: 0,
                }}>
                    <div
                        className="flex items-center justify-center cxe43 cnbwt cini7 cjxg0 cys4p codu7 clbq0"
                        role="dialog"
                        aria-modal="true">
                        <div className="bg-white rounded-lg max-w-96 flex cm4ey csusu">
                            {/* Icon */}
                            <div className="rounded-full flex items-center justify-center dark:bg-gray-700 cyhlg coqgc cr0m4 c59cs">
                                <svg
                                className="cbm9w czr3n coqgc"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                >
                                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                                </svg>
                            </div>
                            {/* Content */}
                            <div>
                                {/* Modal header */}
                                <div className="c6f83">
                                <div className="text-gray-800 dark:text-gray-100 cgulq c7x0x">
                                Suppression de l'événement? 
                                </div>
                                </div>
                                {/* Modal content */}
                                <div className="text-sm ckdp3">
                                <div className="cweej">
                                    <p>
                                    La suppression d'un événement est une action irréversible. Veuillez soumettre une demande si vous êtes certain de cette décision.
                                    </p>
                                </div>
                                </div>
                                {/* Modal footer */}
                                <div className="flex flex-wrap justify-end ch3kz">
                                <button onClick={closeDeleteCardModal} className="border-gray-200 text-gray-800 cc0oq cghq3 cspbm c0zkc cnf4p">
                                Annuler
                                </button>
                                <button onClick={() => handleAction("delete")} disabled={isSubmitDisabled()} className="text-white c8ham bg-red-500 cpcyu cnf4p">
                                    {loading.delete ? (
                                        <>
                                            <svg
                                                className="animate-spin -ml-1 mr-3 h-5 w-5 ml-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx={12}
                                                    cy={12}
                                                    r={10}
                                                    stroke="currentColor"
                                                    strokeWidth={3}
                                                />
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                />
                                            </svg>
                                            Un instant...
                                        </>
                                        ) : (
                                        <>Soumettre</>
                                    )}
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </div>
    )
}
